import * as HoverCard from '@radix-ui/react-hover-card';
import { ReactElement } from 'react';

import { stringToColour } from '../../../utils/utils';

import Flex from '../../primitives/flex';
import { PulseAvatar } from '../../pulse-avatar/pulse-avatar';
// import { EventStreamHoverCard } from '../../../pages/event/components/event-stream-hover-card/event-stream-hover-card';
import { PointData } from '../../../pages/event/state-manager/store-types';
import { LiveChip } from '../../live-chip/live-chip';
import useEventStore from '../../../pages/event/state-manager/store';
import { Typo } from '../../primitives/typo/typo';

interface HoveraCardElementProps {
  cardElement: ReactElement;
  children: ReactElement;
}

function HoverCardElement({ children, cardElement }: HoveraCardElementProps) {
  return (
    <HoverCard.Root openDelay={0} closeDelay={3000}>
      <HoverCard.Trigger asChild>{children}</HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content style={{ zIndex: 10 }} side="right" sideOffset={8}>
          {cardElement}
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}

interface IProps {
  isPreviewLocation?: boolean;
  isHidden?: boolean;
  isSelected?: boolean;
  isLive?: boolean;
  onClick?: () => void;
  username: string;
  useravatar: string;
  timestamp: number;
  streamTileUrl: string;
  pulseLine: PointData;
}

export default function GenericMapMarkerEvent({
  isPreviewLocation = false,
  isHidden = false,
  onClick,
  isSelected = false,
  username,
  useravatar,
  timestamp,
  streamTileUrl,
  pulseLine,
  isLive,
}: IProps) {
  // const setFeatStream = useEventStore((state) => state.setFeatStream);

  const getMarkerOpacity = () => {
    if (isHidden) return 0;
    return isPreviewLocation ? 0.7 : 1;
  };

  const onClickMarker = () => {
    // setFeatStream(pulseLine);

    if (onClick) {
      onClick();
    }
  };

  return (
    <Flex
      css={{
        width: isSelected ? '50px' : '40px',
        height: isSelected ? '50px' : '40px',
        position: 'relative',
        alignItems: 'center',
        // transition: 'all 0.5s',
        opacity: getMarkerOpacity(),
        overflow: 'visible',
        '& .icon-hover-wrapper': {
          opacity: isSelected ? 1 : 0,
        },
      }}
      className="avatar-user"
    >
      <Flex
        className="channel-name"
        onClick={onClickMarker}
        css={{
          height: '2rem',
          // width: '20rem',
          alignItems: 'center',
          backgroundColor: '$blackTranslucentGlass',
          color: '$white',
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
          position: 'absolute',
          left: 13,
          paddingLeft: '3rem',
          paddingRight: '1.5rem',
          'box-shadow': '0 0 20px rgba(0,0,0,.4)',
        }}
      >
        <Typo css={{ whiteSpace: 'nowrap' }}>{pulseLine.title}</Typo>
      </Flex>

      <PulseAvatar imageUrl={useravatar} title={username} size={isSelected ? 3.12 : 2.5} isSelected={isSelected} />
      <Flex
        onClick={onClickMarker}
        css={{ width: 40, height: 40, position: 'absolute', backgroundColor: 'transparent', zIndex: 100 }}
      />
      {isLive && (
        <Flex css={{ position: 'absolute', bottom: '-0.2rem', right: '-0.4rem', zIndex: 2 }}>
          <LiveChip />
        </Flex>
      )}

      <Flex
        css={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          boxSizing: 'border-box',
          border: '1px solid $black200',
          backgroundColor: stringToColour(username),
          position: 'absolute',
          bottom: '-18px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
    </Flex>
  );
}
