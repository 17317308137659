import { useContext } from 'react';
import { MapRef } from 'react-map-gl';
import { VideoJsPlayer } from 'video.js';
import MapBoxGeneric from '../../../../components/third-party/map/map-generic';
import { ISquadVideoContext, RealTimePosition, SquadVideoContext } from '../../context';

interface IProps {
  onMapReady: (ref: MapRef | null) => void;
}

export function EventMap({ onMapReady }: IProps) {
  const {
    realTimeAthletePositions,
    athletePositionsOnHover,
    streamList,
    setFeaturedStream,
    featuredStream,
    isLiveSelected,
    liveStreams,
    isMapFollowLocked,
    eventInfo,
    setIsMapFollowLocked,
  } = useContext<ISquadVideoContext>(SquadVideoContext);

  const getCurrentVideoTime = (videoPlayer: VideoJsPlayer | undefined | null): number => {
    if (!videoPlayer) return 0;
    return videoPlayer?.currentTime() || 0;
  };

  const onSelectAthlete = (realTimePosition: RealTimePosition | undefined, isSelected: boolean) => {
    if (!realTimePosition || !streamList) return;

    if (isSelected) return setIsMapFollowLocked(true);

    let foundStream;
    if (isLiveSelected) {
      foundStream = liveStreams.find((stream) => stream.id === realTimePosition.id);
    } else {
      foundStream = streamList?.get(realTimePosition.id);
    }
    setFeaturedStream({
      offset_ms: isLiveSelected ? 0 : getCurrentVideoTime(realTimePosition.videoPlayerRef) * 1000,
      ...foundStream,
    });
    setIsMapFollowLocked(true);
  };

  return (
    <MapBoxGeneric
      isMapFollowLocked={isMapFollowLocked}
      setIsMapFollowLocked={setIsMapFollowLocked}
      onMapReady={onMapReady}
      realTimeAthletePositions={realTimeAthletePositions}
      athletePositionsOnHover={athletePositionsOnHover}
      featuredStream={featuredStream}
      onSelectAthlete={onSelectAthlete}
      mapCenter={[eventInfo?.longitude || 0, eventInfo?.latitude || 0]}
    />
  );
}
