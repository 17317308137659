/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react';

import { MoveHorizontalControl } from '../../../../components/move-horizontal-control/move-horizontal-control';
import Flex from '../../../../components/primitives/flex';
import Timeline, { Polyline } from '../../../../components/timeline/timeline';
import useEventStore from '../../state-manager/store';
import { useQueryTimelineData } from '../../hooks/useQueryTimelineData';
import { EventPlayControl } from '../event-play-control/event-play-control';
import { NewEventStream } from '../../state-manager/store-types';

let timelineElementRef: HTMLElement | null = null;
let isTimelineMouseUpLocked = false;

export function EventTimeline() {
  const eventInfo = useEventStore((state) => state.eventInfo);
  const currentDay = useEventStore((state) => state.currentDay);
  const setHoverTime = useEventStore((state) => state.setHoverTime);
  const pulseLinesGroupedByStream = useEventStore((state) => state.pulseLinesGroupedByStream);
  const eventStreamsLinearList = useEventStore((state) => state.eventStreamsLinearList);
  const setHoverEventStreamsLinearList = useEventStore((state) => state.setHoverEventStreamsLinearList);
  const pulseLinesGroupedByUserAndSortedByTimestamp = useEventStore(
    (state) => state.pulseLinesGroupedByUserAndSortedByTimestamp,
  );

  const intervalId = useEventStore((state) => state.intervalId);
  const setCurrentTime = useEventStore((state) => state.setCurrentTime);
  const setFeatStream = useEventStore((state) => state.setFeatStream);
  const isUserWatchingLive = useEventStore((state) => state.isUserWatchingLive);
  const currentTime = useEventStore((state) => state.currentTime);
  const setEventCurrentStreams = useEventStore((state) => state.setEventCurrentStreams);
  const setEventHoverStreams = useEventStore((state) => state.setEventHoverStreams);
  const setCurrentEventStreamsLinearList = useEventStore((state) => state.setCurrentEventStreamsLinearList);
  const getEventTimeline = useQueryTimelineData();

  function getEndOfDay(date: Date) {
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    return endOfDay;
  }

  const setCurrentSelectedTime = (time: Date | null) => {
    if (isTimelineMouseUpLocked) return;
    setCurrentTime(time);
  };

  useEffect(() => {
    if (currentDay) getEventTimeline(eventInfo?.id, currentDay);
  }, [currentDay]);

  const fillCurrentStreams = (streams: Polyline) => {
    setEventCurrentStreams(streams);
  };

  const fillHoverPreviewStreams = (streams: Polyline) => {
    setEventHoverStreams(streams);
  };

  const onFindCurrentStreamList = (streams: NewEventStream[]) => {
    const newList = streams.map((stream) => ({
      ...stream,
      stream_end_date_ms: stream.status === 'started' ? Date.now() : stream.stream_end_date_ms,
    }));
    setCurrentEventStreamsLinearList(newList);
  };

  const onFindHoverStreamList = (streams: NewEventStream[]) => {
    setHoverEventStreamsLinearList(streams);
  };

  const onHoverTimeline = (e: Date | null) => {
    setHoverTime(e);
  };

  const getTimelineElement = (timelineRef: HTMLElement) => {
    timelineElementRef = timelineRef;
  };

  const moveTimeline = (isLeft?: boolean) => {
    if (timelineElementRef) {
      const currentScrollPosition = timelineElementRef.scrollLeft;
      timelineElementRef.scrollTo({
        left: isLeft ? currentScrollPosition - 50 : currentScrollPosition + 50,
        behavior: 'smooth',
      });
    }
  };

  const onClickTimelineAvatar = (stream: NewEventStream) => {
    isTimelineMouseUpLocked = true;
    const streamStartDate = new Date(stream.stream_start_date_ms);

    setCurrentTime(streamStartDate);
    setFeatStream(stream);
    setTimeout(() => {
      isTimelineMouseUpLocked = false;
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  return (
    <Flex
      className="timeline-component"
      css={{
        padding: '0.75rem',
        gap: '$2xs',
        backgroundColor: '$blackTranslucentGlass',
        backdropFilter: 'blur(3px)',
        position: 'absolute',
        bottom: '0.8rem',
        right: '0.8rem',
        left: '0.8rem',
        borderRadius: '$lgg',
        alignItems: 'center',
        // overflow: 'hidden',
      }}
    >
      {eventInfo && currentDay && (
        <EventPlayControl />
        // <EventDatePicker dateRange={eventInfo.timeline_days} value={currentDay} onValueChange={changeCurrentDay} />
      )}

      <Flex
        className="timeline-wrapper"
        css={{
          border: '1px solid $gunMetal',
          borderRadius: '0.5rem',
          height: '3.75rem',
          width: 'calc(100% - 4.87rem - 4.5rem - 2rem)',
        }}
      >
        {currentDay && !!pulseLinesGroupedByStream.length && currentTime && (
          <Timeline
            currentTime={currentTime}
            onHover={onHoverTimeline}
            onFindCurrentTimeStreamPositions={fillCurrentStreams}
            onFindHoverPreviewStreamPositions={fillHoverPreviewStreams}
            startDate={new Date(currentDay)}
            endDate={getEndOfDay(new Date(currentDay))}
            polylinesGroupedByUser={pulseLinesGroupedByUserAndSortedByTimestamp}
            polylinesGroupedByStream={pulseLinesGroupedByStream}
            setCurrentSelectedTime={setCurrentSelectedTime}
            isLive={isUserWatchingLive()}
            onReady={getTimelineElement}
            linearStreamList={eventStreamsLinearList}
            onFindCurrentStreamList={onFindCurrentStreamList}
            onFindHoverStreamList={onFindHoverStreamList}
            onClickTimelineAvatar={onClickTimelineAvatar}
          />
        )}
      </Flex>
      <MoveHorizontalControl onClickLeft={() => moveTimeline(true)} onClickRight={() => moveTimeline(false)} />
    </Flex>
  );
}
