import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MapRef } from 'react-map-gl';
import { keyframes, styled } from '../../../stitches.config';

import { AddIcon } from '../../icons/add';
import { MyLocationFocused } from '../../icons/my-location-focused';
import { MyLocationNotFocusedIcon } from '../../icons/my-location-not-focused';
import { RemoveIcon } from '../../icons/remove';
import Flex from '../../primitives/flex';
import { TwoDimensionIcon } from '../../icons/2d-icon';
import { ThreeDimensionIcon } from '../../icons/3d-icon';
import { useIsMobile } from '../../../utils/useIsMobile';
import RotateIcon from '../../icons/rotate';
import { ButtonTooltip } from '../../primitives/button-tooltip/button-tooltip';

const Wrapper = styled(Flex, {
  width: '2.5rem',
  height: '13rem',
  position: 'absolute',
  right: 12.5,
  bottom: 10,
  zIndex: 100,
  flexDirection: 'column',
  backgroundColor: '$constantWhite',
  alignItems: 'center',
  // paddingBottom: '$nano',
  // paddingTop: '$nano',
  opacity: 0.05,
  '@hover': {
    '&:hover': {
      opacity: 1.0,
    },
  },
  justifyContent: 'space-between',
  borderRadius: '$sm',
  '.button-item': {
    cursor: 'pointer',
    width: '100%',
    minHeight: '1.5rem',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.5s',
    svg: {
      transition: 'all 0.5s',
    },
    '@hover': {
      '&:hover': {
        backgroundColor: '#1313132E',
        svg: {
          transform: 'scale(.90)',
        },
      },
    },
  },
});

const orbitAnimation = keyframes({
  from: { transform: 'rotate(360deg)' },
  to: { transform: 'rotate(0deg)' },
});

const Divider = styled(Flex, {
  width: '100%',
  height: '2px',
  backgroundColor: '#1313132E',
});

interface IProps {
  mapRef: MapRef;
  isMapFollowLocked?: boolean;
  setIsMapFollowLocked?: Dispatch<SetStateAction<boolean>>;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onChangeTo2D?: () => void;
  onChangeTo3D?: () => void;
  onLockOnUserPosition?: () => void;
  onFlyAround?: () => void;
  isRotating?: boolean;
  css?: any;
}

export function MapControls({
  mapRef,
  isMapFollowLocked,
  setIsMapFollowLocked,
  onZoomIn,
  onZoomOut,
  onChangeTo2D,
  onChangeTo3D,
  onFlyAround,
  isRotating,
  onLockOnUserPosition,
  css,
}: IProps) {
  const [is2D, setIs2D] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const lockUserPosition = () => {
    if (onLockOnUserPosition && !isMapFollowLocked) onLockOnUserPosition();
    if (setIsMapFollowLocked) setIsMapFollowLocked(true);
  };

  const goTo3D = () => {
    setIs2D(false);

    if (isMapFollowLocked) {
      mapRef.setPitch(58, { duration: 3000 });
      lockUserPosition();
    } else {
      mapRef.setPitch(58, { duration: 3000 });
    }

    if (onChangeTo3D) onChangeTo3D();
  };

  const goTo2D = () => {
    setIs2D(true);

    if (isMapFollowLocked) {
      mapRef.setPitch(0, { duration: 3000 });
      lockUserPosition();
    } else {
      mapRef.setPitch(0, { duration: 3000 });
    }

    if (onChangeTo2D) onChangeTo2D();
  };

  const zoomIn = () => {
    mapRef.zoomIn();
    if (onZoomIn) onZoomIn();
  };

  const flyAround = () => {
    if (onFlyAround) onFlyAround();
  };

  const zoomOut = () => {
    mapRef.zoomOut();
    if (onZoomOut) onZoomOut();
  };

  const onMount = () => {
    if (isMobile) goTo2D();
    if (onLockOnUserPosition && !isMapFollowLocked) onLockOnUserPosition();
  };
  useEffect(onMount, []);

  const toggleIsFollowLocked = () => {
    if (onLockOnUserPosition && !isMapFollowLocked) onLockOnUserPosition();
    if (setIsMapFollowLocked) setIsMapFollowLocked(!isMapFollowLocked);
  };

  return (
    <Wrapper css={{ ...css }}>
      <ButtonTooltip content="Zoom in" side="left">
        <Flex as="button" css={{ touchAction: 'manipulation' }} className="button-item" onClick={zoomIn}>
          <AddIcon />
        </Flex>
      </ButtonTooltip>

      <Divider />

      <ButtonTooltip content="Zoom out" side="left">
        <Flex as="button" className="button-item" css={{ touchAction: 'manipulation' }} onClick={zoomOut}>
          <RemoveIcon />
        </Flex>
      </ButtonTooltip>

      <Divider />

      <ButtonTooltip content={is2D ? 'Toggle 3D view' : 'Toggle 2D view'} side="left">
        <Flex
          as="button"
          className="button-item"
          onClick={!is2D ? goTo2D : goTo3D}
          title={!is2D ? 'View in 2D' : 'View in 3D'}
          css={{ touchAction: 'manipulation' }}
        >
          {is2D ? <TwoDimensionIcon /> : <ThreeDimensionIcon />}
        </Flex>
      </ButtonTooltip>

      <Divider />

      <ButtonTooltip content={isRotating ? 'Stop orbit' : 'Orbit'} side="left">
        <Flex
          className="button-item"
          as="button"
          css={{
            touchAction: 'manipulation',
            paddingBottom: '$quark',
            '& svg': {
              animation: isRotating ? `${orbitAnimation} 30s linear infinite` : '',
            },
          }}
          onClick={flyAround}
        >
          <RotateIcon />
          {/* <Typo css={{ fontSize: '16px', color: '#6C6C6E' }}>{isRotating ? '◉' : '◎'}</Typo> */}
        </Flex>
      </ButtonTooltip>

      <Divider />

      <ButtonTooltip content="Track" side="left">
        <Flex
          className="button-item"
          as="button"
          css={{
            touchAction: 'manipulation',
            paddingBottom: '$quark',
          }}
          onClick={toggleIsFollowLocked}
        >
          {isMapFollowLocked ? <MyLocationFocused /> : <MyLocationNotFocusedIcon />}
        </Flex>
      </ButtonTooltip>
    </Wrapper>
  );
}
