/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';
import { Timeline } from '../../../../components/third-party/timeline/timeline';
import useEventStore from '../../state-manager/store';
import skeletonStyle from '../../../../common-styles/skeleton';
import { diffInnSecondsBetweenTwoDates } from '../../../../utils/utils';
import { useIsMobile } from '../../../../utils/useIsMobile';

import { EventStream, EventPlayStatus } from '../../state-manager/store-types';
import EventLiveChannelsCarousel from '../event-live-channels-carousel/event-live-channels-carousel';
import EventDateList from '../event-date-list/event-date-list';
import EventLiveHostChannelsCarousel from '../event-live-host-channels-carousel/event-live-host-channels-carousel';
import HostPill from '../../../../components/icons/host-pill';

import ExpandContentIcon from '../../../../components/icons/expand_content';
import TimelineIcon from '../../../../components/icons/timeline';

dayjs.extend(isBetween);
let eventStreamsOnTimelineRef: EventStream[] = [];

function convertDateFormat(dateString: string): string {
  const originalDate = new Date(dateString);
  const year = originalDate.getFullYear();
  const month = originalDate.getMonth() + 1;
  const day = originalDate.getDate();

  const convertedDateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

  return convertedDateString;
}

export function EventTimeline() {
  const setTimelineElement = useEventStore((state) => state.setTimelineElement);
  const timelineElement = useEventStore((state) => state.timelineElement);
  const eventStreams = useEventStore((state) => state.eventStreams);
  const eventInfo = useEventStore((state) => state.eventInfo);
  eventStreamsOnTimelineRef = eventStreams;
  const isMobile = useIsMobile();

  const [isTimelineExpanded, setIsTimelineExpanded] = useState<boolean>(true);
  const setFeatStream = useEventStore((state) => state.setFeatStream);
  const playerElement = useEventStore((state) => state.playerElement);
  const setPlayingHostStream = useEventStore((state) => state.setPlayingHostStream);

  const playStatus = useEventStore((state) => state.playStatus);
  const setPlayStatus = useEventStore((state) => state.setPlayStatus);
  const timelineUpdateController = useEventStore((state) => state.timelineUpdateController);
  const { items, groups } = timelineUpdateController.getAllItems();

  const getTimelineHeight = useCallback(() => `${110 + groups.length * 50}px`, [groups]);

  const isLive = useMemo(
    () => !!eventStreams.some((stream) => stream.content.status === 'started' && !stream.isProducer),
    [eventStreams],
  );
  const hasLiveHostCommentary = useMemo(
    () => !!eventStreams.some((stream) => stream.content.status === 'started' && stream.isProducer),
    [eventStreams],
  );

  const findSelectedStream = (id: string) => eventStreamsOnTimelineRef.find((stream) => stream.id === id);
  const [selectedDate, setSelectedDate] = useState<Date | undefined | string>(undefined);

  const onSelectHostLiveStream = (id: string) => {
    const foundStream = findSelectedStream(id);
    if (!foundStream) return;
    if (isMobile) {
      playerElement?.muted(true);
    } else {
      playerElement?.volume(0.1);
    }
    setPlayingHostStream(foundStream);
  };

  const toggleIsTimelineExpandedState = () => {
    setIsTimelineExpanded(!isTimelineExpanded);
  };

  const onSelectStream = (id: string, currentHoverTime: Date | null) => {
    const foundStream = findSelectedStream(id);
    if (!foundStream) return;
    if (!currentHoverTime) return;

    const isLiveStream = foundStream?.content.status === 'started';
    if (!isLiveStream) setPlayStatus(EventPlayStatus.STARTED_STREAMING);
    if (isLiveStream) setPlayStatus(EventPlayStatus.LIVE);
    const timeoffsetInSeconds = isLiveStream ? -1 : diffInnSecondsBetweenTwoDates(foundStream?.start, currentHoverTime);
    const currentFeatStream = { ...foundStream, timeoffsetInSeconds };

    setFeatStream(currentFeatStream || null);
  };
  const onSelectLiveStream = (id: string) => {
    const foundStream = findSelectedStream(id);
    if (!foundStream) return;

    setPlayStatus(EventPlayStatus.LIVE);
    const timeoffsetInSeconds = -1;
    const currentFeatStream = { ...foundStream, timeoffsetInSeconds };

    setFeatStream(currentFeatStream || null);
    setTimeout(() => timelineElement?.focus(currentFeatStream.id), 1000);
  };

  const playVOD = () => {
    const lastEventDayIndex = eventInfo?.timeline_days?.length ? eventInfo.timeline_days.length - 1 : 0;
    const lastEventDay = eventInfo?.timeline_days[lastEventDayIndex] || null;
    const lastStreamAvailable = eventStreams.length - 1;
    const streamFromMostRecentDay = eventStreams.find(
      (stream) => convertDateFormat(stream.content.start_time) === lastEventDay,
    );
    const streamToPlayFirst = streamFromMostRecentDay || eventStreams[lastStreamAvailable];
    setFeatStream(streamToPlayFirst);
    setTimeout(() => timelineElement?.focus(streamToPlayFirst.id), 1000);
    setPlayStatus(EventPlayStatus.STARTED_STREAMING);
  };

  const playLiveStream = () => {
    const firstLiveStream = eventStreams.find((stream) => stream.content.status === 'started');
    if (!firstLiveStream) return;
    setFeatStream(firstLiveStream);
    setTimeout(() => timelineElement?.focus(firstLiveStream.id), 1000);
    setPlayStatus(EventPlayStatus.LIVE);
  };

  const autoPlayOnFirstMount = () => {
    if (isLive) {
      playLiveStream();
    } else {
      playVOD();
    }
  };

  const filterStreamsOnGivenDateRange = (start: Date, end: Date): string[] => {
    const filteredStreamsIds = eventStreams
      .filter((stream) => {
        const streamStart = dayjs(stream.start);
        const isInsideTimeRange = dayjs(streamStart).isBetween(start, end, 'second', '[]');
        return !!isInsideTimeRange;
      })
      .map((filteredStream) => filteredStream.id);

    return filteredStreamsIds;
  };

  const onChangeSelectedDate = (nextSelectedDate: string) => {
    if (!isTimelineExpanded) setIsTimelineExpanded(true);
    const startDateDayJS = dayjs(nextSelectedDate).startOf('day').format();
    const endDateDayJS = dayjs(nextSelectedDate).endOf('day').format();
    const startJsDate = new Date(startDateDayJS);
    const endJsDate = new Date(endDateDayJS);
    const start = startJsDate;
    const end = endJsDate;
    setSelectedDate(nextSelectedDate);
    const streamIds = filterStreamsOnGivenDateRange(start, end);
    timelineElement?.focus(streamIds);
  };

  const onChangeTimelineRange = (startDate: Date) => {
    setSelectedDate(startDate);
  };

  const onReceiveTimelineStreams = () => {
    if (playStatus === EventPlayStatus.IDLE && eventStreams.length && timelineElement) {
      autoPlayOnFirstMount();
    }
  };
  useEffect(onReceiveTimelineStreams, [eventStreams, timelineElement]);

  return (
    <Flex css={{ width: '100%', flexDirection: 'column', gap: '$xs' }}>
      <Flex
        className="event-timeline-wrapper"
        css={{
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          backgroundColor: '$strongBlackGlass',
          position: 'relative',

          '@bp3': {
            borderTopLeftRadius: '$2lg',
            borderTopRightRadius: '$2lg',
          },
        }}
      >
        <Flex
          className="event-timeline-header"
          css={{
            alignItems: 'center',
            paddingRight: '$xs',
            minHeight: '3.5rem',
            borderBottom: '1px solid $gs500',
            backgroundColor: '$bgAppScreen200',
            justifyContent: 'flex-end',
            '@bp3': {
              minHeight: '2.5rem',
            },
          }}
        >
          {!isMobile && (
            <Flex css={{ gap: '$2xs', alignItems: 'center', width: '100%' }}>
              {eventInfo?.timeline_days && (
                <EventDateList
                  dateRange={eventInfo?.timeline_days}
                  value={selectedDate}
                  onValueChange={onChangeSelectedDate}
                />
              )}
            </Flex>
          )}
          {isMobile && (
            <Flex
              css={{ justifyContent: 'space-between', width: '100%', alignItems: 'center', paddingLeft: '1rem' }}
              onClick={toggleIsTimelineExpandedState}
            >
              <Flex css={{ gap: '$2xs', alignItems: 'center' }}>
                <TimelineIcon />
                <Typo type="bodyBold" css={{ color: '$constantWhite', fontSize: '0.875rem' }}>
                  Timeline
                </Typo>
              </Flex>

              {isMobile && <ExpandContentIcon />}
            </Flex>
          )}
        </Flex>

        {isMobile && (
          <Flex
            css={{
              backgroundColor: '$bgAppScreen200',
              minHeight: '2.5rem',
              display: 'flex',
              alignItems: 'center',
              // gap: '$xs',
              borderBottom: '1px solid $gs500',
              // padding: '0px 10px',
            }}
          >
            <Flex css={{ gap: '$2xs', alignItems: 'center', width: '100%' }}>
              {eventInfo?.timeline_days && (
                <EventDateList
                  dateRange={eventInfo?.timeline_days}
                  value={selectedDate}
                  onValueChange={onChangeSelectedDate}
                />
              )}
            </Flex>
          </Flex>
        )}

        {isLive && !isMobile && (
          <Flex
            css={{
              backgroundColor: '$bgAppScreen200',
              height: '5rem',
              display: 'flex',
              alignItems: 'center',
              // gap: '$xs',
              borderBottom: '1px solid $gs500',
              padding: '0px 10px',
            }}
          >
            <Flex
              css={{
                backgroundColor: '$vsLive100',
                padding: '0px $2xs',
                height: '2.21rem',
                alignItems: 'center',
                borderRadius: '$lg',
              }}
            >
              <Typo css={{ textTransform: 'uppercase', color: '$constantWhite', fontWeight: '$bold' }}>Live</Typo>
            </Flex>
            <Flex css={{ width: 'calc(100% - 40px)' }}>
              <EventLiveChannelsCarousel onSelectStream={onSelectLiveStream} />
            </Flex>
          </Flex>
        )}
        {hasLiveHostCommentary && !isMobile && (
          <Flex
            css={{
              backgroundColor: '$bgAppScreen200',
              height: '5rem',
              display: 'flex',
              alignItems: 'center',
              // gap: '$xs',
              borderBottom: '1px solid $gs500',
              padding: '0px 10px',
            }}
          >
            <HostPill />
            <Flex css={{ width: 'calc(100% - 40px)' }}>
              <EventLiveHostChannelsCarousel onSelectStream={onSelectHostLiveStream} />
            </Flex>
          </Flex>
        )}
        <Flex
          className="event-timeline-content"
          css={{
            height: isTimelineExpanded ? getTimelineHeight() : 0,
            '&:empty': skeletonStyle,
          }}
        >
          {!!eventStreams.length && (
            <Timeline
              items={items}
              groups={groups}
              onTimelineReady={setTimelineElement}
              onSelectItem={onSelectStream}
              onChangeTimelineRange={onChangeTimelineRange}
              isMobile={isMobile}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
