import { useRef, useEffect } from 'react';
import { VideoJsPlayer } from 'video.js';
import { useInterval } from 'usehooks-ts';
import dayjs from 'dayjs';
import throttle from 'lodash/throttle';

import { GpsMetaData } from '../../../../components/third-party/video-stream/gps-type';
import VideoStream from '../../../../components/third-party/video-stream/video-stream';
import useGlobalStore from '../../../../app-global-store';
import useEventStore from '../../state-manager/store';
import { addSeconds } from '../../../../utils/utils';
import Flex from '../../../../components/primitives/flex';
import skeletonStyle from '../../../../common-styles/skeleton';

import NoNewStreams from './no-new-streams';
import { useQueryTimelineData } from '../../hooks/useQueryTimelineData';
import { EventStream, EventPlayStatus } from '../../state-manager/store-types';

interface IProps {
  isPip?: boolean;
  showSponsorLogo?: boolean;
}

let featStreamRef: any;
let eventStreamsRef: EventStream[];

export default function EventVideo({ isPip = false, showSponsorLogo = true }: IProps) {
  const featStream = useEventStore((state) => state.featStream);
  featStreamRef = featStream;
  const setFeatStream = useEventStore((state) => state.setFeatStream);
  const setPlayStatus = useEventStore((state) => state.setPlayStatus);
  const setPlayerElement = useEventStore((state) => state.setPlayerElement);
  const playerElement = useEventStore((state) => state.playerElement);
  const playStatus = useEventStore((state) => state.playStatus);

  const eventStreams = useEventStore((state) => state.eventStreams);
  eventStreamsRef = eventStreams;
  const timelineElement = useEventStore((state) => state.timelineElement);
  const setUserLocation = useEventStore((state) => state.setUserLocation);
  const isStreamVisible = useEventStore((state) => state.isStreamVisible);
  const eventInfo = useEventStore((state) => state.eventInfo);
  // const navigate = useNavigate();

  const analytics = useGlobalStore((state) => state.analytics);
  const locationRef = useRef<GpsMetaData | null>(null);

  useEffect(() => {
    locationRef.current = null;
  }, [featStream]);

  const getEventTimeline = useQueryTimelineData();

  const onChangeGpsData = (data: GpsMetaData) => {
    locationRef.current = data;
  };

  const formatDate = (date: string | Date | number) => dayjs(date).format('HH:mm');

  const setPlayingVerticalLineLabelValueRef = (time: Date) => {
    const labelElement = document.getElementById('labelPlayingElement');
    if (labelElement) {
      const formatedDate = formatDate(time);
      labelElement.innerHTML = formatedDate === 'Invalid Date' ? 'Loading' : formatedDate;
    }
  };
  const WAIT_MILISECONDS = 1000;
  const setPlayingVerticalLineLabelValue = throttle(setPlayingVerticalLineLabelValueRef, WAIT_MILISECONDS);

  const onTimeUpdate = (currentTimeInSeconds: number) => {
    if (!timelineElement || !featStream?.start) return;
    const isLiveStream = featStream.content.status === 'started';
    const currentStreamDateTime = isLiveStream
      ? new Date()
      : addSeconds(new Date(featStream?.start.getTime()), currentTimeInSeconds);

    timelineElement.setCustomTime(currentStreamDateTime, 't1');
    setPlayingVerticalLineLabelValue(currentStreamDateTime);
  };

  const onMount = () => {
    if (!featStream || !featStream.content?.latitude || !featStream.content?.longitude) return;
    setUserLocation({
      latitude: featStream.content?.latitude,
      longitude: featStream.content?.longitude,
      username: featStream?.content?.username || '',
      useravatar: featStream?.content?.useravatar || '',
      stream_id: featStream?.content?.id,
    });
  };
  useEffect(onMount, []);

  const jumToNextVideo = () => {
    const foundLiveStream = eventStreamsRef.find(
      (stream) => stream.content.status === 'started' && stream.id !== featStream?.id && !stream.isProducer,
    );
    if (foundLiveStream) {
      setFeatStream(foundLiveStream);
      return setTimeout(() => timelineElement?.focus(foundLiveStream.id), 2000);
    }

    const currentStreamIndex = eventStreamsRef.findIndex((stream) => stream.id === featStreamRef?.id);
    if (currentStreamIndex !== -1 && eventStreamsRef[currentStreamIndex + 1]) {
      setFeatStream(eventStreamsRef[currentStreamIndex + 1]);
      setTimeout(() => timelineElement?.focus(eventStreamsRef[currentStreamIndex + 1].id), 2000);
    } else {
      setPlayStatus(EventPlayStatus.ENDED_ALL_STREAMS);
    }
  };

  const startEventOver = () => {
    setPlayStatus(EventPlayStatus.STARTED_STREAMING);
    setFeatStream(eventStreams[0]);
    timelineElement?.focus(eventStreams[0].id);
  };

  const replayCurrentStream = () => {
    playerElement?.currentTime(0);
    timelineElement?.focus(featStream?.id);
    setPlayStatus(EventPlayStatus.STARTED_STREAMING);
  };

  const onChangeFeatStream = () => {
    if (!featStream || !featStream.content?.latitude || !featStream.content?.longitude) return setUserLocation(null);
  };
  useEffect(onChangeFeatStream, [featStream]);

  const onVideoEnds = async () => {
    await getEventTimeline(eventInfo?.id);
    jumToNextVideo();
  };

  useInterval(() => {
    if (
      !locationRef?.current ||
      !locationRef?.current.gps_latitude ||
      !locationRef?.current.gps_longitude ||
      !featStream
    )
      return;
    setUserLocation({
      ...locationRef.current,
      latitude: locationRef?.current.gps_latitude,
      longitude: locationRef?.current.gps_longitude,
      username: featStream?.content?.username || '',
      useravatar: featStream?.content?.useravatar || '',
      stream_id: featStream?.content?.id,
    });
  }, 1000);

  const getTimeOffset = () => {
    if (!featStream?.timeoffsetInSeconds) return 0;
    if (featStream?.timeoffsetInSeconds === -1) return undefined;
    return featStream.timeoffsetInSeconds * 1000;
  };

  const checkIfThereIsALiveStreamWhileThePlayStatusIsEnded = () => {
    if (playStatus !== EventPlayStatus.ENDED_ALL_STREAMS) return;
    const liveStream = eventStreams.find((stream) => stream.content.status === 'started');
    if (liveStream && liveStream?.id !== featStream?.id) {
      setFeatStream(liveStream);
      setTimeout(() => timelineElement?.focus(liveStream.id), 2000);
      setPlayStatus(EventPlayStatus.LIVE);
    }
  };
  useEffect(checkIfThereIsALiveStreamWhileThePlayStatusIsEnded, [eventStreams]);

  const onVideoPlayerReady = (playerRef: VideoJsPlayer) => {
    setPlayerElement(playerRef);
  };

  const isLive = () => {
    if (featStream?.content?.duration_in_seconds === null || featStream?.content?.duration_in_seconds === undefined)
      return false;
    return featStream?.content?.status === 'started';
  };

  if (!isStreamVisible) return null;

  return (
    <Flex css={{ height: '100%', width: '100%', '&:empty': skeletonStyle, position: 'relative' }}>
      {isStreamVisible && (
        <VideoStream
          url={featStream?.content?.playback_url || ''}
          channelTitle={featStream?.channelTitle}
          isFeatured
          isPip={isPip}
          onChangeGpsData={onChangeGpsData}
          streamId={featStream?.id}
          username={featStream?.content?.username || ''}
          useravatar={featStream?.content?.useravatar || ''}
          isLive={isLive()}
          newTelemetry
          onTimeUpdate={onTimeUpdate}
          timeOffset={getTimeOffset()}
          speedType={featStream?.content?.activity_type?.speed_type}
          onPlay={() => analytics.track('played_video', { id: featStream?.id })}
          onPause={() => analytics.track('paused_video', { id: featStream?.id })}
          onVideoEnds={onVideoEnds}
          onVideoError={onVideoEnds}
          onVideoPlayerReady={onVideoPlayerReady}
          sponsorImageUrl={showSponsorLogo ? featStream?.content.logoUrl : undefined}
          showAtheleteThumbnail
        />
      )}
      {playStatus === EventPlayStatus.ENDED_ALL_STREAMS && (
        <NoNewStreams onClickReplayCurrentStream={replayCurrentStream} onClickStartOver={startEventOver} />
      )}
    </Flex>
  );
}
