/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useContext, useEffect, useState } from 'react';
import { MapRef } from 'react-map-gl';

import { VideoJsPlayer } from 'video.js';
import { SourceLocation, TimelineModel } from '../../../../../domain/models/event-timeline-model';
import { StreamModel } from '../../../../../domain/models/stream-model';
import TimelineIcon from '../../../../components/icons/timeline';
import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';
import AppleLoading from '../../../../components/spinners/AppleLoading/AppleLoading';
import StreamThumb from '../../../../components/stream-thumb/stream-thumb';
import MapBoxGeneric from '../../../../components/third-party/map/map-generic';
import { removeTimeZoneFromTimeStamp, stringToColour } from '../../../../utils/utils';
import { ISquadVideoContext, RealTimePosition, SquadVideoContext } from '../../context';
import MobileDateSelect from '../date-select/mobile-date-select';
import TimeItem from './time-item';

interface IProps {
  getOrderedDateListToPicker: () => string[];
  localStateSelectedDate: string | null;
  localStateSelectedTime: string | null;
  onDateSelectChanges: (e: string) => void;
  liveStreams: StreamModel[];
  timeline: TimelineModel[];
  onSelectPlaybackTime: (time: string) => void;
  getTime: (time: string) => string;
  openLiveStream: () => void;
  onSelectStream?: () => void;
  isTimelineLoading: boolean;
  isLiveSelected: boolean;
}

export default function MobileTimeLineEvent({
  getOrderedDateListToPicker,
  localStateSelectedDate,
  localStateSelectedTime,
  onDateSelectChanges,
  liveStreams,
  timeline,
  onSelectPlaybackTime,
  getTime,
  openLiveStream,
  isTimelineLoading,
  isLiveSelected,
  onSelectStream,
}: IProps) {
  const {
    eventInfo,
    realTimeAthletePositions,
    streamSourceLocation,
    streamList,
    featuredStream,
    setIsMapFollowLocked,
    setFeaturedStream,
  } = useContext<ISquadVideoContext>(SquadVideoContext);

  const [atheletePositions, setAthletePositions] = useState<Map<string, RealTimePosition>>(realTimeAthletePositions);

  const realTimeAthletePositionsIdKeys = Array.from(atheletePositions.keys());

  const getArrayOfAtheletePositions = useCallback(
    () =>
      realTimeAthletePositionsIdKeys.map((positionKey: string) => ({
        lng: atheletePositions.get(positionKey)?.longitude || 0,
        lat: atheletePositions.get(positionKey)?.latitude || 0,
      })),
    [atheletePositions],
  );

  const [mapRef, setMapRef] = useState<MapRef | null>(null);

  const initializeMapRef = (propertyMapRef: MapRef | null) => {
    setMapRef(propertyMapRef);
  };

  const fitMapToAtheletePositions = () => {
    if (!mapRef) return;
    const bounds = getArrayOfAtheletePositions();
    if (!bounds.length) return;
    if (bounds.length === 1) return mapRef.flyTo({ center: [bounds[0].lng, bounds[0].lat], zoom: 16, speed: 0.5 });
    const firstThreeBounds = bounds.slice(0, 3);
    // @ts-ignore
    mapRef.fitBounds(firstThreeBounds, { padding: 35 });
  };

  const onChangeAtheletePositions = () => {
    fitMapToAtheletePositions();
  };
  useEffect(onChangeAtheletePositions, [mapRef, atheletePositions]);

  const selectTimelineTime = (time: string, sourceLocations: SourceLocation[]) => {
    const previewLocations = new Map();
    const usersPreviewLocations = sourceLocations.map(({ stream_id, latitude, longitude }) => {
      const stream = streamList?.get(stream_id);
      return {
        id: stream_id,
        username: stream?.channel.username,
        useravatar: stream?.channel.hero_photo.original,
        color: stringToColour(stream?.channel.username),
        latitude,
        longitude,
      };
    });
    usersPreviewLocations.forEach((userLocation) => {
      previewLocations.set(userLocation.id, userLocation);
    });
    setAthletePositions(previewLocations);

    onSelectPlaybackTime(time);
  };

  const getCurrentVideoTime = (videoPlayer: VideoJsPlayer | undefined | null): number => {
    if (!videoPlayer) return 0;
    return videoPlayer?.currentTime() || 0;
  };

  const onSelectAthlete = (realTimePosition: RealTimePosition | undefined, isSelected: boolean) => {
    if (!realTimePosition || !streamList) return;
    if (onSelectStream) onSelectStream();
    if (isSelected) return setIsMapFollowLocked(true);

    let foundStream;
    if (isLiveSelected) {
      foundStream = liveStreams.find((stream) => stream.id === realTimePosition.id);
    } else {
      foundStream = streamList?.get(realTimePosition.id);
    }
    setFeaturedStream({
      offset_ms: isLiveSelected ? 0 : getCurrentVideoTime(realTimePosition.videoPlayerRef) * 1000,
      ...foundStream,
    });
    setIsMapFollowLocked(true);
  };

  return (
    <Flex css={{ flexDirection: 'column', height: '100%', width: '100%' }} className="timeline-wrapper">
      <Flex css={{ flex: 1, zIndex: 1, overflow: 'hidden' }}>
        <MapBoxGeneric
          onMapReady={initializeMapRef}
          mapName="timeline-map"
          realTimeAthletePositions={atheletePositions}
          mapCenter={[eventInfo?.longitude || 0, eventInfo?.latitude || 0]}
          onSelectAthlete={onSelectAthlete}
        />
      </Flex>
      <Flex
        className="stream-thumb-wrapper"
        css={{
          height: '7.2rem',
          width: '100%',
          overflowX: 'auto',
          alignItems: 'center',
          gap: '$xs',
          '&::-webkit-scrollbar': {
            width: '0px',
            height: '0px',
          },
        }}
      >
        {streamSourceLocation.map(({ stream_id }, index: number) => (
          <StreamThumb
            key={stream_id}
            streamData={streamList?.get(stream_id)}
            isSelected={featuredStream?.id === stream_id}
            isFirstOnList={index === 0}
            isLastOnList={index === streamSourceLocation.length - 1}
            forceShowDesktop
            showVideo={false}
            onSelect={onSelectStream}
          />
        ))}
      </Flex>
      <Flex css={{ flexDirection: 'column', height: '15rem' }}>
        <Flex
          className="timeline-title"
          css={{
            gap: '$2xs',
            alignItems: 'center',
            paddingLeft: '$xs',
            minHeight: '3.37rem',
            borderBottom: '1px solid $gs500',
            backgroundColor: '$bgAppScreen200',
            justifyContent: 'space-between',
          }}
        >
          <Flex
            css={{
              gap: '$2xs',
              alignItems: 'center',
            }}
          >
            <TimelineIcon />
            <Typo type="bodyBold" css={{ color: '$constantWhite' }}>
              Timeline
            </Typo>
          </Flex>

          <MobileDateSelect
            dateRange={getOrderedDateListToPicker()}
            value={localStateSelectedDate}
            onValueChange={onDateSelectChanges}
          />
        </Flex>
        <Flex
          className="time-list"
          css={{
            padding: '$3xs',
            overflowY: 'auto',
            height: '100%',
            flexDirection: 'column',
            // gap: '$3xs',
            position: 'relative',
          }}
        >
          <Flex className="time-zone">
            <Typo type="bodySm" css={{ color: '$gs400', fontWeight: '$book', padding: '0px $3xs' }}>
              GMT +02:00
            </Typo>
          </Flex>

          {!!liveStreams.length && (
            <TimeItem onClick={openLiveStream} isSelected={isLiveSelected} timeStamp="now">
              Now
            </TimeItem>
          )}
          {timeline.map(({ time, stream_source_locations }: TimelineModel) => (
            <TimeItem
              key={time}
              onClick={(sourceLocations) => selectTimelineTime(time, sourceLocations)}
              isSelected={removeTimeZoneFromTimeStamp(time) === localStateSelectedTime && !isLiveSelected}
              sourceLocations={stream_source_locations}
              timeStamp={removeTimeZoneFromTimeStamp(time)}
            >
              {getTime(time)}
            </TimeItem>
          ))}
        </Flex>
      </Flex>

      {isTimelineLoading && (
        <Flex
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.2)',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AppleLoading />
        </Flex>
      )}
    </Flex>
  );
}
