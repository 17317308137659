import { useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useNavigate, useParams } from 'react-router-dom';
import { RemoteChannel } from '../../../data/usecases/remote-channel';
import { RemoteStream } from '../../../data/usecases/remote-stream';
import { RemoteStreamAnonymous } from '../../../data/usecases/remote-stream-anonymous';
import { LocalStorageAdapter } from '../../../infra/cache/local-storage-adapter';

import Flex from '../../components/primitives/flex';
import { SideBar } from '../../components/sidebar/sidebar';
import TopHeader from '../../components/top-header/top-header';
import { useIsMobile } from '../../utils/useIsMobile';
import { transformTextToDash } from '../../utils/utils';
import useAuthStore from '../auth-wrapper/state-manager/store';
import { StreamViewGrid } from './components/stream-view-grid/stream-view-grid';
import { IStreamViewContext, StreamViewContextProvider } from './state-manager/context';
import useStreamViewStore from './state-manager/store';

interface IProps {
  streamRepository: RemoteStream;
  streamAnonymousRepository: RemoteStreamAnonymous;
  channelRepository: RemoteChannel;
  localStorageAdapter: LocalStorageAdapter;
}

export default function StreamViewPage({
  streamRepository,
  streamAnonymousRepository,
  channelRepository,
  localStorageAdapter,
}: IProps) {
  const setStreamInfo = useStreamViewStore((state) => state.setStreamInfo);
  const streamInfo = useStreamViewStore((state) => state.streamInfo);
  const isMobile = useIsMobile();
  const authToggleState = useAuthStore((state) => state.authToggleState);

  const navigate = useNavigate();
  const { streamId } = useParams();
  const getSelectedStream = async () => {
    try {
      const stream = await streamRepository.get(streamId || '');
      navigate(`/streams/${stream.id}/${transformTextToDash(stream.title)}`);
      setStreamInfo(stream);
    } catch (e) {
      navigate('/not-found');
    }
  };

  const onMount = () => {
    if (authToggleState) getSelectedStream();
  };
  useEffect(onMount, [authToggleState]);

  const sharedValues: IStreamViewContext = useMemo(
    () => ({
      streamRepository,
      streamAnonymousRepository,
      channelRepository,
      localStorageAdapter,
    }),
    [streamRepository, streamAnonymousRepository, channelRepository, localStorageAdapter],
  );

  return (
    <StreamViewContextProvider value={sharedValues}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{streamInfo?.title ? `Pulse - ${streamInfo.title}` : 'Pulse - Share What You See'}</title>
        <meta property="al:ios:url" content="pulse://stream/${match.params.id}" />
        <meta name="description" content={streamInfo?.description || ''} />
        <meta
          property="og:title"
          content={streamInfo?.title ? `Pulse - ${streamInfo.title}` : 'Pulse - Share What You See'}
        />
        <meta property="og:description" content={streamInfo?.description || ''} />
        <meta property="og:image" content={streamInfo?.thumbnail} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Flex
        css={{
          width: '100%',
          height: '100%',
          backgroundColor: '$bgAppScreen100',
          flexDirection: 'column',
        }}
      >
        {!isMobile && <TopHeader showShareButton={false} />}
        <Flex
          className="stream-view-content"
          css={{
            height: 'calc(100% - 4.5rem)',
            '@bp3': {
              height: '100%',
            },
          }}
        >
          <SideBar />
          <Flex className="stream-view-grid-wrapper" css={{ width: '100%' }}>
            <StreamViewGrid />
          </Flex>
        </Flex>
      </Flex>
    </StreamViewContextProvider>
  );
}
