import { useRef, useEffect } from 'react';
import { useInterval } from 'usehooks-ts';
import { GpsMetaData } from '../../../../components/third-party/video-stream/gps-type';
import VideoStream from '../../../../components/third-party/video-stream/video-stream';
import useStreamViewStore from '../../state-manager/store';
import useGlobalStore from '../../../../app-global-store';

interface IProps {
  isPip?: boolean;
}

export default function StreamViewVideo({ isPip = false }: IProps) {
  const streamInfo = useStreamViewStore((state) => state.streamInfo);
  const setUserLocation = useStreamViewStore((state) => state.setUserLocation);
  const analytics = useGlobalStore((state) => state.analytics);
  const locationRef = useRef<GpsMetaData | null>(null);

  const onChangeGpsData = (data: GpsMetaData) => {
    locationRef.current = data;
  };

  const onMount = () => {
    if (!streamInfo || !streamInfo.latitude || !streamInfo.longitude) return;
    setUserLocation({
      latitude: streamInfo.latitude,
      longitude: streamInfo.longitude,
      username: streamInfo.channel.username,
      useravatar: streamInfo.channel.hero_photo.original,
    });
  };
  useEffect(onMount, []);

  useInterval(() => {
    if (
      !locationRef?.current ||
      !locationRef?.current.gps_latitude ||
      !locationRef?.current.gps_longitude ||
      !streamInfo
    )
      return;
    setUserLocation({
      latitude: locationRef?.current.gps_latitude,
      longitude: locationRef?.current.gps_longitude,
      username: streamInfo.channel.username,
      useravatar: streamInfo.channel.hero_photo.original,
    });
  }, 1000);
  return (
    <VideoStream
      url={streamInfo?.playback_url || ''}
      isFeatured
      isLive={streamInfo?.status === 'started'}
      isPip={isPip}
      onChangeGpsData={onChangeGpsData}
      newTelemetry
      channelTitle={streamInfo?.channel.title}
      username={streamInfo?.channel?.username || ''}
      useravatar={streamInfo?.channel?.hero_photo.original || ''}
      speedType={streamInfo?.activity_type?.speed_type}
      showAtheleteThumbnail={false}
      onPlay={() => analytics.track('played_video', { id: streamInfo?.id })}
      onPause={() => analytics.track('paused_video', { id: streamInfo?.id })}
    />
  );
}
