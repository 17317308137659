import EventPage from '../../presentation/pages/event-v1/event-page';
import { makeLocalStorageAdapter } from '../factories/cache';
import { makeEventTrackingClient } from '../factories/event-tracking';
import { makeFirebaseAuthClient } from '../factories/social-auth';
import { makeRemoteAddProfile } from '../usecases/remote-add-profile-factory';
import { makeRemoteGetEventInfo } from '../usecases/remote-get-event-factory';

export function makeEventPage() {
  return (
    <EventPage
      addProfile={makeRemoteAddProfile()}
      getEvent={makeRemoteGetEventInfo()}
      socialSignIn={makeFirebaseAuthClient()}
      localStorageAdapter={makeLocalStorageAdapter()}
      userMarketingEventTrack={makeEventTrackingClient()}
    />
  );
}
