import { useState } from 'react';

import GenericStreamMap, { ContainerState } from '../../../../components/generic-stream-map/generic-stream-map';
import useStreamViewStore from '../../state-manager/store';
import { StreamViewMap } from '../stream-view-map/stream-view-map';
import StreamViewVideo from '../stream-view-video/stream-view-video';
import useGlobalStore from '../../../../app-global-store';

export function StreamViewMapStream() {
  const mapRef = useStreamViewStore((state) => state.mapRef);
  const streamInfo = useStreamViewStore((state) => state.streamInfo);
  const analytics = useGlobalStore((state) => state.analytics);
  const [isPip, setIsPip] = useState<boolean>(true);

  const redrawMap = () => {
    if (mapRef) {
      setTimeout(() => {
        try {
          mapRef.resize();
        } catch (e) {
          // console.error(e);
        }
      }, 1500);
    }
  };

  const isMapDisabled = () => streamInfo?.latitude === 1 || streamInfo?.latitude === 0;

  const onChangeContainerState = (state: ContainerState) => {
    analytics.track(`change_stream_size_${ContainerState[state]}`);
    if (state === ContainerState.MapFull) return setIsPip(true);
    setIsPip(false);
  };

  return (
    <GenericStreamMap
      streamElement={<StreamViewVideo isPip={isPip} />}
      mapComponent={isMapDisabled() ? null : <StreamViewMap />}
      onAnimationEnds={redrawMap}
      onChangeContainerState={onChangeContainerState}
    />
  );
}
