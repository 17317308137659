/* eslint-disable @typescript-eslint/naming-convention */
import { useRef, useEffect, useState } from 'react';
import { VideoJsPlayer } from 'video.js';

import VideoStream from '../../../../components/third-party/video-stream/video-stream';
import useEventStore, { changeEventPlaybackSpeed, pauseEvent, playEvent } from '../../state-manager/store';
import Flex from '../../../../components/primitives/flex';
import skeletonStyle from '../../../../common-styles/skeleton';
import { NewEventStream } from '../../state-manager/store-types';
import { useEventAutoPlay } from '../../hooks/useEventAutoPlay';
import GenericStreamMap, { ContainerState } from '../../../../components/generic-stream-map/generic-stream-map';
import { EventMap } from '../event-map/event-map';

interface IProps {
  isStreamExpanded?: boolean;
  featStream: NewEventStream | null;
  showTelemetry?: boolean;
  showLiveBadge?: boolean;
}

let setSplitMode: ((arg0: any) => void) | null = null;
export default function EventVideo({
  isStreamExpanded = false,
  featStream,
  showTelemetry = true,
  showLiveBadge = true,
}: IProps) {
  const addPlayerRef = useEventStore((state) => state.addPlayerRef);
  const removePlayerRef = useEventStore((state) => state.removePlayerRef);
  const mapRef = useEventStore((state) => state.mapRef);
  const fullScreenControler = useEventStore((state) => state.fullScreenControler);
  const setFullScreenControler = useEventStore((state) => state.setFullScreenControler);
  const currentTime = useEventStore((state) => state.currentTime);
  const isStreamLive = useEventStore((state) => state.isStreamLive);
  const [isLiveState, setIsLiveState] = useState(false);
  const isSeekAvailable = useRef(false);
  const { onSeek } = useEventAutoPlay();

  useEffect(() => {
    if (!isStreamExpanded && setSplitMode) {
      setSplitMode(ContainerState.StreamFull);
    }
  }, [isStreamExpanded]);

  const {
    id,
    playback_url,
    // vod_playback_url,
    avatarPicture,
    stream_start_date_ms,
    activity_type,
    title,
  } = featStream || {};

  useEffect(() => {
    const isLive = featStream ? isStreamLive(featStream?.id) : false;
    setIsLiveState(isLive);
  }, [featStream]);

  const onMount = () => {
    pauseEvent();
    changeEventPlaybackSpeed(1);
    setTimeout(() => {
      isSeekAvailable.current = true;
    }, 2000);

    playEvent();
    return () => {
      isSeekAvailable.current = false;
      removePlayerRef(featStream?.id || '');
    };
  };
  useEffect(onMount, []);

  const redrawMap = () => {
    if (mapRef) {
      setTimeout(() => {
        try {
          mapRef.resize();
        } catch (e) {
          // console.error(e);
        }
      }, 1500);
    }
  };

  const onVideoPlayerReady = (playerRef: VideoJsPlayer) => {
    addPlayerRef({ id: featStream?.id || '', ref: playerRef });
  };

  const onReceiveFullScreenMethods = (controler: { isFullScreen: boolean; toggleFullscreen: () => void }) => {
    setFullScreenControler(controler);
  };

  const onGetContainerControl = (setState: any) => {
    setSplitMode = setState;
  };

  if (!playback_url) return null;

  const timeOffset = (currentTime?.getTime() || 0) - (stream_start_date_ms || 0);

  return (
    <Flex css={{ height: '100%', width: '100%', '&:empty': skeletonStyle, position: 'relative' }}>
      <GenericStreamMap
        mapPipCss={{ width: '20%', height: '45%' }}
        onReceiveFullScreenMethods={onReceiveFullScreenMethods}
        onGetContainerControl={onGetContainerControl}
        streamElement={
          <VideoStream
            url={playback_url || ''}
            isFeatured
            isPip={!isStreamExpanded}
            streamId={id}
            username={featStream?.channel.username || ''}
            useravatar={avatarPicture || ''}
            // posterImage={`${stream_tile_url}?timestamp=${timestamp}`}
            timeOffset={timeOffset}
            showTelemetry={showTelemetry}
            showLiveBadge={showLiveBadge}
            onSeek={onSeek}
            isLive={isLiveState}
            startTimeInMs={stream_start_date_ms}
            onPlay={playEvent}
            onPause={pauseEvent}
            newTelemetry
            channelTitle={featStream?.channel.title}
            onVideoError={pauseEvent}
            speedType={activity_type?.speed_type}
            onVideoPlayerReady={onVideoPlayerReady}
            sponsorImageUrl={showTelemetry ? featStream?.channel?.logo?.small : undefined}
            showAtheleteThumbnail
          />
        }
        mapComponent={
          isStreamExpanded ? (
            <EventMap isStreamExpanded={isStreamExpanded} isFullScreen={!!fullScreenControler?.isFullScreen} />
          ) : null
        }
        onAnimationEnds={redrawMap}
        // onChangeContainerState={onChangeContainerState}
        // telemetryData={telemetryData}
      />
    </Flex>
  );
}
