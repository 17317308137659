export default function UnfoldLessIcon() {
  return (
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.00005 5.47499C4.86672 5.47499 4.73772 5.44999 4.61305 5.39999C4.48772 5.34999 4.38338 5.28332 4.30005 5.19999L1.20005 2.09999C1.01672 1.91665 0.925049 1.68332 0.925049 1.39999C0.925049 1.11665 1.01672 0.883321 1.20005 0.699987C1.38338 0.516654 1.61671 0.424988 1.90005 0.424988C2.18338 0.424988 2.41672 0.516654 2.60005 0.699987L5.00005 3.09999L7.40005 0.699987C7.58338 0.516654 7.81672 0.424988 8.10005 0.424988C8.38338 0.424988 8.61672 0.516654 8.80005 0.699987C8.98338 0.883321 9.07505 1.11665 9.07505 1.39999C9.07505 1.68332 8.98338 1.91665 8.80005 2.09999L5.70005 5.19999C5.60005 5.29999 5.49172 5.37065 5.37505 5.41199C5.25838 5.45399 5.13338 5.47499 5.00005 5.47499ZM1.20005 15.3C1.01672 15.1167 0.925049 14.8833 0.925049 14.6C0.925049 14.3167 1.01672 14.0833 1.20005 13.9L4.30005 10.8C4.40005 10.7 4.50838 10.6293 4.62505 10.588C4.74172 10.546 4.86672 10.525 5.00005 10.525C5.13338 10.525 5.26271 10.546 5.38805 10.588C5.51271 10.6293 5.61671 10.7 5.70005 10.8L8.82505 13.925C9.00838 14.1083 9.09572 14.3373 9.08705 14.612C9.07905 14.8873 8.98338 15.1167 8.80005 15.3C8.61672 15.4833 8.38338 15.575 8.10005 15.575C7.81672 15.575 7.58338 15.4833 7.40005 15.3L5.00005 12.9L2.60005 15.3C2.41672 15.4833 2.18338 15.575 1.90005 15.575C1.61671 15.575 1.38338 15.4833 1.20005 15.3Z"
        fill="white"
      />
    </svg>
  );
}
