import { initializeApp } from 'firebase/app';
import {
  Auth,
  FacebookAuthProvider,
  getAuth,
  getIdToken,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { SignInMethod, SocialAuth } from '../../data/protocols/social-auth';

interface ProviderData {
  providerId: string;
  uid: string;
  displayName: string;
  email: string;
  phoneNumber: string | null;
  photoURL: string;
}

export interface AuthFireBaseUser {
  uid: string;
  email: string;
  emailVerified: boolean;
  displayName: string;
  isAnonymous: boolean;
  providerData: ProviderData[];
  stsTokenManager: {
    refreshToken: string;
    accessToken: string;
    expirationTime: number;
  };
  createdAt: string;
  lastLoginAt: string;
  apiKey: string;
  appName: string;
}

export class FirebaseAuthClient implements SocialAuth {
  initialize() {
    return initializeApp({
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      appId: import.meta.env.VITE_FIREBASE_APP_ID,
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
      authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
      measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
    });
  }

  getAuth(): Auth {
    const app = this.initialize();
    return getAuth(app);
  }

  signIn(method: SignInMethod) {
    const auth = this.getAuth();
    let provider;
    switch (method) {
      case 'facebook.com':
        provider = new FacebookAuthProvider();
        break;
      case 'apple.com':
        provider = new OAuthProvider('apple.com');
        break;
      case 'google.com':
      default:
        provider = new GoogleAuthProvider();
    }
    return signInWithPopup(auth, provider);
  }

  logout() {
    const auth = this.getAuth();
    signOut(auth);
  }

  async getCurrentUser(): Promise<any> {
    const auth = this.getAuth();
    if (!auth.currentUser) return;
    const currentUser = await auth.currentUser;
    return currentUser;
  }

  async getUserToken(): Promise<any> {
    return new Promise<any>((resolve) => {
      const unsub = onAuthStateChanged(this.getAuth(), async (user) => {
        if (user) {
          const token = await getIdToken(user);
          unsub();
          resolve(token);
        }
        unsub();
        resolve(null);
      });
    });
  }
}
