/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Dispatch, SetStateAction, useContext } from 'react';
import { GenericBottomSheet } from '../../../../components/generic-bottom-sheet/generic-bottom-sheet';
import ForumIcon from '../../../../components/icons/forum';
import TimelineIcon from '../../../../components/icons/timeline';

import Flex from '../../../../components/primitives/flex';
import GenericPulseChat from '../../../../components/third-party/chat/generic-chat';

import useAuthStore from '../../../auth-wrapper/state-manager/store';
import { ISquadVideoContext, SquadVideoContext } from '../../context';
import TimelineEvent from '../timeline-event/timeline-event';

interface IProps {
  isChatBottomSheetOpened: boolean;
  isTimelineBottomSheetOpened: boolean;
  setIsChatBottomSheetOpened: Dispatch<SetStateAction<boolean>>;
  setIsTimelineBottomSheetOpened: Dispatch<SetStateAction<boolean>>;
}

export default function MobileBottomSheet({
  isChatBottomSheetOpened,
  isTimelineBottomSheetOpened,
  setIsChatBottomSheetOpened,
  setIsTimelineBottomSheetOpened,
}: IProps) {
  const { eventInfo } = useContext<ISquadVideoContext>(SquadVideoContext);

  const setIsAuthDialogVisible = useAuthStore((state) => state.setIsAuthDialogVisible);

  const onUserSelectStream = () => {
    setIsTimelineBottomSheetOpened(false);
  };

  const onClickOpenAuthDialog = () => {
    setIsAuthDialogVisible(true);
  };

  return (
    <Flex>
      {eventInfo?.getstream_channel_id && (
        <GenericBottomSheet
          isOpen={isChatBottomSheetOpened}
          onClickClose={() => setIsChatBottomSheetOpened(false)}
          title="Live Chat"
          headerIcon={<ForumIcon />}
        >
          <GenericPulseChat
            chatType="event"
            chatId={eventInfo?.getstream_channel_id}
            onClickOpenAuthDialog={onClickOpenAuthDialog}
          />
        </GenericBottomSheet>
      )}

      <GenericBottomSheet
        isOpen={isTimelineBottomSheetOpened}
        onClickClose={() => setIsTimelineBottomSheetOpened(false)}
        title="Timeline"
        headerIcon={<TimelineIcon />}
      >
        <TimelineEvent onSelectStream={onUserSelectStream} />
      </GenericBottomSheet>
    </Flex>
  );
}
