/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';

import { MapRef } from 'react-map-gl';

import MAP_STYLE from './map-style';

import { RealTimePosition } from '../../../pages/event-v1/context';
import Flex from '../../primitives/flex';
import Marker from './marker';
import { StreamModel } from '../../../../domain/models/stream-model';

import { MapControls } from './map-controls';
import GenericMapMarkerEvent from './map-marker-generic-event';
import {
  EventStream,
  ListOfPolylines,
  NewEventStream,
  PointData,
  Polyline,
} from '../../../pages/event/state-manager/store-types';
import Source from './source';
import Layer from './layer';
import { stringToColour } from '../../../utils/colorConversion';

interface IProps {
  onMapReady?: (mapRef: MapRef | null) => void;
  hasNativeControls?: boolean;
  mapName?: string;
  isMapFollowLocked?: boolean;
  positions: Polyline;
  hoverPositions: Polyline;
  polylines: ListOfPolylines;
  isMobile?: boolean;
  setIsMapFollowLocked?: Dispatch<SetStateAction<boolean>>;
  onSelectAthlete?: (realTimePosition: RealTimePosition | undefined, isSelected: boolean) => void;
  featuredStream?: NewEventStream | null;
  mapCenter?: [lng: number, lat: number];
  onZoomIn?: () => void;
  mapStyle?: string | null;
  onZoomOut?: () => void;
  onMarkerClick?: (location: PointData) => void;
  onChangeTo2D?: () => void;
  onChangeTo3D?: () => void;
  onLockOnUserPosition?: () => void;
  mapControlsCss?: any;
}

// let map: any;
const TOKEN = import.meta.env.VITE_MAP_BOX_ID;
let isRotatingRef = false;
let actualAnimationFrame = 0;
let lastRotationAngle = 0;

export function isLive(timestampInMs: number | undefined) {
  if (!timestampInMs) return false;
  const currentTime = Date.now();
  return Math.abs(currentTime - timestampInMs) <= 30000;
}

export default function MapBoxGenericEvent({
  onMapReady,
  hasNativeControls,
  mapName = 'map',
  isMapFollowLocked,
  setIsMapFollowLocked,
  positions,
  hoverPositions,
  onSelectAthlete,
  featuredStream,
  isMobile = false,
  mapCenter = [0, 0],
  onMarkerClick,
  onZoomIn,
  onZoomOut,
  onChangeTo2D,
  onChangeTo3D,
  onLockOnUserPosition,
  mapStyle,
  mapControlsCss,
  polylines,
}: IProps) {
  const map = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [flyDuration, setFlyDuration] = useState<number>(0);
  const [isRotating, setIsRotating] = useState<boolean>(false);

  const polylineToGeoJSON = (polyline: Polyline) => ({
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: polyline
        ?.filter((pointData) => pointData.long && pointData.lat)
        ?.map((pointData) => [pointData.long, pointData.lat]),
      color: stringToColour(polyline?.length ? polyline[0]?.username || '' : '', 0.7),
    },
    properties: {
      elevations: polyline?.map((point) => point?.altitude), // Extract elevations
    },
  });

  const geoJSONPolylines = polylines?.map(polylineToGeoJSON);

  const initializeMapBox = () => {
    mapboxgl.accessToken = TOKEN;
    map.current = new mapboxgl.Map({
      container: mapName,
      center: mapCenter,
      pitch: isMobile ? 0 : 58,
      bearing: 0,
      zoom: 15,
      cooperativeGestures: true,
      // style: 'mapbox://styles/mapbox/satellite-streets-v12?optimize=true',
      // @ts-ignore
      style: mapStyle || MAP_STYLE,
      attributionControl: false,
    });
  };

  const add3dMapSource = () => {
    map.current.addSource('mapbox-dem', {
      type: 'raster-dem',
      url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
      tileSize: 512,
      maxzoom: 14,
    });
    map.current.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
    map.current.setFog({
      range: [10, 20],
      color: '#dc9f9f',
      'horizon-blend': 0.5,
      'high-color': '#245bde',
      'space-color': '#000000',
      'star-intensity': 0.15,
    });
  };

  const rotateCamera = (timestamp: number) => {
    if (!isRotatingRef) return;

    // clamp the rotation between 0 -360 degrees
    // Divide timestamp by 100 to slow rotation to ~10 degrees / sec
    lastRotationAngle = (timestamp / 300) % 360;
    map.current.rotateTo(lastRotationAngle, { duration: 0, essential: true });
    // Request the next frame of the animation.
    actualAnimationFrame = requestAnimationFrame(rotateCamera);
  };

  const cancelRotation = () => {
    cancelAnimationFrame(actualAnimationFrame);
    isRotatingRef = false;
    setIsRotating(false);
  };

  const onFlyAround = () => {
    if (!isRotatingRef) {
      setIsRotating(true);
      isRotatingRef = true;
      rotateCamera(lastRotationAngle);
    } else {
      cancelRotation();
    }
  };

  const onInteractWithMap = () => {
    cancelRotation();
    if (setIsMapFollowLocked) setIsMapFollowLocked(false);
  };

  const onMount = () => {
    initializeMapBox();
    map.current.on('load', () => {
      if (onMapReady) onMapReady(map.current);
      add3dMapSource();
      setFlyDuration(4000);
    });
    map.current.on('dragstart', onInteractWithMap);
    map.current.on('wheel', onInteractWithMap);
    map.current.on('pitchstart', onInteractWithMap);

    if (hasNativeControls) map.current.addControl(new mapboxgl.NavigationControl());

    return () => map.current.remove();
  };
  useEffect(onMount, []);

  const selectAthelete = (realTimePosition: RealTimePosition | undefined, isSelected: boolean) => {
    if (onSelectAthlete) onSelectAthlete(realTimePosition, isSelected);
  };

  const followSelectedStream = () => {
    if (!map || !isMapFollowLocked || !featuredStream?.latitude || !featuredStream?.longitude) return;
    const featSteramCurrentPosition = positions.find((position) => position.id === featuredStream.id);

    if (!featSteramCurrentPosition?.lat || !featSteramCurrentPosition?.long) return;
    map.current.jumpTo({
      center: [featSteramCurrentPosition.long, featSteramCurrentPosition.lat],
      zoom: map.current.getZoom()?.toFixed(0),
    });
  };
  useEffect(followSelectedStream, [featuredStream, positions]);

  const onUserClickToLockPosition = () => {
    if (onLockOnUserPosition) onLockOnUserPosition();
    if (setIsMapFollowLocked) setIsMapFollowLocked(true);
    followSelectedStream();
  };

  const lockMapFollowToSelectedStream = () => {
    if (!featuredStream || !setIsMapFollowLocked) return;
    setIsMapFollowLocked(true);
  };
  useEffect(lockMapFollowToSelectedStream, [featuredStream]);

  const getLocationMarkers = () => {
    if (map.current && positions && !hoverPositions.length) {
      return positions.map((positionItem) => {
        const { timestamp, long, lat, username, avatarPicture, stream_tile_url, id, producer } = positionItem;
        if (!long || !lat || producer) return null;

        const isSelected = featuredStream?.id === id;

        return (
          <Marker
            longitude={long}
            latitude={lat}
            map={map.current}
            mapLib={mapboxgl}
            key={id}
            offset={isSelected ? [0, -36] : [0, -33]}
          >
            <GenericMapMarkerEvent
              username={username || ''}
              isSelected={isSelected}
              useravatar={avatarPicture || ''}
              onClick={() => onMarkerClick && onMarkerClick(positionItem)}
              streamTileUrl={stream_tile_url}
              timestamp={timestamp}
              pulseLine={positionItem}
              isLive={isLive(timestamp)}
            />
          </Marker>
        );
      });
    }
    return [];
  };

  const getHoverLocationMarkers = () => {
    if (map.current && hoverPositions) {
      return hoverPositions.map((positionItem) => {
        const { long, lat, username, avatarPicture, timestamp, producer, id } = positionItem;
        if (!long || !lat || producer) return null;
        return (
          <Marker longitude={long} latitude={lat} map={map.current} mapLib={mapboxgl} key={id} offset={[0, -33]}>
            <GenericMapMarkerEvent
              isPreviewLocation
              username={username || ''}
              useravatar={avatarPicture || ''}
              onClick={() => onMarkerClick && onMarkerClick(positionItem)}
              pulseLine={positionItem}
              isLive={isLive(timestamp)}
            />
          </Marker>
        );
      });
    }
    return [];
  };

  return (
    <Flex
      css={{
        height: '100%',
        width: '100%',
        '& .map-wrapper': { height: '100%', width: '100%' },
        '& .mapboxgl-ctrl-logo': {
          display: 'none !important',
        },
        position: 'relative',
      }}
    >
      <Flex id={mapName} className="map-wrapper">
        {map.current &&
          !!geoJSONPolylines?.length &&
          geoJSONPolylines?.map((geoJSONPolyline, index) => (
            <Source key={`source-${index}`} type="geojson" data={geoJSONPolyline} map={map?.current}>
              <Layer
                key={`layer-${index}`}
                map={map?.current}
                type="line"
                layout={{
                  'line-cap': 'round',
                  'line-join': 'round',
                }}
                paint={{
                  'line-color': geoJSONPolyline.geometry.color,
                  'line-width': 6,
                }}
              />
            </Source>
          ))}

        {getLocationMarkers()}
        {getHoverLocationMarkers()}

        {!!map.current && (
          <MapControls
            mapRef={map.current}
            setIsMapFollowLocked={setIsMapFollowLocked}
            isMapFollowLocked={isMapFollowLocked}
            onZoomIn={onZoomIn}
            onZoomOut={onZoomOut}
            onChangeTo2D={onChangeTo2D}
            onChangeTo3D={onChangeTo3D}
            onLockOnUserPosition={onUserClickToLockPosition}
            onFlyAround={onFlyAround}
            isRotating={isRotating}
            css={mapControlsCss}
          />
        )}
      </Flex>
    </Flex>
  );
}
