/* eslint-disable class-methods-use-this */
import { SetStorage, GetStorage } from '@/Data/protocols/cache';

export class LocalStorageAdapter implements SetStorage, GetStorage {
  set(key: string, value: unknown): void {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.removeItem(key);
    }
  }

  get(key: string): any {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) || '') : null;
  }

  remove(key: string): void {
    return localStorage.removeItem(key);
  }
}
