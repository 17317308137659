import { useState } from 'react';
import Flex from '../../../../components/primitives/flex';
import useGlobalStore from '../../../../app-global-store';
import useEventStore from '../../state-manager/store';
// import { IUserLocation } from '../../state-manager/store-types';
import MapBoxGenericEvent from '../../../../components/third-party/map/map-generic-event';
import { PointData } from '../../state-manager/store-types';

interface IProps {
  mapId?: string;
  isPipMap?: boolean;
  isStreamExpanded?: boolean;
  isFullScreen?: boolean;
}

export function EventMap({ mapId, isPipMap = false, isStreamExpanded, isFullScreen }: IProps) {
  const eventInfo = useEventStore((state) => state.eventInfo);
  const featStream = useEventStore((state) => state.featStream);
  const eventStreamsLinearList = useEventStore((state) => state.eventStreamsLinearList);
  const setFeatStream = useEventStore((state) => state.setFeatStream);

  const analytics = useGlobalStore((state) => state.analytics);
  const setMapRef = useEventStore((state) => state.setMapRef);

  const eventCurrentStreams = useEventStore((state) => state.eventCurrentStreams);
  const eventHoverStreams = useEventStore((state) => state.eventHoverStreams);
  const pulseLinesGroupedByStream = useEventStore((state) => state.pulseLinesGroupedByStream);

  const setEventHoverStreams = useEventStore((state) => state.setEventHoverStreams);
  const setHoverEventStreamsLinearList = useEventStore((state) => state.setHoverEventStreamsLinearList);

  const [isMapFollowLocked, setIsMapFollowLocked] = useState<boolean>(true);

  const getMapControlsCss = () => {
    if (isFullScreen) return {};
    if (isStreamExpanded) return { display: 'none' };
    return { right: '18.3rem', top: '1rem' };
  };

  const onMarkerClick = (position: PointData) => {
    const foundStream = eventStreamsLinearList.find((stream) => stream.id === position.id);
    if (foundStream) setFeatStream(foundStream);
  };

  const cleanHoverStreams = () => {
    setEventHoverStreams([]);
    setHoverEventStreamsLinearList([]);
  };

  return (
    <Flex css={{ width: '100%', height: '100%' }} onMouseEnter={cleanHoverStreams}>
      {eventInfo && (
        <MapBoxGenericEvent
          mapCenter={[eventInfo.longitude || 0, eventInfo.latitude || 0]}
          mapName={mapId}
          onMapReady={(mapRef) => setMapRef(mapRef)}
          mapControlsCss={getMapControlsCss()}
          positions={eventCurrentStreams}
          hoverPositions={eventHoverStreams}
          isMapFollowLocked={isMapFollowLocked}
          featuredStream={featStream}
          setIsMapFollowLocked={setIsMapFollowLocked}
          onZoomIn={() => analytics.track('map_zoom_in_stream_view')}
          onZoomOut={() => analytics.track('map_zoom_out_stream_view')}
          onChangeTo2D={() => analytics.track('map_changed_2D_stream_view')}
          onChangeTo3D={() => analytics.track('map_changed_3D_stream_view')}
          onLockOnUserPosition={() => analytics.track('map_lock_user_location_stream_view')}
          mapStyle={eventInfo?.map_style}
          polylines={pulseLinesGroupedByStream}
          onMarkerClick={onMarkerClick}
        />
      )}
    </Flex>
  );
}
