import { styled } from '../../stitches.config';
import Flex from '../primitives/flex';

export const StyledWrapper = styled(Flex, {
  transition: 'all 0.5s',
  position: 'relative',
  width: '100%',
  height: '100%',
  '& .video-wrapper': {
    transition: 'all 0.5s',
    position: 'relative',
    '&.video-closed': {
      position: 'absolute',
      display: 'none',
      // width: '23.37rem',
      // height: '24.87rem',
      border: '2px solid $primary500',
      top: '1rem',
      left: '1rem',
      zIndex: 1,
      borderRadius: '$2lg',
      overflow: 'hidden',
      transformOrigin: 'top left',
      '&:hover': {
        transform: 'scale(1.1)',
      },
      '@bp3': {
        width: '8rem',
        height: '5.25rem',
        '&:hover': {
          width: '12.5rem',
          height: '8.25rem',
        },
      },
    },
  },

  '& .map-wrapper': {
    transition: 'all 0.5s',
    position: 'relative',
    justifySelf: 'flex-end',
    '&.map-closed': {
      position: 'absolute',
      border: '2px solid $primary500',
      top: '1rem',
      right: '1rem',
      zIndex: 1,
      borderRadius: '$2lg',
      overflow: 'hidden',
      transformOrigin: 'top right',
      '&:hover': {
        transform: 'scale(1.1)',
      },
      '@bp3': {
        width: '8rem',
        height: '5.25rem',
        '&:hover': {
          width: '12.5rem',
          height: '8.25rem',
        },
      },
    },
  },

  '& .video-stream-picture-and-picture': {
    transition: 'all 0.5s',
    position: 'absolute',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    right: 10,
    top: 10,
    zIndex: 1,
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.75)',
    '&:hover': {
      transform: 'scale(.96)',
    },
    '&:active': {
      transform: 'scale(.90)',
    },
    '&.video-opened': {
      right: 'initial',
      left: 120,
    },
    '@bp3': {
      top: 50,
      left: '15px !important',
    },
  },
  '& .map-picture-and-picture': {
    transition: 'all 0.5s',
    position: 'absolute',
    cursor: 'pointer',
    right: 10,
    top: 10,
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.75)',
    '&:hover': {
      transform: 'scale(.96)',
    },
    '&:active': {
      transform: 'scale(.90)',
    },
  },
  '& .map-full-screen': {
    transition: 'all 0.5s',
    position: 'absolute',
    cursor: 'pointer',
    right: 55,
    top: 10,
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.75)',
    '&:hover': {
      transform: 'scale(.96)',
    },
    '&:active': {
      transform: 'scale(.90)',
    },
  },
});
