import { createContext, Dispatch, SetStateAction } from 'react';
import { VideoJsPlayer } from 'video.js';
import { AnalyticsLogging } from '../../../data/protocols/event-tracking/event-tracking';
import { SocialAuth } from '../../../data/protocols/social-auth';
import { SourceLocation, TimelineModel } from '../../../domain/models/event-timeline-model';
import { StreamModel } from '../../../domain/models/stream-model';
import { GetEventInfo } from '../../../domain/usecases';
import { LocalStorageAdapter } from '../../../infra/cache';

export interface RealTimePosition {
  id: string;
  latitude: number;
  longitude: number;
  color?: string;
  useravatar?: string;
  username?: string;
  videoTimeOffset?: number;
  videoPlayerRef?: VideoJsPlayer | null;
}
export interface ISquadVideoContext {
  isMobile: boolean;
  socialSignIn: SocialAuth;
  onFirebaseAuthSignIn: () => void;
  localStorageAdapter: LocalStorageAdapter;
  userMarketingEventTrack: AnalyticsLogging;
  eventInfo: GetEventInfo.Model | null;
  isChatVisible: boolean;
  isTimelineLoading: boolean;
  selectedDate: string | null;
  setSelectedDate: Dispatch<SetStateAction<string | null>>;
  timeline: TimelineModel[];
  streamList: Map<string, StreamModel> | null;
  streamSourceLocation: SourceLocation[];
  selectedTime: string | null;
  setSelectedTime: Dispatch<SetStateAction<string | null>>;
  setStateRealTimeAthletePositions: Dispatch<SetStateAction<Map<string, RealTimePosition>>>;
  realTimeAthletePositions: Map<string, RealTimePosition>;
  setRealTimeAthletePositions: (positions: Map<string, RealTimePosition>) => void;
  athletePositionsOnHover: Map<string, RealTimePosition>;
  setAthletePositionsOnHover: Dispatch<SetStateAction<Map<string, RealTimePosition>>>;
  featuredStream: Partial<StreamModel> | null;
  setFeaturedStream: Dispatch<SetStateAction<Partial<StreamModel> | null>>;
  getEvent: GetEventInfo;
  isLiveSelected: boolean;
  setIsLiveSelected: Dispatch<SetStateAction<boolean>>;
  liveStreams: StreamModel[];
  setLiveStreams: Dispatch<SetStateAction<StreamModel[]>>;
  openLiveStream: () => void;
  isMapFollowLocked: boolean;
  setIsMapFollowLocked: Dispatch<SetStateAction<boolean>>;
  featuredStreamPlayerRef: VideoJsPlayer | null;
  setFeaturedStreamPlayerRef: Dispatch<SetStateAction<VideoJsPlayer | null>>;
}

export const SquadVideoContext = createContext({} as ISquadVideoContext);
