import { NewStreamModel } from '../../../../domain/models/new-stream-model';
import { EventStream, ListOfPolylines, NewEventStream, PointData, Polyline } from '../state-manager/store-types';

export function groupPulseLinesByUserNameAndSortByTimestamp(polylines: ListOfPolylines): PointData[][] {
  const groupedByUsername: Record<string, PointData[]> = {};

  polylines?.forEach((polyline) => {
    polyline?.forEach((pointData) => {
      const { username } = pointData;

      if (!groupedByUsername[username]) {
        groupedByUsername[username] = [];
      }

      groupedByUsername[username].push(pointData);
    });
  });

  const sortedData: PointData[][] = Object.values(groupedByUsername).map((points) =>
    points.sort((a, b) => a.timestamp - b.timestamp),
  );

  // Sort the higher-level polylines based on the timestamp of the first position in each polyline
  sortedData.sort((a, b) => a[0].timestamp - b[0].timestamp);

  return sortedData;
}

export function getAllPulseLinesFromAllStreams(eventStreams: NewEventStream[] | EventStream[]): ListOfPolylines {
  return eventStreams.map((stream) => stream.pulse_lines as Polyline);
}

export function isLive(status: string) {
  return status === 'started';
}

export function hasLiveStreamOnEvent(streamList: NewEventStream[] | NewStreamModel[]): boolean {
  return streamList.some((stream) => isLive(stream.status));
}

export function calculateStreamProgress(
  stream_start_date_ms: number,
  stream_end_date_ms: number | null,
  current_time_ms: number,
): number {
  const stream_start_date_s = stream_start_date_ms / 1000;
  const stream_end_date_s = stream_end_date_ms ? stream_end_date_ms / 1000 : Date.now() / 1000;

  if (current_time_ms / 1000 < stream_start_date_s) {
    return 0;
  }

  if (current_time_ms / 1000 > stream_end_date_s) {
    return 100;
  }

  const total_stream_duration_s = stream_end_date_s - stream_start_date_s;
  const elapsed_time_s = current_time_ms / 1000 - stream_start_date_s;
  const progress = (elapsed_time_s / total_stream_duration_s) * 100;

  return progress;
}
