import { create } from 'zustand';
import { AnalyticsLogging } from '../data/protocols/event-tracking/event-tracking';
import { AnalyticsLoggingClient } from '../infra/event-tracking/event-tracking';

interface AppGlobalState {
  analytics: AnalyticsLogging;
}

const useGlobalStore = create<AppGlobalState>()(() => ({
  analytics: new AnalyticsLoggingClient(),
}));

export default useGlobalStore;
