import AvatarUser from '../../../../components/avatar-user/avatar-user';
import { LiveChipWithBorderIcon } from '../../../../components/icons/live-chip-border';
import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';
import { stringToColour } from '../../../../utils/utils';
import { EventStream } from '../../state-manager/store-types';

interface ILiveChannelItemProps {
  featuredStream: EventStream | null;
  stream: EventStream;
  onClick: (id: string) => void;
}

export function LiveChannelItem({ featuredStream, stream, onClick }: ILiveChannelItemProps) {
  return (
    <Flex
      onClick={() => onClick(stream.id)}
      key={stream.id}
      css={{
        touchAction: 'manipulation',
        cursor: 'pointer',
        height: '3rem',
        padding: '0px $xs',
        alignItems: 'center',
        width: 'fit-content',
        borderRadius: '$lg',
        transition: 'all .5s ease',
        gap: '$2xs',
        backgroundColor: stringToColour(stream?.content.username, 0.1),
        border: featuredStream?.id === stream.id ? `2px solid ${stringToColour(stream?.content.username)}` : '',
        filter: featuredStream?.id === stream.id ? 'brightness(1.75)' : '',
        '&:active': {
          transform: 'scale(.98)',
        },
        '@hover': {
          '&:hover': {
            transform: 'scale(.96)',
          },
        },
      }}
    >
      <AvatarUser
        user={{
          username: stream?.content.username || '',
          useravatar: stream?.content.useravatar || '',
        }}
        isSelected={featuredStream?.id === stream.id}
        css={{
          opacity: featuredStream?.id === stream.id ? 1 : 0.5,
          borderWidth: '1px',
          width: '1.8rem',
          height: '1.8rem',
        }}
      />
      <Flex css={{ flexDirection: 'column' }}>
        <Flex css={{ gap: '$3xs', alignItems: 'center' }}>
          <Typo type="bodySmallMedium" css={{ color: '$constantWhite', whiteSpace: 'nowrap' }}>
            {stream.channelTitle}
          </Typo>
          <LiveChipWithBorderIcon />
        </Flex>

        <Typo type="caption" css={{ color: '$constantWhite', whiteSpace: 'nowrap', opacity: '0.7' }}>
          {stream?.content.title}
        </Typo>
      </Flex>
    </Flex>
  );
}
