import { useMediaQuery } from 'usehooks-ts';
import Flex from '../primitives/flex';
import ButtonSocialInvite from '../button-social-invite/button-social-invite';
import Box from '../primitives/box';
import Button from '../primitives/button';
import { PulseLogoHorizontalDark } from '../icons/pulse-logo-horizontal-dark';
import useGlobalStore from '../../app-global-store';
import useAuthStore from '../../pages/auth-wrapper/state-manager/store';
import { LoggedAvatar } from '../logged-avatar/logged-avatar';

interface IProps {
  showShareButton?: boolean;
}

export default function TopHeader({ showShareButton = true }: IProps) {
  const analytics = useGlobalStore((state) => state.analytics);
  useAuthStore((state) => state.authToggleState);
  const isLogged = useAuthStore((state) => state.isLogged);
  const openAuthModalIfItsNotLogged = useAuthStore((state) => state.openAuthModalIfItsNotLogged);
  const isUserLogged = isLogged();
  const isMobile = useMediaQuery('(max-width: 900px)');

  return (
    <Flex
      className="top-header"
      css={{
        width: '100%',
        height: '4.5rem',
        backgroundColor: '$bgAppScreen100',
        paddingLeft: '$sm',
        paddingRight: '$sm',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .mobile': {
          display: 'none',
        },
        '@landscape': {
          // display: 'none',
        },
        '@bp3': {
          backgroundColor: '$bgAppScreen100',
          paddingLeft: '$sm',
          paddingRight: '$3xs',
          height: '3rem',
          '& .mobile': {
            display: 'flex',
          },
          '& .desktop': {
            display: 'none',
          },
        },
      }}
    >
      <Box
        as="a"
        href="https://pulse.tv/"
        onClick={() => {
          analytics?.track(`clicked_pulse_logo`);
        }}
        className="logo-wrapper"
        target="_blank"
        css={{
          touchAction: 'manipulation',
          transition: 'all 0.5s',
          marginLeft: '0.625rem',
          '&:hover': {
            transform: 'scale(.96)',
          },
          '&:active': {
            transform: 'scale(.90)',
          },
        }}
      >
        <PulseLogoHorizontalDark />
      </Box>

      <Flex className="header-right-side" css={{ alignItems: 'center' }}>
        {showShareButton && <ButtonSocialInvite />}

        {!isUserLogged && !isMobile && (
          <>
            <Button
              onClick={openAuthModalIfItsNotLogged}
              variant="transparent"
              css={{ alignItems: 'center', gap: '$3xs', textDecoration: 'none' }}
            >
              <span className="desktop">Sign up</span>
            </Button>
            <Button
              onClick={openAuthModalIfItsNotLogged}
              variant="transparent"
              css={{ alignItems: 'center', gap: '$3xs', textDecoration: 'none' }}
            >
              <span className="desktop">Login</span>
            </Button>
          </>
        )}
        {!!isUserLogged && <LoggedAvatar />}
        {!isUserLogged && isMobile && (
          <Button
            onClick={openAuthModalIfItsNotLogged}
            variant="transparent"
            css={{ alignItems: 'center', gap: '$3xs', textDecoration: 'none' }}
          >
            <span>Login</span>
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
