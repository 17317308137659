export function PulseLogoHorizontalDark() {
  return (
    <svg width="86" height="20" viewBox="0 0 86 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_107_7511)">
        <path
          d="M3.99268 18.077L6.48156 19.5019H0.421367C0.309441 19.5014 0.20227 19.4571 0.123302 19.3787C0.0443333 19.3003 -1.09567e-06 19.1943 0 19.0837V0.918194C-1.09567e-06 0.807603 0.0443333 0.701512 0.123302 0.623139C0.20227 0.544765 0.309441 0.500489 0.421367 0.5H11.7009L4.02265 4.03243C3.9471 4.06728 3.88319 4.1227 3.83841 4.19219C3.79363 4.26168 3.76984 4.34234 3.76983 4.42472V17.6959C3.76948 17.7732 3.78993 17.8493 3.82909 17.9162C3.86824 17.9832 3.9247 18.0387 3.99268 18.077Z"
          fill="#DAFF01"
        />
        <path
          d="M12.2759 0.5L14.8509 1.98033C14.8938 2.00542 14.9288 2.04159 14.9524 2.08494C14.9759 2.12828 14.987 2.17716 14.9845 2.22629C14.982 2.27542 14.966 2.32294 14.9382 2.36373C14.9103 2.40452 14.8718 2.43702 14.8266 2.45774L7.27571 5.9217C7.20404 5.95437 7.14326 6.00652 7.10048 6.07205C7.0577 6.13759 7.03469 6.2138 7.03412 6.29178V19.3427C7.03686 19.396 7.02026 19.4485 6.9873 19.4907H18.8062C18.9181 19.4903 19.0253 19.446 19.1042 19.3676C19.1832 19.2892 19.2275 19.1832 19.2275 19.0726V0.918194C19.2275 0.807603 19.1832 0.701512 19.1042 0.623138C19.0253 0.544765 18.9181 0.500489 18.8062 0.5H12.2759ZM15.5176 10.7328C15.5159 10.7967 15.4967 10.859 15.4621 10.9131C15.4275 10.9671 15.3787 11.011 15.321 11.0399L9.90691 13.5158C9.86479 13.5348 9.81851 13.5429 9.77234 13.5396C9.72617 13.5362 9.68161 13.5214 9.64278 13.4965C9.60395 13.4716 9.57211 13.4374 9.55019 13.3971C9.52827 13.3568 9.51698 13.3117 9.51738 13.266V11.1287C9.5182 11.0242 9.54886 10.9221 9.60585 10.834C9.66283 10.746 9.74382 10.6756 9.83949 10.631L12.6261 9.34681C12.6832 9.31935 12.7311 9.27639 12.7642 9.22296C12.7974 9.16953 12.8145 9.10786 12.8134 9.04519V6.46387C12.8149 6.36211 12.8447 6.26272 12.8995 6.17657C12.9544 6.09041 13.0321 6.02081 13.1243 5.97536L15.1262 5.07606C15.1681 5.0568 15.2143 5.04828 15.2604 5.05127C15.3065 5.05427 15.3512 5.06869 15.3901 5.09321C15.4291 5.11773 15.4613 5.15156 15.4835 5.19157C15.5058 5.23159 15.5176 5.27649 15.5176 5.32217V10.7328Z"
          fill="#DAFF01"
        />
        <path
          d="M23.7969 16.665V3.34202C23.7944 3.3021 23.8006 3.26211 23.815 3.22473C23.8294 3.18734 23.8517 3.15342 23.8804 3.12523C23.9091 3.09703 23.9436 3.07521 23.9815 3.06122C24.0194 3.04723 24.0599 3.04139 24.1003 3.0441H28.3571C31.6999 3.0441 33.3105 4.89452 33.3105 7.55911C33.3105 10.2237 31.6905 12.0889 28.3571 12.0889H26.6716V16.665C26.6741 16.7049 26.6679 16.7449 26.6535 16.7823C26.6391 16.8196 26.6168 16.8536 26.5881 16.8818C26.5594 16.91 26.5249 16.9318 26.487 16.9458C26.4491 16.9598 26.4086 16.9656 26.3682 16.9629H24.1003C24.0599 16.9656 24.0194 16.9598 23.9815 16.9458C23.9436 16.9318 23.9091 16.91 23.8804 16.8818C23.8517 16.8536 23.8294 16.8196 23.815 16.7823C23.8006 16.7449 23.7944 16.7049 23.7969 16.665ZM28.2466 9.5631C29.4751 9.5631 30.5425 9.14491 30.5425 7.55355C30.5425 5.9622 29.4751 5.56436 28.2466 5.56436H26.6716V9.5631H28.2466Z"
          fill="#DAFF01"
        />
        <path
          d="M37.3218 11.6726V3.34578C37.3194 3.30602 37.3255 3.2662 37.3398 3.22896C37.354 3.19171 37.3762 3.15788 37.4047 3.12972C37.4332 3.10156 37.4674 3.07969 37.5051 3.06558C37.5428 3.05147 37.5831 3.04543 37.6233 3.04787H39.8987C39.9391 3.04516 39.9796 3.05099 40.0175 3.06498C40.0555 3.07897 40.0899 3.10079 40.1186 3.12899C40.1474 3.15718 40.1696 3.1911 40.184 3.22849C40.1984 3.26587 40.2046 3.30586 40.2021 3.34578V11.6208C40.2021 13.4527 41.1066 14.4464 42.6573 14.4464C44.2079 14.4464 45.0918 13.4527 45.0918 11.6208V3.33653C45.0895 3.29642 45.096 3.2563 45.1107 3.21886C45.1255 3.18143 45.1482 3.14754 45.1772 3.11949C45.2063 3.09144 45.2412 3.06987 45.2794 3.05624C45.3177 3.04261 45.3584 3.03723 45.399 3.04046H47.6743C47.7146 3.03803 47.7549 3.04407 47.7926 3.05818C47.8303 3.07229 47.8645 3.09415 47.893 3.12232C47.9215 3.15048 47.9436 3.18431 47.9579 3.22156C47.9722 3.2588 47.9783 3.29862 47.9758 3.33838V11.6652C47.9758 15.3253 45.7623 17.2164 42.661 17.2164C39.5597 17.2164 37.3218 15.3327 37.3218 11.6726Z"
          fill="#DAFF01"
        />
        <path
          d="M60.5682 14.7354V16.6653C60.5706 16.7051 60.5645 16.7449 60.5502 16.7822C60.5359 16.8194 60.5138 16.8532 60.4853 16.8814C60.4568 16.9096 60.4226 16.9314 60.3849 16.9455C60.3472 16.9596 60.3069 16.9657 60.2666 16.9632H53.3731C53.3328 16.9657 53.2926 16.9596 53.2549 16.9455C53.2172 16.9314 53.1829 16.9096 53.1544 16.8814C53.1259 16.8532 53.1038 16.8194 53.0895 16.7822C53.0752 16.7449 53.0691 16.7051 53.0716 16.6653V3.34239C53.0691 3.30263 53.0752 3.26281 53.0895 3.22556C53.1038 3.18832 53.1259 3.15449 53.1544 3.12633C53.1829 3.09816 53.2172 3.0763 53.2549 3.06219C53.2926 3.04808 53.3328 3.04204 53.3731 3.04447H55.6485C55.6887 3.04204 55.729 3.04808 55.7667 3.06219C55.8044 3.0763 55.8386 3.09816 55.8671 3.12633C55.8956 3.15449 55.9178 3.18832 55.932 3.22556C55.9463 3.26281 55.9524 3.30263 55.95 3.34239V14.4448H60.2573C60.2976 14.4411 60.3383 14.446 60.3766 14.459C60.4148 14.4721 60.4499 14.493 60.4793 14.5205C60.5087 14.548 60.5319 14.5814 60.5472 14.6185C60.5625 14.6555 60.5697 14.6954 60.5682 14.7354Z"
          fill="#DAFF01"
        />
        <path
          d="M64.4204 15.0347C64.3699 14.9754 64.3438 14.8995 64.3472 14.822C64.3507 14.7446 64.3835 14.6712 64.4392 14.6165L65.6471 13.2435C65.6724 13.2097 65.705 13.1819 65.7425 13.1622C65.7801 13.1424 65.8216 13.1311 65.8641 13.1292C65.9066 13.1272 65.949 13.1346 65.9882 13.1509C66.0275 13.1672 66.0626 13.1919 66.0909 13.2232C66.9767 14.1188 67.7764 14.5962 68.93 14.5962C70.1173 14.5962 70.902 14.0984 70.902 13.1048C70.902 12.1703 70.1978 11.8317 69.2165 11.3931L67.5048 10.5974C66.0066 9.92205 64.6957 8.86732 64.6957 6.79856C64.6957 4.2709 66.649 2.87939 69.0461 2.87939C70.7166 2.87939 72.065 3.53629 73.1661 4.70945C73.1986 4.73771 73.2249 4.77219 73.2435 4.81079C73.2621 4.84938 73.2726 4.89131 73.2744 4.93402C73.2761 4.97674 73.2691 5.01935 73.2537 5.05931C73.2383 5.09927 73.2149 5.13573 73.1849 5.1665L72.0369 6.5395C72.016 6.57666 71.9871 6.60878 71.9522 6.63355C71.9173 6.65832 71.8773 6.67511 71.835 6.68273C71.7927 6.69034 71.7492 6.68858 71.7077 6.67757C71.6662 6.66656 71.6277 6.64658 71.5949 6.61907C70.7297 5.8641 70.0237 5.48477 69.1379 5.48477C68.1116 5.48477 67.4674 5.98253 67.4674 6.81892C67.4674 7.6553 68.0292 7.95137 69.1191 8.44913L70.5687 9.10602C72.0987 9.82213 73.7092 10.7566 73.7092 13.1048C73.7092 15.91 71.3927 17.2016 68.9974 17.2016C66.7951 17.2016 65.2501 16.1487 64.4204 15.0347Z"
          fill="#DAFF01"
        />
        <path
          d="M78.2281 16.6653V3.34239C78.2256 3.30263 78.2317 3.26281 78.246 3.22556C78.2603 3.18832 78.2824 3.15449 78.3109 3.12633C78.3394 3.09816 78.3737 3.0763 78.4114 3.06219C78.4491 3.04808 78.4893 3.04204 78.5296 3.04447H85.6984C85.7387 3.04204 85.779 3.04808 85.8167 3.06219C85.8544 3.0763 85.8886 3.09816 85.9171 3.12633C85.9456 3.15449 85.9677 3.18832 85.982 3.22556C85.9963 3.26281 86.0024 3.30263 85.9999 3.34239V5.26126C86.0024 5.30102 85.9963 5.34084 85.982 5.37809C85.9677 5.41533 85.9456 5.44916 85.9171 5.47732C85.8886 5.50549 85.8544 5.52735 85.8167 5.54146C85.779 5.55557 85.7387 5.56161 85.6984 5.55918H81.1083V8.64381H85.4363C85.4765 8.64138 85.5168 8.64742 85.5545 8.66153C85.5922 8.67564 85.6264 8.6975 85.6549 8.72566C85.6834 8.75383 85.7056 8.78766 85.7198 8.8249C85.7341 8.86215 85.7402 8.90197 85.7378 8.94173V10.8717C85.7402 10.9115 85.7341 10.9513 85.7198 10.9885C85.7056 11.0258 85.6834 11.0596 85.6549 11.0878C85.6264 11.1159 85.5922 11.1378 85.5545 11.1519C85.5168 11.166 85.4765 11.1721 85.4363 11.1696H81.1083V14.4319H85.6984C85.7387 14.4295 85.779 14.4355 85.8167 14.4496C85.8544 14.4637 85.8886 14.4856 85.9171 14.5137C85.9456 14.5419 85.9677 14.5757 85.982 14.613C85.9963 14.6502 86.0024 14.6901 85.9999 14.7298V16.6598C86.0024 16.6995 85.9963 16.7394 85.982 16.7766C85.9677 16.8139 85.9456 16.8477 85.9171 16.8758C85.8886 16.904 85.8544 16.9259 85.8167 16.94C85.779 16.9541 85.7387 16.9601 85.6984 16.9577H78.5296C78.4898 16.9601 78.45 16.9542 78.4128 16.9404C78.3755 16.9266 78.3415 16.9052 78.3131 16.8777C78.2847 16.8501 78.2624 16.817 78.2478 16.7804C78.2332 16.7438 78.2264 16.7046 78.2281 16.6653Z"
          fill="#DAFF01"
        />
      </g>
      <defs>
        <clipPath id="clip0_107_7511">
          <rect width="86" height="19" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
