import { useState } from 'react';
import useAuthStore from '../../pages/auth-wrapper/state-manager/store';
import { darkTheme } from '../../stitches.config';
import AvatarUser from '../avatar-user/avatar-user';
import Button from '../primitives/button';
import { Provider, Tooltip, TooltipContent, TooltipTrigger } from '../primitives/tootip/tooltip';

export function LoggedAvatar() {
  const getCurrentUser = useAuthStore((state) => state.getCurrentUser);
  const isLogged = useAuthStore((state) => state.isLogged);
  const logout = useAuthStore((state) => state.logout);
  const [isTootipOpened, setIsTootipOpened] = useState<boolean>(false);

  const toggleTooltip = () => {
    setIsTootipOpened(!isTootipOpened);
  };

  const profile = getCurrentUser();
  if (!profile || !isLogged()) return null;
  return (
    <Provider>
      <Tooltip delayDuration={0} open={isTootipOpened}>
        <TooltipTrigger asChild>
          <Button onClick={toggleTooltip} variant="transparent" size="sm">
            <AvatarUser
              css={{ height: '2.2rem', width: '2.2rem', border: 'none' }}
              user={{ username: profile.username, useravatar: profile.profile_photo.original }}
            />
          </Button>
        </TooltipTrigger>
        <TooltipContent
          className={darkTheme}
          side="bottom"
          align="start"
          alignOffset={-130}
          onPointerDownOutside={toggleTooltip}
          css={{
            backgroundColor: '$bgAppScreen200',
            width: '12.5rem',
            height: '6',
            padding: '$xs',
            borderRadius: '$2lg',
            zIndex: 10000,
          }}
        >
          <Button onClick={logout} variant="transparent">
            Log out
          </Button>
        </TooltipContent>
      </Tooltip>
    </Provider>
  );
}
