import { useCallback, useMemo } from 'react';
import Flex from '../../primitives/flex';
import { Typo } from '../../primitives/typo/typo';
import { stringToColour } from '../../../utils/utils';
import { LiveChipTimelineIcon } from '../../icons/live-chip-timeline';
import useEventStore from '../../../pages/new-event/state-manager/store';
import { Avatar, AvatarFallback, AvatarImage } from '../../primitives/avatar-image/avatar-image';
import { keyframes } from '../../../stitches.config';

interface IProps {
  content?: any;
}

const skeletonAnimation = keyframes({
  from: { backgroundPosition: '-60% 0, 0 0' },
  to: { backgroundPosition: '180% 0, 0 0' },
});

export default function TimelineItem({ content }: IProps) {
  const isLiveStream = useCallback(() => content?.duration_in_seconds <= 0, [content]);
  const featStream = useEventStore((state) => state.featStream);
  const isSelected = useMemo(() => featStream?.id === content?.id, [featStream]);
  if (!content) return null;

  const activeStyle = {
    background: `linear-gradient( to right, rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.1) 80%), ${stringToColour(
      content?.username || 'pulse',
    )}`,
    backgroundRepeat: 'repeat-y',
    backgroundSize: '40% 800px',
    backgroundPosition: '0 0',
    animation: `${skeletonAnimation} 2s infinite`,
  };

  return (
    <Flex
      className="timeline-item"
      id={content?.id}
      data-state={isSelected ? 'active' : 'none'}
      css={{
        gap: '$xs',
        minWidth: '100%',
        height: '100%',
        backgroundColor: stringToColour(content?.username || 'pulse', isSelected ? 1 : 0.5),
        alignItems: 'center',
        paddingLeft: '$nano',
        position: 'relative',
        '&[data-state="active"]': activeStyle,
      }}
    >
      <Flex css={{ gap: '$3xs', alignItems: 'center' }}>
        <Avatar
          css={{
            width: '16px',
            height: '16px',
          }}
          className="avatar-user"
        >
          <AvatarImage src={content.useravatar} alt={content.username} />
          <AvatarFallback delayMs={600}>{content.username?.substring(0, 2).toUpperCase()}</AvatarFallback>
        </Avatar>

        <Typo css={{ color: '$constantWhite' }}>{content?.title}</Typo>
        {isLiveStream() && (
          <Flex>
            <LiveChipTimelineIcon />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
