export function ThreeDimensionIcon() {
  return (
    <svg width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.43611 10C3.61714 10 2.92556 9.90141 2.36138 9.70423C1.79719 9.50704 1.34221 9.25822 0.996419 8.95775C0.65973 8.64789 0.409487 8.32864 0.245692 8C0.0909972 7.66197 0.0090997 7.35211 0 7.07042C0 6.98592 0.0272992 6.91549 0.0818975 6.85916C0.145596 6.79343 0.218393 6.76056 0.300291 6.76056H3.04386C3.17125 6.76056 3.26225 6.78404 3.31685 6.83099C3.38055 6.86855 3.44424 6.92019 3.50794 6.98592C3.56254 7.03286 3.62624 7.07981 3.69904 7.12676C3.77183 7.17371 3.86738 7.21127 3.98568 7.23944C4.10397 7.25822 4.25867 7.26761 4.44976 7.26761C4.76825 7.26761 4.99575 7.19718 5.13224 7.05634C5.27784 6.9061 5.35064 6.7277 5.35064 6.52113C5.35064 6.30517 5.27329 6.15024 5.11859 6.05634C4.973 5.95305 4.75005 5.90141 4.44976 5.90141H2.74357C2.64347 5.90141 2.55702 5.86385 2.48422 5.78873C2.41143 5.71362 2.37503 5.62441 2.37503 5.52113V4.38028C2.37503 4.23944 2.40233 4.12207 2.45692 4.02817C2.52062 3.92488 2.58432 3.85446 2.64802 3.8169L4.54531 2.74648H0.955471C0.855374 2.74648 0.768926 2.70892 0.696128 2.6338C0.623331 2.55869 0.586932 2.46948 0.586932 2.3662V0.380282C0.586932 0.276996 0.623331 0.187794 0.696128 0.112676C0.768926 0.0375588 0.855374 0 0.955471 0H7.94406C8.04415 0 8.1306 0.0375588 8.2034 0.112676C8.27619 0.187794 8.31259 0.276996 8.31259 0.380282V2.16901C8.31259 2.30047 8.28529 2.41784 8.2307 2.52113C8.1761 2.61502 8.1124 2.68545 8.0396 2.73239L6.4426 3.83099V3.87324C6.89759 3.98592 7.31163 4.15493 7.68471 4.38028C8.0578 4.60563 8.35354 4.91549 8.57194 5.30986C8.79033 5.69484 8.89953 6.1831 8.89953 6.77465C8.89953 7.42254 8.70388 7.99061 8.31259 8.47887C7.93041 8.95775 7.40262 9.33333 6.72924 9.60563C6.06496 9.86855 5.30059 10 4.43611 10Z"
        fill="#131313"
        fillOpacity="0.62"
      />
      <path
        d="M10.4827 9.85916C10.3826 9.85916 10.2961 9.8216 10.2233 9.74648C10.1505 9.67136 10.1141 9.58216 10.1141 9.47887V0.380282C10.1141 0.276996 10.1505 0.187794 10.2233 0.112676C10.2961 0.0375588 10.3826 0 10.4827 0H14.4137C15.2691 0 16.0335 0.150235 16.7069 0.450704C17.3803 0.741784 17.9171 1.17371 18.3175 1.74648C18.727 2.31925 18.9454 3.02817 18.9727 3.87324C18.9909 4.277 19 4.6338 19 4.94366C19 5.24413 18.9909 5.58685 18.9727 5.97183C18.9363 6.85446 18.727 7.58685 18.3448 8.16901C17.9626 8.74178 17.4394 9.16901 16.7751 9.45071C16.1199 9.72301 15.3556 9.85916 14.482 9.85916H10.4827ZM13.5265 7.11268H14.4137C14.6139 7.11268 14.7914 7.07512 14.9461 7C15.1099 6.91549 15.2418 6.78873 15.3419 6.61972C15.442 6.4507 15.4966 6.23474 15.5057 5.97183C15.5239 5.69953 15.533 5.4554 15.533 5.23944C15.5421 5.02348 15.5421 4.80751 15.533 4.59155C15.533 4.3662 15.5239 4.12676 15.5057 3.87324C15.4875 3.47887 15.3647 3.19249 15.1372 3.01408C14.9188 2.83568 14.6549 2.74648 14.3455 2.74648H13.5265V7.11268Z"
        fill="#131313"
        fillOpacity="0.62"
      />
    </svg>
  );
}
