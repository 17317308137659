/* eslint-disable react/no-array-index-key */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import React, { useRef, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';
import { closestTimestampIndex } from './find-location';
import * as S from './styles';
import { styled } from '../../stitches.config';
import { Text } from '../primitives/text/text';
import { HoverPreview } from './HoverPreview';
import { NewEventStream } from '../../pages/event/state-manager/store-types';
import Flex from '../primitives/flex';
import { PulseAvatar } from '../pulse-avatar/pulse-avatar';

const TICK_WIDTH = 282;
const TICKS_PER_HOUR = 6;
const TICKS_COUNT = 24 * TICKS_PER_HOUR;
const HOUR_IN_MINUTES = 60;
const DAY_IN_MINUTES = 24 * HOUR_IN_MINUTES;
const UPDATE_INTERVAL = 1000;

const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;
const TOTAL_MINUTES_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR;

export type PointData = {
  timestamp: number;
  lat: number;
  long: number;
  altitude: number | null;
  speed: number | null;
  gps_precision: number;
  id: string;
  playback_url: string;
  vod_playback_url: string;
  logo_url: string;
  title: string;
  username: string;
  avatarPicture: string;
  duration_in_seconds: number;
  stream_tile_url: string;
  offset_ms: number;
  stream_start_date_ms: number;
  stream_end_date_ms: number | null;
  producer: boolean;
  speedType?: string;
  sponsorLogo?: string;
  official: boolean;
};

export type Polyline = PointData[];

export type ListOfPolylines = Polyline[];

export interface TimelineProps {
  currentTime: Date;
  onHover?: (hoveredTime: Date | null) => void;
  onReady?: (timelineElement: HTMLElement) => void;
  isLive?: boolean;
  selectedTime?: Date | null;
  startDate: Date;
  endDate: Date;
  polylinesGroupedByUser: ListOfPolylines;
  polylinesGroupedByStream?: ListOfPolylines;
  onFindCurrentTimeStreamPositions: (closestData: Polyline) => void;
  onFindHoverPreviewStreamPositions: (closestData: Polyline) => void;
  setCurrentSelectedTime?: (time: Date | null) => void;
  onFindCurrentStreamList: (streams: NewEventStream[]) => void;
  onFindHoverStreamList: (streams: NewEventStream[]) => void;
  onClickTimelineAvatar: (stream: NewEventStream) => void;
  linearStreamList: NewEventStream[];
}

type TimeInterval = {
  startDate: Date;
  endDate: Date;
  stream: NewEventStream;
};

const getAvailableContent = (streamLinearListParam: NewEventStream[]): TimeInterval[] => {
  const intervals: TimeInterval[] = [];

  streamLinearListParam.forEach((stream) => {
    // Extract the timestamp and duration from the first PointData item
    const startDate = new Date(stream.stream_start_date_ms);
    const endDate = stream.stream_end_date_ms ? new Date(stream.stream_end_date_ms) : new Date();

    // Add the current interval to the list without checking for overlap
    intervals.push({ startDate, endDate, stream });
  });
  return intervals;
};

const AvailableContent = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  backgroundColor: 'rgba(218, 255, 1, 0.25)',
  zIndex: 0,
});

function TimelineComponent({
  currentTime,
  onHover,
  isLive = false,
  selectedTime,
  startDate,
  endDate,
  polylinesGroupedByUser,
  polylinesGroupedByStream,
  onFindCurrentTimeStreamPositions,
  onFindHoverPreviewStreamPositions,
  onFindCurrentStreamList,
  onFindHoverStreamList,
  onClickTimelineAvatar,
  linearStreamList,
  setCurrentSelectedTime,
  onReady,
}: TimelineProps) {
  const timelineRef = useRef<HTMLDivElement | null>(null);
  const hoverLineElement = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [hoveredPosition, setHoveredPosition] = useState<number | null>(null);
  const [isWithMouseOnTopOfTheTimeline, setIsWithMouseOnTopOfTheTimeline] = useState<boolean>(false);
  const [previewLeft, setPreviewLeft] = useState<number | null>(null);

  const [now, setNow] = useState<Date>(new Date());
  const [hoveredTime, setHoveredTime] = useState<Date | null>(null);

  const [hasBeenDragged, setHasBeenDragged] = useState<boolean>(false);
  const [intervals, setIntervals] = useState<TimeInterval[]>([]);
  const scrollToTime = (time: Date) => {
    const minutesPassed = time.getHours() * HOUR_IN_MINUTES + time.getMinutes();
    const pixelsPassed = (minutesPassed * TICK_WIDTH) / HOUR_IN_MINUTES;
    const timelineElement = timelineRef.current;

    if (timelineElement) {
      const targetPosition = pixelsPassed - timelineElement.clientWidth / 2;
      timelineElement.scrollTo({
        left: targetPosition,
        behavior: 'smooth',
      });
    }
  };

  const onMount = () => {
    scrollToTime(currentTime);
  };
  useEffect(onMount, [isLive]);

  const onTimelineReady = () => {
    if (!timelineRef?.current || !timelineRef || !onReady) return;
    onReady(timelineRef.current);
  };
  useEffect(onTimelineReady, [timelineRef]);

  useEffect(() => {
    setIntervals(getAvailableContent(linearStreamList || []));
  }, [currentTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, UPDATE_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const totalTimelineWidth = 24 * TICK_WIDTH; // Total width of the timeline (24 hours * TICK_WIDTH px per hour)

  const calculatePositionInPixels = (date: Date) => {
    if (!date) return 0;

    const minutes = date.getHours() * MINUTES_IN_HOUR + date.getMinutes();
    return (minutes * totalTimelineWidth) / TOTAL_MINUTES_IN_DAY;
  };

  const getHoveredTime = (xPos: number) => {
    const totalSeconds = Math.floor((xPos / TICK_WIDTH) * HOUR_IN_MINUTES * 60);
    const hours = Math.floor(totalSeconds / 3600);
    const remainingSecondsAfterHours = totalSeconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const seconds = remainingSecondsAfterHours % 60;

    const newHoveredTime = new Date(currentTime);
    newHoveredTime.setHours(hours);
    newHoveredTime.setMinutes(minutes);
    newHoveredTime.setSeconds(seconds);
    return newHoveredTime;
  };

  const getClosestDataForPolylines = (polylinesProperty: Polyline[], hoveredTimeProperty: Date): Polyline[number][] =>
    polylinesProperty
      .map((polyline) => {
        const closestIndex = closestTimestampIndex(polyline, hoveredTimeProperty.getTime());
        return closestIndex !== null ? polyline[closestIndex] : null;
      })
      .filter((data): data is NonNullable<typeof data> => data !== null);

  const getCurrentStreams = (streamList: NewEventStream[], currentTime_P: Date): NewEventStream[] =>
    streamList.filter((stream: NewEventStream) => {
      const { stream_start_date_ms, stream_end_date_ms } = stream;
      return (
        stream_start_date_ms <= currentTime_P.getTime() &&
        (!stream_end_date_ms || stream_end_date_ms >= currentTime_P.getTime())
      );
    });

  const onChangeCurrentSelectedTimeRef = () => {
    if (!currentTime) return;
    const closestData = getClosestDataForPolylines(polylinesGroupedByUser, currentTime);
    const currentStreams = getCurrentStreams(linearStreamList, currentTime);
    if (onFindCurrentTimeStreamPositions && !!closestData) onFindCurrentTimeStreamPositions(closestData);
    if (onFindCurrentStreamList) onFindCurrentStreamList(currentStreams);
  };

  const onChangeCurrentSelectedTime = throttle(onChangeCurrentSelectedTimeRef, 1000);

  useEffect(onChangeCurrentSelectedTime, [currentTime]);

  const moveHoverLine = (xPos: number) => {
    if (hoverLineElement && hoverLineElement?.current) hoverLineElement.current.style.left = `${xPos}px`;
  };

  const findStreamsWhenHoverRef = (newHoveredTime: Date, xPos: number) => {
    if (onHover) onHover(newHoveredTime);
    setHoveredPosition(xPos);
    setHoveredTime(newHoveredTime);
    const closestData = getClosestDataForPolylines(polylinesGroupedByUser, newHoveredTime);
    const currentStreams = getCurrentStreams(linearStreamList, newHoveredTime);
    if (onFindHoverPreviewStreamPositions && !!closestData) onFindHoverPreviewStreamPositions(closestData);
    if (onFindHoverStreamList) onFindHoverStreamList(currentStreams);
  };

  const findStreamsWhenHover = throttle(findStreamsWhenHoverRef, 2000);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const leftWithinTimeline = e.clientX;
    setPreviewLeft(leftWithinTimeline);

    const rect = timelineRef?.current?.getBoundingClientRect() || { left: 0 };
    const scrollLeft = timelineRef?.current?.scrollLeft || 0;
    const xPos = e.clientX - (rect?.left || 0) + scrollLeft;
    moveHoverLine(xPos);
    const newHoveredTime = getHoveredTime(xPos);
    findStreamsWhenHover(newHoveredTime, xPos);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const isTimeWithinRange = (time: Date, startDateProperty: Date, endDateProperty: Date) =>
    time.getTime() >= startDateProperty.getTime() && time.getTime() <= endDateProperty.getTime();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
      const direction = e.key === 'ArrowRight' ? 1 : -1;
      const deltaMinutes = (TICK_WIDTH / TICKS_PER_HOUR) * HOUR_IN_MINUTES * direction;
      const newSelectedTime = new Date(selectedTime || currentTime);
      newSelectedTime.setMinutes(newSelectedTime.getMinutes() + deltaMinutes);
      if (isTimeWithinRange(newSelectedTime, startDate, endDate)) {
        // setSelectedPosition(calculatePositionInPixels(newSelectedTime));
        if (onHover) onHover(newSelectedTime);
      }
    }
  };

  const handleMouseUp = () => {
    if (!hasBeenDragged) {
      if (setCurrentSelectedTime) setCurrentSelectedTime(hoveredTime);
    }
    setIsDragging(false);
    setHasBeenDragged(false);
  };

  const handleMouseLeave = () => {
    setIsWithMouseOnTopOfTheTimeline(false);
    if (onHover) onHover(null);

    setIsDragging(false);
    if (onFindHoverPreviewStreamPositions) onFindHoverPreviewStreamPositions([]);
    if (onFindHoverStreamList) onFindHoverStreamList([]);
  };

  const handleMouseLeaveTimeline = () => {
    setIsWithMouseOnTopOfTheTimeline(false);
  };

  const handleMouseEnter = () => {
    setIsWithMouseOnTopOfTheTimeline(true);
  };

  useEffect(() => {
    const timeline = timelineRef.current;

    const handleTimelineDrag = (e: MouseEvent) => {
      if (isDragging) {
        e.preventDefault();
        if (timeline) timeline.scrollLeft -= e.movementX;
        setHasBeenDragged(true);
      }
    };

    timeline?.addEventListener('mousemove', handleTimelineDrag);
    return () => {
      timeline?.removeEventListener('mousemove', handleTimelineDrag);
    };
  }, [isDragging]);

  const progressWidth = (now.getHours() * HOUR_IN_MINUTES + now.getMinutes()) * (totalTimelineWidth / DAY_IN_MINUTES);
  const currentSelectedTimeLeftPosition = currentTime ? calculatePositionInPixels(currentTime) : null;
  return (
    <S.StyledTimeline
      ref={timelineRef}
      className="timeline"
      tabIndex={0}
      role="slider"
      aria-valuemin={startDate.getTime()}
      aria-valuemax={endDate.getTime()}
      aria-valuenow={selectedTime ? selectedTime?.getTime() : currentTime?.getTime()}
      aria-valuetext={selectedTime ? selectedTime?.toISOString() : currentTime?.toISOString()}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeaveTimeline}
      onMouseEnter={handleMouseEnter}
      onKeyDown={handleKeyDown}
    >
      {currentTime.toDateString() === now.toDateString() && (
        <S.CurrentTimeLine className="current-time-line" css={{ left: `${progressWidth}px` }} />
      )}
      {intervals.map((interval) => {
        const left = calculatePositionInPixels(interval.startDate);
        const width = calculatePositionInPixels(interval.endDate) - left;

        return (
          <AvailableContent
            key={`${interval.startDate}`}
            style={{
              minWidth: '3px',
              left: `${left}px`,
              width: `${width}px`,
            }}
          />
        );
      })}

      <HoverPreview previewLeft={previewLeft} onMouseLeave={handleMouseLeave} />

      {Array.from({ length: TICKS_COUNT }).map((_, i) => {
        const hour = Math.floor(i / TICKS_PER_HOUR);
        const minute = (i % TICKS_PER_HOUR) * (HOUR_IN_MINUTES / TICKS_PER_HOUR);
        // const isHour = minute === 0;
        return (
          <S.Tick
            key={`${hour}-${minute}-${i}`}
            css={{
              left: `${i * (TICK_WIDTH / TICKS_PER_HOUR)}px`,
              minWidth: `${TICK_WIDTH / TICKS_PER_HOUR}px`,
            }}
          >
            <Text
              className="tick-label"
              css={{
                fontSize: '0.68rem',
                color: '$white',
                marginLeft: '$nano',
                textShadow:
                  '-0.3px -0.3px 0 rgba(0, 0, 0, 0.5),  0.3px -0.3px 0 rgba(0, 0, 0, 0.5), -0.3px 0.3px 0 rgba(0, 0, 0, 0.5), 0.3px 0.3px 0 rgba(0, 0, 0, 0.5)',
              }}
            >
              {String(hour).padStart(2, '0')}:{String(minute).padStart(2, '0')}
            </Text>
          </S.Tick>
        );
      })}

      {intervals.map((interval) => {
        const left = calculatePositionInPixels(interval.startDate);

        return (
          <Flex
            key={interval.stream.id}
            onMouseDown={(event) => {
              if (isDragging) return;
              event.stopPropagation();
              onClickTimelineAvatar(interval.stream);
            }}
            onMouseUp={(event) => {
              event.nativeEvent.stopImmediatePropagation();
              event.stopPropagation();
            }}
            css={{ position: 'absolute', left: `${left}px`, bottom: 2, zIndex: 10, opacity: 1 }}
          >
            <PulseAvatar
              imageUrl={interval.stream.avatarPicture || ''}
              title={interval.stream.channel.username || ''}
              size={1.5}
              isSelected={false}
            />
          </Flex>
        );
      })}

      {hoveredPosition !== null && isWithMouseOnTopOfTheTimeline && (
        <>
          <S.HoveredLine className="hovered-line" ref={hoverLineElement} />
          {/* <S.HoveredTooltip
            className="hovered-tooltip"
            style={{
              left: `${hoveredPosition}px`,
              transform: 'translateX(7%)',
              zIndex: 51,
            }}
          >
            <Text css={{ fontSize: '0.8rem', color: '$black200', fontWeight: 600 }}>
              Go to {String(hoveredTime?.getHours()).padStart(2, '0')}:
              {String(hoveredTime?.getMinutes()).padStart(2, '0')}:{String(hoveredTime?.getSeconds()).padStart(2, '0')}
            </Text>
          </S.HoveredTooltip> */}
        </>
      )}
      {currentSelectedTimeLeftPosition !== null && (
        <>
          <S.SelectedLine
            className="selected-line"
            css={{
              left: `${currentSelectedTimeLeftPosition}px`,
              backgroundColor: isLive ? '$vsLive100' : '#F2F1F6',
            }}
          />

          {/* <S.CurrentTimeTooltip
            style={{
              left: `${currentSelectedTimeLeftPosition}px`,
              transform: 'translateX(7%)',
              backgroundColor: isLive ? '#FF3B30' : '#F2F1F6',
              border: isLive ? '1px solid #FF3B30' : '1px solid #99989B',
              zIndex: 50,
            }}
          >
            <Text
              css={{
                fontSize: '0.8rem',
                color: isLive ? '$white' : '$blizzardBlastVeryDarkMuted',
                fontWeight: 600,
              }}
            >
              {String(currentTime?.getHours()).padStart(2, '0')}:{String(currentTime?.getMinutes()).padStart(2, '0')}:
              {String(currentTime?.getSeconds()).padStart(2, '0')}
            </Text>
          </S.CurrentTimeTooltip> */}
        </>
      )}
    </S.StyledTimeline>
  );
}

export default React.memo(TimelineComponent);
