import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from '../../presentation/components/generic-error-page/error-page';
import GenericNotFound from '../../presentation/components/generic-not-found/generic-not-found';
import NewEventPage from '../../presentation/pages/new-event/new-event-page';
import { makeEventPage } from '../pages/event-factory';
import { makeStreamViewPage } from '../pages/stream-view-factory';
import { EventPage } from '../../presentation/pages/event/event-page';

const router = createBrowserRouter([
  {
    path: '/',
    element: makeEventPage(),
    errorElement: <ErrorPage />,
  },
  {
    path: '/old-events/:eventName',
    element: makeEventPage(),
    errorElement: <ErrorPage />,
  },
  {
    path: '/eventsv4/:eventName',
    element: <EventPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/legacy/:eventName',
    element: <NewEventPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/events/:eventName',
    element: <NewEventPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/streams/:streamId/:streamTitle?',
    element: makeStreamViewPage(),
    errorElement: <ErrorPage />,
  },
  {
    path: '/not-found',
    element: <GenericNotFound />,
  },
  {
    path: '*',
    element: <GenericNotFound />,
  },
]);

export default function Router() {
  return <RouterProvider router={router} />;
}
