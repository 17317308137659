import { useState } from 'react';
import skeletonStyle from '../../../../common-styles/skeleton';
import { GenericBottomSheet } from '../../../../components/generic-bottom-sheet/generic-bottom-sheet';
import ExpandContentIcon from '../../../../components/icons/expand_content';
import ForumIcon from '../../../../components/icons/forum';
import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';
import GenericPulseChat from '../../../../components/third-party/chat/generic-chat';
import { useIsMobile } from '../../../../utils/useIsMobile';
import useAuthStore from '../../../auth-wrapper/state-manager/store';
import useEventStore from '../../state-manager/store';

export default function EventChat() {
  const eventInfo = useEventStore((state) => state.eventInfo);
  const setIsAuthDialogVisible = useAuthStore((state) => state.setIsAuthDialogVisible);
  const authToggleState = useAuthStore((state) => state.authToggleState);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const onClickOpenAuthDialog = () => {
    setIsAuthDialogVisible(true);
  };

  const openChatSheet = () => {
    if (isOpen || !isMobile) return;
    setIsOpen(true);
  };

  const onClickClose = () => {
    setIsOpen(false);
  };

  return (
    <Flex
      css={{
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',
        borderRadius: '$lg',
        zIndex: 2,
        overflow: 'hidden',
        '@bp3': {
          paddingBottom: '0px',
          minHeight: '7.40rem',
        },
      }}
      onClick={openChatSheet}
    >
      {!!eventInfo?.getstream_channel_id && (
        <>
          <Flex
            className="chat-title"
            css={{
              alignItems: 'center',
              paddingLeft: '$xs',
              backgroundColor: '$blackTranslucentGlass',
              backdropFilter: 'blur(3px)',
              paddingRight: '$xs',
              height: '3rem',
              borderBottom: '1px solid $gunMetal',
              justifyContent: 'space-between',
            }}
          >
            <Flex css={{ gap: '$2xs' }}>
              <ForumIcon color="#fff" />
              <Typo css={{ color: '$white', fontSize: '0.875rem', fontWeight: '$demi' }}>Live Chat</Typo>
            </Flex>

            {isMobile && <ExpandContentIcon />}
          </Flex>
          <Flex
            className="chat-body"
            css={{ height: '100%', width: '100%', overflow: 'hidden', '&:empty': skeletonStyle }}
          >
            {authToggleState && (
              <>
                <GenericPulseChat
                  showInput={!isMobile}
                  chatType="event"
                  chatId={eventInfo?.getstream_channel_id}
                  onClickOpenAuthDialog={onClickOpenAuthDialog}
                  chatCss={{
                    '& ::-webkit-scrollbar-track': {
                      backdropFilter: 'blur(3px)',
                    },
                    '& ::-webkit-scrollbar-thumb': {
                      background: '$silver',
                    },

                    '& .str-chat': {
                      width: '100%',
                      backgroundColor: '$blackTranslucentGlass',
                      backdropFilter: 'blur(3px)',
                    },
                    '& .str-chat__list': {
                      backgroundColor: 'transparent',
                      // backdropFilter: 'blur(3px)',
                      '&::-webkit-scrollbar': {
                        backgroundColor: '$lynx',
                      },
                    },
                    '& .str-chat__main-panel': {
                      backgroundColor: 'transparent !important',
                    },
                    '& .str-chat__message-input': {
                      backgroundColor: 'transparent',
                    },
                    // '& .rta__textarea.str-chat__textarea__textarea.str-chat__message-textarea': {
                    //   color: 'white !important',
                    // },
                  }}
                />

                <GenericBottomSheet
                  isOpen={isOpen}
                  onClickClose={onClickClose}
                  title="Live Chat"
                  headerIcon={<ForumIcon />}
                >
                  <GenericPulseChat
                    chatId={eventInfo?.getstream_channel_id}
                    chatType="event"
                    onClickOpenAuthDialog={onClickOpenAuthDialog}
                  />
                </GenericBottomSheet>
              </>
            )}
          </Flex>
        </>
      )}
      {isMobile && (
        <Flex css={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0 }} onClick={openChatSheet} />
      )}
    </Flex>
  );
}
