export default function SubscriptionsIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 2C5.71667 2 5.479 1.904 5.287 1.712C5.09567 1.52067 5 1.28333 5 1C5 0.716667 5.09567 0.479 5.287 0.287C5.479 0.0956666 5.71667 0 6 0H14C14.2833 0 14.5207 0.0956666 14.712 0.287C14.904 0.479 15 0.716667 15 1C15 1.28333 14.904 1.52067 14.712 1.712C14.5207 1.904 14.2833 2 14 2H6ZM3 5C2.71667 5 2.479 4.90433 2.287 4.713C2.09567 4.521 2 4.28333 2 4C2 3.71667 2.09567 3.479 2.287 3.287C2.479 3.09567 2.71667 3 3 3H17C17.2833 3 17.5207 3.09567 17.712 3.287C17.904 3.479 18 3.71667 18 4C18 4.28333 17.904 4.521 17.712 4.713C17.5207 4.90433 17.2833 5 17 5H3ZM2 20C1.45 20 0.979333 19.8043 0.588 19.413C0.196 19.021 0 18.55 0 18V8C0 7.45 0.196 6.979 0.588 6.587C0.979333 6.19567 1.45 6 2 6H18C18.55 6 19.021 6.19567 19.413 6.587C19.8043 6.979 20 7.45 20 8V18C20 18.55 19.8043 19.021 19.413 19.413C19.021 19.8043 18.55 20 18 20H2ZM2 18H18V8H2V18ZM9.55 15.975L12.75 13.825C13.05 13.625 13.2 13.35 13.2 13C13.2 12.65 13.05 12.375 12.75 12.175L9.55 10.025C9.21667 9.79167 8.875 9.775 8.525 9.975C8.175 10.175 8 10.475 8 10.875V15.125C8 15.525 8.175 15.825 8.525 16.025C8.875 16.225 9.21667 16.2083 9.55 15.975Z"
        fill="white"
      />
    </svg>
  );
}
