import { keyframes } from '../stitches.config';

const skeletonAnimation = keyframes({
  from: { backgroundPosition: '-60% 0, 0 0' },
  to: { backgroundPosition: '180% 0, 0 0' },
});

const skeletonStyle = {
  // width: '100%',
  // height: '100%',
  display: 'block',
  background:
    'linear-gradient( to right, rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 80%), $bgAppScreen200',
  backgroundRepeat: 'repeat-y',
  backgroundSize: '40% 500px',
  backgroundPosition: '0 0',
  animation: `${skeletonAnimation} 1.2s infinite`,
};

export default skeletonStyle;
