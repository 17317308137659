/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { useInterval } from 'usehooks-ts';
import { GetEventInfo } from '../../../../domain/usecases';
import { generateNewGroups, generateNewItems } from '../components/event-timeline/generate-items';
import useEventStore from '../state-manager/store';
import { EventStream } from '../state-manager/store-types';
import { insertOrUpdateObjectInsideArray } from '../../../utils/utils';

let eventStreamsRef: EventStream[] = [];

export function useUpdateTimeline() {
  const setEventStreams = useEventStore((state) => state.setEventStreams);
  const timelineUpdateController = useEventStore((state) => state.timelineUpdateController);

  const updateLiveStreamsStickOnTimeline = () => {
    const liveStreams = eventStreamsRef.filter((stream) => stream.content.status === 'started' && !stream.isProducer);
    const updatedLiveStreams = liveStreams.map((stream) => ({ ...stream, end: new Date() }));
    updatedLiveStreams.forEach((stream) => timelineUpdateController.addOrUpdateSingleItem(stream));
  };
  useInterval(updateLiveStreamsStickOnTimeline, 5000);

  const updateData = async (timeline: GetEventInfo.TimeLineV2Model, eventStreamsP: EventStream[]) => {
    try {
      eventStreamsRef = eventStreamsP;
      const generatedStreamsFormatForTimelineLib = generateNewItems(timeline);
      const groups = generateNewGroups(timeline);
      timelineUpdateController.addOrUpdateAllGroupsAndItems(groups, generatedStreamsFormatForTimelineLib);

      generatedStreamsFormatForTimelineLib.forEach((stream) => {
        // @ts-ignore
        eventStreamsRef = insertOrUpdateObjectInsideArray(eventStreamsRef, stream, 'id');
      });
      setEventStreams(eventStreamsRef);
    } catch (e) {
      // navigate('/not-found');
    }
  };

  return updateData;
}
