import { useState } from 'react';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import CloseCrossIcon from '../../../../components/icons/close-cross';
import Box from '../../../../components/primitives/box';
import Button from '../../../../components/primitives/button';
import { Dialog, DialogContent, DialogTrigger } from '../../../../components/primitives/dialog/dialog';
import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';
import { darkTheme, styled } from '../../../../stitches.config';

interface IProps {
  dateRange: string[];
  value: string | null;
  onValueChange: (event: string) => void;
}

const StyledItem = styled(Flex, {
  fontSize: '$body',
  lineHeight: 1,
  color: '$gs100',
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  height: '2.26rem',
  gap: '$3xs',
  paddingLeft: '$2xs',

  position: 'relative',
  userSelect: 'none',

  '&[data-selected="true"]': {
    backgroundColor: '$gs500',
  },
});

export default function MobileDateSelect({ dateRange, value, onValueChange }: IProps) {
  const [open, setOpen] = useState<boolean>(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const onChangeDate = (p_date: string) => {
    closeDialog();
    onValueChange(p_date);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild onClick={openDialog}>
        <Flex className="inner-dialog-wrapper">
          <Button variant="transparent" size="md" css={{ gap: '$3xs', align: 'center', color: '$constantWhite' }}>
            {value || 'Loading'}
            <ChevronDownIcon />
          </Button>
        </Flex>
      </DialogTrigger>

      <DialogContent css={{ backgroundColor: '$bgAppScreen200', padding: 0, height: '60%' }} className={darkTheme}>
        <Flex css={{ flexDirection: 'column', gap: '$xs' }} className="content-inner-wrapper">
          <Flex
            css={{
              borderBottom: '1px solid #454545',
              height: '3.37rem',
              alignItems: 'center',
              paddingLeft: '$xs',
              justifyContent: 'space-between',
              top: 0,
              position: 'sticky',
              width: '100%',
              backgroundColor: '$bgAppScreen200',
              zIndex: 10,
            }}
          >
            <Typo type="h6" as="h6">
              Select date
            </Typo>
            <Button variant="transparent" size="md" onClick={closeDialog}>
              <CloseCrossIcon />
            </Button>
          </Flex>
          <Flex
            css={{
              flexDirection: 'column',
              padding: '$3xs $nano',
              gap: '$3xs',
              paddingLeft: '$3xs',
              paddingRight: '$xs',
            }}
          >
            {dateRange.map((date) => (
              <StyledItem key={date} data-selected={date === value} onClick={() => onChangeDate(date)}>
                {date === value && (
                  <Box
                    css={{
                      height: '0.125rem',
                      width: '0.625rem',
                      borderRadius: '50%',
                      backgroundColor: '$primaryAdrenaline100',
                    }}
                  />
                )}

                <Typo>{date}</Typo>
              </StyledItem>
            ))}
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
