import { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '../primitives/dialog/dialog';

import Flex from '../primitives/flex';
import { darkTheme, keyframes, styled } from '../../stitches.config';
import { Typo } from '../primitives/typo/typo';
import CloseCrossIcon from '../icons/close-cross';
import Button from '../primitives/button';
import { useIsLandScape } from '../../utils/useIsLandScape';

const CustomSheet = styled('div', {
  backgroundColor: '$bgAppScreen200',
  borderRadius: '16px 16px 0px 0px',
  overflow: 'hidden',
  zIndex: 10,
  width: '100%',
  bottom: 0,
});

interface IProps {
  isOpen: boolean;
  children: React.ReactElement;
  headerIcon: React.ReactElement;
  title: string;
  onClickClose: () => void;
}

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translateY(100%)' },
  '100%': { opacity: 1, transform: 'translateY(0px)' },
});

export function GenericBottomSheet({ isOpen, children, headerIcon, title, onClickClose }: IProps) {
  const [visualViewportHeight, setVisualViewportHeight] = useState<number>(window?.visualViewport?.height || 0);
  const isLandScape = useIsLandScape();
  const streamElementSize = 18 * 16; // 18rem - 1 rem is 16px
  const MAX_SNAP_POINT =
    visualViewportHeight < 500
      ? Math.round(visualViewportHeight * 0.95)
      : Math.round(visualViewportHeight - streamElementSize);
  const MAX_SNAP_POINT_LANDSCAPE = Math.round(visualViewportHeight * 0.95);

  const SNAP_POINT = isLandScape ? MAX_SNAP_POINT_LANDSCAPE : MAX_SNAP_POINT;

  const disableBodyScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableBodyScroll = () => {
    document.body.style.overflow = 'auto';
  };
  useEffect(() => {
    const listener = () => {
      setVisualViewportHeight(window?.visualViewport?.height || 0);
    };
    window?.visualViewport?.addEventListener('resize', listener);
    if (isOpen) {
      disableBodyScroll();
    }

    return () => {
      enableBodyScroll();
      window?.visualViewport?.removeEventListener('resize', listener);
    };
  }, [isOpen]);

  return (
    <Dialog open={isOpen}>
      <DialogContent
        hasOverlay
        css={{
          backgroundColor: '$bgAppScreen200',
          width: isLandScape ? '50%' : '100%',
          right: 0,
          // borderRadius: '$2lg',
          overflow: 'hidden',
          transition: 'all 0.3s',
          height: isOpen ? SNAP_POINT : 0,
          padding: '0px',
          transform: 'translate(0px,0px)',
          top: 'unset',
          left: 'unset',
          bottom: '0px',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          '@media (prefers-reduced-motion: no-preference)': {
            animation: `${contentShow} 0.3s cubic-bezier(0.16, 1, 0.3, 1)`,
          },
        }}
        className={darkTheme}
      >
        <CustomSheet
          className={darkTheme}
          css={{
            width: '100%',
            height: '100%',
          }}
        >
          <Flex
            css={{
              flexDirection: 'column',
              paddingTop: '0px',
              overflow: 'auto',
              transition: 'padding 200ms',
              height: '100%',
              width: '100%',
            }}
            className="sheet-content-wrapper"
          >
            <Flex
              className="chat-title"
              css={{
                alignItems: 'center',
                paddingLeft: '$xs',
                paddingRight: '$xs',
                minHeight: '3.37rem',
                borderBottom: '1px solid $gs500',
                backgroundColor: '$bgAppScreen200',
                justifyContent: 'space-between',
              }}
            >
              <Flex css={{ gap: '$2xs' }}>
                {headerIcon}
                <Typo type="bodyBold" css={{ color: '$constantWhite' }}>
                  {title}
                </Typo>
              </Flex>

              <Button onClick={onClickClose} variant="transparent">
                <CloseCrossIcon />
              </Button>
            </Flex>
            <Flex css={{ height: 'calc(100% - 3.37rem)', width: '100%' }}>{isOpen && children}</Flex>
          </Flex>
        </CustomSheet>
      </DialogContent>
    </Dialog>
  );
}
