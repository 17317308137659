import { create } from 'zustand';
import { MapRef } from 'react-map-gl';
import { StreamModel } from '../../../../domain/models/stream-model';

export type IUserLocation = { latitude: number; longitude: number; username: string; useravatar: string } | null;

interface StreamViewState {
  streamInfo: StreamModel | null;
  setStreamInfo: (stream: StreamModel) => void;
  userLocation: IUserLocation;
  setUserLocation: (location: IUserLocation) => void;
  mapRef: MapRef | null;
  setMapRef: (map: MapRef | null) => void;
  isAuthDialogVisible: boolean;
  setIsAuthDialogVisible: (isVisible: boolean) => void;
}

const useStreamViewStore = create<StreamViewState>()((set) => ({
  streamInfo: null,
  setStreamInfo: (stream) => set(() => ({ streamInfo: stream })),
  userLocation: null,
  setUserLocation: (location) => set(() => ({ userLocation: location })),
  mapRef: null,
  setMapRef: (mapRef) => set(() => ({ mapRef })),
  isAuthDialogVisible: false,
  setIsAuthDialogVisible: (isAuthDialogVisible) => set(() => ({ isAuthDialogVisible })),
}));

export default useStreamViewStore;
