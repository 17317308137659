export function IosShareIcon() {
  return (
    <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.07593 15C7.79259 15 7.55526 14.904 7.36393 14.712C7.17193 14.5207 7.07593 14.2834 7.07593 14V3.82505L6.17593 4.72505C5.99259 4.90838 5.76359 5.00005 5.48893 5.00005C5.21359 5.00005 4.97593 4.90005 4.77593 4.70005C4.59259 4.50005 4.50093 4.26238 4.50093 3.98705C4.50093 3.71238 4.59259 3.48338 4.77593 3.30005L7.37593 0.700049C7.45926 0.616716 7.56359 0.550049 7.68893 0.500049C7.81359 0.450049 7.94259 0.425049 8.07593 0.425049C8.20926 0.425049 8.33859 0.450049 8.46393 0.500049C8.58859 0.550049 8.69259 0.616716 8.77593 0.700049L11.3759 3.30005C11.5759 3.50005 11.6759 3.74172 11.6759 4.02505C11.6759 4.30838 11.5759 4.54172 11.3759 4.72505C11.1759 4.90838 10.9386 5.00005 10.6639 5.00005C10.3886 5.00005 10.1593 4.90838 9.97593 4.72505L9.07593 3.82505V14C9.07593 14.2834 8.98026 14.5207 8.78893 14.712C8.59693 14.904 8.35926 15 8.07593 15ZM2.07593 22C1.52593 22 1.05526 21.8044 0.663928 21.413C0.271928 21.021 0.0759277 20.55 0.0759277 20V9.00005C0.0759277 8.45005 0.271928 7.97905 0.663928 7.58705C1.05526 7.19571 1.52593 7.00005 2.07593 7.00005H4.07593C4.35926 7.00005 4.59693 7.09572 4.78893 7.28705C4.98026 7.47905 5.07593 7.71672 5.07593 8.00005C5.07593 8.28338 4.98026 8.52071 4.78893 8.71205C4.59693 8.90405 4.35926 9.00005 4.07593 9.00005H2.07593V20H14.0759V9.00005H12.0759C11.7926 9.00005 11.5553 8.90405 11.3639 8.71205C11.1719 8.52071 11.0759 8.28338 11.0759 8.00005C11.0759 7.71672 11.1719 7.47905 11.3639 7.28705C11.5553 7.09572 11.7926 7.00005 12.0759 7.00005H14.0759C14.6259 7.00005 15.0969 7.19571 15.4889 7.58705C15.8803 7.97905 16.0759 8.45005 16.0759 9.00005V20C16.0759 20.55 15.8803 21.021 15.4889 21.413C15.0969 21.8044 14.6259 22 14.0759 22H2.07593Z"
        fill="#999999"
      />
    </svg>
  );
}
