import './apple-loading.scss';

export default function AppleLoading() {
  return (
    <div className="apple-loading">
      <div className="segment" />
      <div className="segment" />
      <div className="segment" />
      <div className="segment" />
      <div className="segment" />
      <div className="segment" />
      <div className="segment" />
      <div className="segment" />
      <div className="segment" />
      <div className="segment" />
      <div className="segment" />
      <div className="segment" />
    </div>
  );
}
