import { useEffect, useRef, useState } from 'react';
import * as S from './styles';
import { stringToColour } from '../../utils/colorConversion';
import { LiveChip } from '../live-chip/live-chip';
import Flex from '../primitives/flex';
import { PulseAvatar } from '../pulse-avatar/pulse-avatar';

export enum StreamType {
  ATHLETE = 'ATHLETE',
  STATIONARY = 'STATIONARY',
  SPECTATOR = 'SPECTATOR',
  COMMENTARY = 'COMMENTARY',
}

interface AvatarProps {
  imageUrl: string;
  title: string;
  subtitle?: string;
  isSelected?: boolean;
  streamType?: StreamType;
  isLive?: boolean;
  onClick?: () => void;
  limitWidth?: boolean;
}

export function EventAvatarWithTooltip({
  imageUrl,
  title,
  subtitle,
  isSelected,
  isLive = false,
  streamType = StreamType.ATHLETE,
  onClick,
  limitWidth,
}: AvatarProps) {
  const titleRef = useRef<HTMLDivElement>(null);
  const subtitleRef = useRef<HTMLDivElement>(null);
  const [detailWidth, setDetailWidth] = useState(0);

  useEffect(() => {
    if (titleRef.current && subtitleRef.current) {
      const titleWidth = titleRef.current.clientWidth;
      const subtitleWidth = subtitleRef.current.clientWidth;
      const greaterWidth = Math.max(titleWidth, subtitleWidth);
      const paddingRight = 12;
      const paddingLeft = 45;
      const gap = 30;
      setDetailWidth(greaterWidth + paddingLeft + paddingRight + gap);
    }
  }, []);

  const avatarColor = stringToColour(title);

  return (
    <S.AvatarContainer className="avatar-container" streamType={streamType} onClick={onClick}>
      <PulseAvatar imageUrl={imageUrl} isSelected={isSelected} title={title} />

      <S.AvatarDetail
        css={{
          border: `1px solid ${avatarColor}`,
          width: detailWidth ? `${detailWidth}px` : 'auto',

          '.avatar-container:hover &': {
            width: detailWidth ? `${detailWidth}px` : 'auto',
          },
          '.avatar-container:focus &': {
            width: detailWidth ? `${detailWidth}px` : 'auto',
          },
        }}
        data-is-spectator={streamType === StreamType.SPECTATOR}
        aria-label={`${title}, ${subtitle || ''}`}
      >
        <S.Title css={{ maxWidth: limitWidth ? '5.5rem' : '' }} ref={titleRef}>
          {title}
        </S.Title>
        <S.Subtitle css={{ maxWidth: limitWidth ? '5.5rem' : '' }} ref={subtitleRef}>
          {subtitle}
        </S.Subtitle>
      </S.AvatarDetail>

      {isLive && (
        <Flex css={{ position: 'absolute', bottom: '-0.2rem', right: '-0.4rem', zIndex: 2 }}>
          <LiveChip />
        </Flex>
      )}
    </S.AvatarContainer>
  );
}
