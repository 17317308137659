export default function FacebookShareIcon() {
  return (
    <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_601_10467)">
        <path
          d="M38.337 18.8834C38.337 8.45443 29.8826 7.62939e-05 19.4536 7.62939e-05C9.02466 7.62939e-05 0.570312 8.45443 0.570312 18.8834C0.570312 28.3086 7.47568 36.1207 16.5031 37.5374V24.3419H11.7085V18.8834H16.5031V14.7232C16.5031 9.99054 19.3223 7.37638 23.6356 7.37638C25.7016 7.37638 27.8626 7.7452 27.8626 7.7452V12.3923H25.4815C23.1357 12.3923 22.4042 13.8479 22.4042 15.3412V18.8834H27.6413L26.8041 24.3419H22.4042V37.5374C31.4316 36.1207 38.337 28.3086 38.337 18.8834Z"
          fill="#1877F2"
        />
        <path
          d="M26.8036 24.3419L27.6408 18.8834H22.4036V15.3412C22.4036 13.8478 23.1352 12.3922 25.481 12.3922H27.8621V7.74517C27.8621 7.74517 25.7011 7.37635 23.6351 7.37635C19.3218 7.37635 16.5026 9.99051 16.5026 14.7232V18.8834H11.708V24.3419H16.5026V37.5373C17.464 37.6882 18.4494 37.7667 19.4531 37.7667C20.4569 37.7667 21.4423 37.6882 22.4036 37.5373V24.3419H26.8036Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_601_10467">
          <rect width="37.7667" height="37.7667" fill="white" transform="translate(0.570312)" />
        </clipPath>
      </defs>
    </svg>
  );
}
