/* eslint-disable prefer-destructuring */
import { create } from 'zustand';
import { ProfileModel } from '../../../../domain/models/profile-model';
import { LocalStorageAdapter } from '../../../../infra/cache';
import { FirebaseAuthClient } from '../../../../infra/social-auth';
import { AnalyticsLoggingClient } from '../../../../infra/event-tracking/event-tracking';

interface AuthState {
  isAuthDialogVisible: boolean;
  setIsAuthDialogVisible: (isVisible: boolean) => void;
  authToggleState: boolean;
  setAuthToggleState: (value: boolean) => void;
  isShareDialogVisible: boolean;
  setIsShareDialogVisible: (isVisible: boolean) => void;
  openAuthModalIfItsNotLogged: () => void;
  localStorageAdapter: LocalStorageAdapter;
  socialAuth: FirebaseAuthClient;
  getCurrentUser: () => ProfileModel | null;
  isLogged: () => boolean;
  logout: () => void;
}

const useAuthStore = create<AuthState>()((set, get) => ({
  isAuthDialogVisible: false,
  setIsAuthDialogVisible: (isAuthDialogVisible) => set(() => ({ isAuthDialogVisible })),
  authToggleState: true,
  setAuthToggleState: (value) => set(() => ({ authToggleState: value })),
  isShareDialogVisible: false,
  setIsShareDialogVisible: (isShareDialogVisible) => set(() => ({ isShareDialogVisible })),
  openAuthModalIfItsNotLogged: () => {
    const analytics = new AnalyticsLoggingClient();
    analytics.track('view_sign_in');
    const isAuthDialogVisible = !get().isLogged();
    return set(() => ({ isAuthDialogVisible }));
  },
  localStorageAdapter: new LocalStorageAdapter(),
  socialAuth: new FirebaseAuthClient(),
  getCurrentUser: () => {
    const localStorageAdapter: LocalStorageAdapter = get().localStorageAdapter;
    const loggedUser = localStorageAdapter.get('profile');

    return loggedUser;
  },
  isLogged: () => {
    const localStorageAdapter = get().localStorageAdapter;
    const loggedUser = localStorageAdapter.get('profile');
    const fireBaseUser = localStorageAdapter.get('fireBaseUser');
    const token = localStorageAdapter.get('token');
    return loggedUser && fireBaseUser && token;
  },
  logout: () => {
    const analytics = new AnalyticsLoggingClient();
    analytics.track('user_logged_out');
    const localStorageAdapter = get().localStorageAdapter;
    const socialAuth = get().socialAuth;
    socialAuth.logout();
    localStorageAdapter.remove('profile');
    localStorageAdapter.remove('token');
    localStorageAdapter.remove('firebaseUser');
    set(() => ({ authToggleState: false }));
    setTimeout(() => set(() => ({ authToggleState: true })), 100);
  },
}));

export default useAuthStore;
