/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { MapRef } from 'react-map-gl';
import dayjs from 'dayjs';
// @ts-ignore
import useMobileDetect from '@groupher/use-mobile-detect-hook';
import useScreenOrientation from 'use-screen-orientation';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useElementSize } from 'usehooks-ts';
import { useSearchParams } from 'react-router-dom';
import { useFullscreen } from 'ahooks';
import { styled } from '../../../../stitches.config';
import Box from '../../../../components/primitives/box';
import Flex from '../../../../components/primitives/flex';
import Heading from '../../../../components/heading/heading';
import { ISquadVideoContext, SquadVideoContext } from '../../context';
import Button from '../../../../components/primitives/button';
import LiveIndicator from '../../../../components/icons/live-animated-indicator';
import { Typo } from '../../../../components/primitives/typo/typo';
import TimelineIcon from '../../../../components/icons/timeline';
import MobileChatEvent from '../chat-event/mobile-chat-event';
import { IMobileGridEventContext, MobileGridEventContext } from './context';
import StreamList from '../stream-list/stream-list';
import ExpandContentIcon from '../../../../components/icons/expand_content';
import MobileBottomSheet from '../mobile-bottom-sheet/mobile-bottom-sheet';
import { removeTimeZoneFromTimeStamp } from '../../../../utils/utils';
import { EventMap } from '../event-map/event-map';

const GridWrapper = styled(Box, {
  display: 'grid',
  overflow: 'hidden',
  gap: '$3xs',
  height: '100%',
  backgroundColor: '$bgAppScreen100',
  alignItems: 'end',
  justifyItems: 'end',
  gridTemplateAreas: `
    "stream-list"
    "map-wrapper"
    "chat-preview-wrapper"
    "open-timeline-button"
    "event-info-wrapper"
  `,

  gridTemplateRows: '1.6fr 1fr 5.37rem 3.1rem 3.43rem',
  '& .padding-grid': {
    padding: '$3xs',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  '& .open-timeline-button': {
    gridArea: 'open-timeline-button',
    width: '100%',
    height: '100%',
    paddingLeft: '$3xs',
    paddingRight: '$3xs',
    '& .inner-timeline': {
      backgroundColor: '$bgAppScreen200',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      borderRadius: '$2lg',
      paddingLeft: '$xs',
      paddingRight: '$xs',
      justifyContent: 'space-between',
    },
  },
  '& .event-info-wrapper': {
    gridArea: 'event-info-wrapper',
    width: '100%',
    paddingLeft: '$3xs',
    paddingRight: '$3xs',
    borderTop: '1px solid $gs500',
  },
  '& .chat-preview-wrapper': {
    gridArea: 'chat-preview-wrapper',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    paddingLeft: '$3xs',
    paddingRight: '$3xs',
  },
  '& .map-wrapper': {
    transition: 'width 0.2s ease-in-out',
    gridArea: 'map-wrapper',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },

  '& .time-line': {
    transition: 'width 0.5s ease-in-out',
    gridArea: 'time-line',
    height: '100%',
    width: '100%',
  },
  '& .stream-list': {
    overflow: 'hidden',
    display: 'flex',
    transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out',
    gridArea: 'stream-list',
    height: '100%',
    width: '100%',
    position: 'relative',
  },

  gridTemplateColumns: '1fr',
  // 1.778 is to mantain 16:9 aspect ratio

  overflowY: 'hidden',

  '& .rounded-border': {
    borderRadius: '$2lg',
    backgroundColor: '$bgAppScreen200',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    transform: 'translateZ(0)',
  },
});

export default function MobileGridEvent() {
  const { eventInfo, isLiveSelected, liveStreams, openLiveStream, timeline } =
    useContext<ISquadVideoContext>(SquadVideoContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const streamListRef = useRef(null);
  const dateFromQueryParam: string | null = searchParams.get('stream_date');
  const detectMobile = useMobileDetect();

  const [isFullExpanded, setIsFullExpanded] = useState<boolean>(false);
  const selectedStreamPlayerRef = useRef<any>(null);
  const [isEventInfoOpened, setIsEventInfoOpened] = useState<boolean>(false);
  const [isChatBottomSheetOpened, setIsChatBottomSheetOpened] = useState<boolean>(false);
  const [isTimelineBottomSheetOpened, setIsTimelineBottomSheetOpened] = useState<boolean>(false);
  const [mapRef, setMapRef] = useState<MapRef | null>(null);
  const [orientation] = useScreenOrientation();

  const [isFullscreen, { toggleFullscreen }] = useFullscreen(streamListRef);

  const [mapWrapperRef, { width, height }] = useElementSize();

  const resizeMap = () => {
    if (mapRef)
      setTimeout(() => {
        try {
          mapRef?.resize();
        } catch (e) {
          //
        }
      }, 200);
  };
  useEffect(resizeMap, [width, height]);

  const onMapReady = (ref: MapRef | null) => {
    setMapRef(ref);
  };

  const toggleExpandEventInfo = () => {
    setIsEventInfoOpened(!isEventInfoOpened);
  };

  const onFullSizeExpand = (isExpanded: boolean) => {
    setIsFullExpanded(isExpanded);
    setTimeout(resizeMap, 100);
  };

  const onFullScreen = () => {
    if (detectMobile.isIos) {
      selectedStreamPlayerRef?.current?.requestFullscreen();
    } else {
      toggleFullscreen();
    }
  };

  const openTimelineSheet = () => {
    setIsTimelineBottomSheetOpened(true);
  };

  const openChatSheet = () => {
    setIsChatBottomSheetOpened(true);
  };

  const insertSelectedDateOnRouteIfIsNotFilled = () => {
    if (!dateFromQueryParam && timeline?.length) {
      const dateStamp = removeTimeZoneFromTimeStamp(timeline[0].time);
      searchParams.set('stream_date', dateStamp);
      setSearchParams(searchParams);
    }
  };

  const onMount = () => {
    insertSelectedDateOnRouteIfIsNotFilled();
    setTimeout(() => {
      const chatScrollElement = document.querySelector('[data-rsbs-scroll="true"]');
      chatScrollElement?.scroll({ top: chatScrollElement.scrollHeight, behavior: 'smooth' });
    }, 2000);
  };
  useEffect(onMount, []);

  const getGridTemplateRows = () => {
    if (orientation === 'landscape') return `1fr`;
    if (isFullExpanded) return `1.6fr 1fr 3.1rem`;
    return `1.6fr 1fr 5.37rem 3.1rem 3.43rem`;
  };

  const getGridTemplateAreas = () => {
    if (orientation === 'landscape')
      return `
    "stream-list"
    `;
    if (isFullExpanded)
      return `
      "stream-list"
      "map-wrapper"
      "open-timeline-button"
    `;
    return `
      "stream-list"
      "map-wrapper"
      "chat-preview-wrapper"
      "open-timeline-button"
      "event-info-wrapper"
    `;
  };

  const sharedValues: IMobileGridEventContext = useMemo(
    () => ({
      onExpand: onFullSizeExpand,
      onFullScreen,
      isFullscreen,
      selectedStreamPlayerRef,
    }),
    [onFullSizeExpand, onFullScreen, isFullscreen, selectedStreamPlayerRef],
  );

  const getSelectedTimelineDate = useCallback(() => {
    if (isLiveSelected) return 'Now';
    return dayjs(dateFromQueryParam).format('DD/MM - HH:mm').toString();
  }, [dateFromQueryParam, isLiveSelected]);

  return (
    <MobileGridEventContext.Provider value={sharedValues}>
      <GridWrapper
        css={{
          gridTemplateRows: getGridTemplateRows(),
          gridTemplateAreas: getGridTemplateAreas(),
          paddingBottom: isFullExpanded ? '$3xs' : 0,
        }}
      >
        <Flex className="stream-list" ref={streamListRef}>
          <StreamList />
        </Flex>

        {orientation !== 'landscape' && (
          <Flex className="map-wrapper padding-grid" ref={mapWrapperRef}>
            <Flex className="rounded-border">
              {!isTimelineBottomSheetOpened && eventInfo && <EventMap onMapReady={onMapReady} />}
            </Flex>
          </Flex>
        )}

        {!isFullExpanded && orientation !== 'landscape' && (
          <Flex className="chat-preview-wrapper" onClick={openChatSheet}>
            <MobileChatEvent isInputVisible={false} />
          </Flex>
        )}

        {orientation !== 'landscape' && (
          <Flex className="open-timeline-button" onClick={openTimelineSheet}>
            <Flex className="inner-timeline">
              <Flex
                className="left"
                css={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center', gap: '$3xs' }}
              >
                <TimelineIcon />
                <Typo type="bodyBold" css={{ color: '$constantWhite' }}>
                  Timeline
                </Typo>
                {!!dateFromQueryParam && (
                  <Typo type="bodySm" css={{ color: '$gs400', fontWeight: '$medium' }}>
                    ● {getSelectedTimelineDate()}
                  </Typo>
                )}

                {!isLiveSelected && !!liveStreams.length && (
                  <Button
                    size="sm"
                    variant="transparent"
                    onClick={openLiveStream}
                    css={{ display: 'flex', gap: '10px', background: 'rgba(218, 255, 1, 0.1)' }}
                  >
                    <LiveIndicator />
                    Jump to live
                  </Button>
                )}
              </Flex>
              <Flex className="right">
                <ExpandContentIcon />
              </Flex>
            </Flex>
          </Flex>
        )}

        {!isFullExpanded && orientation !== 'landscape' && (
          <Flex
            className="event-info-wrapper"
            css={{
              transition: 'all 0.3s',
              backgroundColor: '$bgAppScreen100',
              position: 'sticky',
              flexDirection: 'column',
              height: isEventInfoOpened ? '15rem' : '3.43rem',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
            }}
          >
            <Flex css={{ height: '3.43rem', width: '100%' }}>
              <Heading
                name={eventInfo?.title || 'event not found'}
                link={eventInfo?.more_info_url}
                logoSrc={eventInfo?.photo?.original}
              />
              {eventInfo?.description && (
                <Button
                  onClick={toggleExpandEventInfo}
                  variant="transparent"
                  css={{
                    '& svg': {
                      transition: 'all 0.5s',
                      color: '$constantWhite',
                      transform: !isEventInfoOpened ? 'scale(1.3) rotate(180deg)' : 'scale(1.3) rotate(0deg)',
                    },
                  }}
                >
                  <ChevronDownIcon />
                </Button>
              )}
            </Flex>
            <Flex
              css={{
                transition: 'all 0.3s',
                flexDirection: 'column',
                visibility: isEventInfoOpened ? 'visible' : 'hidden',
              }}
            >
              {/* <Flex css={{ height: '2.81rem' }}>
                <Typo css={{ color: '$constantWhite' }}>Sponsors logos here</Typo>
              </Flex> */}
              <Typo css={{ color: '$constantWhite', maxHeight: '11.5rem', overflow: 'auto' }}>
                {eventInfo?.description}
              </Typo>
            </Flex>
          </Flex>
        )}
      </GridWrapper>

      <MobileBottomSheet
        isChatBottomSheetOpened={isChatBottomSheetOpened}
        isTimelineBottomSheetOpened={isTimelineBottomSheetOpened}
        setIsChatBottomSheetOpened={setIsChatBottomSheetOpened}
        setIsTimelineBottomSheetOpened={setIsTimelineBottomSheetOpened}
      />
    </MobileGridEventContext.Provider>
  );
}
