import { EventStream, NewEventStream } from '../state-manager/store-types';

export function convertOldStreamModelToNewStreamModel(stream: EventStream): NewEventStream {
  return {
    approximate_location: stream.approximate_location,
    created_at: stream.created_at,
    channel: {
      id: '',
      hero_photo: stream?.hero_photo || {
        original: '',
        small: '',
        cropped: '',
      },
      logo: {
        original: stream.logo_url || '',
        small: stream.logo_url || '',
        cropped: stream.logo_url || '',
      },
      username: stream.username || '',
    },
    description: stream.description || '',
    event: {
      id: 'string',
      title: 'string',
      more_info_url: 'string',
      intro_url: 'string',
      owner_display_name: 'string',
      photo: {
        original: 'string',
        small: 'stri',
        cropped: 'string',
      },
      short_code: 'string',
      description: 'string',
      start_time: 'string',
      end_time: 'string',
      latitude: 0,
      longitude: 0,
      time_zone: 'string',
      getstream_channel_id: 'string',
    },
    hashtags: [],
    id: stream.id,
    latest_reactions: [],
    latitude: stream.latitude || 0,
    longitude: stream.longitude || 0,
    playback_url: stream.playback_url,
    vod_playback_url: stream.vod_playback_url,
    reactions_count: stream.reactions_count,
    start_time: stream.start_time,
    status: stream.status,
    thumbnail: stream.thumbnail,
    title: stream.title,
    activity_type: stream.activity_type,
    end_time: stream.end_time,
    duration_in_seconds: stream.duration_in_seconds,
    official: stream.official,
    producer: stream.producer,
    stream_tile_url: stream.stream_tile_url,
    pulse_lines: stream.pulse_lines,
    stream_start_date_ms: stream.stream_start_date_ms,
    avatarPicture: stream?.hero_photo?.small,
    stream_end_date_ms: stream.stream_end_date_ms,
  };
}
