import Flex from '../primitives/flex';
import { Text } from '../primitives/text/text';

export function RecordedChip() {
  return (
    <Flex
      className="recorded-chip"
      css={{
        width: '3.31rem',
        height: '0.8rem',
        backgroundColor: '$sunGlowDark',
        borderRadius: '$md',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text css={{ color: '$raven', fontSize: '0.625rem' }}>Recorded</Text>
    </Flex>
  );
}
