import axios, { AxiosResponse } from 'axios';

import { HttpRequest, HttpResponse, HttpClient } from '@/data/protocols/http';

export class AxiosHttpClient implements HttpClient {
  async request(data: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse;
    try {
      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: {
          'X-Client-Version': 'Web 1.0.0',
          'X-Device-Identifier': '656623d2-9af7-4f5d-8da2-a59b219a2811',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...data.headers,
        },
      });
    } catch (error: any) {
      axiosResponse = error?.response;
    }
    return {
      statusCode: axiosResponse?.status,
      body: axiosResponse?.data,
    };
  }
}
