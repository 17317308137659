import { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import { scrollToElement } from '../../../../utils/utils';
import { ISquadVideoContext, SquadVideoContext } from '../../context';
import DektopStreamListView from './components/desktop-stream-list-view';
import MobileStreamListView from './components/mobile-stream-list-view';

export default function StreamList() {
  const {
    streamSourceLocation,
    streamList,
    selectedDate,
    featuredStream,
    getEvent,
    eventInfo,
    isLiveSelected,
    liveStreams,
    setLiveStreams,
    openLiveStream,
    userMarketingEventTrack,
    setFeaturedStream,
    setIsMapFollowLocked,
  } = useContext<ISquadVideoContext>(SquadVideoContext);
  const elementScrollRef = useRef<HTMLDivElement>(null);
  const listLiveStreamsRef = useRef<any>(null);
  const [isRightArrowVisible, setIsRightArrowVisible] = useState<boolean>(false);
  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState<boolean>(false);
  const [isStreamListVisible, setIsStreamListVisible] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery('(max-width: 900px)');

  const updateRouteQueryParams = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const hasRemainingHorizontalScrollbar = (isLeft = false) => {
    if (!elementScrollRef?.current) return false;
    const element = elementScrollRef?.current;
    const fullScrollWithElementSize = element.scrollLeft + element.clientWidth;
    const hasHorizontalScroll = element.scrollWidth > element.clientWidth;
    const hasFinishedMaximumScrollRightSide = element.scrollWidth === fullScrollWithElementSize;
    const hasFinishedMaximumScrollLeftSide = element.scrollLeft === 0;
    const hasFinishedScroll = isLeft ? hasFinishedMaximumScrollLeftSide : hasFinishedMaximumScrollRightSide;
    return hasHorizontalScroll && !hasFinishedScroll;
  };

  const scrollCarousel = (isLeft = false, amountOfScroll = 400) => {
    if (!elementScrollRef.current) return;
    const actualScrollLeft: number = elementScrollRef.current.scrollLeft;
    const options: ScrollToOptions = {
      behavior: 'smooth',
      left: !isLeft ? actualScrollLeft + amountOfScroll : actualScrollLeft - amountOfScroll,
    };
    elementScrollRef.current.scroll(options);
  };

  const scrollWithKeyboard = (e: KeyboardEvent) => {
    if (e.key === 'ArrowRight') {
      userMarketingEventTrack.track('Scroll carousel with keyboard Right');
      scrollCarousel();
    } else if (e.key === 'ArrowLeft') {
      userMarketingEventTrack.track('Scroll carousel with keyboard Left');
      scrollCarousel(true);
    }
  };

  const listenToScrollWheel = () => {
    if (!elementScrollRef.current) return;

    elementScrollRef.current.addEventListener('wheel', (evt: any) => {
      if (!elementScrollRef.current) return;
      evt.preventDefault();
      elementScrollRef.current.scrollLeft += evt.deltaY;
    });
  };

  const removeWheelListener = () => {
    if (!elementScrollRef.current) return;
    elementScrollRef.current.removeEventListener('wheel', () => {});
  };

  const listenToScrollEvent = () => {
    setIsRightArrowVisible(hasRemainingHorizontalScrollbar());
    setIsLeftArrowVisible(hasRemainingHorizontalScrollbar(true));
  };

  const scrollToStreamSelectedFromQueryParams = () => {
    const streamIdFromRouteQuery = searchParams.get('stream_id');
    if (streamSourceLocation.length)
      setTimeout(() => scrollToElement(`[data-stream-thumb-id='${streamIdFromRouteQuery}']`), 2000);
  };

  const onScrollElementUpdates = () => {
    if (!elementScrollRef.current) return;
    setIsRightArrowVisible(hasRemainingHorizontalScrollbar());
    elementScrollRef.current.addEventListener('scroll', listenToScrollEvent);
  };
  useEffect(onScrollElementUpdates, [elementScrollRef.current, liveStreams, streamSourceLocation]);

  const onStreamListUpdates = () => {
    setIsLoading(true);
    setIsStreamListVisible(false);
    setTimeout(() => {
      setIsLoading(false);
      setIsStreamListVisible(true);
    }, 1000);
  };
  useEffect(onStreamListUpdates, [streamSourceLocation, selectedDate]);

  const listLiveStreams = async () => {
    if (!eventInfo) return [];
    try {
      const liveStreamsFromApi = await getEvent.listStreams(eventInfo?.id);
      setLiveStreams(liveStreamsFromApi);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error list streams', e);
    }
  };

  const initializeGetLiveStreamsIntervalFetch = () => {
    const FIFTEEN_SECONDS = 15000;
    listLiveStreamsRef.current = setInterval(listLiveStreams, FIFTEEN_SECONDS);
  };

  const onSelectStreamMobile = (index: number) => {
    let selectedStream = null;
    if (isLiveSelected) {
      selectedStream = liveStreams[index];
    } else {
      const selectedSourceLocation = streamSourceLocation[index];
      selectedStream = streamList?.get(selectedSourceLocation.stream_id);
    }

    if (selectedStream) updateRouteQueryParams('stream_id', selectedStream?.id);

    userMarketingEventTrack.track(`selected_stream_${selectedStream?.channel?.username}`);
    setFeaturedStream({
      ...selectedStream,
    });
    setIsMapFollowLocked(true);
  };

  const onMount = () => {
    window.addEventListener('keydown', scrollWithKeyboard);
    initializeGetLiveStreamsIntervalFetch();
    setTimeout(onScrollElementUpdates, 2000);
    scrollToStreamSelectedFromQueryParams();
    return () => {
      window.removeEventListener('keydown', scrollWithKeyboard);
      window.removeEventListener('scroll', listenToScrollEvent);
      removeWheelListener();
      clearInterval(listLiveStreamsRef.current);
    };
  };
  useEffect(onMount, []);

  return (
    <>
      {!isMobile && (
        <DektopStreamListView
          listenToScrollWheel={listenToScrollWheel}
          removeWheelListener={removeWheelListener}
          openLiveStream={openLiveStream}
          scrollCarousel={scrollCarousel}
          isLiveSelected={isLiveSelected}
          isRightArrowVisible={isRightArrowVisible}
          isLeftArrowVisible={isLeftArrowVisible}
          isStreamListVisible={isStreamListVisible}
          isLoading={isLoading}
          liveStreams={liveStreams}
          streamList={streamList}
          elementScrollRef={elementScrollRef}
          streamSourceLocation={streamSourceLocation}
          featuredStream={featuredStream}
        />
      )}
      {isMobile && (
        <MobileStreamListView
          isLiveSelected={isLiveSelected}
          isStreamListVisible={isStreamListVisible}
          liveStreams={liveStreams}
          streamList={streamList}
          streamSourceLocation={streamSourceLocation}
          featuredStream={featuredStream}
          isLoading={isLoading}
          onSelectStream={onSelectStreamMobile}
        />
      )}
    </>
  );
}
