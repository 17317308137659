export function TwoDimensionIcon() {
  return (
    <svg width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.373063 10C0.271738 10 0.184229 9.96265 0.110537 9.88796C0.0368458 9.81326 0 9.72456 0 9.62185V8.16527C0 8.10924 0.0138172 8.00187 0.0414515 7.84314C0.0690858 7.67507 0.170412 7.53035 0.345429 7.40896L0.497418 7.29692C1.46462 6.5873 2.27062 5.98973 2.91542 5.5042C3.56943 5.01867 4.05764 4.60317 4.38004 4.2577C4.71165 3.90289 4.87746 3.5901 4.87746 3.31933C4.87746 3.18861 4.85443 3.06256 4.80837 2.94118C4.76232 2.81979 4.61954 2.7591 4.38004 2.7591C4.19581 2.7591 4.05303 2.80579 3.95171 2.89916C3.85038 2.98319 3.77669 3.08123 3.73063 3.19328C3.68458 3.30532 3.64773 3.39869 3.6201 3.47339C3.57404 3.60411 3.50035 3.69281 3.39902 3.7395C3.30691 3.77684 3.19637 3.79552 3.06741 3.79552H0.469784C0.386881 3.79552 0.313189 3.76751 0.248709 3.71148C0.19344 3.64613 0.165806 3.57143 0.165806 3.4874C0.175017 2.95518 0.285555 2.47899 0.497418 2.05882C0.718492 1.62932 1.01786 1.2605 1.39553 0.952381C1.7732 0.644258 2.21535 0.410831 2.72198 0.252101C3.22861 0.0840336 3.77669 0 4.36622 0C5.20446 0 5.93217 0.12605 6.54933 0.378151C7.1665 0.620915 7.6455 0.975724 7.98632 1.44258C8.32714 1.90943 8.49755 2.47432 8.49755 3.13726C8.49755 3.65079 8.3732 4.12698 8.12449 4.56583C7.87578 5.00467 7.52575 5.42484 7.07439 5.82633C6.63224 6.21849 6.11179 6.62465 5.51305 7.04482L5.41633 7.11485H8.33175C8.43307 7.11485 8.52058 7.15219 8.59427 7.22689C8.66797 7.30159 8.70481 7.39029 8.70481 7.493V9.62185C8.70481 9.72456 8.66797 9.81326 8.59427 9.88796C8.52058 9.96265 8.43307 10 8.33175 10H0.373063Z"
        fill="#131313"
        fillOpacity="0.62"
      />
      <path
        d="M10.3781 10C10.2768 10 10.1893 9.96265 10.1156 9.88796C10.0419 9.81326 10.005 9.72456 10.005 9.62185V0.57423C10.005 0.471522 10.0419 0.38282 10.1156 0.308123C10.1893 0.233427 10.2768 0.196078 10.3781 0.196078H14.3574C15.2233 0.196078 15.9971 0.345472 16.6787 0.644258C17.3604 0.933707 17.9038 1.36321 18.3091 1.93277C18.7237 2.50233 18.9447 3.20728 18.9724 4.04762C18.9908 4.44911 19 4.80392 19 5.11204C19 5.41083 18.9908 5.75163 18.9724 6.13445C18.9355 7.01214 18.7237 7.74043 18.3368 8.31933C17.9499 8.88889 17.4202 9.31373 16.7478 9.59384C16.0846 9.86461 15.3108 10 14.4265 10H10.3781ZM13.4593 7.26891H14.3574C14.5601 7.26891 14.7397 7.23156 14.8963 7.15686C15.0621 7.07283 15.1957 6.94678 15.297 6.77871C15.3983 6.61064 15.4536 6.39589 15.4628 6.13445C15.4812 5.86368 15.4904 5.62092 15.4904 5.40616C15.4997 5.19141 15.4997 4.97666 15.4904 4.76191C15.4904 4.53782 15.4812 4.29972 15.4628 4.04762C15.4444 3.65546 15.32 3.37068 15.0897 3.19328C14.8687 3.01587 14.6015 2.92717 14.2883 2.92717H13.4593V7.26891Z"
        fill="#131313"
        fillOpacity="0.62"
      />
    </svg>
  );
}
