import { useState } from 'react';
import Flex from '../../../../components/primitives/flex';
import MapBoxGeneric from '../../../../components/third-party/map/map-generic';
import useGlobalStore from '../../../../app-global-store';
import useEventStore from '../../state-manager/store';
import { EventPlayStatus, IUserLocation } from '../../state-manager/store-types';

export function EventMap() {
  const eventInfo = useEventStore((state) => state.eventInfo);
  const featUserLocation = useEventStore((state) => state.userLocation);
  const selectLiveStream = useEventStore((state) => state.selectLiveStream);
  const setFeatUserLocation = useEventStore((state) => state.setUserLocation);
  const playStatus = useEventStore((state) => state.playStatus);
  const locationsOfLiveUsers = useEventStore((state) => state.locationsOfLiveUsers);
  const analytics = useGlobalStore((state) => state.analytics);
  const setMapRef = useEventStore((state) => state.setMapRef);
  const locationsOfLiveUserFiltered = locationsOfLiveUsers.filter(
    (user) => user?.stream_id !== featUserLocation?.stream_id,
  );

  const [isMapFollowLocked, setIsMapFollowLocked] = useState<boolean>(true);

  const onMarkerClick = (position: IUserLocation) => {
    setFeatUserLocation(position);
    if (!position?.stream_id) return;
    selectLiveStream(position?.stream_id);
  };

  let mapLocation;
  if (!featUserLocation) {
    mapLocation = {
      longitude: eventInfo?.longitude || 0,
      latitude: eventInfo?.latitude || 0,
      useravatar: '',
      username: '',
    };
  } else {
    mapLocation = featUserLocation;
  }
  return (
    <Flex css={{ width: '100%', height: '100%' }}>
      <MapBoxGeneric
        mapCenter={[mapLocation.longitude, mapLocation.latitude]}
        singleAthletePosition={featUserLocation}
        athletePositions={playStatus === EventPlayStatus.LIVE ? locationsOfLiveUserFiltered : []}
        onMapReady={(mapRef) => setMapRef(mapRef)}
        isMapFollowLocked={isMapFollowLocked}
        setIsMapFollowLocked={setIsMapFollowLocked}
        onZoomIn={() => analytics.track('map_zoom_in_stream_view')}
        onZoomOut={() => analytics.track('map_zoom_out_stream_view')}
        onChangeTo2D={() => analytics.track('map_changed_2D_stream_view')}
        onChangeTo3D={() => analytics.track('map_changed_3D_stream_view')}
        onLockOnUserPosition={() => analytics.track('map_lock_user_location_stream_view')}
        onMarkerClick={onMarkerClick}
        mapStyle={eventInfo?.map_style}
        accessToken={eventInfo?.access_token}
      />
    </Flex>
  );
}
