interface IProps {
  color?: string;
}
export function AddIcon({ color = '#131313' }: IProps) {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 14C7.21667 14 6.97933 13.904 6.788 13.712C6.596 13.5207 6.5 13.2833 6.5 13V8H1.5C1.21667 8 0.979 7.904 0.787 7.712C0.595667 7.52067 0.5 7.28333 0.5 7C0.5 6.71667 0.595667 6.479 0.787 6.287C0.979 6.09567 1.21667 6 1.5 6H6.5V1C6.5 0.716667 6.596 0.479 6.788 0.287C6.97933 0.0956668 7.21667 0 7.5 0C7.78333 0 8.021 0.0956668 8.213 0.287C8.40433 0.479 8.5 0.716667 8.5 1V6H13.5C13.7833 6 14.0207 6.09567 14.212 6.287C14.404 6.479 14.5 6.71667 14.5 7C14.5 7.28333 14.404 7.52067 14.212 7.712C14.0207 7.904 13.7833 8 13.5 8H8.5V13C8.5 13.2833 8.40433 13.5207 8.213 13.712C8.021 13.904 7.78333 14 7.5 14Z"
        fill={color}
        fillOpacity="0.81"
      />
    </svg>
  );
}
