import { useEffect, useState } from 'react';

export default function useWindowInnerHeight() {
  const [vh, setVh] = useState(window.visualViewport?.height);

  useEffect(() => {
    const updateVh = () => {
      setVh(window.visualViewport?.height || 100);
    };

    window.addEventListener('resize', updateVh);

    return () => window.removeEventListener('resize', updateVh);
  }, []);

  return vh || 0;
}
