/* eslint-disable import/export */
import { MissingParamError, UnexpectedError } from '../../domain/errors';
import { GetEventInfo } from '../../domain/usecases';
import { HttpStatusCode, HttpClient } from '../protocols/http';

export class RemoteGetEventInfo implements GetEventInfo {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<GetEventInfo.Model | any>) {}

  async get(eventName: string): Promise<GetEventInfo.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${eventName}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;

      default:
        throw new UnexpectedError();
    }
  }

  async listStreams(eventId: string): Promise<GetEventInfo.StreamList> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${eventId}/streams?status=started`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;

      default:
        throw new UnexpectedError();
    }
  }

  async timeline(eventId: string, day: string): Promise<GetEventInfo.TimeLineModel> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${eventId}/timeline?day=${day}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new MissingParamError();

      default:
        throw new UnexpectedError();
    }
  }

  async timelineV2(eventId: string, day?: string): Promise<GetEventInfo.TimeLineV2Model> {
    const httpResponse = await this.httpClient.request({
      url: day ? `${this.url}/${eventId}/timeline_v2?day=${day}` : `${this.url}/${eventId}/timeline_v2`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new MissingParamError();

      default:
        throw new UnexpectedError();
    }
  }

  async timelineV3(eventId: string, day?: string): Promise<GetEventInfo.TimeLineV3Model> {
    const httpResponse = await this.httpClient.request({
      url: day ? `${this.url}/${eventId}/timeline_v3?start_date=${day}` : `${this.url}/${eventId}/timeline_v3`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new MissingParamError();

      default:
        throw new UnexpectedError();
    }
  }

  async getStreams(eventId: string, day: string): Promise<GetEventInfo.NewStreamList> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${eventId}/streams?day=${day}&per_page=10000000000`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new MissingParamError();

      default:
        throw new UnexpectedError();
    }
  }

  async getPulseLines(eventId: string, day: string): Promise<GetEventInfo.StreamPulseLines> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${eventId}/pulse_lines?day=${day}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new MissingParamError();

      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteGetEventInfo {
  export type Model = GetEventInfo.Model;
}
