export function ArrowRightAltIcon() {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.3 11.275C9.1 11.075 9.004 10.8334 9.012 10.55C9.02067 10.2667 9.125 10.025 9.325 9.82505L12.15 7.00005H1C0.716667 7.00005 0.479 6.90405 0.287 6.71205C0.0956669 6.52072 0 6.28338 0 6.00005C0 5.71672 0.0956669 5.47905 0.287 5.28705C0.479 5.09572 0.716667 5.00005 1 5.00005H12.15L9.3 2.15005C9.1 1.95005 9 1.71238 9 1.43705C9 1.16238 9.1 0.925049 9.3 0.725049C9.5 0.525049 9.73767 0.425049 10.013 0.425049C10.2877 0.425049 10.525 0.525049 10.725 0.725049L15.3 5.30005C15.4 5.40005 15.471 5.50838 15.513 5.62505C15.5543 5.74172 15.575 5.86672 15.575 6.00005C15.575 6.13338 15.5543 6.25838 15.513 6.37505C15.471 6.49172 15.4 6.60005 15.3 6.70005L10.7 11.3C10.5167 11.4834 10.2877 11.575 10.013 11.575C9.73767 11.575 9.5 11.475 9.3 11.275Z"
        fill="#DAFF01"
      />
    </svg>
  );
}
