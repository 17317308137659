import dayjs from 'dayjs';
import { useState } from 'react';
import Button from '../../../../components/primitives/button';
import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';
import { useIsMobile } from '../../../../utils/useIsMobile';
import useEventStore from '../../state-manager/store';

const STREAM_DESC_MAX_LENGTH = 162;

export default function EventMoreInfo() {
  const eventInfo = useEventStore((state) => state.eventInfo);
  const isMobile = useIsMobile();
  const getDescInitialState = () => {
    if (!eventInfo?.description?.length) return false;
    return eventInfo?.description.length < STREAM_DESC_MAX_LENGTH;
  };
  const [isFullEventDescVisible, setIsFullEventDescVisible] = useState<boolean>(getDescInitialState());

  const getEventDesc = () => {
    if (!eventInfo?.description) return '';
    if (!isMobile) return eventInfo?.description;
    return isFullEventDescVisible
      ? eventInfo?.description
      : `${eventInfo?.description.slice(0, STREAM_DESC_MAX_LENGTH)}...`;
  };

  const showExpandDescButton = () => {
    if (!eventInfo?.description) return false;
    return isMobile && eventInfo?.description.length > STREAM_DESC_MAX_LENGTH;
  };

  return (
    <Flex
      className="event-more-info-wrapper"
      css={{
        flexDirection: 'column',
        gap: '$xs',
        maxWidth: '79%',
        '@bp3': {
          maxWidth: '100%',
        },
      }}
    >
      <Flex className="event-date-range" css={{ gap: '$3xs' }}>
        <Typo css={{ color: 'rgba(255, 255, 255, 0.91)', fontSize: '14px' }}>
          {dayjs(eventInfo?.start_time).format('DD MMM YYYY')}
        </Typo>
        <Typo css={{ color: 'rgba(255, 255, 255, 0.42)', fontSize: '14px' }}>until</Typo>
        <Typo css={{ color: 'rgba(255, 255, 255, 0.91)', fontSize: '14px' }}>
          {eventInfo?.end_time ? dayjs(eventInfo?.end_time).format('DD MMM YYYY') : dayjs().format('DD MMM YYYY')}
        </Typo>
      </Flex>
      {eventInfo?.description && (
        <Typo className="event-desc" css={{ color: '$constantWhite', lineHeight: '23px' }}>
          {getEventDesc()}
        </Typo>
      )}

      {showExpandDescButton() && (
        <Typo
          css={{
            color: '$gs400',
            lineHeight: '1.43rem',
            alignSelf: 'flex-start',
            marginRight: '10px',
            marginTop: '5px',
          }}
          onClick={() => setIsFullEventDescVisible(!isFullEventDescVisible)}
        >
          {isFullEventDescVisible ? 'Show less' : 'Read full description'}
        </Typo>
      )}

      {eventInfo?.more_info_url && (
        <Button
          as="a"
          target="_blank"
          href={eventInfo?.more_info_url}
          variant="transparentActive"
          css={{ width: 'fit-content' }}
        >
          Learn more
        </Button>
      )}
    </Flex>
  );
}
