import useEventStore, { pauseEvent, playEvent } from '../state-manager/store';
import { ListOfPolylines } from '../state-manager/store-types';
import {
  getAllPulseLinesFromAllStreams,
  groupPulseLinesByUserNameAndSortByTimestamp,
  hasLiveStreamOnEvent,
} from '../utils/utils';
import { convertBackendStreamModelIntoFrontEndStreamModel, getNewFlattenStreams } from '../utils/getNewFlattenStreams';

export function useQueryTimelineData() {
  const eventRepository = useEventStore((state) => state.eventRepository);
  const setPulseLinesGroupedByStream = useEventStore((state) => state.setPulseLinesGroupedByStream);
  const setFeatStream = useEventStore((state) => state.setFeatStream);
  const setCurrentTime = useEventStore((state) => state.setCurrentTime);
  const setIsEventLive = useEventStore((state) => state.setIsEventLive);
  const setEventStreams = useEventStore((state) => state.setEventStreams);
  const setEventStreamsLinearList = useEventStore((state) => state.setEventStreamsLinearList);
  const setPulseLinesGroupedByUserAndSortedByTimestamp = useEventStore(
    (state) => state.setPulseLinesGroupedByUserAndSortedByTimestamp,
  );

  const queryData = async (eventId: string | undefined, eventDate?: string) => {
    if (!eventId) return;
    try {
      const newStreams = await eventRepository.getStreams(eventId || '', eventDate || '');

      const eventIsLive = hasLiveStreamOnEvent(newStreams);
      setCurrentTime(eventIsLive ? new Date() : new Date(newStreams[0].start_time));

      setIsEventLive(eventIsLive);
      if (newStreams.length && !eventIsLive) {
        setFeatStream(convertBackendStreamModelIntoFrontEndStreamModel(newStreams[0]));
      } else {
        const liveStream = newStreams.find((stream) => stream.status === 'started');
        if (liveStream) setFeatStream(convertBackendStreamModelIntoFrontEndStreamModel(liveStream));
      }

      const newPulseLines = await eventRepository.getPulseLines(eventId || '', eventDate || '');
      pauseEvent();

      const { flattenStreams, streamsHashMap } = getNewFlattenStreams(newStreams, newPulseLines);
      setEventStreams(streamsHashMap);
      setEventStreamsLinearList(flattenStreams);
      const pulseLinesGroupedByStream: ListOfPolylines = getAllPulseLinesFromAllStreams(flattenStreams);
      const pulseLinesGroupedByUserAndSortedByTimestamp =
        groupPulseLinesByUserNameAndSortByTimestamp(pulseLinesGroupedByStream);

      setPulseLinesGroupedByStream(pulseLinesGroupedByStream);

      setPulseLinesGroupedByUserAndSortedByTimestamp(pulseLinesGroupedByUserAndSortedByTimestamp);
      playEvent();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log({ e });
      // navigate('/not-found');
    }
  };

  return queryData;
}
