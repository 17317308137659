import { RefObject } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SourceLocation } from '../../../../../../domain/models/event-timeline-model';
import { StreamModel } from '../../../../../../domain/models/stream-model';
import LiveIndicator from '../../../../../components/icons/live-animated-indicator';
import SimpleLeftArrowIcon from '../../../../../components/icons/simple-left-arrow';
import SimpleRightArrowIcon from '../../../../../components/icons/simple-right-arrow';
import SubscriptionsIcon from '../../../../../components/icons/subscriptions';
import Button from '../../../../../components/primitives/button';
import Flex from '../../../../../components/primitives/flex';
import { Typo } from '../../../../../components/primitives/typo/typo';
import AppleLoading from '../../../../../components/spinners/AppleLoading/AppleLoading';
import StreamThumb from '../../../../../components/stream-thumb/stream-thumb';

interface IProps {
  listenToScrollWheel: () => void;
  removeWheelListener: () => void;
  openLiveStream: () => void;
  scrollCarousel: (isLeft?: boolean) => void;
  isLiveSelected: boolean;
  isRightArrowVisible: boolean;
  isLeftArrowVisible: boolean;
  isStreamListVisible: boolean;
  isLoading: boolean;
  streamList: Map<string, StreamModel> | null;
  liveStreams: StreamModel[];
  elementScrollRef: RefObject<HTMLDivElement>;
  streamSourceLocation: SourceLocation[];
  featuredStream: Partial<StreamModel> | null;
}

export default function DektopStreamListView({
  listenToScrollWheel,
  removeWheelListener,
  openLiveStream,
  scrollCarousel,
  isLiveSelected,
  isRightArrowVisible,
  isLeftArrowVisible,
  isStreamListVisible,
  isLoading,
  liveStreams,
  streamList,
  elementScrollRef,
  streamSourceLocation,
  featuredStream,
}: IProps) {
  const [searchParams] = useSearchParams();

  const getStreamOffsetInMs = (isSelected: boolean, offsetMs: number): number => {
    const offSetMsFromQueryParam = searchParams.get('offset_ms');
    if (!offSetMsFromQueryParam) return offsetMs;
    if (isSelected && Number(offSetMsFromQueryParam) >= 0) return Number(offSetMsFromQueryParam);
    return offsetMs;
  };

  return (
    <Flex
      className="stream-list-scroll"
      css={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '$xs',
        position: 'relative',
      }}
      onMouseEnter={listenToScrollWheel}
      onMouseLeave={removeWheelListener}
    >
      <Flex
        className="stream-list-title"
        css={{ gap: '$2xs', alignItems: 'center', paddingLeft: '$xs', height: '2.1rem' }}
      >
        <SubscriptionsIcon />
        <Typo type="bodyBold" css={{ color: '$constantWhite' }}>
          {isLiveSelected ? 'Live Streams' : 'Recorded Streams'}
        </Typo>
        {!isLiveSelected && !!liveStreams.length && (
          <Button
            size="sm"
            variant="transparent"
            onClick={openLiveStream}
            css={{ display: 'flex', gap: '10px', background: 'rgba(218, 255, 1, 0.1)' }}
          >
            <LiveIndicator />
            Jump to live
          </Button>
        )}
      </Flex>
      <Flex
        className="inner-scroll"
        ref={elementScrollRef}
        css={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          gap: '$2xs',
          overflowX: 'auto',
          position: 'relative',
          '&::-webkit-scrollbar': {
            width: '0px',
            height: '0px',
          },
        }}
      >
        {isStreamListVisible &&
          !isLiveSelected &&
          streamSourceLocation.map(({ stream_id, offset_ms }, index: number) => (
            <StreamThumb
              key={stream_id}
              streamData={streamList?.get(stream_id)}
              isSelected={featuredStream?.id === stream_id}
              isFirstOnList={index === 0}
              isLastOnList={index === streamSourceLocation.length - 1}
              videoTimeOffset={getStreamOffsetInMs(featuredStream?.id === stream_id, offset_ms)}
            />
          ))}
        {isStreamListVisible &&
          isLiveSelected &&
          liveStreams.map((stream, index: number) => (
            <StreamThumb
              key={stream.id}
              streamData={stream}
              isSelected={featuredStream?.id === stream.id}
              isFirstOnList={index === 0}
              isLastOnList={index === streamSourceLocation.length - 1}
            />
          ))}
      </Flex>
      <Flex
        className="right-navigation"
        onClick={() => scrollCarousel()}
        css={{
          transition: 'all 0.5s',
          opacity: isRightArrowVisible ? 1 : 0,
          visibility: isRightArrowVisible ? 'visible' : 'hidden',
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          top: '3.125rem',
          bottom: 0,
          right: 0,
          minWidth: '5.5rem',
          background: 'linear-gradient(270deg, rgba(44, 44, 47, 0.8) 24.05%, rgba(44, 44, 47, 0) 100%)',
          '& .simple-right-arrow': {
            transition: 'all 0.5s',
          },
          '&:hover': {
            '& .simple-right-arrow': {
              transform: 'scale(.96) translateX(10px)',
            },
          },
          '&:active': {
            '& .simple-right-arrow': {
              transform: 'scale(.85) translateX(10px)',
            },
          },
        }}
      >
        <SimpleRightArrowIcon />
      </Flex>

      <Flex
        className="right-navigation"
        onClick={() => scrollCarousel(true)}
        css={{
          position: 'absolute',
          minWidth: '5.5rem',
          transition: 'all 0.5s',
          opacity: isLeftArrowVisible ? 1 : 0,
          visibility: isLeftArrowVisible ? 'visible' : 'hidden',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          top: '3.125rem',
          left: 0,
          bottom: 0,
          width: '5.5rem',
          background: 'linear-gradient(90deg, rgba(44, 44, 47, 0.8) 24.05%, rgba(44, 44, 47, 0) 100%)',
          '& .simple-left-arrow': {
            transition: 'all 0.5s',
          },
          '&:hover': {
            '& .simple-left-arrow': {
              transform: 'scale(.96) translateX(-10px)',
            },
          },
          '&:active': {
            '& .simple-left-arrow': {
              transform: 'scale(.85) translateX(-10px)',
            },
          },
        }}
      >
        <SimpleLeftArrowIcon />
      </Flex>
      {isLoading && (
        <Flex
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.2)',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AppleLoading />
        </Flex>
      )}
    </Flex>
  );
}
