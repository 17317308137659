/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useRef } from 'react';
import { DataSet } from 'vis-data';
import dayjs from 'dayjs';

import { Timeline as VisTimeline } from 'vis-timeline';
import 'vis-timeline/dist/vis-timeline-graph2d.min.css';

import Flex from '../../primitives/flex';

import { style } from './timeline-custom-style';
import { options } from './timeline-options';

const currentTimeId = 't1';
const hoverTimeId = 't2';
let currentHoverTime: Date | null = null;

interface ITimelineItem {
  id: string;
  content: any;
  group: string | undefined;
  start: Date;
  end: Date;
}

interface ITimelineGroup {
  id: string | undefined;
  content: any;
}
interface IProps {
  items: DataSet<ITimelineItem, 'id'>;
  groups: DataSet<ITimelineGroup, 'id'>;
  onSelectItem?: (id: string, date: Date | null) => void;
  onTimelineReady?: (timelineRef: VisTimeline) => void;
  onChangeTimelineRange?: (startDate: Date) => void;
  isMobile?: boolean;
}

export function Timeline({ onSelectItem, onTimelineReady, onChangeTimelineRange, items, groups, isMobile }: IProps) {
  const timelineRef = useRef<VisTimeline | null>(null);

  const addLabelToCustomTimers = () => {
    const customPlayingVerticalLineElement = document.querySelector('.t1');
    const customHoverTimeVerticalLineElement = document.querySelector('.t2');
    const labelHoverElement = document.createElement('label');
    const labelPlayingElement = document.createElement('label');
    labelHoverElement.setAttribute('id', 'labelHoverElement');
    labelPlayingElement.setAttribute('id', 'labelPlayingElement');
    if (customPlayingVerticalLineElement) customPlayingVerticalLineElement.appendChild(labelPlayingElement);
    if (customHoverTimeVerticalLineElement) customHoverTimeVerticalLineElement.appendChild(labelHoverElement);
  };

  const addCustomTimeVerticalLine = () => {
    const date = new Date();
    timelineRef?.current?.addCustomTime(date, currentTimeId);
    timelineRef?.current?.addCustomTime(date, hoverTimeId);
    setTimeout(addLabelToCustomTimers, 1000);
  };

  const formatDate = (date: string | Date | number) => dayjs(date).format('HH:mm');

  const setHoverVerticalLineLabelValue = (time: number) => {
    const labelElement = document.getElementById('labelHoverElement');
    if (labelElement) {
      labelElement.innerHTML = formatDate(time);
    }
  };

  const listenToTimelineHover = () => {
    if (!isMobile) {
      timelineRef?.current?.on('mouseMove', (event: any) => {
        const hoverDate = new Date(event.time);
        timelineRef?.current?.setCustomTime(hoverDate, hoverTimeId);
        currentHoverTime = hoverDate;
        setHoverVerticalLineLabelValue(event.time);
      });
    }
  };

  const listenToTimelineItemSelect = () => {
    timelineRef?.current?.on('select', ({ items: selectedItems }: any) => {
      if (selectedItems?.length && currentHoverTime && !isMobile) {
        timelineRef?.current?.setCustomTime(currentHoverTime, currentTimeId);
        if (onSelectItem) onSelectItem(selectedItems[0], currentHoverTime);
      }
    });
  };

  const listenToTimelineRangeChange = () => {
    timelineRef?.current?.on('rangechanged', ({ start }) => {
      if (onChangeTimelineRange) onChangeTimelineRange(start);
    });
  };

  const listenToTimelineClick = () => {
    timelineRef?.current?.on('click', (event: any) => {
      const props = timelineRef?.current?.getEventProperties(event);
      // @ts-ignore
      const clickedTime = props?.event.time;
      // @ts-ignore
      const itemId = props?.event.item;
      if (onSelectItem && itemId && isMobile) {
        timelineRef?.current?.setCustomTime(clickedTime, currentTimeId);
        onSelectItem(`${itemId}`, clickedTime);
      }
    });
  };

  const setInitialZoom = () => {
    const dateRange = timelineRef?.current?.getItemRange();
    if (!dateRange?.min || !dateRange?.max) return;
    setTimeout(() => timelineRef?.current?.setWindow(dateRange.min, dateRange.max), 1000);
  };

  const initializeTimelineEventListeners = () => {
    if (!timelineRef || !timelineRef?.current) return;
    listenToTimelineHover();
    listenToTimelineItemSelect();
    listenToTimelineClick();
    listenToTimelineRangeChange();
    if (onTimelineReady) onTimelineReady(timelineRef?.current);
  };

  const initializeTimeline = () => {
    if (timelineRef.current) return;
    const timelineElement = document.getElementById('timeline');
    if (timelineElement) {
      // @ts-ignore
      timelineRef.current = new VisTimeline(timelineElement, items, groups, options);
      addCustomTimeVerticalLine();
      initializeTimelineEventListeners();
      setInitialZoom();
    }
  };

  const onMount = () => {
    initializeTimeline();
    return () => {
      // timelineRef?.current?.destroy();
    };
  };
  useEffect(onMount, []);

  return (
    <Flex className="timeline-wrapper" css={style}>
      <div id="timeline" />
    </Flex>
  );
}
