import { ReactElement, useEffect } from 'react';
import { SocialAuth } from '../../../data/protocols/social-auth';
import { AddProfile } from '../../../domain/usecases';
import { LocalStorageAdapter } from '../../../infra/cache';
import { AuthFireBaseUser } from '../../../infra/social-auth';
import GenericAuthDialog from '../../components/auth-dialog/generic-auth-dialog';
import { ShareModal } from '../../components/share-modal/share-modal';
import { createUserName } from '../../utils/utils';
import useAuthStore from './state-manager/store';
import useGlobalStore from '../../app-global-store';

interface IProps {
  children: ReactElement;
  socialSignIn: SocialAuth;
  addProfile: AddProfile;
  localStorageAdapter: LocalStorageAdapter;
}

export function AuthWrapper({ children, socialSignIn, localStorageAdapter, addProfile }: IProps) {
  const isAuthDialogVisible = useAuthStore((state) => state.isAuthDialogVisible);
  const setIsAuthDialogVisible = useAuthStore((state) => state.setIsAuthDialogVisible);
  const setAuthToggleState = useAuthStore((state) => state.setAuthToggleState);
  const isShareDialogVisible = useAuthStore((state) => state.isShareDialogVisible);
  const setIsShareDialogVisible = useAuthStore((state) => state.setIsShareDialogVisible);
  const analytics = useGlobalStore((state) => state.analytics);

  const onFirebaseAuthStateChanged = ({ user, token }: { user: AuthFireBaseUser; token: string }) => {
    localStorageAdapter.set('fireBaseUser', user);
    localStorageAdapter.set('token', token);
  };

  const toggleAuthState = () => {
    setAuthToggleState(false);
    setTimeout(() => setAuthToggleState(true), 2000);
  };

  const createProfile = async () => {
    try {
      const firebaseUser: AuthFireBaseUser = localStorageAdapter.get('fireBaseUser') as unknown as AuthFireBaseUser;
      const profileCreated = await addProfile.add({
        bio: '',
        display_name: firebaseUser.displayName,
        profile_photo: firebaseUser?.providerData[0]?.photoURL,
        username: createUserName(firebaseUser.email),
      });
      localStorageAdapter.set('profile', profileCreated);
      analytics.identify(profileCreated);
      toggleAuthState();
    } catch (error) {
      const firebaseUser: AuthFireBaseUser = localStorageAdapter.get('fireBaseUser') as unknown as AuthFireBaseUser;
      analytics.track('error_creating_new_profile', { firebaseUser, error });
    }
  };

  const onMount = () => {
    const profile = localStorageAdapter.get('profile');
    if (profile) analytics.identify(profile);
  };
  useEffect(onMount, []);

  return (
    <>
      {children}
      <GenericAuthDialog
        onSuccessSignIn={createProfile}
        onFirebaseAuthStateChanged={onFirebaseAuthStateChanged}
        socialSignIn={socialSignIn}
        open={isAuthDialogVisible}
        setOpen={setIsAuthDialogVisible}
      />
      <ShareModal isOpen={isShareDialogVisible} setIsOpen={setIsShareDialogVisible} analytics={analytics} />
    </>
  );
}
