/* eslint-disable no-console */
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Consumer, Mixin, Subscription } from '@rails/actioncable';

import consumer from './web-socket/web-socket';

import Flex from '../../components/primitives/flex';
import TopHeader from '../../components/top-header/top-header';
import { useIsMobile } from '../../utils/useIsMobile';
import { EventGrid } from './components/event-grid/event-grid';

import useEventStore from './state-manager/store';
import { useQueryTimelineData } from './hooks/useQueryTimelineData';
import { EventPlayStatus, EventStream, IUserLocation } from './state-manager/store-types';
import { useUpdateTimeline } from './hooks/useUpdateTimeline';
import { useUpdateMapLiveLocations } from './hooks/useUpdateMapLiveLocations';
import { GetEventInfo } from '../../../domain/usecases';

interface IWSLocation {
  channel: {
    id: string;
    username: string;
    hero_photo: string;
    stream: {
      id: string;
      location: {
        latitude: number;
        longitude: number;
        gps_precision: number;
        captured_at: number;
        offset_ms: number;
      };
    };
  };
}

let eventStreamsNewRef: EventStream[] = [];
let wsLocationSubscription: (Mixin & Subscription<Consumer>) | null | any = null;
let eventInfo: GetEventInfo.Model | null = null;
export default function NewEventPage() {
  const { eventName } = useParams();
  const eventRepository = useEventStore((state) => state.eventRepository);
  const eventStreams = useEventStore((state) => state.eventStreams);
  const playStatus = useEventStore((state) => state.playStatus);
  eventStreamsNewRef = eventStreams;
  const setEventInfo = useEventStore((state) => state.setEventInfo);
  const addOrUpdateLiveUserLocation = useEventStore((state) => state.addOrUpdateLiveUserLocation);

  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const getEventTimeline = useQueryTimelineData();
  const updateTimeline = useUpdateTimeline();
  const checkAndRemoveMarkerIfItsNotLive = useUpdateMapLiveLocations();

  const subscribeToEventTimelineUpdateChannel = (event_id: string) => {
    consumer.subscriptions.create(
      { channel: 'EventTimelineUpdateChannel', event_id },
      {
        initialized: () => {
          console.log('initialized');
        },
        connected() {
          console.log('connected');
        },
        disconnected() {
          console.log('disconnected');
        },
        received(timeline) {
          updateTimeline(timeline, eventStreamsNewRef);
          checkAndRemoveMarkerIfItsNotLive(eventStreamsNewRef);
        },
      },
    );
  };

  const convertWebSocketObjectToUserLocation = (location: IWSLocation): IUserLocation => {
    const streamInfo = eventStreamsNewRef.find((stream) => stream.id === location.channel.stream.id);
    const { latitude, longitude } = location.channel.stream.location;
    return {
      latitude,
      longitude,
      username: streamInfo?.content.username || `${location.channel.stream.id}`,
      useravatar: streamInfo?.content.useravatar || `${location.channel.stream.id}`,
      stream_id: location.channel.stream.id,
      duration_in_seconds: streamInfo?.content?.duration_in_seconds,
    };
  };

  const subscribeToEventStreamLocationUpdateChannel = (event_id: string) => {
    wsLocationSubscription = consumer.subscriptions.create(
      { channel: 'EventStreamLocationUpdateChannel', event_id },
      {
        initialized: (): any => {},
        connected(): any {},
        disconnected(): any {},
        received(data: IWSLocation): any {
          const location = convertWebSocketObjectToUserLocation(data);
          addOrUpdateLiveUserLocation(location, eventStreamsNewRef);
        },
      },
    );
  };

  const unsubscribeToLiveLocationUpdates = () => {
    if (wsLocationSubscription) wsLocationSubscription?.unsubscribe();
  };

  const startListeningToLiveLocationUpdates = () => {
    if (!eventInfo) return;
    if (playStatus === EventPlayStatus.LIVE) {
      subscribeToEventStreamLocationUpdateChannel(eventInfo.id);
    } else if (wsLocationSubscription) {
      unsubscribeToLiveLocationUpdates();
    }
  };
  useEffect(startListeningToLiveLocationUpdates, [playStatus]);

  const getEventInfo = async () => {
    try {
      const event = await eventRepository.get(eventName || '');
      eventInfo = event;
      setEventInfo(event);
      getEventTimeline(event.id);
      subscribeToEventTimelineUpdateChannel(event.id);
    } catch (e) {
      navigate('/not-found');
    }
  };

  const onMount = () => {
    getEventInfo();
  };
  useEffect(onMount, []);

  return (
    <Flex
      css={{
        width: '100%',
        height: '100%',
        backgroundColor: '$bgAppScreen100',
        flexDirection: 'column',
      }}
    >
      {!isMobile && <TopHeader showShareButton={false} />}
      <Flex
        className="event-content"
        css={{
          height: 'calc(100% - 4.5rem)',
          '@bp3': {
            height: '100%',
          },
        }}
      >
        <Flex className="event-grid-wrapper" css={{ width: '100%' }}>
          <EventGrid />
        </Flex>
      </Flex>
    </Flex>
  );
}
