import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';

import Flex from '../../components/primitives/flex';
import EventChat from './components/event-chat/event-chat';
import EventHeader from './components/event-header/event-header';
import { EventMap } from './components/event-map/event-map';
import EventMoreInfo from './components/event-more-info/event-more-info';
import { EventTimeline } from './components/event-timeline/event-timeline';
import useEventStore from './state-manager/store';
import { EventLeftContainer } from './components/event-left-container/event-left-container';
import { EventFeatStream } from './components/event-feat-stream/event-feat-stream';
import { EventDateSelect } from './components/event-date-select/event-date-select';

import { useWsChannelUpdate } from './hooks/useWsChanneUpdate';
import { useWsLocationlUpdate } from './hooks/useWsLocationUpdate';
import { EventJumpToLiveButton } from './components/event-jump-to-live-button/event-jump-to-live-button';
// import { EventCommentary } from './components/event-commentary/event-commentary';
import { useIsMobile } from '../../utils/useIsMobile';
import { EventCurrentTime } from './components/event-current-time/event-current-time';
// import { GetEventInfo } from '../../../domain/usecases';

export function EventPage() {
  const { eventName } = useParams();
  const navigate = useNavigate();
  const mainContentRef = useRef(null);
  const isMobile = useIsMobile();
  const eventRepository = useEventStore((state) => state.eventRepository);
  const setMainContentElement = useEventStore((state) => state.setMainContentElement);
  const setEventInfo = useEventStore((state) => state.setEventInfo);
  const eventInfo = useEventStore((state) => state.eventInfo);
  const setCurrentDay = useEventStore((state) => state.setCurrentDay);
  const subscribeToEventTimelineUpdateChannel = useWsChannelUpdate();
  const { subscribeToEventStreamLocationUpdateChannel, unsubscribeToLiveLocationUpdates } = useWsLocationlUpdate();

  const getEventInfo = async () => {
    try {
      const event = await eventRepository.get(eventName || '');
      document.title = `Pulse | ${event?.title}`;
      setCurrentDay(event?.timeline_days[event.timeline_days.length - 1]);
      setEventInfo(event);
      subscribeToEventTimelineUpdateChannel(event.id);
    } catch (e) {
      //   navigate('/not-found');
    }
  };

  const redirectToMobileVersion = () => {
    if (isMobile) {
      navigate(`/legacy/${eventName}`);
    }
  };
  useEffect(redirectToMobileVersion, [isMobile]);

  const onReceiveMainContentRef = () => {
    if (!mainContentRef.current) return;
    setMainContentElement(mainContentRef.current);
  };
  useEffect(onReceiveMainContentRef, [mainContentRef]);

  const startListeningToLiveLocationUpdates = () => {
    if (!eventInfo) return;
    subscribeToEventStreamLocationUpdateChannel(eventInfo.id);

    return () => {
      unsubscribeToLiveLocationUpdates();
    };
  };
  useEffect(startListeningToLiveLocationUpdates, [eventInfo]);

  const onMount = () => {
    getEventInfo();
  };
  useEffect(onMount, []);

  return (
    <Flex
      as="main"
      className="event-page"
      css={{
        backgroundColor: '$bgAppScreen100',
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
        overflow: 'auto',
      }}
      role="main"
      aria-label="Event Page"
    >
      <EventHeader />
      <Flex
        as="section"
        className="event-content"
        css={{
          padding: '0.5rem 0px',
          paddingTop: '0.2rem',
          flexDirection: 'column',
          height: 'calc(100% - 4.5rem)',
          alignItems: 'center',
          '@bp3': {
            height: '100%',
          },
        }}
        role="region"
        aria-label="Event Content"
      >
        <Flex
          as="article"
          className="main-content"
          css={{
            minHeight: '90%',
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
          }}
          role="region"
          aria-label="Main Content"
          ref={mainContentRef}
        >
          <Flex className="map-wrapper" css={{ width: '100%', height: '100%' }}>
            <EventFeatStream />

            <Flex
              css={{
                position: 'absolute',
                right: '18.5rem',
                bottom: '6.9rem',
                gap: '1rem',
                zIndex: 50,
                alignItems: 'center',
              }}
            >
              <EventJumpToLiveButton />
              <EventCurrentTime />
            </Flex>
            <EventDateSelect />
            <EventLeftContainer />
            <EventMap />
            <EventTimeline />
            <Flex
              className="right-container"
              css={{
                position: 'absolute',
                right: '0.8rem',
                top: '0.8rem',
                width: '17rem',
                bottom: '6.8rem',
                zIndex: 1000,
                flexDirection: 'column',
                gap: '$3xs',
              }}
              role="complementary"
              aria-label="Event Chat"
            >
              {/* <EventCommentary /> */}
              <EventChat />
            </Flex>
          </Flex>
        </Flex>
        <EventMoreInfo />
      </Flex>
    </Flex>
  );
}
