import Flex from '../primitives/flex';
import { Typo } from '../primitives/typo/typo';

export default function HostPill() {
  return (
    <Flex
      css={{
        backgroundColor: '$primaryAdrenaline100',
        padding: '0px $2xs',
        height: '1.5rem',
        alignItems: 'center',
        borderRadius: '2.5rem',
        gap: '$3xs',
      }}
    >
      <Typo css={{ color: '$black200', fontWeight: '$demi', fontSize: '0.8rem' }}>Host</Typo>
    </Flex>
  );
}
