/* eslint-disable @typescript-eslint/ban-ts-comment */
import flatten from 'lodash/flatten';
import { GPSData } from '../../../../domain/models/event-timeline-v3-model';
import { GetEventInfo } from '../../../../domain/usecases';
import { EventStream, NewEventStream } from '../state-manager/store-types';
import { NewStreamModel, StreamPulseLine } from '../../../../domain/models/new-stream-model';

function isEmptyObject(obj: object | undefined | null): boolean {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
}

export function getNewFlattenStreams(streams: GetEventInfo.NewStreamList, pulselines: GetEventInfo.StreamPulseLines) {
  const streamsHashMap: any = {};

  const pulseLinesHashMap = pulselines?.reduce((acc: any, item: StreamPulseLine) => {
    acc[item.uuid] = item.pulse_line;
    return acc;
  }, {});

  const flattenStreams = streams.map((stream) => {
    const streamInfo = {
      ...stream.channel,
      ...stream,
      stream_start_date_ms: Math.floor(new Date(stream.start_time).getTime()),
      stream_end_date_ms: stream.end_time ? Math.floor(new Date(stream.end_time).getTime()) : null,
      title: stream.title,
      avatarPicture: stream.channel.hero_photo?.small,
      pulse_lines: isEmptyObject(pulseLinesHashMap)
        ? [
            {
              timestamp: Date.now(),
              lat: stream.latitude,
              long: stream.longitude,
              altitude: 0,
              speed: 0,
              gps_precision: 0,
              id: stream.id,
              playback_url: stream.playback_url,
              vod_playback_url: stream.vod_playback_url,
              logo_url: stream?.channel?.logo,
              title: stream.channel.title || stream.title,
              username: stream.channel.username,
              avatarPicture: stream.channel.hero_photo?.small,
              duration_in_seconds: stream.duration_in_seconds,
              stream_tile_url: stream.stream_tile_url,
              // @ts-ignore
              offset_ms: Date.now() - new Date(stream.start_time),
              stream_start_date_ms: Math.floor(new Date(stream.start_time).getTime()),
              stream_end_date_ms: stream.end_time ? Math.floor(new Date(stream.end_time).getTime()) : null,
              producer: stream.producer,
              speedType: stream?.activity_type?.speed_type,
              sponsorLogo: stream?.channel?.logo,
              official: stream.official,
            },
          ]
        : pulseLinesHashMap[stream.id]?.map((backEndPulseLine: GPSData) => ({
            timestamp: backEndPulseLine[0],
            lat: backEndPulseLine[1],
            long: backEndPulseLine[2],
            altitude: backEndPulseLine[3],
            speed: backEndPulseLine[4],
            gps_precision: backEndPulseLine[5],
            id: stream.id,
            playback_url: stream.playback_url,
            vod_playback_url: stream.vod_playback_url,
            logo_url: stream?.channel?.logo,
            title: stream.channel.title || stream.title,
            username: stream.channel.username,
            avatarPicture: stream.channel.hero_photo?.small,
            duration_in_seconds: stream.duration_in_seconds,
            stream_tile_url: stream.stream_tile_url,
            // @ts-ignore
            offset_ms: new Date(backEndPulseLine[0]) - new Date(stream.start_time),
            stream_start_date_ms: Math.floor(new Date(stream.start_time).getTime()),
            stream_end_date_ms: stream.end_time ? Math.floor(new Date(stream.end_time).getTime()) : null,
            producer: stream.producer,
            speedType: stream?.activity_type?.speed_type,
            sponsorLogo: stream?.channel?.logo,
            official: stream.official,
          })),
    };
    streamsHashMap[streamInfo.id] = streamInfo;
    return streamInfo;
  });

  return { flattenStreams, streamsHashMap };
}

export function convertBackendStreamModelIntoFrontEndStreamModel(stream: NewStreamModel): NewEventStream {
  return {
    ...stream.channel,
    ...stream,
    stream_start_date_ms: Math.floor(new Date(stream.start_time).getTime()),
    stream_end_date_ms: stream.end_time ? Math.floor(new Date(stream.end_time).getTime()) : null,
    title: stream.channel.title || stream.title,
    avatarPicture: stream.channel.hero_photo?.small,
    pulse_lines: [],
  };
}

export function getStreamsHash(timeline: GetEventInfo.TimeLineV3Model) {
  const pulseLinesHashMap: any = {};

  timeline.channels?.forEach((channel) =>
    channel.streams?.forEach((stream) => {
      pulseLinesHashMap[stream.id] = {
        ...channel,
        ...stream,
        title: channel.title || stream.title,
      };
    }),
  );
  return pulseLinesHashMap;
}

export function getFlattenStreamsFromWebSocket(eventTimeline: GetEventInfo.TimeLineV2Model): EventStream[] {
  if (!eventTimeline) return [];
  return flatten(
    eventTimeline?.channels?.map((channel) =>
      channel.streams.map((stream) => ({
        ...channel,
        ...stream,
        avatarPicture: channel.hero_photo?.small || '',
        stream_start_date_ms: Math.floor(new Date(stream.start_time).getTime()),
        stream_end_date_ms: stream.end_time ? Math.floor(new Date(stream.end_time).getTime()) : null,
      })),
    ),
  );
}
