import { useMemo } from 'react';
import { GenericCarouselScroller } from '../../../../components/generic-carousel-scroller/generic-carousel-scroller';

import useEventStore from '../../state-manager/store';
import { LiveChannelItem } from '../event-live-item/event-live-item';

interface IProps {
  onSelectStream: (index: string) => void;
}

export default function EventLiveHostChannelsCarousel({ onSelectStream }: IProps) {
  const eventStreams = useEventStore((state) => state.eventStreams);
  const liveHostStreams = useMemo(
    () => eventStreams.filter((stream) => stream.content.status === 'started' && stream.isProducer),
    [eventStreams],
  );
  const playingHostStream = useEventStore((state) => state.playingHostStream);

  const onClickAvatar = (index: string) => {
    onSelectStream(index);
  };

  return (
    <GenericCarouselScroller list={liveHostStreams}>
      {liveHostStreams.map((hostStream) => (
        <LiveChannelItem
          onClick={() => onClickAvatar(hostStream.id)}
          key={hostStream.id}
          stream={hostStream}
          featuredStream={playingHostStream}
        />
      ))}
    </GenericCarouselScroller>
  );
}
