import { Dispatch, ReactElement, SetStateAction } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { darkTheme } from '../../stitches.config';
import { Dialog, DialogContent, DialogTrigger } from '../primitives/dialog/dialog';
import { Provider, Tooltip, TooltipContent, TooltipTrigger } from '../primitives/tootip/tooltip';

interface IProps {
  triggerElement: ReactElement;
  contentElement: ReactElement;
  isTootipOpened: boolean;
  setIsTooltipOpened: Dispatch<SetStateAction<boolean>>;
}
export default function DialogOrTooltip({
  triggerElement,
  contentElement,
  isTootipOpened,
  setIsTooltipOpened,
}: IProps) {
  const isMobile = useMediaQuery('(max-width: 900px)');

  const closeToolTip = () => {
    setIsTooltipOpened(false);
  };

  return (
    <>
      {!isMobile && (
        <Provider>
          <Tooltip delayDuration={0} open={isTootipOpened}>
            <TooltipTrigger asChild>{triggerElement}</TooltipTrigger>
            <TooltipContent
              className={darkTheme}
              side="bottom"
              align="start"
              sideOffset={20}
              alignOffset={-250}
              onPointerDownOutside={closeToolTip}
              css={{
                backgroundColor: '$bgAppScreen200',
                width: '22rem',
                height: '15.31rem',
                padding: '$xs',
                borderRadius: '$2lg',
                zIndex: 2,
              }}
            >
              {contentElement}
            </TooltipContent>
          </Tooltip>
        </Provider>
      )}
      {isMobile && (
        <Dialog open={isTootipOpened} onOpenChange={setIsTooltipOpened}>
          <DialogTrigger asChild>{triggerElement}</DialogTrigger>
          <DialogContent
            css={{
              backgroundColor: '$bgAppScreen200',
              width: '22rem',
              height: '15.31rem',
              padding: '$xs',
              borderRadius: '$2lg',
            }}
            className={darkTheme}
          >
            {contentElement}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
