export default function ExpandContentIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.166687 11.8333V6.83329H1.83335V10.1666H5.16669V11.8333H0.166687ZM10.1667 5.16663V1.83329H6.83335V0.166626H11.8334V5.16663H10.1667Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>
  );
}
