import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { SignInMethod, SocialAuth } from '../../../data/protocols/social-auth';
import { AuthFireBaseUser } from '../../../infra/firebase/model';
import AppStoreIcon from '../icons/app-store';
import AppleIcon from '../icons/apple';
import FacebookIcon from '../icons/facebook';
import GoogleIcon from '../icons/google';
import PulseLogoMarkDark from '../icons/pulse-logo-mark-dark';
import Button from '../primitives/button';
import { Dialog, DialogContent } from '../primitives/dialog/dialog';
import Flex from '../primitives/flex';
import { Typo } from '../primitives/typo/typo';
import CloseCrossIcon from '../icons/close-cross';

interface IProps {
  onFirebaseAuthStateChanged?: (userObj: { user: AuthFireBaseUser; token: string }) => void;
  onSuccessSignIn?: () => void;
  socialSignIn: SocialAuth;
  open: boolean;
  setOpen: any;
}

export default function GenericAuthDialog({
  onFirebaseAuthStateChanged,
  onSuccessSignIn,
  socialSignIn,
  open,
  setOpen,
}: IProps) {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const auth = socialSignIn.getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      const firebaseUser: AuthFireBaseUser = currentUser as unknown as AuthFireBaseUser;
      if (onFirebaseAuthStateChanged)
        onFirebaseAuthStateChanged({ user: firebaseUser, token: firebaseUser?.stsTokenManager?.accessToken });
      setOpen(false);
    });
    return () => {
      unsubscribe();
    };
  }, [loading]);

  const onClickSignIn = async (method: SignInMethod) => {
    try {
      setLoading(true);
      await socialSignIn.signIn(method);
      setLoading(false);
      if (onSuccessSignIn) onSuccessSignIn();
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent css={{ maxWidth: '30rem' }}>
        <>
          <Flex css={{ flexDirection: 'column', gap: '$lg', zIndex: 10 }}>
            <Flex css={{ flexDirection: 'column', gap: '$md', alignItems: 'center' }}>
              <PulseLogoMarkDark />
              <Typo as="h4" type="h4">
                Sign in to Pulse
              </Typo>
              <Typo type="bodySmallMedium" css={{ textAlign: 'center' }}>
                Get front row seats to your favorite athletes’ point of view with Pulse.
              </Typo>
            </Flex>

            <Flex css={{ flexDirection: 'column', gap: '$xs' }}>
              <Button variant="outline" onClick={() => onClickSignIn('apple.com')}>
                <Flex css={{ position: 'absolute', left: '1rem', top: '0.7rem' }}>
                  <AppleIcon />
                </Flex>
                <Typo type="bodyBold">Continue with Apple</Typo>
              </Button>
              <Button variant="outline" onClick={() => onClickSignIn('google.com')}>
                <Flex css={{ position: 'absolute', left: '1rem', top: '0.8rem' }}>
                  <GoogleIcon />
                </Flex>
                <Typo type="bodyBold">Continue with Google</Typo>
              </Button>
              <Button variant="outline" onClick={() => onClickSignIn('facebook.com')}>
                <Flex css={{ position: 'absolute', left: '1rem', top: '0.8rem' }}>
                  <FacebookIcon />
                </Flex>
                <Typo type="bodyBold">Continue with Facebook</Typo>
              </Button>
            </Flex>

            <Flex css={{ flexDirection: 'column', gap: '$lg', alignItems: 'center' }}>
              <Typo type="caption" css={{ textAlign: 'center', color: '$gs300' }}>
                By signing in, you agree to{' '}
                <Button
                  as="a"
                  variant="transparent"
                  href="https://pulse.tv/terms"
                  target="_blank"
                  css={{ display: 'inline', padding: 2, height: 'fit-content', color: '$gs300' }}
                >
                  Pulse’s Terms & Conditions
                </Button>{' '}
                <Button
                  as="a"
                  variant="transparent"
                  href="https://pulse.tv/privacy-policy"
                  target="_blank"
                  css={{ display: 'inline', padding: 2, height: 'fit-content', color: '$gs300' }}
                >
                  and Privacy Policy
                </Button>
              </Typo>
              <Button
                as="a"
                variant="transparent"
                href="https://apps.apple.com/us/app/pulse-share-what-you-see/id1576189738"
                target="_blank"
              >
                <AppStoreIcon />
              </Button>
            </Flex>
          </Flex>
          <Button variant="transparent" css={{ position: 'absolute', right: 0, top: 0 }} onClick={() => setOpen(false)}>
            <CloseCrossIcon color="black" />
          </Button>
        </>
      </DialogContent>
    </Dialog>
  );
}
