export default function LinkedinShareIcon() {
  return (
    <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_601_10477)">
        <rect x="5.04395" y="4.32898" width="29.1692" height="29.4073" fill="white" />
        <path
          d="M33.3363 32.0953H27.7139V23.3953C27.7139 21.3207 27.6765 18.65 24.7898 18.65C21.8614 18.65 21.4134 20.9104 21.4134 23.2442V32.0947H15.791V14.2039H21.1885V16.6489H21.2641C21.8042 15.7363 22.5848 14.9856 23.5226 14.4767C24.4604 13.9678 25.5203 13.7197 26.5895 13.7589C32.288 13.7589 33.3386 17.4625 33.3386 22.2807L33.3363 32.0953ZM9.44718 11.7584C8.80187 11.7585 8.17101 11.5695 7.63439 11.2154C7.09778 10.8612 6.67953 10.3578 6.43247 9.76877C6.18542 9.17972 6.12067 8.53152 6.24645 7.90613C6.37223 7.28073 6.68288 6.70624 7.1391 6.25529C7.59531 5.80434 8.17662 5.49719 8.8095 5.37268C9.44239 5.24818 10.0984 5.3119 10.6946 5.55581C11.2909 5.79971 11.8005 6.21283 12.1591 6.74293C12.5177 7.27303 12.7092 7.89631 12.7093 8.53393C12.7094 8.9573 12.6251 9.37653 12.4612 9.7677C12.2973 10.1589 12.057 10.5143 11.7541 10.8137C11.4512 11.1131 11.0915 11.3507 10.6957 11.5128C10.2999 11.6748 9.87564 11.7583 9.44718 11.7584ZM12.2583 32.0953H6.63015V14.2039H12.2583V32.0953ZM36.1393 0.151602H3.80493C3.07103 0.143419 2.36384 0.423468 1.8388 0.930211C1.31376 1.43695 1.01384 2.12893 1.00488 2.85408V34.9361C1.01353 35.6616 1.31328 36.354 1.8383 36.8613C2.36332 37.3686 3.07067 37.6492 3.80493 37.6415H36.1393C36.875 37.6506 37.5843 37.3708 38.1114 36.8635C38.6385 36.3563 38.9402 35.663 38.9504 34.9361V2.85177C38.9399 2.12518 38.638 1.43241 38.1109 0.925683C37.5838 0.418953 36.8746 0.139704 36.1393 0.149287"
          fill="#0A66C2"
        />
      </g>
      <defs>
        <clipPath id="clip0_601_10477">
          <rect width="38.1443" height="37.7667" fill="white" transform="translate(0.855469)" />
        </clipPath>
      </defs>
    </svg>
  );
}
