import { useContext, useState } from 'react';

import { darkTheme } from '../../stitches.config';
import ContentCopyIcon from '../icons/content-copy';
import FacebookShareIcon from '../icons/facebook-share';
import PersonAdd from '../icons/person-add';
import Box from '../primitives/box';
import Button from '../primitives/button';
import Flex from '../primitives/flex';
import { Toast, ToastTitle } from '../primitives/toast/toast';
import { Typo } from '../primitives/typo/typo';
import LinkedinShareIcon from '../icons/linkedin-share';
import TwitterShareIcon from '../icons/twitter-share';
import WhatsAppShareIcon from '../icons/whatsapp-share';
import CloseCrossIcon from '../icons/close-cross';
import { ISquadVideoContext, SquadVideoContext } from '../../pages/event-v1/context';

import { CheckboxRoot, CheckboxIndicator, CheckboxIcon, CheckboxLabel } from '../primitives/checkbox/checkbox';
import DialogOrTooltip from './dialog-or-tooltip';
import useGlobalStore from '../../app-global-store';

export default function ButtonSocialInvite() {
  const analytics = useGlobalStore((state) => state.analytics);
  const { eventInfo } = useContext<ISquadVideoContext>(SquadVideoContext);
  const [isToastOpened, setIsToastOpened] = useState<boolean>(false);
  const [isTootipOpened, setIsTooltipOpened] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean | 'indeterminate'>(false);

  const getFullOrMinimumUrl = () => {
    const regularExpression = /^[^?]+/g;
    const result = regularExpression.exec(window.location.href);
    if (result?.length && !isChecked) {
      return result[0];
    }
    return window.location.href;
  };

  const socialIcons = [
    {
      url: `https://wa.me/?text=Check%20out%20${
        eventInfo?.title
      }%20on%20PulseTV:%20${getFullOrMinimumUrl()}?utm_source=washare`,
      icon: <WhatsAppShareIcon />,
    },
    {
      url: `https://twitter.com/share?url=${getFullOrMinimumUrl()}?utm_source=twshare&text=Check%20out%20${
        eventInfo?.title
      }%20on%20PulseTV&hashtags=pulsetogether`,
      icon: <TwitterShareIcon />,
    },
    {
      url: `https://www.facebook.com/sharer.php?u=${getFullOrMinimumUrl()}?utm_source=fbshare`,
      icon: <FacebookShareIcon />,
    },
    {
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${getFullOrMinimumUrl()}?utm_source=lishare`,
      icon: <LinkedinShareIcon />,
    },
  ];

  const copyLinkToClipBoard = () => {
    analytics.track('Copied event link to clipboard');
    navigator.clipboard.writeText(getFullOrMinimumUrl());
    setIsToastOpened(true);
    setIsTooltipOpened(false);
  };

  const closeToolTip = () => {
    setIsTooltipOpened(false);
  };

  const openSocialInviteTooltip = () => {
    setIsTooltipOpened(true);
  };

  const onChangeCheckBox = (checked: boolean | 'indeterminate') => {
    setIsChecked(checked);
  };

  return (
    <>
      <DialogOrTooltip
        isTootipOpened={isTootipOpened}
        setIsTooltipOpened={setIsTooltipOpened}
        triggerElement={
          <Button
            variant="transparent"
            css={{ alignItems: 'center', gap: '$3xs', '@bp3': { '& .person-add-icon': { display: 'none' } } }}
            onClick={openSocialInviteTooltip}
          >
            <PersonAdd />
            Share
          </Button>
        }
        contentElement={
          <Flex css={{ flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <Flex css={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Typo type="h6">Invite your friends to watch</Typo>
              <Box
                onClick={closeToolTip}
                css={{
                  cursor: 'pointer',
                  transition: 'all 0.5s',
                  '&:hover': {
                    transform: 'scale(.96)',
                  },
                  '&:active': {
                    transform: 'scale(.90)',
                  },
                }}
              >
                <CloseCrossIcon />
              </Box>
            </Flex>
            <Flex css={{ alignItems: 'center' }}>
              <CheckboxRoot defaultChecked id="c1" checked={isChecked} onCheckedChange={onChangeCheckBox}>
                <CheckboxIndicator>
                  <CheckboxIcon />
                </CheckboxIndicator>
              </CheckboxRoot>
              <CheckboxLabel css={{ paddingLeft: 15, width: '80%' }} htmlFor="c1">
                Share at current stream and it’s current time.
              </CheckboxLabel>
            </Flex>
            <Flex
              css={{
                height: '3rem',
                backgroundColor: '$gs500',
                alignItems: 'center',
                padding: '$xs',
                justifyContent: 'space-between',
                borderRadius: '$sm',
                cursor: 'pointer',
                transition: 'all 0.5s',
                '&:hover': {
                  transform: 'scale(.96)',
                },
                '&:active': {
                  transform: 'scale(.90)',
                },
              }}
              onClick={copyLinkToClipBoard}
            >
              <Typo
                css={{
                  width: '85%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: '$constantWhite',
                }}
                type="bodyRegular"
              >
                {getFullOrMinimumUrl()}
              </Typo>
              <Flex css={{ minWidth: '25px' }}>
                <ContentCopyIcon />
              </Flex>
            </Flex>
            <Flex css={{ gap: '$sm', alignSelf: 'center' }}>
              {socialIcons.map((socialIcon) => (
                <Box
                  as="a"
                  href={socialIcon.url}
                  onClick={() => {
                    analytics.track(`Clicked on share event button`, { url: socialIcon.url });
                  }}
                  target="_blank"
                  key={socialIcon.url}
                  css={{
                    transition: 'all 0.5s',
                    '&:hover': {
                      transform: 'scale(.96)',
                    },
                    '&:active': {
                      transform: 'scale(.90)',
                    },
                  }}
                >
                  {socialIcon.icon}
                </Box>
              ))}
            </Flex>
          </Flex>
        }
      />

      <Toast
        open={isToastOpened}
        className={darkTheme}
        onOpenChange={setIsToastOpened}
        css={{ backgroundColor: '$bgAppScreen200' }}
      >
        <ToastTitle css={{ color: '$primaryAdrenaline100' }}>Link copied to clipboard</ToastTitle>
      </Toast>
    </>
  );
}
