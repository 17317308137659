import { styled } from '../../../stitches.config';

export type FlexProps = React.ComponentPropsWithRef<typeof Flex>;

const Flex = styled('div', {
  display: 'flex',
  variants: {
    full: {
      true: {
        width: '100%',
      },
    },
  },
});

export { Flex };
