import { generateNewGroups, generateNewItems } from '../components/event-timeline/generate-items';
import useEventStore from '../state-manager/store';

export function useQueryTimelineData() {
  const setEventStreams = useEventStore((state) => state.setEventStreams);
  const setEventChannels = useEventStore((state) => state.setEventChannels);

  const eventRepository = useEventStore((state) => state.eventRepository);
  const timelineUpdateController = useEventStore((state) => state.timelineUpdateController);

  const queryData = async (eventId: string | undefined) => {
    if (!eventId) return;
    try {
      const timeline = await eventRepository.timelineV2(eventId || '');
      const items = generateNewItems({ ...timeline });
      const groups = generateNewGroups({ ...timeline });
      timelineUpdateController.addOrUpdateAllGroupsAndItems(groups, items);
      setEventStreams(items);
      setEventChannels(groups);
      return items;
    } catch (e) {
      // navigate('/not-found');
    }
  };

  return queryData;
}
