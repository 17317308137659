import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSwiper } from 'swiper/react';
import { VideoJsPlayer } from 'video.js';
import { StreamModel } from '../../../../domain/models/stream-model';
import {
  IMobileGridEventContext,
  MobileGridEventContext,
} from '../../../pages/event-v1/components/mobile-grid-event/context';
import { ISquadVideoContext, SquadVideoContext } from '../../../pages/event-v1/context';

import Flex from '../../primitives/flex';
import { GpsMetaData } from '../../third-party/video-stream/gps-type';
import VideoStream from '../../third-party/video-stream/video-stream';

interface IProps {
  isSelected: boolean;
  isVideoVisible: boolean;
  showVideo?: boolean;
  videoTimeOffset: number;
  streamData: StreamModel | undefined;
  onSelectStream: () => void;
  onVideoError: () => void;
  onVideoPlayerReady: (playerRef: VideoJsPlayer) => void;
  onChangeGpsData: (data: GpsMetaData, player: VideoJsPlayer) => void;
  index?: number;
}

export default function MobileStreamThumb({
  isSelected,
  isVideoVisible,
  videoTimeOffset,
  streamData,
  showVideo,
  onSelectStream,
  onVideoError,
  onVideoPlayerReady,
  onChangeGpsData,
  index,
}: IProps) {
  const { onExpand, onFullScreen, isFullscreen } = useContext<IMobileGridEventContext>(MobileGridEventContext);
  const { featuredStream } = useContext<ISquadVideoContext>(SquadVideoContext);

  const swiper = useSwiper();
  const [searchParams] = useSearchParams();

  const getStreamOffsetInMs = (offsetMsParam: number): number => {
    const offSetMsFromQueryParam = searchParams.get('offset_ms');
    if (!offSetMsFromQueryParam) return offsetMsParam;
    if (isSelected && Number(offSetMsFromQueryParam) >= 0) return Number(offSetMsFromQueryParam);
    return offsetMsParam;
  };

  const onMount = () => {
    const streamIdFromRouteQuery = searchParams.get('stream_id');
    if (streamData?.id === streamIdFromRouteQuery) {
      setTimeout(() => swiper.slideTo(index || 0), 2000);
    }
  };
  useEffect(onMount, []);

  if (!streamData) return <div>Stream not found</div>;

  return (
    <Flex
      title={streamData.title}
      data-selected={isSelected}
      className="video-thumbnail-wrapper"
      css={{ width: '100%', height: '100%' }}
      onClick={onSelectStream}
    >
      {isVideoVisible && showVideo && (
        <VideoStream
          url={streamData?.playback_url}
          isMobile
          onChangeGpsData={onChangeGpsData}
          timeOffset={getStreamOffsetInMs(videoTimeOffset)}
          onVideoPlayerReady={onVideoPlayerReady}
          onVideoError={onVideoError}
          onClickExpand={onExpand}
          onClickFullScreen={onFullScreen}
          isFeatured={isSelected}
          isAndroidFullScreen={isFullscreen}
          username={featuredStream?.channel?.username || ''}
          useravatar={featuredStream?.channel?.hero_photo.original || ''}
          speedType={featuredStream?.activity_type?.speed_type}
        />
      )}
      {!showVideo && <img alt="thumnail of video stream" src={streamData.thumbnail} />}
    </Flex>
  );
}
