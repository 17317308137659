/* eslint-disable react/jsx-props-no-spreading */
import { ButtonHTMLAttributes, ReactElement } from 'react';
import { motion } from 'framer-motion';

import { LiveChip } from '../live-chip/live-chip';
import Flex from '../primitives/flex';
import { Text } from '../primitives/text/text';
import { PulseAvatar, StreamType } from '../pulse-avatar/pulse-avatar';
import { RecordedChip } from '../recorded-chip/recorded-chip';
import { styled } from '../../stitches.config';

const OpacityAnimationWrapper = styled(motion.div, {});

function LivePill() {
  return (
    <Flex css={{ position: 'absolute', left: '0.31rem', top: '0.35rem', zIndex: 7 }}>
      <LiveChip />
    </Flex>
  );
}

function RecordedPill() {
  return (
    <Flex css={{ position: 'absolute', left: '0.31rem', top: '0.35rem', zIndex: 7 }}>
      <RecordedChip />
    </Flex>
  );
}

interface VideoOrImageProps {
  children: ReactElement;
}

function VideoOrImage({ children }: VideoOrImageProps) {
  return <Flex css={{ width: '100%', height: '100%' }}>{children}</Flex>;
}

interface AvatarAndTextProps {
  imageUrl: string;
  title: string;
  streamType?: StreamType;
}

function AvatarAndText({ imageUrl, title, streamType }: AvatarAndTextProps) {
  return (
    <Flex
      css={{
        gap: '$3xs',
        alignItems: 'center',
        position: 'absolute',
        left: '0.31rem',
        width: '100%',
        bottom: '0.35rem',
      }}
    >
      <PulseAvatar imageUrl={imageUrl} title={title} size={1.2} streamType={streamType} />
      <Text css={{ color: '$white', fontSize: '0.75rem', zIndex: 5, whiteSpace: 'nowrap', userSelect: 'none' }}>
        {title}
      </Text>
    </Flex>
  );
}

function BackFilter() {
  return (
    <Flex
      css={{
        backgroundColor: 'rgba(0,0,0,0.2)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 4,
      }}
    />
  );
}

function PlayIcon() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.65 14.25L13.525 11.125C13.7583 10.975 13.875 10.7667 13.875 10.5C13.875 10.2333 13.7583 10.025 13.525 9.875L8.65 6.75C8.4 6.58333 8.14583 6.57083 7.8875 6.7125C7.62917 6.85417 7.5 7.075 7.5 7.375V13.625C7.5 13.925 7.62917 14.1458 7.8875 14.2875C8.14583 14.4292 8.4 14.4167 8.65 14.25ZM10 20.5C8.61667 20.5 7.31667 20.2375 6.1 19.7125C4.88333 19.1875 3.825 18.475 2.925 17.575C2.025 16.675 1.3125 15.6167 0.7875 14.4C0.2625 13.1833 0 11.8833 0 10.5C0 9.11667 0.2625 7.81667 0.7875 6.6C1.3125 5.38333 2.025 4.325 2.925 3.425C3.825 2.525 4.88333 1.8125 6.1 1.2875C7.31667 0.7625 8.61667 0.5 10 0.5C11.3833 0.5 12.6833 0.7625 13.9 1.2875C15.1167 1.8125 16.175 2.525 17.075 3.425C17.975 4.325 18.6875 5.38333 19.2125 6.6C19.7375 7.81667 20 9.11667 20 10.5C20 11.8833 19.7375 13.1833 19.2125 14.4C18.6875 15.6167 17.975 16.675 17.075 17.575C16.175 18.475 15.1167 19.1875 13.9 19.7125C12.6833 20.2375 11.3833 20.5 10 20.5ZM10 18.5C12.2333 18.5 14.125 17.725 15.675 16.175C17.225 14.625 18 12.7333 18 10.5C18 8.26667 17.225 6.375 15.675 4.825C14.125 3.275 12.2333 2.5 10 2.5C7.76667 2.5 5.875 3.275 4.325 4.825C2.775 6.375 2 8.26667 2 10.5C2 12.7333 2.775 14.625 4.325 16.175C5.875 17.725 7.76667 18.5 10 18.5Z"
        fill="white"
      />
    </svg>
  );
}

function CurrentPlayingMask({ keyRef }: { keyRef?: string }) {
  return (
    <OpacityAnimationWrapper
      className="current-playing-mask"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      key={keyRef}
      css={{
        display: 'flex',
        backgroundColor: 'rgba(0,0,0,0.7)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        gap: '$nano',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PlayIcon />
      <Text css={{ color: '$white', fontWeight: '$demi', fontSize: '0.81rem' }}>Playing</Text>
    </OpacityAnimationWrapper>
  );
}

function ProgressBar({ progress = 0 }: { progress?: number }) {
  return (
    <Flex
      className="progress-bar"
      css={{
        position: 'absolute',
        transition: 'all 0.3s',
        bottom: 0,
        left: 0,
        right: 0,
        background: '#E1F7AA66',
        height: '0.18rem',
        zIndex: 15,
      }}
    >
      <Flex
        css={{
          backgroundColor: '$eletricLimeFluorescent',
          height: '100%',
          width: `${progress}%`,
          transition: 'all 0.3s',
        }}
      />
    </Flex>
  );
}

function ActionButtonsWrapper({ children }: { children: ReactElement }) {
  return (
    <Flex
      css={{
        position: 'absolute',
        height: '1.5rem',
        width: 'fit-content',
        zIndex: 5,
        right: '0.25rem',
        bottom: '0.25rem',
      }}
    >
      {children}
    </Flex>
  );
}

const SButton = styled('button', {
  padding: '0px $3xs',
  height: '1.5rem',
  width: 'fit-content',
  zIndex: 5,
  backgroundColor: 'rgba(255,255,255,0.7)',
  backdropFilter: 'blur(6px)',
  border: '1px solid $white',
  cursor: 'pointer',
  borderRadius: '$3xs',
  '&:active': {
    transform: 'scale(.98)',
  },
  '@hover': {
    '&:hover': {
      transform: 'scale(.96)',
    },
  },
});

function Button({ children, ...props }: { children: ReactElement } & ButtonHTMLAttributes<HTMLButtonElement>) {
  return <SButton {...props}>{children}</SButton>;
}

interface IProps {
  children: ReactElement;
  css?: any;
}

export function StreamWrapper({ children, css }: IProps) {
  return (
    <Flex
      css={{
        width: '10rem',
        height: '10rem',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '$lg',
        cursor: 'pointer',
        transition: 'all .5s ease',
        '&:active': {
          transform: 'scale(.98)',
        },
        '@hover': {
          '&:hover': {
            transform: 'scale(.96)',
            '& .progress-bar': {
              height: '0.4rem',
            },
          },
        },
        ...css,
      }}
    >
      {children}
    </Flex>
  );
}

StreamWrapper.LivePill = LivePill;
StreamWrapper.RecordedPill = RecordedPill;
StreamWrapper.AvatarAndText = AvatarAndText;
StreamWrapper.VideoOrImage = VideoOrImage;
StreamWrapper.BackFilter = BackFilter;
StreamWrapper.CurrentPlayingMask = CurrentPlayingMask;
StreamWrapper.ProgressBar = ProgressBar;
StreamWrapper.ActionButtonsWrapper = ActionButtonsWrapper;
StreamWrapper.Button = Button;
