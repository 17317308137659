import { ChannelModel } from './channel-type';
import { PhotoModel } from './photo-model';

export enum SpeedType {
  minutes_per_distance = 'minutes_per_distance',
  distance_per_hour = 'distance_per_hour',
}

export type ActivityType = {
  id: string;
  title: string;
  speed_type: SpeedType;
};

export type StreamEventModel = {
  id: string;
  title: string;
  photo: PhotoModel;
  short_code: string;
  description: string | null;
  getstream_channel_id: string;
};

export type StreamModel = {
  approximate_location: string;
  channel: ChannelModel;
  created_at: string;
  offset_ms?: number;
  views: number;
  description: string | null;
  getstream_channel_id: string;
  hashtags: string[];
  id: string;
  uuid: string;
  latitude: number | null;
  longitude: number | null;
  username?: string;
  playback_url: string;
  vod_playback_url: string;
  reactions_count: number;
  duration_in_seconds: number;
  liked_by_profile?: boolean;
  owned_by_profile?: boolean;
  producer: boolean;
  status: string;
  start_time: string;
  end_time: string | null;
  thumbnail: string;
  title: string;
  official: boolean;
  stream_tile_url: string;
  latest_reactions: string[];
  activity_type: ActivityType | null;
  event: StreamEventModel;
};
