import flatten from 'lodash/flatten';
import { GetEventInfo } from '../../../../../domain/usecases';
import { addSeconds } from '../../../../utils/utils';

export const generateNewItems = (eventTimeline: GetEventInfo.TimeLineV2Model | null) => {
  if (!eventTimeline) return [];
  const eventStreams = flatten(
    eventTimeline?.channels?.map((channel) =>
      channel.streams
        .filter((stream) => stream.start_time !== null && stream.playback_url !== null)
        .map((stream) => {
          const startDate = new Date(stream.start_time);
          const endTime =
            stream?.status === 'started'
              ? new Date()
              : addSeconds(new Date(startDate.getTime()), stream.duration_in_seconds);

          return {
            id: stream.id,
            channelTitle: channel?.title,
            content: {
              username: channel?.username,
              useravatar: channel?.hero_photo?.original,
              channelId: channel.id,
              logoUrl: channel?.logo_url,
              ...stream,
            },
            group: channel.id,
            start: startDate,
            end: endTime,
            isProducer: channel.producer || false,
          };
        }),
    ),
  );
  return eventStreams;
};

export const generateNewGroups = (eventTimeline: GetEventInfo.TimeLineV2Model | null) => {
  if (!eventTimeline) return [];

  return eventTimeline?.channels
    .filter((channel) => !channel.producer)
    .map((channel) => ({
      id: channel.id,
      content: channel,
      style: 'height: 50px',
    }));
};
