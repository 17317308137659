import { Typo } from '../../../../components/primitives/typo/typo';
import useEventStore from '../../state-manager/store';

export function EventCurrentTime() {
  const currentTime = useEventStore((store) => store.currentTime);
  if (!currentTime) return null;
  return (
    <Typo css={{ fontSize: '1.7rem', fontWeight: 600, textShadow: '0px 1px 5px #000000DE', color: '$white' }}>
      {String(currentTime?.getHours()).padStart(2, '0')}:{String(currentTime?.getMinutes()).padStart(2, '0')}:
      {String(currentTime?.getSeconds()).padStart(2, '0')}
    </Typo>
  );
}
