export function EventIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_252_1067"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="white" />
      </mask>
      <g mask="url(#mask0_252_1067)">
        <path
          d="M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V14C2.55 14 3.021 13.804 3.413 13.412C3.80433 13.0207 4 12.55 4 12C4 11.45 3.80433 10.979 3.413 10.587C3.021 10.1957 2.55 10 2 10V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.8043 4.97933 22 5.45 22 6V10C21.45 10 20.979 10.1957 20.587 10.587C20.1957 10.979 20 11.45 20 12C20 12.55 20.1957 13.0207 20.587 13.412C20.979 13.804 21.45 14 22 14V18C22 18.55 21.8043 19.021 21.413 19.413C21.021 19.8043 20.55 20 20 20H4ZM4 18H20V15.45C19.3833 15.0833 18.896 14.596 18.538 13.988C18.1793 13.3793 18 12.7167 18 12C18 11.2833 18.1793 10.6207 18.538 10.012C18.896 9.404 19.3833 8.91667 20 8.55V6H4V8.55C4.61667 8.91667 5.10433 9.404 5.463 10.012C5.821 10.6207 6 11.2833 6 12C6 12.7167 5.821 13.3793 5.463 13.988C5.10433 14.596 4.61667 15.0833 4 15.45V18ZM12 17C12.2833 17 12.521 16.904 12.713 16.712C12.9043 16.5207 13 16.2833 13 16C13 15.7167 12.9043 15.479 12.713 15.287C12.521 15.0957 12.2833 15 12 15C11.7167 15 11.4793 15.0957 11.288 15.287C11.096 15.479 11 15.7167 11 16C11 16.2833 11.096 16.5207 11.288 16.712C11.4793 16.904 11.7167 17 12 17ZM12 13C12.2833 13 12.521 12.904 12.713 12.712C12.9043 12.5207 13 12.2833 13 12C13 11.7167 12.9043 11.479 12.713 11.287C12.521 11.0957 12.2833 11 12 11C11.7167 11 11.4793 11.0957 11.288 11.287C11.096 11.479 11 11.7167 11 12C11 12.2833 11.096 12.5207 11.288 12.712C11.4793 12.904 11.7167 13 12 13ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.9043 8.52067 13 8.28333 13 8C13 7.71667 12.9043 7.479 12.713 7.287C12.521 7.09567 12.2833 7 12 7C11.7167 7 11.4793 7.09567 11.288 7.287C11.096 7.479 11 7.71667 11 8C11 8.28333 11.096 8.52067 11.288 8.712C11.4793 8.904 11.7167 9 12 9Z"
          fill="white"
          fillOpacity="0.6"
        />
      </g>
    </svg>
  );
}
