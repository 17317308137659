import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { AnalyticsLoggingClient } from './infra/event-tracking/event-tracking';
import Router from './main/routes/router';
import { ToastProvider, ToastViewport } from './presentation/components/primitives/toast/toast';
import { darkTheme, globalStyles } from './presentation/stitches.config';
import 'mapbox-gl/dist/mapbox-gl.css';
import { AuthWrapper } from './presentation/pages/auth-wrapper/auth-wrapper';
import { makeLocalStorageAdapter } from './main/factories/cache';
import { makeFirebaseAuthClient } from './main/factories/social-auth';
import { makeRemoteAddProfile } from './main/usecases/remote-add-profile-factory';

const eventTrackingClient = new AnalyticsLoggingClient();
const addProfile = makeRemoteAddProfile();
const localStorageAdapter = makeLocalStorageAdapter();
const socialSignIn = makeFirebaseAuthClient();

globalStyles();

function initializeMonitoringServices() {
  if (import.meta.env.VITE_SENTRY_DSN) {
    // eslint-disable-next-line no-console
    console.log('Initialized Sentry');
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [new BrowserTracing(), new Sentry.Replay()],
      tracesSampleRate: 1.0,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: import.meta.env.PROD ? 0.1 : 1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
    });
  }
  if (import.meta.env.VITE_SEGMENT_ID) {
    // eslint-disable-next-line no-console
    console.log('Initialized segment');
    eventTrackingClient.initialize(import.meta.env.VITE_SEGMENT_ID);
  }
}

initializeMonitoringServices();

function App() {
  return (
    <ToastProvider swipeDirection="right">
      <AuthWrapper addProfile={addProfile} socialSignIn={socialSignIn} localStorageAdapter={localStorageAdapter}>
        <div className={`app ${darkTheme}`}>
          <Router />
        </div>
      </AuthWrapper>

      <ToastViewport />
    </ToastProvider>
  );
}

export default App;
