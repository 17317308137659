import Flex from '../../../../components/primitives/flex';
import TimelineIcon from '../../../../components/icons/timeline';
import { DateSelect } from '../date-select/date-select';
import TimeItem from './time-item';
import AppleLoading from '../../../../components/spinners/AppleLoading/AppleLoading';
import { Typo } from '../../../../components/primitives/typo/typo';
import { StreamModel } from '../../../../../domain/models/stream-model';
import { TimelineModel } from '../../../../../domain/models/event-timeline-model';
import { removeTimeZoneFromTimeStamp } from '../../../../utils/utils';

interface IProps {
  getOrderedDateListToPicker: () => string[];
  localStateSelectedDate: string | null;
  localStateSelectedTime: string | null;
  onDateSelectChanges: (e: string) => void;
  liveStreams: StreamModel[];
  timeline: TimelineModel[];
  onSelectPlaybackTime: (time: string) => void;
  getTime: (time: string) => string;
  openLiveStream: () => void;
  isTimelineLoading: boolean;
  isLiveSelected: boolean;
}

export default function DesktopTimelineEvent({
  getOrderedDateListToPicker,
  localStateSelectedDate,
  localStateSelectedTime,
  onDateSelectChanges,
  liveStreams,
  timeline,
  onSelectPlaybackTime,
  getTime,
  openLiveStream,
  isTimelineLoading,
  isLiveSelected,
}: IProps) {
  return (
    <Flex className="timeline-event-wrapper" css={{ flexDirection: 'column', width: '100%', position: 'relative' }}>
      <Flex
        className="timeline-title"
        css={{
          gap: '$2xs',
          alignItems: 'center',
          paddingLeft: '$xs',
          paddingRight: '$xs',
          minHeight: '3.37rem',
          borderBottom: '1px solid $gs500',
          justifyContent: 'space-between',
          backgroundColor: '$bgAppScreen200',
        }}
      >
        <Flex
          css={{
            gap: '$2xs',
            alignItems: 'center',
          }}
        >
          <TimelineIcon />
          <Typo type="bodyBold" css={{ color: '$constantWhite' }}>
            Timeline
          </Typo>
        </Flex>
        <DateSelect
          dateRange={getOrderedDateListToPicker()}
          value={localStateSelectedDate}
          onValueChange={onDateSelectChanges}
        />
      </Flex>
      <Flex
        className="time-list"
        css={{
          padding: '$3xs',
          overflowY: 'auto',
          height: '100%',
          flexDirection: 'column',
          // gap: '$3xs',
          position: 'relative',
          '@bp3': {
            marginTop: '4rem',
          },
        }}
      >
        <Flex className="time-zone">
          <Typo type="bodySm" css={{ color: '$gs400', fontWeight: '$book', padding: '0px $3xs' }}>
            GMT +02:00
          </Typo>
        </Flex>

        {!!liveStreams.length && (
          <TimeItem onClick={openLiveStream} isSelected={isLiveSelected} timeStamp="now">
            Now
          </TimeItem>
        )}
        {timeline.map(({ time, stream_source_locations }: TimelineModel) => (
          <TimeItem
            key={time}
            onClick={() => onSelectPlaybackTime(time)}
            isSelected={removeTimeZoneFromTimeStamp(time) === localStateSelectedTime && !isLiveSelected}
            sourceLocations={stream_source_locations}
            timeStamp={removeTimeZoneFromTimeStamp(time)}
          >
            {getTime(time)}
          </TimeItem>
        ))}
      </Flex>
      {isTimelineLoading && (
        <Flex
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.2)',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AppleLoading />
        </Flex>
      )}
    </Flex>
  );
}
