import { createRoot } from 'react-dom/client';
import { TimelineAvatar } from './timeline-avatar';
import TimelineItem from './timeline-item';

const elements: any = [];

export const options = {
  locale: 'en',
  locales: {
    en: {},
  },
  // clickToUse: true,
  preferZoom: true,
  // autoResize: false,
  horizontalScroll: false,
  verticalScroll: false,
  zoomKey: 'ctrlKey',
  orientation: { axis: 'top', item: 'top' },
  height: 300,
  zoomMin: 1000 * 60 * 5,
  zoomMax: 1000 * 60 * 60 * 24 * 700,
  template: ({ content }: any, element: any) => {
    const foundElement = elements.find(({ elementRef }: any) => elementRef === element);
    const root = foundElement ? foundElement.root : createRoot(element);
    if (!foundElement) elements.push({ elementRef: element, root });
    return root.render(<TimelineItem content={content} />);
  },
  groupTemplate: ({ content }: any, element: any) => {
    const root = createRoot(element);
    root.render(
      <TimelineAvatar
        username={content.username}
        useravatar={content.hero_photo.original}
        channelTitle={content?.title}
        channelStreams={content.streams}
        content={content}
        showLiveChip
      />,
    );
  },

  groupOrder: 'content',
  stack: false,
  margin: { axis: 5 },
};
