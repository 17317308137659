import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useState } from 'react';
import { StreamEventModel, StreamModel } from '../../../domain/models/stream-model';
import useGlobalStore from '../../app-global-store';
import { styled } from '../../stitches.config';
import { useIsMobile } from '../../utils/useIsMobile';
import { ArrowRightAltIcon } from '../icons/arrow-right-alt';
import { PlayArrowIcon } from '../icons/play-arrow';
import Flex from '../primitives/flex';
import { Typo } from '../primitives/typo/typo';

dayjs.extend(relativeTime);

interface IProps {
  streamInfo?: StreamModel | null;
}

const SImg = styled('img', {});

export default function StreamInfo({ streamInfo }: IProps) {
  const STREAM_DESC_MAX_LENGTH = 132;
  const analytics = useGlobalStore((state) => state.analytics);
  const isMobile = useIsMobile();
  const activityTitle = streamInfo?.activity_type?.title;
  const count = streamInfo?.views || 0;
  const streamEndDate = streamInfo?.created_at;
  const streamTitle = streamInfo?.title;
  const streamDesc = streamInfo?.description;
  const event: StreamEventModel | undefined = streamInfo?.event;

  const getDescInitialState = () => {
    if (!streamDesc) return false;
    return streamDesc.length < STREAM_DESC_MAX_LENGTH;
  };

  const [isFullStreamDescVisible, setIsFullStreamDescVisible] = useState<boolean>(getDescInitialState());

  const getViewsLabel = () => {
    if (count > 1) return `${count} views`;
    return `${count} view`;
  };

  const getStreamDesc = () => {
    if (!streamDesc) return '';
    if (!isMobile) return streamDesc;
    return isFullStreamDescVisible ? streamDesc : `${streamDesc.slice(0, STREAM_DESC_MAX_LENGTH)}...`;
  };

  const showExpandStreamDescButton = () => {
    if (!streamDesc) return false;
    return isMobile && streamDesc.length > STREAM_DESC_MAX_LENGTH;
  };

  return (
    <Flex
      className="stream-title-type"
      css={{
        flexDirection: 'column',
        gap: '$3xs',
        width: '100%',
        height: 'fit-content',
        '@bp3': {
          marginBottom: '10rem',
        },
      }}
    >
      <Flex className="stream-type-views-count" css={{ gap: '$sm', alignItems: 'center' }}>
        <Flex css={{ alignItems: 'center' }}>
          {!!activityTitle && (
            <Typo type="footnote" css={{ color: '$white300' }}>
              {activityTitle} •
            </Typo>
          )}
          {!!streamEndDate && (
            <Typo type="footnote" css={{ color: '$white300', marginLeft: '2px' }}>
              {dayjs(streamEndDate).fromNow(true)} ago
            </Typo>
          )}
        </Flex>

        <Flex className="stream-views" css={{ alignItems: 'center', gap: '$3xs' }}>
          <PlayArrowIcon />
          <Typo type="footnote" css={{ color: '$white300' }}>
            {getViewsLabel()}
          </Typo>
        </Flex>
      </Flex>
      <Flex
        className="stream-title-desc"
        css={{
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: streamDesc?.length ? '20px' : '0px',
          gap: '$2xl',
          '@bp3': {
            flexDirection: 'column',
          },
        }}
      >
        <Flex
          className="stream-title-event"
          css={{
            flexDirection: 'column',
            gap: '$xs',
            width: '70%',
            '@bp3': {
              width: '100%',
            },
          }}
        >
          <Typo
            type="h5"
            as="h5"
            css={{
              color: '$constantWhite',
              lineHeight: '2rem',
              '@bp3': {
                fontSize: '1.2rem',
                lineHeight: '1.7rem',
              },
            }}
          >
            {streamTitle}
          </Typo>
          {!!event && (
            <Flex className="event-info" css={{ alignItems: 'center', gap: '$xs' }}>
              {!!event?.photo?.original && (
                <SImg
                  css={{ width: '3.62rem', height: '3.62rem', borderRadius: '$lg' }}
                  src={event?.photo?.original}
                  alt="Event image"
                />
              )}

              <Flex css={{ flexDirection: 'column', gap: '$3xs' }}>
                <Typo type="bodySm" css={{ color: '$white300', fontWeight: '$medium' }}>
                  This stream is a part of an event.
                </Typo>
                <Flex
                  as="a"
                  href={`${import.meta.env.VITE_APP_ORIGIN}events/${event?.short_code}`}
                  target="_blank"
                  onClick={() => analytics.track('opened_event_page_stream_view')}
                  css={{
                    touchAction: 'manipulation',
                    alignItems: 'center',
                    gap: '$xs',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    '& svg': {
                      transition: 'all 0.2s ease-in-out',
                    },
                    '&:hover': {
                      '& svg': {
                        transform: 'translateX(10px)',
                      },
                    },
                  }}
                >
                  <Typo
                    type="bodyBold"
                    css={{
                      color: '$constantWhite',
                    }}
                  >
                    {event?.title}
                  </Typo>

                  <ArrowRightAltIcon />
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>

        <Flex
          className="stream-desc-wrapper"
          css={{
            flexDirection: 'column',
            width: '60%',
            marginTop: '-27px',
            '@bp3': {
              width: '100%',
              marginTop: '-30px',
            },
          }}
        >
          <Typo
            css={{
              color: '$constantWhite',
              opacity: 0.8,
              marginTop: '2rem',
              lineHeight: '1.43rem',
              '@bp3': {
                marginTop: '0.5rem',
              },
            }}
          >
            {getStreamDesc()}
          </Typo>
          {showExpandStreamDescButton() && (
            <Typo
              css={{
                color: '$gs400',
                lineHeight: '1.43rem',
                alignSelf: 'flex-end',
                marginRight: '10px',
                marginTop: '5px',
              }}
              onClick={() => setIsFullStreamDescVisible(!isFullStreamDescVisible)}
            >
              {isFullStreamDescVisible ? 'Show less' : 'Read full description'}
            </Typo>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
