import { useContext } from 'react';
import ForumIcon from '../../../../components/icons/forum';
import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';
import PulseChat from '../../../../components/third-party/chat/chat';
import { ISquadVideoContext, SquadVideoContext } from '../../context';

export default function ChatEvent() {
  const { isChatVisible } = useContext<ISquadVideoContext>(SquadVideoContext);

  return (
    <Flex className="chat-wrapper" css={{ flexDirection: 'column' }}>
      <Flex
        className="chat-title"
        css={{
          gap: '$2xs',
          alignItems: 'center',
          paddingLeft: '$xs',
          minHeight: '3.37rem',
          borderBottom: '1px solid $gs500',
          backgroundColor: '$bgAppScreen200',
          // '@bp3': {
          //   position: 'fixed',
          //   top: '18px',
          //   minHeight: '3rem',
          //   marginTop: '1px',
          //   width: '100%',
          //   zIndex: 2,
          // },
        }}
      >
        <ForumIcon />
        <Typo type="bodyBold" css={{ color: '$constantWhite' }}>
          Live Chat
        </Typo>
      </Flex>
      <Flex className="chat-body" css={{ height: 'calc(100% - 3.37rem)' }}>
        {isChatVisible && <PulseChat />}
      </Flex>
    </Flex>
  );
}
