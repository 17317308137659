/* eslint-disable import/export */
import { AccessDeniedError, UnexpectedError } from '../../domain/errors';
import { AddProfile } from '../../domain/usecases/add-profile';
import { HttpStatusCode, HttpClient } from '../protocols/http';

export class RemoteAddProfile implements AddProfile {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<RemoteAddProfile.Model | any>) {}

  async add(params: AddProfile.Params): Promise<AddProfile.Model> {
    const formData = new FormData();
    if (params.profile_photo) {
      formData.append('profile_photo', params.profile_photo);
    }
    formData.append('username', params.username);
    formData.append('display_name', params.username);

    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: formData,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteAddProfile {
  export type Model = AddProfile.Model;
}
