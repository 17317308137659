interface IProps {
  color?: string;
}

export default function ArrowCircleRightIcon({ color = '#DAFF01' }: IProps) {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1101_7323"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="20"
      >
        <rect x="0.5" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1101_7323)">
        <path
          d="M11.0837 12.7498L13.2503 10.5832C13.417 10.4165 13.5003 10.2221 13.5003 9.99984C13.5003 9.77762 13.417 9.58317 13.2503 9.4165L11.0628 7.229C10.91 7.07623 10.7192 7.00317 10.4903 7.00984C10.2609 7.01706 10.0698 7.09706 9.91699 7.24984C9.76421 7.40262 9.68783 7.59706 9.68783 7.83317C9.68783 8.06928 9.76421 8.26373 9.91699 8.4165L10.667 9.1665H7.97949C7.74338 9.1665 7.54894 9.24623 7.39616 9.40567C7.24338 9.56567 7.16699 9.76373 7.16699 9.99984C7.16699 10.2359 7.24699 10.4337 7.40699 10.5932C7.56644 10.7532 7.76421 10.8332 8.00033 10.8332H10.667L9.89616 11.604C9.74338 11.7568 9.67033 11.9479 9.67699 12.1773C9.68422 12.4062 9.76421 12.5971 9.91699 12.7498C10.0698 12.9026 10.2642 12.979 10.5003 12.979C10.7364 12.979 10.9309 12.9026 11.0837 12.7498ZM10.5003 18.3332C9.34755 18.3332 8.26421 18.1143 7.25033 17.6765C6.23644 17.2393 5.35449 16.6457 4.60449 15.8957C3.85449 15.1457 3.26088 14.2637 2.82366 13.2498C2.38588 12.2359 2.16699 11.1526 2.16699 9.99984C2.16699 8.84706 2.38588 7.76373 2.82366 6.74984C3.26088 5.73595 3.85449 4.854 4.60449 4.104C5.35449 3.354 6.23644 2.76012 7.25033 2.32234C8.26421 1.88511 9.34755 1.6665 10.5003 1.6665C11.6531 1.6665 12.7364 1.88511 13.7503 2.32234C14.7642 2.76012 15.6462 3.354 16.3962 4.104C17.1462 4.854 17.7398 5.73595 18.177 6.74984C18.6148 7.76373 18.8337 8.84706 18.8337 9.99984C18.8337 11.1526 18.6148 12.2359 18.177 13.2498C17.7398 14.2637 17.1462 15.1457 16.3962 15.8957C15.6462 16.6457 14.7642 17.2393 13.7503 17.6765C12.7364 18.1143 11.6531 18.3332 10.5003 18.3332ZM10.5003 16.6665C12.3475 16.6665 13.9206 16.0173 15.2195 14.719C16.5178 13.4201 17.167 11.8471 17.167 9.99984C17.167 8.15262 16.5178 6.57956 15.2195 5.28067C13.9206 3.98234 12.3475 3.33317 10.5003 3.33317C8.6531 3.33317 7.08033 3.98234 5.78199 5.28067C4.4831 6.57956 3.83366 8.15262 3.83366 9.99984C3.83366 11.8471 4.4831 13.4201 5.78199 14.719C7.08033 16.0173 8.6531 16.6665 10.5003 16.6665Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
