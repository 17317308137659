import { useState } from 'react';
import Flex from '../../../../components/primitives/flex';
import MapBoxGeneric from '../../../../components/third-party/map/map-generic';
import useStreamViewStore from '../../state-manager/store';
import useGlobalStore from '../../../../app-global-store';

export function StreamViewMap() {
  const userLocation = useStreamViewStore((state) => state.userLocation);
  const analytics = useGlobalStore((state) => state.analytics);
  const setMapRef = useStreamViewStore((state) => state.setMapRef);

  const [isMapFollowLocked, setIsMapFollowLocked] = useState<boolean>(true);

  return (
    <Flex css={{ width: '100%', height: '100%' }}>
      {userLocation && (
        <MapBoxGeneric
          mapCenter={[userLocation.longitude, userLocation.latitude]}
          singleAthletePosition={userLocation}
          onMapReady={(mapRef) => setMapRef(mapRef)}
          isMapFollowLocked={isMapFollowLocked}
          setIsMapFollowLocked={setIsMapFollowLocked}
          onZoomIn={() => analytics.track('map_zoom_in_stream_view')}
          onZoomOut={() => analytics.track('map_zoom_out_stream_view')}
          onChangeTo2D={() => analytics.track('map_changed_2D_stream_view')}
          onChangeTo3D={() => analytics.track('map_changed_3D_stream_view')}
          onLockOnUserPosition={() => analytics.track('map_lock_user_location_stream_view')}
        />
      )}
    </Flex>
  );
}
