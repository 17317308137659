import { useMemo } from 'react';
import HostPill from '../../../../components/icons/host-pill';
import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';
import useEventStore from '../../state-manager/store';
import EventLiveChannelsCarousel from '../event-live-channels-carousel/event-live-channels-carousel';
import EventLiveHostChannelsCarousel from '../event-live-host-channels-carousel/event-live-host-channels-carousel';
import { EventPlayStatus, EventStream } from '../../state-manager/store-types';
import { useIsMobile } from '../../../../utils/useIsMobile';

let eventStreamsOnTimelineRef: EventStream[] = [];

export function EventLiveStreamsAndHosts() {
  const eventStreams = useEventStore((state) => state.eventStreams);
  const playerElement = useEventStore((state) => state.playerElement);
  const timelineElement = useEventStore((state) => state.timelineElement);
  const setPlayStatus = useEventStore((state) => state.setPlayStatus);
  const setFeatStream = useEventStore((state) => state.setFeatStream);
  const setPlayingHostStream = useEventStore((state) => state.setPlayingHostStream);
  const isMobile = useIsMobile();

  eventStreamsOnTimelineRef = eventStreams;

  const findSelectedStream = (id: string) => eventStreamsOnTimelineRef.find((stream) => stream.id === id);

  const isLive = useMemo(
    () => !!eventStreams.some((stream) => stream.content.status === 'started' && !stream.isProducer),
    [eventStreams],
  );
  const hasLiveHostCommentary = useMemo(
    () => !!eventStreams.some((stream) => stream.content.status === 'started' && stream.isProducer),
    [eventStreams],
  );

  const onSelectHostLiveStream = (id: string) => {
    const foundStream = findSelectedStream(id);
    if (!foundStream) return;
    if (isMobile) {
      playerElement?.muted(true);
    } else {
      playerElement?.volume(0.1);
    }
    setPlayingHostStream(foundStream);
  };

  const onSelectLiveStream = (id: string) => {
    const foundStream = findSelectedStream(id);
    if (!foundStream) return;

    setPlayStatus(EventPlayStatus.LIVE);
    const timeoffsetInSeconds = -1;
    const currentFeatStream = { ...foundStream, timeoffsetInSeconds };

    setFeatStream(currentFeatStream || null);
    setTimeout(() => timelineElement?.focus(currentFeatStream.id), 1000);
  };

  return (
    <Flex css={{ flexDirection: 'column' }}>
      {isLive && (
        <Flex
          css={{
            backgroundColor: '$bgAppScreen200',
            height: '4rem',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            // gap: '$xs',
            borderBottom: '1px solid $gs500',
            padding: '0px 10px',
          }}
        >
          <Flex
            css={{
              backgroundColor: '$vsLive100',
              padding: '0px $2xs',
              height: '1.5rem',
              alignItems: 'center',
              borderRadius: '$lg',
            }}
          >
            <Typo
              css={{ textTransform: 'uppercase', color: '$constantWhite', fontWeight: '$bold', fontSize: '0.8rem' }}
            >
              Live
            </Typo>
          </Flex>
          <Flex css={{ width: 'calc(100% - 40px)' }}>
            <EventLiveChannelsCarousel onSelectStream={onSelectLiveStream} />
          </Flex>
        </Flex>
      )}
      {hasLiveHostCommentary && (
        <Flex
          className="host-wrapper"
          css={{
            backgroundColor: '$bgAppScreen200',
            height: '4rem',
            display: 'flex',
            alignItems: 'center',
            // gap: '$xs',
            borderBottom: '1px solid $gs500',
            padding: '0px 10px',
          }}
        >
          <HostPill />
          <Flex css={{ width: 'calc(100% - 40px)' }}>
            <EventLiveHostChannelsCarousel onSelectStream={onSelectHostLiveStream} />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
