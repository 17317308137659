import PulseLogoMarkDark from '../icons/pulse-logo-mark-dark';
import Button from '../primitives/button';
import Flex from '../primitives/flex';
import { Typo } from '../primitives/typo/typo';

export default function EventNotFound() {
  return (
    <Flex
      className="not-found-wrapper"
      css={{
        width: '100%',
        height: '100vh',
        backgroundColor: '$bgAppScreen100',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '$md',
        '& .pulse-logo-mark-dark': {
          transform: 'scale(2) translateY(-30px)',
        },
      }}
    >
      <Flex className="pulse-logo">
        <PulseLogoMarkDark color="#fff" />
      </Flex>
      <Flex
        className="not-found-title"
        css={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '$xs' }}
      >
        <Typo as="h3" type="h3">
          Event not found
        </Typo>
        <Typo css={{ color: '$constantWhite' }} type="bodyBold">
          Watch previous events:
        </Typo>
      </Flex>

      <Flex css={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '$xs' }}>
        <Button as="a" href="https://utct.pulse.tv/" target="_blank" variant="transparentActive">
          UTCT - Ultra Trail Cape Town
        </Button>
        <Button as="a" href="https://mda.pulse.tv/" target="_blank" variant="transparentActive">
          MDA - Athens
        </Button>
      </Flex>
    </Flex>
  );
}
