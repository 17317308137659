import StreamViewPage from '../../presentation/pages/stream-view/stream-view-page';
import { makeStreamViewRepository } from '../usecases/remote-stream-repository-factory';
import { makeStreamViewAnonymousRepository } from '../usecases/remote-stream-anonymous-repository-factory';
import { makeChannelRepository } from '../usecases/remote-channel-factory';
import { makeLocalStorageAdapter } from '../factories/cache';

export function makeStreamViewPage() {
  return (
    <StreamViewPage
      streamRepository={makeStreamViewRepository()}
      streamAnonymousRepository={makeStreamViewAnonymousRepository()}
      channelRepository={makeChannelRepository()}
      localStorageAdapter={makeLocalStorageAdapter()}
    />
  );
}
