import { useContext, useEffect, useState } from 'react';
import { VideoJsPlayer } from 'video.js';
import { useSearchParams } from 'react-router-dom';

import { ISquadVideoContext, RealTimePosition, SquadVideoContext } from '../../pages/event-v1/context';
import { GpsMetaData } from '../third-party/video-stream/gps-type';
import { StreamModel } from '../../../domain/models/stream-model';
import { stringToColour } from '../../utils/utils';
import DesktopStreamThumb from './components/desktop-stream-thumb';
import MobileStreamThumb from './components/mobile-stream-thumb';

interface IProps {
  isFirstOnList?: boolean;
  isLastOnList?: boolean;
  isSelected?: boolean;
  streamData: StreamModel | undefined;
  videoTimeOffset?: number;
  showVideo?: boolean;
  forceShowDesktop?: boolean;
  onPlayerReady?: (playerRef: VideoJsPlayer) => void;
  onSelect?: () => void;
  index?: number;
}

let isLocationUpdateDisabled = false;
export default function StreamThumb({
  isFirstOnList = false,
  isLastOnList = false,
  streamData,
  videoTimeOffset = 0,
  showVideo = true,
  forceShowDesktop = false,
  isSelected = false,
  onSelect,
  onPlayerReady,
  index,
}: IProps) {
  const {
    realTimeAthletePositions,
    setRealTimeAthletePositions,
    selectedTime,
    setFeaturedStream,
    isLiveSelected,
    userMarketingEventTrack,
    setIsMapFollowLocked,
    isMobile,
  } = useContext<ISquadVideoContext>(SquadVideoContext);
  const [videoPlayer, setVideoPlayer] = useState<VideoJsPlayer | null>(null);
  const [isVideoVisible, setIsVideoVisible] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const onSelectedTimeChanges = () => {
    isLocationUpdateDisabled = true;
    setTimeout(() => {
      isLocationUpdateDisabled = false;
    }, 1000);
  };
  useEffect(onSelectedTimeChanges, [selectedTime]);

  if (!streamData) return <div>Stream not found</div>;

  const onChangeGpsData = (data: GpsMetaData, player: VideoJsPlayer) => {
    if (isLocationUpdateDisabled) return;
    const userActualLocation: RealTimePosition = {
      id: streamData.id,
      latitude: data.gps_latitude,
      longitude: data.gps_longitude,
      color: stringToColour(streamData?.channel.username),
      useravatar: streamData?.channel.hero_photo.original,
      username: streamData?.channel.username,
      videoPlayerRef: player,
    };
    realTimeAthletePositions.set(streamData.id, userActualLocation);
    setRealTimeAthletePositions(realTimeAthletePositions);
  };

  const onVideoPlayerReady = (playerRef: VideoJsPlayer) => {
    if (onPlayerReady) onPlayerReady(playerRef);
    setVideoPlayer(playerRef);
  };

  const getCurrentVideoTime = (): number => {
    if (!videoPlayer) return 0;
    return videoPlayer?.currentTime() || 0;
  };

  const onSelectStream = () => {
    if (onSelect) onSelect();
    if (isSelected) return setIsMapFollowLocked(true);
    userMarketingEventTrack.track(`Selected stream ${streamData?.channel?.username}`);
    searchParams.set('stream_id', streamData.id);
    setSearchParams(searchParams);
    setFeaturedStream({
      ...streamData,
      offset_ms: isLiveSelected ? 0 : getCurrentVideoTime() * 1000,
    });
    setIsMapFollowLocked(true);
  };

  const toggleVideoVisibility = () => {
    const TIME_TO_WAIT = 5000;
    setIsVideoVisible(false);
    setTimeout(() => setIsVideoVisible(true), TIME_TO_WAIT);
  };

  const onVideoError = () => {
    realTimeAthletePositions.delete(streamData.id);
    setRealTimeAthletePositions(new Map(realTimeAthletePositions));
    toggleVideoVisibility();
  };

  return (
    <>
      {(!isMobile || forceShowDesktop) && (
        <DesktopStreamThumb
          onSelectStream={onSelectStream}
          onChangeGpsData={onChangeGpsData}
          onVideoPlayerReady={onVideoPlayerReady}
          onVideoError={onVideoError}
          streamData={streamData}
          isSelected={isSelected}
          isLastOnList={isLastOnList}
          isVideoVisible={isVideoVisible}
          isFirstOnList={isFirstOnList}
          videoTimeOffset={videoTimeOffset}
          showVideo={showVideo}
        />
      )}
      {isMobile && !forceShowDesktop && (
        <MobileStreamThumb
          isSelected={isSelected}
          isVideoVisible={isVideoVisible}
          videoTimeOffset={videoTimeOffset}
          streamData={streamData}
          onSelectStream={onSelectStream}
          onVideoError={onVideoError}
          onVideoPlayerReady={onVideoPlayerReady}
          onChangeGpsData={onChangeGpsData}
          showVideo={showVideo}
          index={index}
        />
      )}
    </>
  );
}
