import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import PulseLogoMarkDark from '../icons/pulse-logo-mark-dark';
import Button from '../primitives/button';

import Flex from '../primitives/flex';
import { Typo } from '../primitives/typo/typo';
import animationData from './data.json';

export default function GenericNotFound() {
  const navigate = useNavigate();

  return (
    <Flex
      className="not-found-wrapper"
      css={{
        width: '100%',
        height: '100%',
        backgroundColor: '$bgAppScreen100',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '$md',
      }}
    >
      <Flex className="pulse-logo">
        <PulseLogoMarkDark color="#fff" />
      </Flex>
      <Flex className="404-logo">
        <Lottie animationData={animationData} loop />
      </Flex>
      <Flex
        className="not-found-title"
        css={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '$sm', padding: '$xs' }}
      >
        <Typo as="h3" type="h3" css={{ lineHeight: '3rem', textAlign: 'center' }}>
          Oops, We can’t seem to find the page what you are looking for.
        </Typo>
        <Button variant="transparentActive" onClick={() => navigate('/')}>
          Get me back to home
        </Button>
      </Flex>
    </Flex>
  );
}
