import CloseCrossIcon from '../../../../components/icons/close-cross';
import { Dialog, DialogContent, DialogClose } from '../../../../components/primitives/dialog/dialog';
import Flex from '../../../../components/primitives/flex';
import { darkTheme } from '../../../../stitches.config';

interface IProps {
  introUrl: string;
  setIsModalVisible: any;
}

export function EventIntroModal({ introUrl, setIsModalVisible }: IProps) {
  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Dialog open>
      <DialogContent
        css={{
          backgroundColor: '$bgAppScreen200',
          maxWidth: '95%',
          maxHeight: '95%',
          height: '95%',
          width: '95%',
          borderRadius: '$2lg',
          padding: 0,
        }}
        className={darkTheme}
      >
        <>
          <DialogClose>
            <Flex
              as="button"
              css={{
                width: '4rem',
                height: '4rem',
                backgroundColor: 'rgba(0,0,0,0.7)',
                borderRadius: '50%',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: 10000,
              }}
              onClick={closeModal}
              onTouchStart={closeModal}
            >
              <CloseCrossIcon />
            </Flex>
          </DialogClose>

          <Flex
            as="iframe"
            css={{ width: '100%', height: '100%', border: 'none' }}
            title="pre-event-info"
            src={introUrl}
          />
        </>
      </DialogContent>
    </Dialog>
  );
}
