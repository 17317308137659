import { useEffect, useRef } from 'react';
import { keyframes } from '../../stitches.config';
import { scrollToElement, stringToColour } from '../../utils/utils';
import { Avatar, AvatarImage, AvatarFallback } from '../primitives/avatar-image/avatar-image';
import Flex from '../primitives/flex';

interface IUser {
  username: string;
  color?: string;
  useravatar: string;
}

interface IProps {
  user: IUser;
  isSelected?: boolean;
  isAnimatedBorder?: boolean;
  css?: any;
}

const rotateAnimation = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' },
});

export default function AvatarUserWithSelectedAnimation({
  user,
  css = {},
  isSelected = false,
  isAnimatedBorder = false,
}: IProps) {
  const avatarRef = useRef(null);
  const onAvatarIsSelected = () => {
    if (isSelected) {
      scrollToElement('', avatarRef?.current);
    }
  };
  useEffect(onAvatarIsSelected, [isSelected]);

  return (
    <Flex css={{ zIndex: 3 }}>
      <Avatar
        ref={avatarRef}
        css={{
          transition: 'all 0.5s',
          width: isSelected ? '2rem' : '1.5rem',
          height: isSelected ? '2rem' : '1.5rem',
          '&::before': {
            content: '',
            position: 'absolute',
            width: isSelected ? '2.375rem' : '1.875rem',
            height: isSelected ? '2.375rem' : '1.875rem',

            background: isAnimatedBorder
              ? `linear-gradient(90deg, ${stringToColour(user.username || 'pulse')} 65%, $primaryAdrenaline100 100%)`
              : `${stringToColour(user.username || 'pulse')}`,
            zIndex: -1,
            borderRadius: '50%',
            animation: isAnimatedBorder ? `${rotateAnimation} 1.2s linear infinite` : 'none',
          },
          ...css,
        }}
        className="avatar-user"
      >
        <AvatarImage src={user.useravatar} alt={user.username} />
        <AvatarFallback delayMs={600}>{user?.username?.substring(0, 2).toUpperCase()}</AvatarFallback>
      </Avatar>
    </Flex>
  );
}
