import { useEffect, useState } from 'react';
import useEventStore from '../state-manager/store';
import { Polyline } from '../state-manager/store-types';
import { closestTimestampIndex } from '../../../components/timeline/find-location';

const getClosestDataForPolylines = (polylinesProperty: Polyline[], hoveredTimeProperty: Date): Polyline[number][] =>
  polylinesProperty
    .map((polyline) => {
      const closestIndex = closestTimestampIndex(polyline, hoveredTimeProperty.getTime());
      return closestIndex !== null ? polyline[closestIndex] : null;
    })
    .filter((data): data is NonNullable<typeof data> => data !== null);

export function useEventAutoPlay() {
  const [isAutoPlayBlocked, setIsAutoPlayBlocked] = useState(true);
  const featStream = useEventStore((state) => state.featStream);
  const eventStreamsLinearList = useEventStore((state) => state.eventStreamsLinearList);
  const pulseLinesGroupedByStream = useEventStore((state) => state.pulseLinesGroupedByStream);
  const pulseLinesGroupedByUserAndSortedByTimestamp = useEventStore(
    (state) => state.pulseLinesGroupedByUserAndSortedByTimestamp,
  );

  useEffect(() => {
    setTimeout(() => setIsAutoPlayBlocked(false), 2000);
  }, []);

  const currentTime = useEventStore((state) => state.currentTime);
  const setCurrentTime = useEventStore((state) => state.setCurrentTime);
  const setFeatStream = useEventStore((state) => state.setFeatStream);

  const findStreamByUserNameAndCurrentTime = (username: string) => {
    if (!currentTime) return false;
    const currentStreams = getClosestDataForPolylines(pulseLinesGroupedByUserAndSortedByTimestamp, currentTime);
    return currentStreams.find((stream) => stream.username === username);
  };

  const finStreamIndexOnLinearArrayById = (id: string) =>
    eventStreamsLinearList.findIndex((stream) => stream.id === id);

  const onSeek = (seekTime: number) => {
    const seekTimeObj = new Date(seekTime);
    setCurrentTime(seekTimeObj);
  };

  const isCurrentTimeAheadOfFeatStreamEndTime = (endTimeMs: number) => {
    if (!currentTime) return false;
    return currentTime.getTime() >= endTimeMs;
  };

  const isCurrentTimeBeforeOfFeatStreamEndTime = (startTimeMs: number) => {
    if (!currentTime) return false;

    return currentTime.getTime() <= startTimeMs;
  };

  const isStreamOutOfCurrentTimeRange = (startDate: number, endDate: number): boolean =>
    isCurrentTimeBeforeOfFeatStreamEndTime(startDate) || isCurrentTimeAheadOfFeatStreamEndTime(endDate);

  const findPointDataInsidePulseLine = (id?: string) =>
    pulseLinesGroupedByStream.find((streamPulseLines) => (streamPulseLines[0] ? streamPulseLines[0].id === id : null));

  const isCurrentTimeOneSecondAheadOfFeatStreamEnd = () => {
    if (!currentTime || !featStream?.stream_end_date_ms) return false;
    const difference = Math.abs(currentTime.getTime() - featStream.stream_end_date_ms);
    return difference >= 1000 && difference <= 1500;
  };

  const autoSwitchStreamWhenNoUserInteraction = () => {
    if (!featStream?.channel.username || !featStream?.stream_start_date_ms || !featStream?.stream_end_date_ms) return;

    const foundStreamIndex = finStreamIndexOnLinearArrayById(featStream.id);

    const nextStream = eventStreamsLinearList[foundStreamIndex + 1];
    const pulseLineBasedOnNextStream = findPointDataInsidePulseLine(nextStream?.id);
    if (pulseLineBasedOnNextStream && pulseLineBasedOnNextStream[0].username === featStream.channel.username) {
      setFeatStream(nextStream);
      setCurrentTime(new Date(nextStream.stream_start_date_ms));
    } else {
      setFeatStream(null);
    }
  };

  const autoSwitchFeatStreamIfUserInteractsWithTimeline = () => {
    if (!featStream?.channel.username) return;

    const foundStreamBasedOnUserName = findStreamByUserNameAndCurrentTime(featStream?.channel.username);
    if (foundStreamBasedOnUserName) {
      const foundStream = eventStreamsLinearList.find((stream) => stream.id === foundStreamBasedOnUserName.id);

      if (foundStream) setFeatStream(foundStream);
    } else {
      setFeatStream(null);
    }
  };

  const calculateAutoPlayWhenCurrentTimeChanges = () => {
    if (
      !featStream?.channel.username ||
      !featStream?.stream_start_date_ms ||
      !featStream?.stream_end_date_ms ||
      isAutoPlayBlocked
    )
      return;

    if (isCurrentTimeOneSecondAheadOfFeatStreamEnd()) {
      autoSwitchStreamWhenNoUserInteraction();
    } else if (isStreamOutOfCurrentTimeRange(featStream?.stream_start_date_ms, featStream?.stream_end_date_ms)) {
      autoSwitchFeatStreamIfUserInteractsWithTimeline();
    }
  };
  useEffect(calculateAutoPlayWhenCurrentTimeChanges, [currentTime]);

  return { onSeek };
}
