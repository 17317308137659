export default function FullScreenExitIcon({ color = 'white' }: { color?: string }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.99998 15.525C4.63331 15.525 4.32064 15.4 4.06198 15.15C3.80398 14.9 3.67498 14.5833 3.67498 14.2V12.325H1.79998C1.41664 12.325 1.09998 12.196 0.849976 11.938C0.599976 11.6793 0.474976 11.3667 0.474976 11C0.474976 10.6333 0.599976 10.3207 0.849976 10.062C1.09998 9.80401 1.41664 9.67501 1.79998 9.67501H4.99998C5.36664 9.67501 5.67931 9.80401 5.93798 10.062C6.19598 10.3207 6.32498 10.6333 6.32498 11V14.2C6.32498 14.5833 6.19598 14.9 5.93798 15.15C5.67931 15.4 5.36664 15.525 4.99998 15.525ZM1.79998 6.32501C1.41664 6.32501 1.09998 6.19601 0.849976 5.93801C0.599976 5.67934 0.474976 5.36667 0.474976 5.00001C0.474976 4.63334 0.599976 4.32067 0.849976 4.06201C1.09998 3.80401 1.41664 3.67501 1.79998 3.67501H3.67498V1.80001C3.67498 1.41667 3.80398 1.10001 4.06198 0.850006C4.32064 0.600006 4.63331 0.475006 4.99998 0.475006C5.36664 0.475006 5.67931 0.600006 5.93798 0.850006C6.19598 1.10001 6.32498 1.41667 6.32498 1.80001V5.00001C6.32498 5.36667 6.19598 5.67934 5.93798 5.93801C5.67931 6.19601 5.36664 6.32501 4.99998 6.32501H1.79998ZM11 15.525C10.6333 15.525 10.3206 15.4 10.062 15.15C9.80398 14.9 9.67498 14.5833 9.67498 14.2V11C9.67498 10.6333 9.80398 10.3207 10.062 10.062C10.3206 9.80401 10.6333 9.67501 11 9.67501H14.2C14.5833 9.67501 14.9 9.80401 15.15 10.062C15.4 10.3207 15.525 10.6333 15.525 11C15.525 11.3667 15.4 11.6793 15.15 11.938C14.9 12.196 14.5833 12.325 14.2 12.325H12.325V14.2C12.325 14.5833 12.196 14.9 11.938 15.15C11.6793 15.4 11.3666 15.525 11 15.525ZM11 6.32501C10.6333 6.32501 10.3206 6.19601 10.062 5.93801C9.80398 5.67934 9.67498 5.36667 9.67498 5.00001V1.80001C9.67498 1.41667 9.80398 1.10001 10.062 0.850006C10.3206 0.600006 10.6333 0.475006 11 0.475006C11.3666 0.475006 11.6793 0.600006 11.938 0.850006C12.196 1.10001 12.325 1.41667 12.325 1.80001V3.67501H14.2C14.5833 3.67501 14.9 3.80401 15.15 4.06201C15.4 4.32067 15.525 4.63334 15.525 5.00001C15.525 5.36667 15.4 5.67934 15.15 5.93801C14.9 6.19601 14.5833 6.32501 14.2 6.32501H11Z"
        fill={color}
      />
    </svg>
  );
}
