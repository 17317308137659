interface IProps {
  color?: string;
}
export function MyLocationFocused({ color = '#131313' }: IProps) {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4998 21.9501C11.2165 21.9501 10.9791 21.8544 10.7878 21.663C10.5958 21.4711 10.4998 21.2334 10.4998 20.9501V19.9501C8.41647 19.7167 6.62914 18.8544 5.1378 17.3631C3.6458 15.8711 2.78314 14.0834 2.5498 12H1.5498C1.26647 12 1.0288 11.904 0.836805 11.712C0.645471 11.5207 0.549805 11.2834 0.549805 11C0.549805 10.7167 0.645471 10.479 0.836805 10.287C1.0288 10.0957 1.26647 10 1.5498 10H2.5498C2.78314 7.91672 3.6458 6.12905 5.1378 4.63705C6.62914 3.14572 8.41647 2.28338 10.4998 2.05005V1.05005C10.4998 0.766716 10.5958 0.529049 10.7878 0.337049C10.9791 0.145716 11.2165 0.0500488 11.4998 0.0500488C11.7831 0.0500488 12.0208 0.145716 12.2128 0.337049C12.4041 0.529049 12.4998 0.766716 12.4998 1.05005V2.05005C14.5831 2.28338 16.3708 3.14572 17.8628 4.63705C19.3541 6.12905 20.2165 7.91672 20.4498 10H21.4498C21.7331 10 21.9708 10.0957 22.1628 10.287C22.3541 10.479 22.4498 10.7167 22.4498 11C22.4498 11.2834 22.3541 11.5207 22.1628 11.712C21.9708 11.904 21.7331 12 21.4498 12H20.4498C20.2165 14.0834 19.3541 15.8711 17.8628 17.3631C16.3708 18.8544 14.5831 19.7167 12.4998 19.9501V20.9501C12.4998 21.2334 12.4041 21.4711 12.2128 21.663C12.0208 21.8544 11.7831 21.9501 11.4998 21.9501ZM11.4998 18C13.4331 18 15.0831 17.3167 16.4498 15.95C17.8165 14.5834 18.4998 12.9334 18.4998 11C18.4998 9.06672 17.8165 7.41672 16.4498 6.05005C15.0831 4.68338 13.4331 4.00005 11.4998 4.00005C9.56647 4.00005 7.91647 4.68338 6.5498 6.05005C5.18314 7.41672 4.4998 9.06672 4.4998 11C4.4998 12.9334 5.18314 14.5834 6.5498 15.95C7.91647 17.3167 9.56647 18 11.4998 18ZM11.4998 15C10.3998 15 9.45814 14.6084 8.6748 13.825C7.89147 13.0417 7.4998 12.1 7.4998 11C7.4998 9.90005 7.89147 8.95838 8.6748 8.17505C9.45814 7.39172 10.3998 7.00005 11.4998 7.00005C12.5998 7.00005 13.5415 7.39172 14.3248 8.17505C15.1081 8.95838 15.4998 9.90005 15.4998 11C15.4998 12.1 15.1081 13.0417 14.3248 13.825C13.5415 14.6084 12.5998 15 11.4998 15Z"
        fill={color}
        fillOpacity="0.81"
      />
    </svg>
  );
}
