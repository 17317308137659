/* eslint-disable react/no-unescaped-entities */
import { useState } from 'react';
import { keyframes } from '../../stitches.config';
import { CabinIcon } from '../icons/cabin';
import { CreateIcon } from '../icons/create';
import { DiscoverIcon } from '../icons/discover';
import { EventIcon } from '../icons/event';
import { FollowingIcon } from '../icons/following';
import { ShakaIcon } from '../icons/shaka';
import { StartIcon } from '../icons/start-icon';
import Box from '../primitives/box';
import Button from '../primitives/button';
import Flex from '../primitives/flex';
import { Typo } from '../primitives/typo/typo';
import { SideBarMenuItem } from './sidebar-menu-item';
import useGlobalStore from '../../app-global-store';

const animateInitialMesage = keyframes({
  '0%': {
    transform: 'translateX(-20px)',
    opacity: 0,
  },

  '100%': {
    transform: 'translateX(0px)',
    opacity: 1,
  },
});

export const shakaShake = keyframes({
  '0%': {
    transform: 'rotate(-15deg)',
  },
  '25%': {
    transform: 'rotate(15deg)',
  },
  '50%': {
    transform: 'rotate(-15deg)',
  },
  '75%': {
    transform: 'rotate(15deg)',
  },
  '100%': {
    transform: 'rotate(0deg)',
  },
});

export function SideBar() {
  const analytics = useGlobalStore((state) => state.analytics);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isInitialMessageOpen, setInitialMessageOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    analytics.track(isOpen ? 'clicked_close_side_bar_stream_view' : 'clicked_open_side_bar_stream_view');
    if (isOpen) setInitialMessageOpen(false);
    setIsOpen(!isOpen);
  };

  const openInitialMessage = () => {
    setInitialMessageOpen(isOpen);
  };

  return (
    <Flex
      css={{
        flexDirection: 'column',
        backgroundColor: '$bgAppScreen100',
        transition: 'all 0.3s ease-in-out',
        minWidth: isOpen ? '10rem' : '3.5rem',
        width: isOpen ? '12rem' : '4.5rem',
        height: '100%',
        padding: '10px 14px 10px 0px',
        overflow: 'hidden',
        '@bp3': {
          display: 'none',
        },
      }}
      onTransitionEnd={openInitialMessage}
    >
      <Flex
        css={{
          marginBottom: '$md',
          cursor: 'pointer',
          paddingLeft: '14px',
        }}
        onClick={toggleOpen}
      >
        <Box
          css={{
            transition: 'all 0.3s ease-in-out',
            transform: isOpen ? 'rotate(180deg)' : '',
          }}
        >
          <StartIcon />
        </Box>
      </Flex>
      <Flex
        className="sidebar-greeting-message"
        css={{
          flexDirection: 'column',
          marginBottom: '$md',
          display: isInitialMessageOpen ? 'block' : 'none',
          animation: `${animateInitialMesage} 0.3s ease-in-out `,
          paddingLeft: '14px',
        }}
      >
        <Flex css={{ marginBottom: '$xs' }}>
          <Box
            css={{
              animation: `${shakaShake} 0.5s ease-in-out `,
            }}
          >
            <ShakaIcon />
          </Box>
        </Flex>
        <Flex className="sidebar-text-wrapper" css={{ flexDirection: 'column', gap: '1.2rem', marginBottom: '$xs' }}>
          <Typo type="bodySmallMedium" css={{ color: '$constantWhite' }}>
            Thanks for checking out Pulse!
          </Typo>
          <Typo type="bodySmallMedium" css={{ color: '$constantWhite' }}>
            We're working hard on finishing the rest of the website.
          </Typo>
          <Typo type="bodySmallMedium" css={{ color: '$constantWhite' }}>
            If you'd like to be notified when we launch, let us know!
          </Typo>
        </Flex>
        <Button variant="transparentNoHover" size="xsm" css={{ textDecoration: 'underline' }}>
          Stay up to date
        </Button>
      </Flex>
      <Flex className="sidebar-menu-items" css={{ gap: '$2xs', flexDirection: 'column', paddingLeft: '7px' }}>
        <SideBarMenuItem showLabel={isOpen} icon={<CabinIcon />} label="Home" />
        <SideBarMenuItem showLabel={isOpen} icon={<FollowingIcon />} label="Following" />
        <SideBarMenuItem showLabel={isOpen} icon={<EventIcon />} label="Events" />
        <SideBarMenuItem showLabel={isOpen} icon={<DiscoverIcon />} label="Discover" />
        <SideBarMenuItem showLabel={isOpen} icon={<CreateIcon />} label="Create" />
      </Flex>
    </Flex>
  );
}
