/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Consumer, Mixin, Subscription } from '@rails/actioncable';
import throttle from 'lodash/throttle';

import consumer from '../web-socket/web-socket';
import useEventStore from '../state-manager/store';
import { EventStream, IWSLocation } from '../state-manager/store-types';
import { GPSData } from '../../../../domain/models/event-timeline-v3-model';

let wsLocationSubscription: (Mixin & Subscription<Consumer>) | null | any = null;

function generatePulseLine(streamInfo: EventStream, backendPulseLine: GPSData) {
  return {
    timestamp: Date.now(),
    lat: backendPulseLine[1],
    long: backendPulseLine[2],
    altitude: backendPulseLine[3],
    speed: backendPulseLine[4],
    gps_precision: backendPulseLine[5],
    id: streamInfo.id,
    playback_url: streamInfo.playback_url,
    vod_playback_url: streamInfo.vod_playback_url,
    logo_url: streamInfo.logo_url || '',
    title: streamInfo.title,
    username: streamInfo.username || '',
    avatarPicture: streamInfo.hero_photo?.original || '',
    duration_in_seconds: streamInfo.duration_in_seconds,
    stream_tile_url: streamInfo.stream_tile_url,
    // @ts-ignore
    offset_ms: Date.now() - new Date(streamInfo.start_time),
    stream_start_date_ms: Math.floor(new Date(streamInfo.start_time).getTime()),
    stream_end_date_ms: streamInfo.end_time ? Math.floor(new Date(streamInfo.end_time).getTime()) : null,
    producer: streamInfo.producer,
    speedType: streamInfo?.activity_type?.speed_type,
    sponsorLogo: streamInfo?.logo_url,
    official: streamInfo.official,
  };
}

function findUserPulseLinesIndex(username: string): number {
  const { pulseLinesGroupedByUserAndSortedByTimestamp } = useEventStore.getState();
  if (!pulseLinesGroupedByUserAndSortedByTimestamp.length) return -1;
  return pulseLinesGroupedByUserAndSortedByTimestamp.findIndex(
    (pulseLineGroupedByUser) => pulseLineGroupedByUser[0].username === username,
  );
}

function findStreamPulseLinesIndex(streamId: string): number {
  const { pulseLinesGroupedByStream } = useEventStore.getState();
  if (!pulseLinesGroupedByStream.length) return -1;
  return pulseLinesGroupedByStream.findIndex((pulseLineGroupedByStream) => pulseLineGroupedByStream[0].id === streamId);
}

function updatePulseLinesRef(locations: IWSLocation[]) {
  try {
    const {
      pulseLinesGroupedByStream,
      pulseLinesGroupedByUserAndSortedByTimestamp,
      eventStreams,
      setPulseLinesGroupedByStream,
      setPulseLinesGroupedByUserAndSortedByTimestamp,
    } = useEventStore.getState();

    locations?.forEach((location) => {
      const locationStreamChannelInfo = eventStreams[location.uuid];
      if (locationStreamChannelInfo) {
        const pulseline = generatePulseLine(locationStreamChannelInfo, location.pulse_line[0]);
        const indexFromPulseLinesGroupedByStream = findStreamPulseLinesIndex(pulseline.id);
        if (!pulseline.username) return;
        const indexFromPulseLinesGroupedByUser = findUserPulseLinesIndex(pulseline.username);

        if (indexFromPulseLinesGroupedByStream !== -1) {
          pulseLinesGroupedByStream[indexFromPulseLinesGroupedByStream].push(pulseline);
        } else {
          pulseLinesGroupedByStream.push([pulseline]);
        }

        if (indexFromPulseLinesGroupedByUser !== -1) {
          pulseLinesGroupedByUserAndSortedByTimestamp[indexFromPulseLinesGroupedByUser].push(pulseline);
        } else {
          pulseLinesGroupedByUserAndSortedByTimestamp.push([pulseline]);
        }
      }
    });

    setPulseLinesGroupedByStream([...pulseLinesGroupedByStream]);
    setPulseLinesGroupedByUserAndSortedByTimestamp([...pulseLinesGroupedByUserAndSortedByTimestamp]);
  } catch (e) {
    console.log({ e });
  }
}

const WAIT_MILISECONDS = 500;

const updatePulseLines = throttle(updatePulseLinesRef, WAIT_MILISECONDS);

export function useWsLocationlUpdate() {
  const subscribeToEventStreamLocationUpdateChannel = (event_id: string) => {
    wsLocationSubscription = consumer.subscriptions.create(
      { channel: 'EventStreamLocationUpdateChannelV2', event_id },
      {
        initialized: (): any => {
          // console.log('initialized location updates');
        },
        connected(): any {
          // console.log('connected');
        },
        disconnected(): any {
          // console.log('disconnected');
        },
        received(locations: IWSLocation[]): any {
          try {
            updatePulseLines(locations);
          } catch (e) {
            console.log({ e });
          }
        },
      },
    );
  };

  const unsubscribeToLiveLocationUpdates = () => {
    if (wsLocationSubscription) wsLocationSubscription?.unsubscribe();
  };

  return { subscribeToEventStreamLocationUpdateChannel, unsubscribeToLiveLocationUpdates };
}
