// import { useMemo } from 'react';
import { GenericCarouselScroller } from '../../../../components/generic-carousel-scroller/generic-carousel-scroller';

import useEventStore from '../../state-manager/store';
import { LiveChannelItem } from '../event-live-item/event-live-item';

interface IProps {
  onSelectStream: (index: string) => void;
}

export default function EventLiveChannelsCarousel({ onSelectStream }: IProps) {
  const eventStreams = useEventStore((state) => state.eventStreams);
  const liveStreams = eventStreams.filter((stream) => stream.content.status === 'started');
  const featuredStream = useEventStore((state) => state.featStream);
  console.log({ liveStreams });
  const onClickAvatar = (index: string) => {
    onSelectStream(index);
  };

  return (
    <GenericCarouselScroller list={liveStreams}>
      {liveStreams.map((stream) => (
        <LiveChannelItem
          onClick={() => onClickAvatar(stream.id)}
          key={stream.id}
          stream={stream}
          featuredStream={featuredStream}
        />
      ))}
    </GenericCarouselScroller>
  );
}
