import { keyframes } from '../../../stitches.config';
import { stringToColour } from '../../../utils/utils';
import ArrowCircleRightIcon from '../../icons/arrow-circle-right';
import { Avatar, AvatarImage, AvatarFallback } from '../../primitives/avatar-image/avatar-image';
import Flex from '../../primitives/flex';
import { Typo } from '../../primitives/typo/typo';

interface IProps {
  isPreviewLocation?: boolean;
  isHidden?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  username: string;
  useravatar: string;
}

const rotateAnimation = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' },
});

export default function GenericMapMarker({
  isPreviewLocation = false,
  isHidden = false,
  onClick,
  isSelected = false,
  username,
  useravatar,
}: IProps) {
  const getMarkerOpacity = () => {
    if (isHidden) return 0;
    return isPreviewLocation ? 0.7 : 1;
  };

  const onClickMarker = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Avatar
      css={{
        border: `2px solid ${stringToColour(username)}`,
        width: isSelected ? '55px' : '55px',
        height: isSelected ? '55px' : '55px',
        position: 'relative',
        transition: 'all 0.5s',
        opacity: getMarkerOpacity(),
        overflow: 'visible',
        '& .icon-hover-wrapper': {
          opacity: isSelected ? 1 : 0,
        },
        '&::before': {
          content: '',
          position: 'absolute',
          width: isSelected ? '57px' : '57px',
          height: isSelected ? '57px' : '57px',
          border: '0px solid black',

          background: isSelected
            ? `linear-gradient(90deg, ${stringToColour(username || 'pulse')} 45%, $primaryAdrenaline100 80%)`
            : `${stringToColour(username || 'pulse')}`,
          zIndex: -1,
          borderRadius: '50%',
          animation: isSelected ? `${rotateAnimation} 1.2s linear infinite` : 'none',
        },
        // '&:hover': {
        //   '& .icon-hover-wrapper': {
        //     // opacity: isPreviewLocation ? 0 : 1,
        //   },
        //   transform: !isPreviewLocation ? 'scale(1.3)' : 'scale(0)',
        //   border: !isPreviewLocation && `4px solid ${realtimeLocation.color}`,
        // },
      }}
      onClick={onClickMarker}
      className="avatar-user"
    >
      <AvatarImage src={useravatar} alt={username} />
      <Flex className="icon-hover-wrapper" css={{ position: 'absolute' }}>
        {isSelected ? <Flex /> : <ArrowCircleRightIcon color="#fff" />}
      </Flex>
      <Flex
        className="channel-name"
        onClick={onClickMarker}
        css={{
          height: '2rem',
          // width: '20rem',
          alignItems: 'center',
          backgroundColor: '$blackTranslucentGlass',
          color: '$white',
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
          borderRadius: '10px',
          position: 'absolute',
          left: 55,
          paddingLeft: '1rem',
          paddingRight: '1rem',
          'box-shadow': '0 0 20px rgba(0,0,0,.4)',
          opacity: 0,
        }}
      >
        <Typo type="bodyRegular" css={{ whiteSpace: 'nowrap' }}>
          {username.replace('_', ' ')}
        </Typo>
      </Flex>

      <AvatarFallback delayMs={300} css={{ width: '50%', height: '50%', backgroundColor: 'transparent' }}>
        {useravatar.substring(0, 1).toUpperCase()}
      </AvatarFallback>
      <Flex
        css={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          boxSizing: 'border-box',
          border: '1px solid $black200',
          backgroundColor: stringToColour(username),
          position: 'absolute',
          bottom: '-18px',
        }}
      />
    </Avatar>
  );
}
