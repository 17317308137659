export default function ForumIcon({ color = '#E8E7EC' }: { color?: string }) {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.666504 12.1667V1.49999C0.666504 1.26388 0.746504 1.06582 0.906504 0.905823C1.06595 0.746379 1.26373 0.666656 1.49984 0.666656H12.3332C12.5693 0.666656 12.7671 0.746379 12.9265 0.905823C13.0865 1.06582 13.1665 1.26388 13.1665 1.49999V8.99999C13.1665 9.2361 13.0865 9.43388 12.9265 9.59332C12.7671 9.75332 12.5693 9.83332 12.3332 9.83332H3.99984L1.37484 12.4583C1.24984 12.5833 1.10039 12.6144 0.926504 12.5517C0.753171 12.4894 0.666504 12.3611 0.666504 12.1667ZM4.83317 14C4.59706 14 4.399 13.92 4.239 13.76C4.07956 13.6005 3.99984 13.4028 3.99984 13.1667V11.5H14.8332V3.99999H16.4998C16.7359 3.99999 16.9337 4.07971 17.0932 4.23916C17.2532 4.39916 17.3332 4.59721 17.3332 4.83332V16.3333C17.3332 16.5278 17.2465 16.6561 17.0732 16.7183C16.8993 16.7811 16.7498 16.75 16.6248 16.625L13.9998 14H4.83317Z"
        fill={color}
      />
    </svg>
  );
}
