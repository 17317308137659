import { useRef, useState, useEffect, ReactNode } from 'react';
import SimpleLeftArrowIcon from '../icons/simple-left-arrow';
import SimpleRightArrowIcon from '../icons/simple-right-arrow';
import Button from '../primitives/button';
import Flex from '../primitives/flex';

interface IProps {
  list: any[];
  children: ReactNode;
}

export function GenericCarouselScroller({ children, list }: IProps) {
  const elementScrollRef = useRef<HTMLDivElement>(null);

  const [isRightArrowVisible, setIsRightArrowVisible] = useState<boolean>(false);
  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState<boolean>(false);

  const hasRemainingHorizontalScrollbar = (isLeft = false) => {
    if (!elementScrollRef?.current) return false;
    const element = elementScrollRef?.current;
    const fullScrollWithElementSize = element.scrollLeft + element.clientWidth;
    const hasHorizontalScroll = element.scrollWidth > element.clientWidth;
    const hasFinishedMaximumScrollRightSide = element.scrollWidth === fullScrollWithElementSize;
    const hasFinishedMaximumScrollLeftSide = element.scrollLeft === 0;
    const hasFinishedScroll = isLeft ? hasFinishedMaximumScrollLeftSide : hasFinishedMaximumScrollRightSide;
    return hasHorizontalScroll && !hasFinishedScroll;
  };

  const listenToScrollEvent = () => {
    setIsRightArrowVisible(hasRemainingHorizontalScrollbar());
    setIsLeftArrowVisible(hasRemainingHorizontalScrollbar(true));
  };

  const onScrollElementUpdates = () => {
    if (!elementScrollRef.current) return;
    setIsRightArrowVisible(hasRemainingHorizontalScrollbar());
    elementScrollRef.current.addEventListener('scroll', listenToScrollEvent);
  };
  useEffect(onScrollElementUpdates, [elementScrollRef.current, list]);

  const scrollCarousel = (isLeft = false, amountOfScroll = 150) => {
    if (!elementScrollRef.current) return;
    const actualScrollLeft: number = elementScrollRef.current.scrollLeft;
    const options: ScrollToOptions = {
      behavior: 'smooth',
      left: isLeft ? actualScrollLeft + amountOfScroll : actualScrollLeft - amountOfScroll,
    };
    elementScrollRef.current.scroll(options);
  };

  return (
    <Flex
      className="slider-wrapper"
      css={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      {isLeftArrowVisible && (
        <Button
          css={{ position: 'absolute', left: 0, '& path': { fill: '$gs300' }, touchAction: 'manipulation' }}
          size="sm"
          variant="transparent"
          onClick={() => scrollCarousel()}
        >
          <SimpleLeftArrowIcon />
        </Button>
      )}

      <Flex
        css={{
          width: 'calc(100% - 60px)',
          minWidth: '90px',
          height: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
          alignItems: 'center',
          gap: '$xs',
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
          '&::-webkit-scrollbar': {
            width: '0px',
            height: '0px',
          },
        }}
        ref={elementScrollRef}
        className="avatar-list"
      >
        {children}
      </Flex>
      {isRightArrowVisible && (
        <Button
          css={{ position: 'absolute', right: 0, '& path': { fill: '$gs300' }, touchAction: 'manipulation' }}
          size="sm"
          variant="transparent"
          onClick={() => scrollCarousel(true)}
        >
          <SimpleRightArrowIcon />
        </Button>
      )}
    </Flex>
  );
}
