import { useState, useEffect } from 'react';
import { ProfileModel } from '../../../../../domain/models/profile-model';
import { ReactionModel } from '../../../../../domain/models/reaction-model';
import { UserShareLike } from '../../../../components/user-share-like/user-share-like';
import useAuthStore from '../../../auth-wrapper/state-manager/store';
import { useStreamViewContext } from '../../state-manager/context';
import useStreamViewStore from '../../state-manager/store';
import useGlobalStore from '../../../../app-global-store';

export function StreamViewLike() {
  const { streamRepository, localStorageAdapter } = useStreamViewContext();
  const analytics = useGlobalStore((state) => state.analytics);
  const streamInfo = useStreamViewStore((state) => state.streamInfo);
  const openAuthModalIfItsNotLogged = useAuthStore((state) => state.openAuthModalIfItsNotLogged);
  const setIsShareDialogVisible = useAuthStore((state) => state.setIsShareDialogVisible);
  const isLogged = useAuthStore((state) => state.isLogged);

  const [isLiked, setIsLiked] = useState<boolean>(!!streamInfo?.liked_by_profile);
  const profile: ProfileModel | null = localStorageAdapter.get('profile');

  const [reactionList, setReactionList] = useState<Partial<ReactionModel>[]>([]);
  const [reactionCounter, setReactionCounter] = useState<number>(streamInfo?.reactions_count || 0);

  const onChangeStreamInfo = () => {
    if (!streamInfo) return;
    streamRepository.listReactions(streamInfo?.id, 1, 3).then((reactions) => {
      setReactionList(reactions);
    });
    setIsLiked(!!streamInfo?.liked_by_profile);
  };
  useEffect(onChangeStreamInfo, [streamInfo]);

  const insertProfileReactionAvatar = () => {
    if (!profile?.channel) return;
    reactionList.push(profile?.channel);
    setReactionList([...reactionList]);
  };

  const removeProfileReactionAvatar = () => {
    if (!profile?.channel) return;
    const newReactionList = reactionList.filter((reaction) => reaction.username !== profile?.channel.username);
    setReactionList(newReactionList);
  };

  const onClickLike = async () => {
    const pastIsFollowingState = isLiked;
    const pastIsFollowingCounter = reactionCounter;

    try {
      if (!streamInfo?.id) return;
      if (!isLogged()) return openAuthModalIfItsNotLogged();
      analytics.track(isLiked ? `unlike_stream` : 'like_stream');

      setIsLiked(!isLiked);

      if (pastIsFollowingState) {
        setReactionCounter(reactionCounter - 1);
        removeProfileReactionAvatar();
        await streamRepository.unlike(streamInfo?.id);
      } else {
        setReactionCounter(reactionCounter + 1);
        insertProfileReactionAvatar();
        await streamRepository.like(streamInfo?.id);
      }
    } catch (e) {
      analytics.track(`liked_rollback_${pastIsFollowingState}`);
      setIsLiked(pastIsFollowingState);
      setReactionCounter(pastIsFollowingCounter);
      removeProfileReactionAvatar();
    }
  };

  const onClickShare = () => {
    analytics.track(`clicked_share_modal_stream_view`);
    setIsShareDialogVisible(true);
  };

  return (
    <UserShareLike
      reactionList={reactionList}
      likesCounter={reactionCounter}
      isLiked={isLiked}
      onClickLike={onClickLike}
      onClickShare={onClickShare}
    />
  );
}
