import LiveChipFullSignal from '../../icons/live-chip-full-signal';
import LiveChipNoSignal from '../../icons/live-chip-no-signal';
import LiveChipMidSignal from '../../icons/live-chip-mid-signal';
import Flex from '../../primitives/flex';
import LiveChipLowSignal from '../../icons/live-chip-low-signal';

interface IProps {
  latencyInSeconds: number;
}

const NO_SIGNAL = 0.0;
const LOW_LATENCY_SECONDS = 10;
const MID_LATENCY_SECONDS = 25;
const HIGH_LATENCY_SECONDS = 35;

export default function LiveBadge({ latencyInSeconds }: IProps) {
  const getLatencyIcon = () => {
    if (latencyInSeconds === NO_SIGNAL) return <LiveChipNoSignal />;
    if (latencyInSeconds <= LOW_LATENCY_SECONDS) return <LiveChipFullSignal />;
    if (latencyInSeconds <= MID_LATENCY_SECONDS) return <LiveChipMidSignal />;
    if (latencyInSeconds <= HIGH_LATENCY_SECONDS) return <LiveChipLowSignal />;
    return <LiveChipNoSignal />;
  };

  return (
    <Flex
      className="live-bagde"
      css={{
        position: 'absolute',
        alignItems: 'center',
        left: '$xs',
        top: '$xs',
        '@bp3': {
          left: '0.8rem',
        },
      }}
    >
      {getLatencyIcon()}
    </Flex>
  );
}
