import { useDebounce, useElementSize } from 'usehooks-ts';
import { useRef } from 'react';

import Flex from '../../../../components/primitives/flex';
import { styled } from '../../../../stitches.config';
import useStreamViewStore from '../../state-manager/store';

import StreamInfo from '../../../../components/stream-info/stream-info';
import StreamViewChat from '../stream-view-chat/stream-view-chat';

import { StreamViewUserInfo } from '../stream-view-user-info/stream-view-user-info';
import { StreamViewLike } from '../stream-view-like/stream-view-like';
import { StreamViewMapStream } from '../stream-view-map-stream/stream-view-map-stream';
import skeletonStyle from '../../../../common-styles/skeleton';
import TopHeader from '../../../../components/top-header/top-header';
import { useIsMobile } from '../../../../utils/useIsMobile';
import { StreamViewMap } from '../stream-view-map/stream-view-map';
import StreamViewVideo from '../stream-view-video/stream-view-video';
import { useScroll } from '../../../../hooks/use-scroll/use-scroll';

const Grid = styled(Flex, {
  display: 'grid',

  width: '100%',
  gap: '$xs',
  gridTemplateColumns: '1fr 20rem',
  paddingRight: '0.75rem',
  maxWidth: '100rem',
  margin: '0px auto',

  gridTemplateAreas: `
    "video-and-map chat-container"
    "streamer-info chat-container"
    "stream-info chat-container"
  `,
  '& .header': {
    gridArea: 'header',
  },
  '& .video-and-map': {
    gridArea: 'video-and-map',
    borderRadius: '$2lg',
    width: '100%',
    aspectRatio: '16/9',
    overflow: 'hidden',
    zIndex: 2,
    '&:empty': skeletonStyle,
  },
  '& .video-mobile': {
    minHeight: '18rem',
    zIndex: 2,
    transition: 'all 0.5s',

    '&:empty': skeletonStyle,
  },
  '& .map-mobile': {
    gridArea: 'map-mobile',
    height: '15rem',
    width: 'auto',
    zIndex: 1,
    overflow: 'hidden',
    marginBottom: '$sm',
    '&:empty': skeletonStyle,
  },
  '& .chat-container': {
    gridArea: 'chat-container',
    width: 'auto',
    maxHeight: 'calc(100vh - 6rem)',
    borderRadius: '$2lg',
    zIndex: 1,
    overflow: 'hidden',
    '&:empty': skeletonStyle,
  },
  '& .streamer-info': {
    gridArea: 'streamer-info',
    '&:empty': skeletonStyle,
  },
  '& .stream-info': {
    gridArea: 'stream-info',
    '&:empty': skeletonStyle,
  },

  '@bp3': {
    // overflow: 'hidden',
    // height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0px',
    paddingRight: '0px',
    gap: '0px',
    '& .responsive-margin': {
      marginRight: '$xs',
      marginLeft: '$xs',
    },

    '& .video-mobile': {
      position: 'sticky',
      top: '0rem',
      overflow: 'hidden',
      borderRadius: 0,
    },
    '& .chat-container': {
      marginBottom: '$sm',
      height: '14rem',
    },
  },

  '@b2k': {
    gridTemplateColumns: '1fr 30rem',
    maxWidth: '130rem',
  },

  '@landscape': {
    '& .video-mobile': {
      minHeight: '15rem',
      width: '50%',
      position: 'static',
      marginBottom: '$sm',
      // maxHeight: '8rem',
      // width: '70%',
      // aspectRatio: '16/9',
    },
    '& .map-mobile': {
      position: 'absolute',
      top: '3rem',
      right: 0,
      minHeight: '15rem',
      width: '50%',
    },
  },
});

export function StreamViewGrid() {
  const streamInfo = useStreamViewStore((state) => state.streamInfo);

  const ref = useRef(null);
  const scrollOffsetTop = useScroll(200)?.top || 0;
  const headerHeight = 36;
  const isMobile = useIsMobile();
  const isVideoExpanded = isMobile && scrollOffsetTop >= headerHeight;

  const [elementRef, { width }] = useElementSize();
  const debouncedWidth = useDebounce<number>(width, 500);
  const getWidth = () => {
    if (debouncedWidth) return `${debouncedWidth * 0.5625}px 2.5rem auto`;
    return `28rem 2.5rem auto`;
  };

  return (
    <Flex
      className="stream-view-content"
      css={{
        width: '100%',
      }}
    >
      <Grid
        ref={ref}
        className="stream-view-grid"
        css={{
          gridTemplateRows: getWidth(),

          '&:empty': {
            gridTemplateRows: `28rem 2.5rem auto`,
          },
        }}
      >
        {isMobile && (
          <Flex className="header" css={{ minHeight: '3rem' }}>
            <Flex css={{ position: 'absolute', right: 0, left: -14, zIndex: 3, minHeight: '3rem' }}>
              <TopHeader showShareButton={false} />
            </Flex>
          </Flex>
        )}

        {!isMobile && (
          <Flex className="video-and-map" ref={elementRef}>
            {!!streamInfo?.playback_url && <StreamViewMapStream />}
          </Flex>
        )}

        {isMobile && (
          <Flex
            className="video-mobile"
            css={{
              boxShadow: isVideoExpanded ? '0px 1rem 2rem rgba(28, 28, 31, 0.7)' : 'none',
            }}
          >
            {!!streamInfo?.playback_url && <StreamViewVideo />}
          </Flex>
        )}
        {isMobile && <Flex className="map-mobile">{!!streamInfo?.playback_url && <StreamViewMap />}</Flex>}

        <Flex className="chat-container responsive-margin">{!!streamInfo && <StreamViewChat />}</Flex>
        <Flex
          className="streamer-info responsive-margin"
          css={{
            justifyContent: 'space-between',
            alignItems: 'center',
            '@bp3': {
              flexDirection: 'column-reverse',
              gap: '$xs',
            },
          }}
        >
          {!!streamInfo && <StreamViewUserInfo />}
          {!!streamInfo && <StreamViewLike />}
        </Flex>
        <Flex className="stream-info responsive-margin">{!!streamInfo && <StreamInfo streamInfo={streamInfo} />}</Flex>
      </Grid>
    </Flex>
  );
}
