/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export const createUserName = (email: string): string => {
  const emailSplitted = email.split('@')[0];
  const randomDigits = Math.floor(1000 + Math.random() * 9000);
  return `${emailSplitted}.${randomDigits}`;
};

function hexToRgbA(hex: string, opacity = 1) {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${opacity})`;
  }
  throw new Error('Bad Hex');
}

export function stringToColour(str = 'pulse', opacity = 1) {
  str = '#36A3DC';
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return hexToRgbA(colour, opacity);
}

export function getUniqueListBy(arr: Array<any>, key: string) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export function removeTimeZoneFromTimeStamp(timeStamp: string) {
  return timeStamp.substring(0, 16);
}

dayjs.extend(isBetween);

export function getCleanDate(timeStamp: string | undefined) {
  const todayDate = dayjs().format();
  if (!timeStamp) return removeTimeZoneFromTimeStamp(todayDate).replace(/-/gi, '/');
  return timeStamp.substring(0, 10).replace(/-/gi, '/');
}

export const getTimeStampDate = (timeStamp: string | undefined) => {
  const todayDate = dayjs().format();
  if (!timeStamp) return todayDate;

  return timeStamp.substring(0, 19);
};

export const getTime = (time: string) => dayjs(getTimeStampDate(time)).format('HH:mm').toString();

export function findPosition(element: any): number | undefined {
  let currenttop = 0;
  if (element.offsetParent) {
    do {
      currenttop += element.offsetTop;
      // eslint-disable-next-line no-cond-assign, no-param-reassign
    } while ((element = element.offsetParent));
    return currenttop;
  }
}
export function findHorizontalPosition(element: any): number | undefined {
  let currentleft = 0;
  if (element.offsetParent) {
    do {
      currentleft += element.offsetLeft;
      // eslint-disable-next-line no-cond-assign, no-param-reassign
    } while ((element = element.offsetParent));
    return currentleft;
  }
}

export function scrollToElement(selector: string, elementRef?: Element | null) {
  const element = elementRef || document.querySelector(selector);
  if (!element) return;
  element.scrollIntoView({ behavior: 'smooth' });
}

export function scrollHorizontallyToElement(selector: string, elementRef?: Element | null) {
  const element = elementRef || document.querySelector(selector);
  if (!element) return;
  element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
}

export function scrollParentElement(selector: string) {
  const element = document.querySelector(selector);
  if (!element) return;
  element?.parentElement?.scrollTo(0, findPosition(element) || 0);
}
export function scrollHorizontalParentElementToSelectedItem(element?: Element | null, paddingLeft?: number) {
  if (!element) return;
  const scrollValueToApperOnScreen = findHorizontalPosition(element) || 0;
  const paddingValue = paddingLeft || 0;
  const scrollValueWithPadding = scrollValueToApperOnScreen + paddingValue;
  element?.parentElement?.scrollTo({ left: scrollValueWithPadding, behavior: 'smooth' });
}

// Parameter 4 is a string with two characters; '[' means inclusive, '(' exclusive
// '()' excludes start and end date (default)
// '[]' includes start and end date
// '[)' includes the start date but excludes the stop
// Granuality offers the precision on start and end inclusive checks.
// For example including the start date on day precision you should use 'day' as 3rd parameter.
export function isDateBelongsToDateInterval(
  actualDate: string | null,
  initialDate: string | undefined,
  endDate: string | undefined,
): boolean {
  if (!actualDate || !initialDate || !endDate || !dayjs(actualDate, 'YYYY-MM-DD').isValid()) return false;
  return dayjs(actualDate).isBetween(initialDate, endDate, 'day', '[]');
}

export function makeid(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const transformTextToDash = (value: string) =>
  value
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('-');

export function subtractMinutes(date: Date, minutes: number) {
  date.setMinutes(date.getMinutes() - minutes);

  return date;
}

export function addMinutes(date: Date, minutes: number) {
  date.setMinutes(date.getMinutes() + minutes);

  return date;
}

export function addSeconds(date: Date, seconds: number) {
  date.setSeconds(date.getSeconds() + seconds);

  return date;
}

export function diffInnSecondsBetweenTwoDates(initialDate: Date, endDate: Date) {
  return Math.abs(initialDate.getTime() - endDate.getTime()) / 1000;
}

export function insertOrUpdateObjectInsideArray(arr: any[], obj: any, key: string) {
  if (!obj) return;
  const index = arr.findIndex((item) => item[key] === obj[key]);

  if (index !== -1) {
    arr[index] = obj; // atualiza o objeto existente
  } else {
    arr.push(obj); // adiciona um novo objeto ao array
  }

  return [...arr];
}
