import useEventStore from '../state-manager/store';
import { EventStream } from '../state-manager/store-types';

export function useUpdateMapLiveLocations() {
  const removeLiveUserLocation = useEventStore((state) => state.removeLiveUserLocation);

  const removeStreamMarkerIfItsNotLive = (stream: EventStream) => {
    if (stream.content.duration_in_seconds > 0) {
      removeLiveUserLocation({
        stream_id: stream.id,
        latitude: 0,
        longitude: 0,
        useravatar: stream.content.useravatar || '',
        username: stream.content.username || '',
      });
    }
  };

  const checkAndRemoveMarkerIfItsNotLive = (eventStreams: EventStream[]) => {
    eventStreams.forEach((item) => {
      removeStreamMarkerIfItsNotLive(item);
    });
  };

  return checkAndRemoveMarkerIfItsNotLive;
}
