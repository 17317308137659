/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { create } from 'zustand';
import { Timeline as VisTimeline } from 'vis-timeline';
import { VideoJsPlayer } from 'video.js';
import { MapRef } from 'react-map-gl';
import { GetEventInfo } from '../../../../domain/usecases';
import { makeRemoteGetEventInfo } from '../../../../main/usecases/remote-get-event-factory';
import { TimelineUpdateController } from '../../../components/third-party/timeline/timeline-update-controller';
import { EventPlayStatus, EventStream, IUserLocation } from './store-types';
import { insertOrUpdateObjectInsideArray } from '../../../utils/utils';

interface EventState {
  eventInfo: GetEventInfo.Model | null;
  setEventInfo: (event: GetEventInfo.Model) => void;
  selectLiveStream: (id: string) => void;
  playStatus: EventPlayStatus;
  setPlayStatus: (status: EventPlayStatus) => void;
  isStreamVisible: boolean;
  setIsStreamVisible: (isVisible: boolean) => void;
  eventTimeline: GetEventInfo.TimeLineV2Model | null;
  setEventTimeline: (timeline: GetEventInfo.TimeLineV2Model) => void;
  timelineElement: VisTimeline | null;
  setTimelineElement: (timelineElement: VisTimeline) => void;
  timelineUpdateController: TimelineUpdateController;
  playerElement: VideoJsPlayer | null;
  setPlayerElement: (playerElement: VideoJsPlayer | null) => void;
  eventStreams: EventStream[];
  setEventStreams: (eventStreams: EventStream[]) => void;
  eventChannels: any[];
  setEventChannels: (eventChannels: any[]) => void;
  featStream: EventStream | null;
  setFeatStream: (stream: EventStream | null) => void;
  playingHostStream: EventStream | null;
  setPlayingHostStream: (stream: EventStream | null) => void;
  userLocation: IUserLocation;
  setUserLocation: (location: IUserLocation) => void;
  locationsOfLiveUsers: IUserLocation[];
  setLocationsOfLiveUser: (locations: IUserLocation[]) => void;
  addOrUpdateLiveUserLocation: (location: IUserLocation, eventStreams: EventStream[]) => void;
  removeLiveUserLocation: (location: IUserLocation) => void;
  getLiveStreams: () => EventStream[];
  hasUserLocation: () => boolean;
  mapRef: MapRef | null;
  setMapRef: (map: MapRef | null) => void;
  isAuthDialogVisible: boolean;
  setIsAuthDialogVisible: (isVisible: boolean) => void;
  eventRepository: GetEventInfo;
}

const useEventStore = create<EventState>()((set, get) => ({
  eventInfo: null,
  setEventInfo: (event) => set(() => ({ eventInfo: event })),
  playStatus: EventPlayStatus.IDLE,
  setPlayStatus: (playStatus) => set(() => ({ playStatus })),
  isStreamVisible: true,
  setIsStreamVisible: (isStreamVisible) => set(() => ({ isStreamVisible })),
  timelineElement: null,
  setTimelineElement: (timelineElement) => set(() => ({ timelineElement })),
  timelineUpdateController: new TimelineUpdateController(),
  playerElement: null,
  setPlayerElement: (playerElement) => set(() => ({ playerElement })),
  eventTimeline: null,
  setEventTimeline: (eventTimeline) => set(() => ({ eventTimeline })),
  eventStreams: [],
  setEventStreams: (eventStreams) => set(() => ({ eventStreams })),
  eventChannels: [],
  setEventChannels: (eventChannels) => set(() => ({ eventChannels })),
  userLocation: null,
  setUserLocation: (location) => set(() => ({ userLocation: location })),
  locationsOfLiveUsers: [],
  setLocationsOfLiveUser: (locations) => set(() => ({ locationsOfLiveUsers: locations })),
  addOrUpdateLiveUserLocation: (location: IUserLocation, eventStreams: EventStream[]) => {
    const locations = get().locationsOfLiveUsers;
    const foundStream = eventStreams.find((stream) => stream.id === location?.stream_id);

    // Should I worry about this?
    // Location updates should just come with live streams, right?
    // Maybe I have added this because of asyncrhonism between channel and location updates!?
    if (foundStream && foundStream?.content?.status === 'started') {
      const newLocations = insertOrUpdateObjectInsideArray(locations, location, 'username');
      set(() => ({ locationsOfLiveUsers: newLocations }));
    } else {
      const newLocations = locations?.filter((locationParam) => locationParam?.stream_id !== location?.stream_id);
      set(() => ({ locationsOfLiveUsers: newLocations }));
    }
  },
  removeLiveUserLocation: (location: IUserLocation) => {
    const locations = get().locationsOfLiveUsers;
    const newLocations = locations.filter(
      (paramLocation: IUserLocation) => paramLocation?.username !== location?.username,
    );
    return set(() => ({ locationsOfLiveUsers: newLocations }));
  },
  getLiveStreams: () => {
    const streams = get().eventStreams;
    return streams.filter((stream) => stream.content.status === 'started' && !stream.isProducer);
  },
  hasUserLocation: () => !!get().userLocation,
  featStream: null,
  setFeatStream: (featStream) => set(() => ({ featStream })),
  playingHostStream: null,
  setPlayingHostStream: (playingHostStream) => set(() => ({ playingHostStream })),
  mapRef: null,
  setMapRef: (mapRef) => set(() => ({ mapRef })),
  isAuthDialogVisible: false,
  setIsAuthDialogVisible: (isAuthDialogVisible) => set(() => ({ isAuthDialogVisible })),
  eventRepository: makeRemoteGetEventInfo(),
  selectLiveStream: (id: string) => {
    const foundStream = get().eventStreams.find((stream) => stream.id === id);
    if (!foundStream) return;

    const timeoffsetInSeconds = -1;
    const currentFeatStream = { ...foundStream, timeoffsetInSeconds };
    get().setPlayStatus(EventPlayStatus.LIVE);

    get().setFeatStream(currentFeatStream || null);
    setTimeout(() => get().timelineElement?.focus(currentFeatStream.id), 1000);
  },
}));

export default useEventStore;
