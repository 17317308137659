export function CabinIcon() {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.99994 18H16.9999V16H4.99994V18ZM4.99994 14H16.9999V12H4.99994V14ZM4.99994 10H16.9999V9.1L15.5499 8H6.44993L4.99994 9.1V10ZM9.04994 6H12.9499L10.9999 4.525L9.04994 6ZM3.99993 20C3.7166 20 3.47894 19.904 3.28694 19.712C3.0956 19.5207 2.99993 19.2833 2.99993 19V10.625L1.99993 11.4C1.7666 11.5667 1.5166 11.6333 1.24993 11.6C0.983268 11.5667 0.766602 11.4333 0.599935 11.2C0.433268 10.9667 0.366602 10.7167 0.399935 10.45C0.433268 10.1833 0.558268 9.96667 0.774935 9.8L2.99993 8.1V6C2.99993 5.71667 3.0956 5.479 3.28694 5.287C3.47894 5.09567 3.7166 5 3.99993 5C4.28327 5 4.52094 5.09567 4.71293 5.287C4.90427 5.479 4.99994 5.71667 4.99994 6V6.575L10.3999 2.475C10.4833 2.40833 10.5749 2.354 10.6749 2.312C10.7749 2.27067 10.8833 2.25 10.9999 2.25C11.1166 2.25 11.2249 2.27067 11.3249 2.312C11.4249 2.354 11.5166 2.40833 11.5999 2.475L21.2249 9.8C21.4416 9.96667 21.5666 10.1833 21.5999 10.45C21.6333 10.7167 21.5666 10.9667 21.3999 11.2C21.2333 11.4167 21.0166 11.5417 20.7499 11.575C20.4833 11.6083 20.2416 11.5417 20.0249 11.375L18.9999 10.625V19C18.9999 19.2833 18.9039 19.5207 18.7119 19.712C18.5206 19.904 18.2833 20 17.9999 20H3.99993ZM4.29994 4C3.93327 4 3.63727 3.84567 3.41194 3.537C3.18727 3.229 3.17493 2.9 3.37493 2.55C3.6416 2.08333 4.00427 1.70833 4.46293 1.425C4.92094 1.14167 5.43327 1 5.99994 1C6.18327 1 6.35827 0.958333 6.52494 0.875C6.6916 0.791667 6.8166 0.658333 6.89994 0.475C6.98327 0.325 7.09593 0.208333 7.23794 0.125C7.37927 0.0416667 7.5416 0 7.72494 0C8.10827 0 8.39994 0.158333 8.59994 0.475C8.79994 0.791667 8.80827 1.11667 8.62494 1.45C8.35827 1.91667 7.9956 2.29167 7.53693 2.575C7.07893 2.85833 6.5666 3 5.99994 3C5.8166 3 5.6416 3.04567 5.47494 3.137C5.30827 3.229 5.18327 3.35833 5.09994 3.525C5.0166 3.69167 4.89994 3.81233 4.74994 3.887C4.59994 3.96233 4.44993 4 4.29994 4Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>
  );
}
