/* eslint-disable import/export */
import { AccessDeniedError, UnexpectedError } from '../../domain/errors';
import { ChannelRepository } from '../../domain/usecases/channel';
import { StreamRepository } from '../../domain/usecases/stream';
import { HttpStatusCode, HttpClient } from '../protocols/http';

export class RemoteChannel implements ChannelRepository {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<StreamRepository.Model | any>) {}

  async follow(channelId: string): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${channelId}/follow`,
      method: 'post',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }

  async unfollow(channelId: string): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${channelId}/follow`,
      method: 'delete',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteChannel {
  export type Model = ChannelRepository.Model;
}
