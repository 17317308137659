/* eslint-disable no-promise-executor-return */
import ReactDOM from 'react-dom';
import { styled } from '../../stitches.config';
import useEventStore from '../../pages/event/state-manager/store';
import Flex from '../primitives/flex';
import { EventStreamOrImageThumbnail } from '../../pages/event/components/event-stream-or-image-thumbnail/event-stream-or-image-thumbnail';
import { calculateStreamProgress, isLive } from '../../pages/event/utils/utils';
import { NewEventStream } from '../../pages/event/state-manager/store-types';
import { Typo } from '../primitives/typo/typo';
import { EventAvatarWithTooltip } from '../event-avatar-with-tooltip/event-avatar-with-tooltip';

interface IProps {
  previewLeft: number | null;
  onMouseLeave: () => void;
}

const SHoverPreview = styled('div', {
  position: 'absolute',
  bottom: '97px',
  height: 'auto',
  width: 'auto',
  zIndex: 1000000,
  // maxWidth: '40%',
  transform: 'translateX(-50%)',
});

const PreviewContent = styled(Flex, {
  // background: '$blackTranslucentGlass',
  // background: 'red',
  backdropFilter: 'blur(3px)',
  borderRadius: '8px',
  height: 'auto',
  width: 'auto',
  gap: '$nano',
  padding: '$3xs',
  flexDirection: 'column',
});

const Button = styled('button', {});

export function HoverPreview({ previewLeft, onMouseLeave }: IProps) {
  const mainContentElement = useEventStore((state) => state.mainContentElement);
  const hoverEventStreamsLinearList = useEventStore((state) => state.hoverEventStreamsLinearList)?.sort((a, b) => {
    if (b?.official === a?.official) return 0; // they're equal, return 0
    return b?.official ? 1 : -1; // if b is true, it will be placed before a
  });

  const currentTime = useEventStore((state) => state.currentTime);
  const hoverTime = useEventStore((state) => state.hoverTime);
  // const setRightSideStream = useEventStore((state) => state.setRightSideStream);
  const featStream = useEventStore((state) => state.featStream);
  const setFeatStream = useEventStore((state) => state.setFeatStream);
  const setCurrentTime = useEventStore((state) => state.setCurrentTime);

  const selectFeatStream = async (stream: NewEventStream) => {
    setCurrentTime(currentTime);

    // if (stream.producer) {
    //   return setRightSideStream(stream);
    // }
    setFeatStream(stream);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  if (!mainContentElement || !hoverEventStreamsLinearList.length) return null;
  return ReactDOM.createPortal(
    <SHoverPreview css={{ left: `${previewLeft}px` }} onMouseMove={handleMouseMove} onMouseLeave={onMouseLeave}>
      <PreviewContent>
        <Flex css={{ gap: '$3xs', marginBottom: '0.5rem' }}>
          <Typo css={{ color: '$white', fontSize: '0.8rem' }}>Streams at</Typo>
          <Flex css={{ backgroundColor: '$white', borderRadius: '20px', padding: '$nano', marginTop: '-0.2rem' }}>
            <Typo css={{ fontSize: '0.8rem' }}>
              {String(hoverTime?.getHours()).padStart(2, '0')}:{String(hoverTime?.getMinutes()).padStart(2, '0')}:
              {String(hoverTime?.getSeconds()).padStart(2, '0')}
            </Typo>
          </Flex>
        </Flex>
        <Flex css={{ gap: '1.3rem', flexDirection: 'column' }}>
          {currentTime &&
            hoverEventStreamsLinearList.map((stream) => {
              const {
                id,
                title,
                avatarPicture,
                channel,
                // stream_tile_url,
                // stream_start_date_ms,
                // stream_end_date_ms,
                status,
              } = stream;
              const { id: selectedStreamId } = featStream || {};

              return (
                <EventAvatarWithTooltip
                  key={id}
                  imageUrl={avatarPicture || ''}
                  title={channel.title || stream.title}
                  subtitle={channel?.username}
                  onClick={() => selectFeatStream(stream)}
                  isSelected={selectedStreamId === id}
                  isLive={status === 'started'}
                />
                // <Button
                //   onClick={() => {
                //     selectFeatStream(stream);
                //   }}
                //   key={id}
                // >
                //   <EventStreamOrImageThumbnail
                //     avatarImgUrl={avatarPicture}
                //     imageUrl={`${stream_tile_url}?timestamp=${hoverTime?.getTime()}&start_time=${stream_start_date_ms}`}
                //     username={stream.channel.username || ''}
                //     isLive={isLive(status)}
                //     title={title}
                //     stream={stream}
                //     progress={calculateStreamProgress(
                //       stream_start_date_ms,
                //       stream_end_date_ms,
                //       hoverTime && hoverEventStreamsLinearList.length
                //         ? hoverTime.getTime()
                //         : currentTime?.getTime() || 0,
                //     )}
                //     isSelected={id === selectedStreamId}
                //     css={{ width: '8.12rem', height: '5.37rem' }}
                //   />
                // </Button>
              );
            })}
        </Flex>
      </PreviewContent>
    </SHoverPreview>,
    mainContentElement,
  );
}
