/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';

import type { MapRef } from 'react-map-gl';

import MAP_STYLE from './map-style';

import { RealTimePosition } from '../../../pages/event-v1/context';
import Flex from '../../primitives/flex';
import Marker from './marker';
import { StreamModel } from '../../../../domain/models/stream-model';
import GenericMapMarker from './map-marker-generic';
import { IUserLocation } from '../../../pages/stream-view/state-manager/store';
import { MapControls } from './map-controls';
import GenericMapMarkerEvent from './map-marker-generic-event';

interface IProps {
  onMapReady?: (mapRef: MapRef | null) => void;
  hasNativeControls?: boolean;
  mapName?: string;
  isMapFollowLocked?: boolean;
  isMobile?: boolean;
  setIsMapFollowLocked?: Dispatch<SetStateAction<boolean>>;
  realTimeAthletePositions?: Map<string, RealTimePosition>;
  athletePositionsOnHover?: Map<string, RealTimePosition>;
  singleAthletePosition?: IUserLocation;
  athletePositions?: IUserLocation[];
  onSelectAthlete?: (realTimePosition: RealTimePosition | undefined, isSelected: boolean) => void;
  featuredStream?: Partial<StreamModel> | null;
  mapCenter?: [lng: number, lat: number];
  onZoomIn?: () => void;
  mapStyle?: string | null;
  accessToken?: string | null;
  onZoomOut?: () => void;
  onMarkerClick?: (location: IUserLocation) => void;
  onChangeTo2D?: () => void;
  onChangeTo3D?: () => void;
  onLockOnUserPosition?: () => void;
  mapControlsCss?: any;
}

// let map: any;
const TOKEN = import.meta.env.VITE_MAP_BOX_ID;
let isRotatingRef = false;
let actualAnimationFrame = 0;
let lastRotationAngle = 0;

export default function MapBoxGeneric({
  onMapReady,
  hasNativeControls,
  mapName = 'map',
  isMapFollowLocked,
  setIsMapFollowLocked,
  realTimeAthletePositions,
  athletePositionsOnHover,
  singleAthletePosition,
  athletePositions,
  onSelectAthlete,
  featuredStream,
  isMobile = false,
  mapCenter = [0, 0],
  onMarkerClick,
  onZoomIn,
  onZoomOut,
  onChangeTo2D,
  onChangeTo3D,
  onLockOnUserPosition,
  mapStyle,
  accessToken,
  mapControlsCss,
}: IProps) {
  const map = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [flyDuration, setFlyDuration] = useState<number>(0);
  const [isRotating, setIsRotating] = useState<boolean>(false);

  const initializeMapBox = () => {
    mapboxgl.accessToken = accessToken || TOKEN;
    map.current = new mapboxgl.Map({
      container: mapName,
      center: mapCenter,
      pitch: isMobile ? 0 : 58,
      bearing: 0,
      zoom: 15,
      cooperativeGestures: true,
      // style: 'mapbox://styles/mapbox/satellite-streets-v12?optimize=true',
      // @ts-ignore
      style: mapStyle || MAP_STYLE,
      attributionControl: false,
    });
  };

  const add3dMapSource = () => {
    map.current.addSource('mapbox-dem', {
      type: 'raster-dem',
      url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
      tileSize: 512,
      maxzoom: 14,
    });
    map.current.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
    map.current.setFog({
      range: [10, 20],
      color: '#dc9f9f',
      'horizon-blend': 0.5,
      'high-color': '#245bde',
      'space-color': '#000000',
      'star-intensity': 0.15,
    });
  };

  const rotateCamera = (timestamp: number) => {
    if (!isRotatingRef) return;

    // clamp the rotation between 0 -360 degrees
    // Divide timestamp by 100 to slow rotation to ~10 degrees / sec
    lastRotationAngle = (timestamp / 300) % 360;
    map.current.rotateTo(lastRotationAngle, { duration: 0, essential: true });
    // Request the next frame of the animation.
    actualAnimationFrame = requestAnimationFrame(rotateCamera);
  };

  const cancelRotation = () => {
    cancelAnimationFrame(actualAnimationFrame);
    isRotatingRef = false;
    setIsRotating(false);
  };

  const onFlyAround = () => {
    if (!isRotatingRef) {
      setIsRotating(true);
      isRotatingRef = true;
      rotateCamera(lastRotationAngle);
    } else {
      cancelRotation();
    }
  };

  const onInteractWithMap = () => {
    cancelRotation();
    if (setIsMapFollowLocked) setIsMapFollowLocked(false);
  };

  const onMount = () => {
    initializeMapBox();
    map.current.on('load', () => {
      if (onMapReady) onMapReady(map.current);
      add3dMapSource();
      setFlyDuration(4000);
    });
    map.current.on('dragstart', onInteractWithMap);
    map.current.on('wheel', onInteractWithMap);
    map.current.on('pitchstart', onInteractWithMap);

    if (hasNativeControls) map.current.addControl(new mapboxgl.NavigationControl());

    return () => map.current.remove();
  };
  useEffect(onMount, []);

  const selectAthelete = (realTimePosition: RealTimePosition | undefined, isSelected: boolean) => {
    if (onSelectAthlete) onSelectAthlete(realTimePosition, isSelected);
  };

  const followSelectedStream = () => {
    if (!map || !isMapFollowLocked) return;
    if (!singleAthletePosition?.latitude || !singleAthletePosition.longitude) return;

    map.current.jumpTo({
      center: [singleAthletePosition.longitude, singleAthletePosition?.latitude],
      zoom: map.current.getZoom()?.toFixed(0),
    });
  };
  useEffect(followSelectedStream, [singleAthletePosition]);

  const followSingleAthleteStream = () => {
    if (!map || !isMapFollowLocked) return;
    const id = featuredStream?.id;
    if (id) {
      const location = realTimeAthletePositions?.get(id);
      if (!location?.latitude || !location.longitude) return;
      map.current.jumpTo({
        center: [location.longitude, location?.latitude],
        zoom: map.current.getZoom()?.toFixed(0),
      });
    }
  };
  useEffect(followSingleAthleteStream, [realTimeAthletePositions]);

  const goToUserLockPosition = () => {
    if (!map) return;
    const eventStreamId = featuredStream?.id;
    let position = mapCenter;

    if (eventStreamId) {
      const location = realTimeAthletePositions?.get(eventStreamId || '');
      position = [location?.longitude || mapCenter[0], location?.latitude || mapCenter[1]];
    } else {
      if (!singleAthletePosition?.latitude || !singleAthletePosition.longitude) return;
      position = [singleAthletePosition.longitude, singleAthletePosition?.latitude];
    }

    map.current.jumpTo({
      center: position,
      zoom: 16,
    });
  };

  const onUserClickToLockPosition = () => {
    if (onLockOnUserPosition) onLockOnUserPosition();
    if (setIsMapFollowLocked) setIsMapFollowLocked(true);
    goToUserLockPosition();
  };

  const lockMapFollowToSelectedStream = () => {
    if (!featuredStream || !setIsMapFollowLocked) return;
    setIsMapFollowLocked(true);
  };
  useEffect(lockMapFollowToSelectedStream, [featuredStream]);

  const realTimeAthletePositionsIdKeys = realTimeAthletePositions ? Array.from(realTimeAthletePositions.keys()) : [];
  const realTimeAthletePositionsIdKeysOnHover = athletePositionsOnHover
    ? Array.from(athletePositionsOnHover.keys())
    : [];

  return (
    <Flex
      css={{
        height: '100%',
        width: '100%',
        '& .map-wrapper': { height: '100%', width: '100%' },
        '& .mapboxgl-ctrl-logo': {
          display: 'none !important',
        },
        position: 'relative',
      }}
    >
      <Flex id={mapName} className="map-wrapper">
        {map.current && singleAthletePosition && (
          <Marker
            longitude={singleAthletePosition?.longitude || 0}
            latitude={singleAthletePosition.latitude || 0}
            map={map.current}
            mapLib={mapboxgl}
            offset={[0, -43]}
          >
            <GenericMapMarker
              isSelected
              username={singleAthletePosition.username}
              useravatar={singleAthletePosition.useravatar}
            />
          </Marker>
        )}

        {map.current &&
          athletePositions &&
          athletePositions.map((position) => (
            <Marker
              longitude={position?.longitude || 0}
              latitude={position?.latitude || 0}
              map={map.current}
              mapLib={mapboxgl}
              key={position?.longitude}
              offset={[0, -33]}
            >
              <GenericMapMarker
                username={position?.username || ''}
                useravatar={position?.useravatar || ''}
                onClick={() => onMarkerClick && onMarkerClick(position)}
              />
            </Marker>
          ))}

        {map.current &&
          realTimeAthletePositionsIdKeys.map((key) => (
            <Marker
              key={key + mapName}
              longitude={realTimeAthletePositions?.get(key)?.longitude || 0}
              latitude={realTimeAthletePositions?.get(key)?.latitude || 0}
              map={map.current}
              mapLib={mapboxgl}
              offset={[0, -38]}
            >
              <GenericMapMarker
                isSelected={realTimeAthletePositions?.get(key)?.id === featuredStream?.id}
                isHidden={!!realTimeAthletePositionsIdKeysOnHover.length}
                username={realTimeAthletePositions?.get(key)?.username || ''}
                useravatar={realTimeAthletePositions?.get(key)?.useravatar || ''}
                onClick={() =>
                  selectAthelete(
                    realTimeAthletePositions?.get(key),
                    realTimeAthletePositions?.get(key)?.id === featuredStream?.id,
                  )
                }
              />
            </Marker>
          ))}

        {map.current &&
          realTimeAthletePositionsIdKeysOnHover.map((key) => (
            <Marker
              key={key}
              longitude={athletePositionsOnHover?.get(key)?.longitude || 0}
              latitude={athletePositionsOnHover?.get(key)?.latitude || 0}
              map={map.current}
              mapLib={mapboxgl}
              offset={[0, -38]}
            >
              <GenericMapMarker
                isPreviewLocation
                username={athletePositionsOnHover?.get(key)?.username || ''}
                useravatar={athletePositionsOnHover?.get(key)?.useravatar || ''}
                isSelected={athletePositionsOnHover?.get(key)?.id === featuredStream?.id}
              />
            </Marker>
          ))}

        {!!map.current && (
          <MapControls
            mapRef={map.current}
            setIsMapFollowLocked={setIsMapFollowLocked}
            isMapFollowLocked={isMapFollowLocked}
            onZoomIn={onZoomIn}
            onZoomOut={onZoomOut}
            onChangeTo2D={onChangeTo2D}
            onChangeTo3D={onChangeTo3D}
            onLockOnUserPosition={onUserClickToLockPosition}
            onFlyAround={onFlyAround}
            isRotating={isRotating}
            css={mapControlsCss}
          />
        )}
      </Flex>
    </Flex>
  );
}
