import { useState } from 'react';
import Flex from '../../../../components/primitives/flex';
import { Text } from '../../../../components/primitives/text/text';
import { useIsMobile } from '../../../../utils/useIsMobile';
import useEventStore from '../../state-manager/store';
import Box from '../../../../components/primitives/box';

const STREAM_DESC_MAX_LENGTH = 162;

export default function EventMoreInfo() {
  const eventInfo = useEventStore((state) => state.eventInfo);
  const isMobile = useIsMobile();
  const getDescInitialState = () => {
    if (!eventInfo?.description?.length) return false;
    return eventInfo?.description.length < STREAM_DESC_MAX_LENGTH;
  };
  const [isFullEventDescVisible, setIsFullEventDescVisible] = useState<boolean>(getDescInitialState());

  const getEventDesc = () => {
    if (!eventInfo?.description) return '';
    if (!isMobile) return eventInfo?.description;
    return isFullEventDescVisible
      ? eventInfo?.description
      : `${eventInfo?.description.slice(0, STREAM_DESC_MAX_LENGTH)}...`;
  };

  const showExpandDescButton = () => {
    if (!eventInfo?.description) return false;
    return isMobile && eventInfo?.description.length > STREAM_DESC_MAX_LENGTH;
  };

  return (
    <Flex
      className="event-more-info-wrapper"
      css={{
        flexDirection: 'row',
        gap: '$4xl',
        alignItems: 'flex-start',
        justifyContent: 'center',
        maxWidth: '64rem',
        marginTop: '2rem',
        paddingBottom: '10rem',
        '@bp3': {
          maxWidth: '100%',
        },
      }}
    >
      <Flex className="event-title" css={{ alignItems: 'flex-start' }}>
        <Flex
          className="event-name"
          css={{
            gap: '$xs',
            // alignItems: 'flex-start',
            width: '100%',
          }}
        >
          {eventInfo?.photo?.original && (
            <Box
              as="img"
              src={eventInfo?.photo?.original}
              alt="event logo"
              css={{
                width: '12rem',
                borderRadius: '$sm',
                overflow: 'hidden',
                objectFit: 'contain',
                '@bp3': {
                  width: '4rem',
                  height: '2.8rem',
                  borderRadius: '$lg',
                },
              }}
            />
          )}

          <Flex
            css={{
              alignItems: 'center',
              gap: '$3xs',
              width: '100%',
            }}
          >
            <Text
              as="h5"
              type="h5"
              className="event-text"
              css={{
                width: '100%',
                overflow: 'hidden',
                color: '$white',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                '@bp3': {
                  fontSize: '$h6',
                  width: '90%',
                },
              }}
            >
              {eventInfo?.title}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex className="event-info" css={{ flexDirection: 'column', gap: '$xs' }}>
        {/* <Flex className="event-date-range" css={{ gap: '$3xs' }}>
          <Text css={{ color: '$charcoal', fontSize: '14px', fontWeight: 'bold' }}>
            {dayjs(eventInfo?.start_time).format('DD MMM YYYY')}
          </Text>
          <Text css={{ color: '$gunMetal', fontSize: '14px' }}>until</Text>
          <Text css={{ color: '$charcoal', fontSize: '14px', fontWeight: 'bold' }}>
            {eventInfo?.end_time ? dayjs(eventInfo?.end_time).format('DD MMM YYYY') : dayjs().format('DD MMM YYYY')}
          </Text>
        </Flex> */}
        {eventInfo?.description && (
          <Text className="event-desc" css={{ color: '$white', lineHeight: '23px' }}>
            {getEventDesc()}
          </Text>
        )}

        {showExpandDescButton() && (
          <Text
            css={{
              color: '$gs400',
              lineHeight: '1.43rem',
              alignSelf: 'flex-start',
              marginRight: '10px',
              marginTop: '5px',
            }}
            onClick={() => setIsFullEventDescVisible(!isFullEventDescVisible)}
          >
            {isFullEventDescVisible ? 'Show less' : 'Read full description'}
          </Text>
        )}

        {/* {eventInfo?.more_info_url && (
          <Button
            as="a"
            target="_blank"
            href={eventInfo?.more_info_url}
            variant="transparentNoHover"
            css={{ width: 'fit-content' }}
          >
            Learn more
          </Button>
        )} */}
      </Flex>
    </Flex>
  );
}
