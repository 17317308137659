import { ReactElement } from 'react';
import { Text } from '../../../../components/primitives/text/text';
import { styled } from '../../../../stitches.config';

const Button = styled('button', {
  background: 'rgba(225, 247, 170, 0.6)',
  minWidth: '2rem',
  height: '1.81rem',
  borderRadius: '6.25rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 0.5rem',
  gap: '$nano',
  cursor: 'pointer',
  transition: 'all .5s ease',
  variants: {
    isSelected: {
      true: {
        background: '$eletricLimeFluorescent',
        border: '2px solid $lynx',
      },
      false: {},
    },
    isLive: {
      true: {
        border: '1px solid $radicalCherryDark',
      },
    },
  },

  '&:active': {
    transform: 'scale(.98)',
  },
  '@hover': {
    '&:hover': {
      transform: 'scale(.96)',
    },
  },
});

interface IProps {
  isSelected?: boolean;
  label?: string;
  date: string;
  onClick: (date: string) => void;
  children?: ReactElement;
  isLive?: boolean;
}

export function DatePill({ isSelected, label, date, onClick, children, isLive }: IProps) {
  return (
    <Button onClick={() => onClick(date)} isSelected={isSelected} isLive={isLive}>
      {children}
      <Text css={{ color: 'rgba(0, 0, 0, 0.7)', fontWeight: '$demi', whiteSpace: 'nowrap' }}>{label}</Text>
      <Text css={{ color: 'rgba(0, 0, 0, 0.5)', fontWeight: '$demi', whiteSpace: 'nowrap' }}>{date}</Text>
    </Button>
  );
}
