import { useFullscreen } from 'ahooks';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { SpeedType } from '../../../domain/models/stream-model';
import FullScreenIcon from '../icons/full-screen';
import FullScreenExitIcon from '../icons/full-screen-exit';
import PictureAndPictureIcon from '../icons/picture-and-picture';
import PictureAndPictureRightIcon from '../icons/picture-and-picture-right';
import { UnionLeftIcon } from '../icons/union-left';
import { UnionRightIcon } from '../icons/union-right';
import { ButtonTooltip } from '../primitives/button-tooltip/button-tooltip';
import Flex from '../primitives/flex';
import { GpsMetaData } from '../third-party/video-stream/gps-type';
import NewTelemetryBadge from '../third-party/video-stream/new-telemetry-badge';
import { StyledWrapper } from './style';

export enum ContainerState {
  MapFull,
  StreamFull,
  Half,
}

interface ITelemetryComponentData {
  gpsData: GpsMetaData | null;
  username: string;
  useravatar: string;
  speedType?: SpeedType;
  showAtheleteThumbnail?: boolean;
  onGetContainerControl?: (setState: any) => void;
}

interface IProps {
  mapComponent: ReactElement | undefined | null;
  streamElement: ReactElement | undefined;
  onAnimationEnds?: () => void;
  onGetContainerControl?: (setState: any) => void;
  onReceiveFullScreenMethods?: (fullScreenControl: { isFullScreen: boolean; toggleFullscreen: () => void }) => void;
  onChangeContainerState?: (state: ContainerState) => void;
  telemetryData?: ITelemetryComponentData;
  mapPipCss?: any;
}

export default function GenericStreamMap({
  mapComponent,
  streamElement,
  onAnimationEnds,
  onChangeContainerState,
  onReceiveFullScreenMethods,
  onGetContainerControl,
  telemetryData,
  mapPipCss = {},
}: IProps) {
  const [wrapperState, setWrapperState] = useState<ContainerState>(ContainerState.StreamFull);
  const [isVideoStreamClosed, setIsVideoStreamClosed] = useState<boolean>(false);
  const [isMapClosed, setIsMapClosed] = useState<boolean>(false);
  const [isMapColapsed, setIsMapColapsed] = useState<boolean>(false);

  const elementRef = useRef(null);

  const [isFullScreen, { toggleFullscreen }] = useFullscreen(elementRef);

  useEffect(() => {
    if (onReceiveFullScreenMethods) onReceiveFullScreenMethods({ isFullScreen, toggleFullscreen });
    if (onGetContainerControl) onGetContainerControl(setWrapperState);
  }, [isFullScreen]);

  const getWidth = (type: ContainerState) => {
    switch (wrapperState) {
      case ContainerState.Half:
        return '50%';
      case type:
        return '100%';
      default:
        return '0%';
    }
  };

  const onChangeWrapperState = () => {
    if (onChangeContainerState) onChangeContainerState(wrapperState);
  };
  useEffect(onChangeWrapperState, [wrapperState]);

  const closePictureAndPictureMode = () => {
    if (wrapperState === ContainerState.MapFull) {
      setIsVideoStreamClosed(false);
    }
    if (wrapperState === ContainerState.StreamFull) {
      setIsMapClosed(false);
    }
  };

  const toggleStreamWrapper = () => {
    closePictureAndPictureMode();
    if (wrapperState === ContainerState.StreamFull || wrapperState === ContainerState.MapFull)
      return setWrapperState(ContainerState.Half);
    setWrapperState(ContainerState.StreamFull);
  };

  const toggleMapWrapper = () => {
    closePictureAndPictureMode();
    if (wrapperState === ContainerState.MapFull || wrapperState === ContainerState.StreamFull) {
      return setWrapperState(ContainerState.Half);
    }
    setWrapperState(ContainerState.MapFull);
  };

  const onTransitionEnds = () => {
    if (onAnimationEnds) onAnimationEnds();
    if (wrapperState === ContainerState.MapFull) return setIsVideoStreamClosed(true);
    if (wrapperState === ContainerState.StreamFull) return setIsMapClosed(true);
    setIsVideoStreamClosed(false);
    setIsMapClosed(false);
  };

  return (
    <StyledWrapper className="video-and-map-wrapper" ref={elementRef}>
      <Flex
        className={`video-wrapper ${isVideoStreamClosed && 'video-closed'}`}
        css={{
          width: getWidth(ContainerState.StreamFull),
          justifyContent: 'center',
          backgroundColor: 'black',
          '&.video-closed': {
            width: isFullScreen ? '28rem' : '18.37rem',
            height: isFullScreen ? '30rem' : '19.87rem',
          },
        }}
        onTransitionEnd={onTransitionEnds}
      >
        {mapComponent && (
          <ButtonTooltip content={wrapperState !== ContainerState.Half ? 'Split screen' : 'Enlarge stream'}>
            <Flex
              className={`video-stream-picture-and-picture ${
                wrapperState === ContainerState.StreamFull && 'video-opened'
              }`}
              onClick={toggleStreamWrapper}
            >
              {wrapperState !== ContainerState.Half ? <UnionLeftIcon /> : <PictureAndPictureRightIcon />}
            </Flex>
          </ButtonTooltip>
        )}

        <Flex
          className="stream-aspect-ratio-wrapper"
          css={{ width: `100%`, alignItems: 'center', justifyContent: 'center' }}
        >
          {streamElement}
        </Flex>
      </Flex>

      {mapComponent && (
        <Flex
          className={`map-wrapper ${isMapClosed && 'map-closed'}`}
          css={{
            width: getWidth(ContainerState.MapFull),
            '&.map-closed': {
              width: isFullScreen ? '23rem' : '16.5rem',
              height: isFullScreen ? '26rem' : '3.8rem',
              opacity: isMapClosed ? 0.1 : 1,
              border: isMapClosed ? 'none' : '2px solid $primary500',
              display: 'none',
              ...mapPipCss,
            },
          }}
          onTransitionEnd={onTransitionEnds}
        >
          <ButtonTooltip content="Fullscreen">
            <Flex className={`map-full-screen ${isMapClosed && 'map-closed'}`} onClick={toggleFullscreen}>
              {isFullScreen ? <FullScreenExitIcon /> : <FullScreenIcon />}
            </Flex>
          </ButtonTooltip>

          <ButtonTooltip content={wrapperState !== ContainerState.Half ? 'Split screen' : 'Enlarge map'}>
            <Flex className={`map-picture-and-picture ${isMapClosed && 'map-closed'}`} onClick={toggleMapWrapper}>
              {wrapperState !== ContainerState.Half ? <UnionRightIcon /> : <PictureAndPictureIcon />}
            </Flex>
          </ButtonTooltip>

          {/* <ButtonTooltip content={wrapperState !== ContainerState.Half ? 'Split screen' : 'Enlarge map'}>
            <Flex
              className={`map-picture-and-picture ${isMapClosed && 'map-closed'}`}
              onClick={() => setIsMapColapsed(!isMapColapsed)}
            >
              {isMapColapsed ? <UnfoldMoreIcon /> : <CloseCrossIcon />}
            </Flex>
          </ButtonTooltip> */}

          {mapComponent}

          {wrapperState === ContainerState.MapFull && telemetryData && (
            <NewTelemetryBadge
              gpsData={telemetryData?.gpsData}
              hasBackDrop
              speedType={telemetryData?.speedType}
              isFullScreen={isFullScreen}
              isVideoStreamClosed={isVideoStreamClosed}
            />
          )}
        </Flex>
      )}
    </StyledWrapper>
  );
}
