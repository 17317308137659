export function StartIcon() {
  return (
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3 11.3C13.1 11.1 13 10.8623 13 10.587C13 10.3123 13.1 10.075 13.3 9.875L16.175 7H5C4.71667 7 4.479 6.904 4.287 6.712C4.09567 6.52067 4 6.28333 4 6C4 5.71667 4.09567 5.479 4.287 5.287C4.479 5.09567 4.71667 5 5 5H16.175L13.3 2.1C13.1167 1.91667 13.0207 1.68733 13.012 1.412C13.004 1.13733 13.1 0.9 13.3 0.7C13.4833 0.516666 13.7167 0.425 14 0.425C14.2833 0.425 14.5167 0.516666 14.7 0.7L19.3 5.3C19.4 5.4 19.471 5.50833 19.513 5.625C19.5543 5.74167 19.575 5.86667 19.575 6C19.575 6.13333 19.5543 6.25833 19.513 6.375C19.471 6.49167 19.4 6.6 19.3 6.7L14.7 11.3C14.5167 11.4833 14.2877 11.575 14.013 11.575C13.7377 11.575 13.5 11.4833 13.3 11.3ZM1 12C0.716667 12 0.479333 11.904 0.288 11.712C0.0960001 11.5207 0 11.2833 0 11V1C0 0.716667 0.0960001 0.479 0.288 0.287C0.479333 0.0956668 0.716667 0 1 0C1.28333 0 1.521 0.0956668 1.713 0.287C1.90433 0.479 2 0.716667 2 1V11C2 11.2833 1.90433 11.5207 1.713 11.712C1.521 11.904 1.28333 12 1 12Z"
        fill="white"
        fillOpacity="0.42"
      />
    </svg>
  );
}
