/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useMediaQuery } from 'usehooks-ts';
import DesktopTimelineEvent from './desktop-timeline-event';
import { ISquadVideoContext, SquadVideoContext } from '../../context';
import MobileTimeLineEvent from './mobile-timeline-event';
import {
  getTime,
  isDateBelongsToDateInterval,
  removeTimeZoneFromTimeStamp,
  scrollParentElement,
  scrollToElement,
} from '../../../../utils/utils';

interface IProps {
  onSelectStream?: () => void;
}

export default function TimelineEvent({ onSelectStream }: IProps) {
  const {
    timeline,
    eventInfo,
    setSelectedDate,
    isTimelineLoading,
    setSelectedTime,
    setRealTimeAthletePositions,
    athletePositionsOnHover,
    realTimeAthletePositions,
    isLiveSelected,
    liveStreams,
    openLiveStream,
    userMarketingEventTrack,
  } = useContext<ISquadVideoContext>(SquadVideoContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [localStateSelectedDate, setLocalStateSelectedDate] = useState<string | null>(null);
  const [localStateSelectedTime, setLocalStateSelectedTime] = useState<string | null>(null);
  const isMobile = useMediaQuery('(max-width: 900px)');

  const formateDatesToSelectPicker = (dateRange: Date[]) => dateRange.map((date) => dayjs(date).format('DD MMM YYYY'));

  const getOrderedDateListToPicker = () => {
    const dateRange = eventInfo?.timeline_days;
    const formattedDates = dateRange?.map((date) => dayjs(date).format('DD MMM YYYY'));
    const reversedFormattedDates = formattedDates?.reverse();
    return reversedFormattedDates || [];
  };

  const onDateSelectChanges = (e: string) => {
    userMarketingEventTrack.track('Click on Date selector', { date: e });
    realTimeAthletePositions.clear();
    setRealTimeAthletePositions(new Map());
    setSelectedDate(e);
    setLocalStateSelectedDate(e);
  };

  const onSelectPlaybackTime = (time: string) => {
    userMarketingEventTrack.track('Click on earlier time', { time });
    realTimeAthletePositions.clear();
    setRealTimeAthletePositions(new Map(athletePositionsOnHover));
    setSelectedTime(removeTimeZoneFromTimeStamp(time));
    setLocalStateSelectedTime(removeTimeZoneFromTimeStamp(time));
  };

  const onChangeTimeline = () => {
    if (!timeline?.length) return;
    const dateFromQueryParam: string | null = searchParams.get('stream_date');

    const firstStreamDate = eventInfo?.timeline_started_at?.substring(0, 10);
    const lastStreamDate = eventInfo?.timeline_updated_at?.substring(0, 10);

    let dateStamp = removeTimeZoneFromTimeStamp(timeline[0].time);
    // Validate here
    if (isDateBelongsToDateInterval(dateFromQueryParam, firstStreamDate, lastStreamDate)) {
      if (!dateFromQueryParam) return;
      dateStamp = dateFromQueryParam;
    }
    searchParams.set('stream_date', dateStamp);
    setSearchParams(searchParams);
    setLocalStateSelectedTime(dateStamp);
    if (timeline.length) {
      if (isMobile) {
        setTimeout(() => scrollParentElement(`[data-time='${dateStamp}']`), 100);
      } else {
        setTimeout(() => scrollToElement(`[data-time='${dateStamp}']`), 2000);
      }
    }
  };
  useEffect(onChangeTimeline, [timeline]);

  const populateInitialDateWithQueryStringParam = (dateList: string[]) => {
    const dateFromQueryParam: string | null = searchParams.get('stream_date');
    if (!dateFromQueryParam) return;
    const convertedDate = formateDatesToSelectPicker([new Date(dateFromQueryParam)])[0];
    if (dateList.find((date: string) => date === convertedDate)) {
      setLocalStateSelectedDate(convertedDate);
    } else {
      setLocalStateSelectedDate(dateList[0]);
    }
  };

  const setInitialSelectedDate = () => {
    if (!timeline.length) return;
    const dateList = getOrderedDateListToPicker();
    if (!searchParams.get('stream_date')) {
      setLocalStateSelectedDate(dateList[0]);
    } else {
      populateInitialDateWithQueryStringParam(dateList);
    }
  };

  const onMount = () => {
    setInitialSelectedDate();
  };
  useEffect(onMount, []);

  return isMobile ? (
    <MobileTimeLineEvent
      getOrderedDateListToPicker={getOrderedDateListToPicker}
      localStateSelectedDate={localStateSelectedDate}
      localStateSelectedTime={localStateSelectedTime}
      onDateSelectChanges={onDateSelectChanges}
      liveStreams={liveStreams}
      timeline={timeline}
      onSelectPlaybackTime={onSelectPlaybackTime}
      getTime={getTime}
      openLiveStream={openLiveStream}
      isTimelineLoading={isTimelineLoading}
      isLiveSelected={isLiveSelected}
      onSelectStream={onSelectStream}
    />
  ) : (
    <DesktopTimelineEvent
      getOrderedDateListToPicker={getOrderedDateListToPicker}
      localStateSelectedDate={localStateSelectedDate}
      localStateSelectedTime={localStateSelectedTime}
      onDateSelectChanges={onDateSelectChanges}
      liveStreams={liveStreams}
      timeline={timeline}
      onSelectPlaybackTime={onSelectPlaybackTime}
      getTime={getTime}
      openLiveStream={openLiveStream}
      isTimelineLoading={isTimelineLoading}
      isLiveSelected={isLiveSelected}
    />
  );
}
