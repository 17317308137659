import { useState } from 'react';

import GenericStreamMap, { ContainerState } from '../../../../components/generic-stream-map/generic-stream-map';
import useGlobalStore from '../../../../app-global-store';
import EventVideo from '../event-video/event-video';
import { EventMap } from '../event-map/event-map';
import useEventStore from '../../state-manager/store';

export function EventVideoAndMap() {
  const mapRef = useEventStore((state) => state.mapRef);
  const analytics = useGlobalStore((state) => state.analytics);
  const featStream = useEventStore((state) => state.featStream);
  const userLocation = useEventStore((state) => state.userLocation);

  const [isPip, setIsPip] = useState<boolean>(true);

  const redrawMap = () => {
    if (mapRef) {
      setTimeout(() => {
        try {
          mapRef.resize();
        } catch (e) {
          // console.error(e);
        }
      }, 1500);
    }
  };

  const onChangeContainerState = (state: ContainerState) => {
    analytics.track(`change_stream_size_${ContainerState[state]}`);
    if (state === ContainerState.MapFull) return setIsPip(true);
    setIsPip(false);
  };

  const telemetryData = {
    username: featStream?.content?.username || '',
    useravatar: featStream?.content?.useravatar || '',
    speedType: featStream?.content?.activity_type?.speed_type,
    gpsData: {
      gps_altitude: userLocation?.gps_altitude || 0,
      gps_latitude: userLocation?.gps_latitude || 0,
      gps_longitude: userLocation?.gps_longitude || 0,
      gps_speed: userLocation?.gps_speed || 0,
      offset: userLocation?.offset || 0,
      sample_time: userLocation?.sample_time || 0,
      version: userLocation?.version || 0,
    },
  };

  return (
    <GenericStreamMap
      streamElement={<EventVideo isPip={isPip} />}
      mapComponent={EventMap() ? <EventMap /> : null}
      onAnimationEnds={redrawMap}
      onChangeContainerState={onChangeContainerState}
      telemetryData={telemetryData}
    />
  );
}
