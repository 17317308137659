/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { darkTheme, keyframes, styled } from '../../../stitches.config';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

// @ts-ignore
export const SIDE_OPTIONS: readonly ['top', 'right', 'bottom', 'left'] = [];
type Side = (typeof SIDE_OPTIONS)[number];

const StyledContent = styled(TooltipPrimitive.Content, {
  borderRadius: '8.4rem',
  padding: '10px 15px',
  fontSize: 15,
  lineHeight: 1,
  color: '$gs100',
  zIndex: 1,
  backgroundColor: 'rgba(0,0,0,0.8)',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  userSelect: 'none',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

export function ButtonTooltip({
  children,
  content,
  side = 'top',
}: {
  children: React.ReactNode;
  content: string;
  side?: Side;
}) {
  return (
    <TooltipPrimitive.Provider delayDuration={300}>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <StyledContent side={side} align="center" className={darkTheme}>
            {content}
          </StyledContent>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}
