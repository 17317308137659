import { Avatar, AvatarFallback, AvatarImage } from '../primitives/avatar-image/avatar-image';
import Button from '../primitives/button';
import Flex from '../primitives/flex';
import { Typo } from '../primitives/typo/typo';

interface IProps {
  realname: string;
  username: string;
  useravatar: string;
  isFollowing: boolean;
  onClickFollow: () => void;
}

export function UserInfo({ realname, username, useravatar, onClickFollow, isFollowing }: IProps) {
  return (
    <Flex
      className="user-info-wrapper"
      css={{
        gap: '$xs',
        alignItems: 'center',
        '@bp3': {
          justifyContent: 'space-between',
          width: '100%',
        },
      }}
    >
      <Flex css={{ gap: '$xs', alignItems: 'center' }}>
        <Avatar
          css={{
            width: '2.5rem',
            height: '2.5rem',
          }}
          className="avatar-user"
        >
          <AvatarImage src={useravatar} alt={username} />

          <AvatarFallback delayMs={300}>{useravatar.substring(0, 1).toUpperCase()}</AvatarFallback>
        </Avatar>
        <Flex className="user-name-username" css={{ flexDirection: 'column', gap: '$nano', marginRight: '1rem' }}>
          <Typo type="bodyBold" css={{ color: '$constantWhite' }}>
            {realname}
          </Typo>
          <Typo type="bodySm" css={{ color: '$gs400' }}>
            @{username}
          </Typo>
        </Flex>
      </Flex>

      <Button
        size="sm"
        variant={isFollowing ? 'realOutline' : 'default'}
        css={{
          color: isFollowing ? 'rgba(255,255,255, 0.6)' : '#131313',
          width: '6rem',
          height: '2rem',
          borderRadius: '$lg',
        }}
        onClick={onClickFollow}
      >
        {isFollowing ? 'Following' : 'Follow'}
      </Button>
    </Flex>
  );
}
