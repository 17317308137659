/* eslint-disable import/export */
import { AccessDeniedError, UnexpectedError } from '../../domain/errors';
import { StreamRepository } from '../../domain/usecases/stream';
import { HttpStatusCode, HttpClient } from '../protocols/http';

export class RemoteStream implements StreamRepository {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<StreamRepository.Model | any>) {}

  async get(streamId: string): Promise<StreamRepository.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${streamId}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new AccessDeniedError();

      default:
        throw new UnexpectedError();
    }
  }

  async listReactions(channelId: string, page = 1, per_page = 100): Promise<StreamRepository.Reaction[]> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${channelId}/reactions?page=${page}&per_page=${per_page}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }

  async listChannelStreams(channelId: string, page = 1, per_page = 100): Promise<StreamRepository.Model[]> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/channels/${channelId}/streams`,
      method: 'post',
      params: {
        page,
        per_page,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }

  async like(streamId: string): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${streamId}/reactions`,
      method: 'post',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }

  async unlike(streamId: string): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${streamId}/reactions`,
      method: 'delete',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteStreamAnonymous {
  export type Model = StreamRepository.Model;
}
