import Flex from '../../primitives/flex';
import BroadCastIcon from '../../icons/broadcast';
import { Typo } from '../../primitives/typo/typo';

export default function RecordedBadge() {
  return (
    <Flex
      className="live-bagde"
      css={{
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        left: '$xs',
        top: '$xs',
        backgroundColor: '$sysCaution',
        height: '1.5rem',
        width: '5.87rem',
        padding: '0.5rem',
        borderRadius: '$3lg',
        gap: '$nano',
        '@bp3': {
          left: '0.8rem',
        },
      }}
    >
      <BroadCastIcon />
      <Typo type="caption">Recorded</Typo>
    </Flex>
  );
}
