import { useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, MessageInput, MessageList, Window } from 'stream-chat-react';

import 'stream-chat-react/dist/css/v2/index.css';
import useAuthStore from '../../../pages/auth-wrapper/state-manager/store';
import { styled } from '../../../stitches.config';
import Button from '../../primitives/button';
import Flex from '../../primitives/flex';

type IProps = {
  chatId: string;
  chatType?: string;
  onClickOpenAuthDialog?: () => void;
  showInput?: boolean;
  chatCss?: any;
};

const NotLoggedMask = styled(Flex, {
  position: 'absolute',
  padding: '1rem',
  bottom: 0,
  left: 0,
  right: 0,
  top: '70%',
  background: 'linear-gradient(360deg, rgba(44, 44, 47, 0.8) 50%, rgba(44, 44, 47, 0) 100%)',

  '& .inner-dialog-wrapper': {
    position: 'absolute',
    bottom: '1rem',
    right: '1rem',
    left: '1rem',
  },
});

export default function GenericPulseChat({
  chatId,
  onClickOpenAuthDialog,
  chatType = 'livestream',
  showInput = true,
  chatCss,
}: IProps) {
  const [chatClient] = useState(new StreamChat(import.meta.env.VITE_CHAT_ID));
  const [channel, setChannel] = useState<any>(null);
  const isLogged = useAuthStore((state) => state.isLogged);
  const getCurrentUser = useAuthStore((state) => state.getCurrentUser);

  const addChannel = async () => {
    const instance = await chatClient.channel(chatType, chatId);
    setChannel(instance);
  };

  const connectUser = () => {
    const profile = getCurrentUser();

    if (isLogged() && profile) {
      return chatClient.connectUser(
        { id: profile.channel.chat_user_id, name: profile.display_name, image: profile.profile_photo.small },
        profile.config.chat_token,
      );
    }

    return chatClient.connectAnonymousUser();
  };

  const disconnect = () => {
    chatClient.closeConnection();
    chatClient.disconnectUser();
  };

  const initializeChat = async () => {
    await connectUser();
    await addChannel();
  };

  useEffect(() => {
    initializeChat();
    return () => {
      disconnect();
    };
  }, []);

  return (
    <Flex
      className="chat-wrapper"
      css={{
        height: '100%',
        width: '100%',
        '& ::-webkit-scrollbar-track': {
          background: '$bgAppScreen200',
        },
        '& ::-webkit-scrollbar-thumb': {
          background: '$gs500',
          borderRadius: '$3lg',
        },

        '& .str-chat': { width: '100%' },
        '& .str-chat__list': {
          backgroundColor: '$bgAppScreen200',
          '&::-webkit-scrollbar': {
            backgroundColor: '$bgAppScreen200',
            width: '.5rem',
          },
        },
        '& .str-chat__main-panel': {
          backgroundColor: '$bgAppScreen200',
        },
        '& .str-chat__message-input': {
          backgroundColor: '$bgAppScreen200',
        },
        '& .str-chat__message-textarea': {
          overflowY: 'hidden !important',
          color: '$gs200 !important',
        },
        '& .str-chat__message-bubble': {
          background: 'transparent !important',
          color: 'white !important',
        },
        '& .str-chat__message-attachment-card--title': {
          color: '$gs200 !important',
        },
        '& .str-chat__message-input .str-chat__send-button svg path': {
          fill: '$primaryAdrenaline100',
        },
        '& .str-chat__message-input .str-chat__send-button:disabled svg path': {
          fill: '#b4b7bb',
        },
        '& .str-chat__empty-channel': {
          backgroundColor: '$bgAppScreen200 !important',
        },
        '& .str-chat__message-input-inner': {
          alignItems: 'center !important',
        },
        '& .str-chat__message-textarea-emoji-picker': {
          display: 'none !important',
        },
        '& .str-chat__message-inner': {
          width: '100%',
          margin: '0 !important',
          display: 'flex !important',
        },
        '& .str-chat__message-simple__actions': {
          display: 'none !important',
        },

        '& .str-chat__avatar-image': {
          width: '20px !important',
          height: '20px !important',
        },

        '& .str-chat__avatar': {
          position: 'absolute !important',
          left: '0px !important',
        },

        '& .str-chat__message-text': {
          padding: '0px !important',
        },
        '& .str-chat__message-reactions-host': {
          display: 'none',
        },
        '& .str-chat__message-text-inner.str-chat__message-simple-text-inner': {
          fontSize: '14px',
        },
        '& .str-chat__message-simple-name, .str-chat__message-simple-timestamp, .str-chat__date-separator-date': {
          fontSize: '14px',
          color: 'white',
          opacity: 0.4,
        },
        '& .str-chat__message.str-chat__message-simple.str-chat__message--regular.str-chat__message--received': {
          gridTemplateColumns: '24px 1fr !important',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingLeft: 30,
          position: 'relative',
        },
        '& .str-chat__message.str-chat__message-simple.str-chat__message--regular.str-chat__message--received.str-chat__message--me.str-chat__message-simple--me.str-chat__message--has-text':
          {
            gridTemplateColumns: 'auto 1fr !important',
          },

        '& .str-chat__message.str-chat__message-simple.str-chat__message--regular.str-chat__message--received.str-chat__message--other.str-chat__message--has-text':
          {
            marginBottom: '16px !important',
          },

        '& .str-chat__message-data.str-chat__message-simple-data.str-chat__message-metadata': {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
        },

        // '@bp3': {
        //   // paddingBottom: showInput ? '5rem' : '2rem',
        //   '& .str-chat__message-input': {
        //     position: 'fixed',
        //     bottom: 0,
        //     left: 0,
        //     zIndex: 100,
        //   },
        //   '& .str-chat__file-input-container': {
        //     display: 'none !important',
        //   },
        // },
        ...chatCss,
      }}
    >
      {channel && (
        <Chat client={chatClient} theme="str-chat__theme-light">
          <Channel channel={channel}>
            <Window>
              <MessageList hideDeletedMessages />
              {isLogged() && showInput && <MessageInput grow disableMentions />}
            </Window>
          </Channel>
        </Chat>
      )}

      {!isLogged() && (
        <NotLoggedMask className="auth-dialog-wrapper">
          <Flex className="inner-dialog-wrapper">
            <Button
              width="full"
              onClick={onClickOpenAuthDialog}
              onTouchStart={onClickOpenAuthDialog}
              css={{
                color: '$gs600',
                transition: 'all 0.5s',
                '&:active': { transform: 'scale(0.92)' },
              }}
            >
              Sign in to join the chat
            </Button>
          </Flex>
        </NotLoggedMask>
      )}
    </Flex>
  );
}
