import axios from 'axios';
import { LocalStorageAdapter } from '../../infra/cache';
import { AnalyticsLoggingClient } from '../../infra/event-tracking/event-tracking';
import { FirebaseAuthClient } from '../../infra/social-auth';

export function createAxiosResponseInterceptor() {
  const firebaseAuth = new FirebaseAuthClient();
  const localStorageAdapter = new LocalStorageAdapter();
  const analytics = new AnalyticsLoggingClient();
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const config = error?.config;

      if (error?.response?.status === 401 && !config?.sent) {
        config.sent = true;
        analytics.track('error_invalid_auth', { error });
        try {
          const token = await firebaseAuth.getUserToken();
          if (token) {
            localStorageAdapter.set('token', token);
            config.headers = {
              ...config.headers,
              authorization: `Bearer ${token}`,
            };
          }
        } catch (e) {
          analytics.track('error_generating_new_token', { e });
          localStorageAdapter.remove('token');
          localStorageAdapter.remove('profile');
          localStorageAdapter.remove('fireBaseUser');
        }

        return axios(config);
      }
      return Promise.reject(error);
    },
  );
}
