import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { GenericCarouselScroller } from '../../../../components/generic-carousel-scroller/generic-carousel-scroller';
import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';
import { useIsMobile } from '../../../../utils/useIsMobile';
import useEventStore from '../../state-manager/store';
import { EventIntroModal } from '../event-intro-modal/event-intro-modal';
import { scrollHorizontallyToElement } from '../../../../utils/utils';

interface IProps {
  dateRange: string[];
  value: string | Date | undefined;
  onValueChange: (event: string) => void;
}

interface DatePillProps {
  date: string | null;
  isSelected: boolean;
  isMobile: boolean;
  onClick: () => void;
}

function DatePill({ date, isSelected, onClick, isMobile }: DatePillProps) {
  const pillRef = useRef(null);
  useEffect(() => {
    if (isSelected) {
      scrollHorizontallyToElement('', pillRef?.current);
    }
  }, [isSelected]);

  return (
    <Flex
      as="button"
      ref={pillRef}
      css={{
        touchAction: 'manipulation',
        backgroundColor: '$bgAppScreen100',
        border: 'none',
        borderRadius: '99px',
        height: '2rem',
        alignItems: 'center',
        padding: '0px $xs',
        cursor: 'pointer',
        transition: 'all .5s ease',
        '&:active': {
          transform: 'scale(.98)',
        },
        '@hover': {
          '&:hover': {
            transform: 'scale(.96)',
          },
        },

        '&:focus': {
          boxShadow: '$focusRing',
        },
      }}
      onClick={onClick}
    >
      <Typo
        type="bodySmallMedium"
        css={{ color: isSelected ? '$primaryAdrenaline100' : '#F2F1F6', whiteSpace: 'nowrap', fontSize: '0.8rem' }}
      >
        {date}
      </Typo>
    </Flex>
  );
}

export default function EventDateList({ dateRange, value, onValueChange }: IProps) {
  const isMobile = useIsMobile();
  const [isIntroVisible, setIsIntroVisible] = useState<boolean>(false);
  const eventInfo = useEventStore((state) => state.eventInfo);
  const formatDate = (date: string | Date) => dayjs(date).format('DD MMM YYYY');
  const formatedDate = useMemo(() => (value ? formatDate(value) : null), [value]);
  const formatedDates = useMemo(() => dateRange.map((date) => formatDate(date)), [dateRange]);
  const dateRangeArr = [...new Set([...formatedDates, formatedDate])];

  const toggleIntroModal = () => {
    setIsIntroVisible(!isIntroVisible);
  };

  return (
    <GenericCarouselScroller list={dateRange}>
      {eventInfo?.intro_url && (
        <DatePill date="Partners" isSelected={false} onClick={toggleIntroModal} isMobile={isMobile} />
      )}
      {dateRangeArr.map((date: string | null) => (
        <DatePill
          key={date}
          date={date}
          isSelected={date === formatedDate}
          onClick={() => onValueChange(date || '')}
          isMobile={isMobile}
        />
      ))}
      {isIntroVisible && eventInfo?.intro_url && (
        <EventIntroModal setIsModalVisible={setIsIntroVisible} introUrl={eventInfo?.intro_url} />
      )}
    </GenericCarouselScroller>
  );
}
