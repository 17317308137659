export function UnionLeftIcon() {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7116 16.7124C20.2036 17.2211 19.5746 17.4754 18.8246 17.4754H3.17461C2.42461 17.4754 1.79528 17.2211 1.28661 16.7124C0.77861 16.2044 0.524609 15.5754 0.524609 14.8254V3.17539C0.524609 2.42539 0.77861 1.79606 1.28661 1.28739C1.79528 0.779391 2.42461 0.525391 3.17461 0.525391H18.8246C19.5746 0.525391 20.2036 0.779391 20.7116 1.28739C21.2203 1.79606 21.4746 2.42539 21.4746 3.17539V14.8254C21.4746 15.5754 21.2203 16.2044 20.7116 16.7124ZM3.17461 14.8254H18.8246V3.17539H3.17461V14.8254ZM4.50019 4C4.22405 4 4.00019 4.22386 4.00019 4.5V13.5C4.00019 13.7761 4.22405 14 4.50019 14H11.5002C11.7763 14 12.0002 13.7761 12.0002 13.5V4.5C12.0002 4.22386 11.7763 4 11.5002 4H4.50019Z"
        fill="white"
        fillOpacity="0.91"
      />
    </svg>
  );
}
