import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState, useContext } from 'react';
import { SignInMethod } from '../../../data/protocols/social-auth';
import { AuthFireBaseUser } from '../../../infra/firebase/model';
import { ISquadVideoContext, SquadVideoContext } from '../../pages/event-v1/context';
import { styled } from '../../stitches.config';
import AppStoreIcon from '../icons/app-store';
import AppleIcon from '../icons/apple';
import FacebookIcon from '../icons/facebook';
import GoogleIcon from '../icons/google';
import PulseLogoMarkDark from '../icons/pulse-logo-mark-dark';
import Button from '../primitives/button';
import { Dialog, DialogContent, DialogTrigger } from '../primitives/dialog/dialog';
import Flex from '../primitives/flex';
import { Typo } from '../primitives/typo/typo';
import useGlobalStore from '../../app-global-store';

export default function AuthDialog() {
  const analytics = useGlobalStore((state) => state.analytics);
  const { onFirebaseAuthSignIn, localStorageAdapter, socialSignIn, isMobile } =
    useContext<ISquadVideoContext>(SquadVideoContext);
  const [open, setOpen] = useState<boolean>(false);
  const [, setLoading] = useState<boolean>(false);
  const loggedUser = localStorageAdapter.get('profile');

  useEffect(() => {
    const auth = socialSignIn.getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      const firebaseUser: AuthFireBaseUser = currentUser as unknown as AuthFireBaseUser;
      localStorageAdapter.set('fireBaseUser', firebaseUser);
      localStorageAdapter.set('token', firebaseUser?.stsTokenManager?.accessToken);
      setOpen(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const openAuthDialog = () => {
    analytics.track('clicked_sign_in_chat');
  };

  const onClickSignIn = async (method: SignInMethod) => {
    try {
      setLoading(true);
      await socialSignIn.signIn(method);
      analytics.track('sign_in_success', { method });
      setLoading(false);
      onFirebaseAuthSignIn();
    } catch (e) {
      setLoading(false);
    }
  };

  const NotLoggedMask = styled(Flex, {
    position: 'absolute',
    padding: '1rem',
    bottom: 0,
    left: 0,
    right: 0,
    top: '70%',
    background: 'linear-gradient(360deg, rgba(44, 44, 47, 0.8) 50%, rgba(44, 44, 47, 0) 100%)',

    '& .inner-dialog-wrapper': {
      position: 'absolute',
      bottom: '1rem',
      right: '1rem',
      left: '1rem',
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!loggedUser && (
        <DialogTrigger asChild onClick={openAuthDialog} onTouchStart={openAuthDialog}>
          {isMobile ? (
            <Button
              width="full"
              css={{
                color: '$gs600',
                transition: 'all 0.5s',
                position: 'absolute',
                bottom: '1rem',
                '&:active': { transform: 'scale(0.92)' },
              }}
            >
              Sign in to join the chat
            </Button>
          ) : (
            <NotLoggedMask className="auth-dialog-wrapper">
              <Flex className="inner-dialog-wrapper">
                <Button
                  width="full"
                  css={{
                    color: '$gs600',
                    transition: 'all 0.5s',
                    '&:active': { transform: 'scale(0.92)' },
                  }}
                >
                  Sign in to join the chat
                </Button>
              </Flex>
            </NotLoggedMask>
          )}
        </DialogTrigger>
      )}

      <DialogContent>
        <Flex css={{ flexDirection: 'column', gap: '$lg', zIndex: 10 }}>
          <Flex css={{ flexDirection: 'column', gap: '$md', alignItems: 'center' }}>
            <PulseLogoMarkDark />
            <Typo as="h4" type="h4">
              Sign in to Pulse
            </Typo>
            <Typo type="bodySmallMedium" css={{ textAlign: 'center' }}>
              Get front row seats to your favorite athletes’ point of view with Pulse.
            </Typo>
          </Flex>

          <Flex css={{ flexDirection: 'column', gap: '$xs' }}>
            <Button variant="outline" onClick={() => onClickSignIn('apple.com')}>
              <Flex css={{ position: 'absolute', left: '1rem', top: '0.7rem' }}>
                <AppleIcon />
              </Flex>
              <Typo type="bodyBold">Continue with Apple</Typo>
            </Button>
            <Button variant="outline" onClick={() => onClickSignIn('google.com')}>
              <Flex css={{ position: 'absolute', left: '1rem', top: '0.8rem' }}>
                <GoogleIcon />
              </Flex>
              <Typo type="bodyBold">Continue with Google</Typo>
            </Button>
            <Button variant="outline" onClick={() => onClickSignIn('facebook.com')}>
              <Flex css={{ position: 'absolute', left: '1rem', top: '0.8rem' }}>
                <FacebookIcon />
              </Flex>
              <Typo type="bodyBold">Continue with Facebook</Typo>
            </Button>
          </Flex>

          <Flex css={{ flexDirection: 'column', gap: '$lg', alignItems: 'center' }}>
            <Typo type="caption" css={{ textAlign: 'center', color: '$gs300' }}>
              By signing in, you agree to{' '}
              <Button
                as="a"
                variant="transparent"
                href="https://pulse.tv/terms"
                target="_blank"
                css={{ display: 'inline', padding: 2, height: 'fit-content', color: '$gs300' }}
              >
                Pulse’s Terms & Conditions
              </Button>{' '}
              <Button
                as="a"
                variant="transparent"
                href="https://pulse.tv/privacy-policy"
                target="_blank"
                css={{ display: 'inline', padding: 2, height: 'fit-content', color: '$gs300' }}
              >
                and Privacy Policy
              </Button>
            </Typo>
            <Button
              as="a"
              variant="transparent"
              href="https://apps.apple.com/us/app/pulse-share-what-you-see/id1576189738"
              target="_blank"
            >
              <AppStoreIcon />
            </Button>
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
