import { ReactElement } from 'react';
import Button from '../primitives/button';
import Flex from '../primitives/flex';
import { Typo } from '../primitives/typo/typo';

interface IProps {
  icon: ReactElement;
  label: string;
  onClick?: () => void;
  showLabel?: boolean;
}

export function SideBarMenuItem({ icon, label, onClick, showLabel = true }: IProps) {
  return (
    <Button
      className="sidebar-item"
      variant="transparent"
      width="full"
      size="xsm"
      css={{ gap: '$3xs', height: 32, width: 32, justifyContent: 'flex-start', paddingLeft: 5 }}
      onClick={onClick && onClick}
      title={label}
    >
      <Flex>{icon}</Flex>
      <Typo
        type="bodySmallMedium"
        css={{
          transition: 'all 0.3s ease-in-out',
          color: '$constantWhite',
          opacity: showLabel ? 0.6 : 0,
          position: 'absolute',
          left: 45,
        }}
      >
        {label}
      </Typo>
    </Button>
  );
}
