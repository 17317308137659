/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-extra-boolean-cast */
import { useDebounce, useElementSize } from 'usehooks-ts';
import { useRef } from 'react';

import Flex from '../../../../components/primitives/flex';
import { Grid } from './style';
import { useIsMobile } from '../../../../utils/useIsMobile';
import { useScroll } from '../../../../hooks/use-scroll/use-scroll';
import useEventStore from '../../state-manager/store';
import TopHeader from '../../../../components/top-header/top-header';
import { EventVideoAndMap } from '../event-video-map/event-video-map';
import EventVideo from '../event-video/event-video';
import { EventMap } from '../event-map/event-map';
import EventChat from '../event-chat/event-chat';
import Heading from '../../../../components/heading/heading';
import { EventTimeline } from '../event-timeline/event-timeline';
import EventShare from '../event-share/event-share';
import EventMoreInfo from '../event-more-info/event-more-info';
import EventHostPlayer from '../event-host-player/event-host-player';
import { EventLiveStreamsAndHosts } from '../event-live-streams-and-hosts/event-live-streams-and-hosts';

export function EventGrid() {
  const eventInfo = useEventStore((state) => state.eventInfo);
  const featStream = useEventStore((state) => state.featStream);
  const userLocation = useEventStore((state) => state.userLocation);
  const ref = useRef(null);
  const scrollOffsetTop = useScroll(200)?.top || 0;
  const headerHeight = 36;
  const isMobile = useIsMobile();
  const isVideoExpanded = isMobile && scrollOffsetTop >= headerHeight;
  const playingHostStream = useEventStore((state) => state.playingHostStream);

  const [elementRef, { width }] = useElementSize();
  const debouncedWidth = useDebounce<number>(width, 500);

  // const [elementMobileRef, { width: widthMobile }] = useElementSize();
  // const debouncedMobileWidth = useDebounce<number>(widthMobile, 500);

  const getWidth = () => {
    if (debouncedWidth) return `${debouncedWidth * 0.5625}px auto 2.93rem auto`;
    return `28rem 17.62rem 2.93rem auto`;
  };

  return (
    <Flex
      className="event-content"
      css={{
        width: '100%',
        marginBottom: '5rem',
      }}
    >
      <Grid
        ref={ref}
        className="event-grid"
        css={{
          gridTemplateRows: getWidth(),
          '@bp3': {
            display: 'flex',
            flexDirection: 'column',
            gap: 'none',
            '& .video-mobile': {
              height: `18rem`,
            },
          },

          '@landscape': {
            '& .video-mobile': {
              minHeight: '15rem',
              position: 'static',
              width: '50%',
            },
            '& .map-mobile': {
              position: 'absolute',
              top: '3rem',
              right: 0,
              height: '18rem',
              width: !!userLocation ? '50%' : '100%',
            },
          },

          '&:empty': {
            gridTemplateRows: `28rem 17.62rem 2.93rem auto`,
          },
        }}
      >
        {isMobile && (
          <Flex className="header" css={{ height: '3rem' }}>
            <Flex css={{ position: 'absolute', right: 0, left: -14 }}>
              <TopHeader showShareButton={false} />
            </Flex>
          </Flex>
        )}

        {isMobile && (
          <Flex
            // ref={elementMobileRef}
            className="video-mobile"
            css={{
              backgroundColor: 'red',
              height: `18rem`,
              boxShadow: isVideoExpanded ? '0px 1rem 2rem rgba(28, 28, 31, 0.7)' : 'none',
            }}
          >
            {!!featStream && <EventVideo />}
          </Flex>
        )}

        {isMobile && playingHostStream && (
          <Flex className="host-player">
            <EventHostPlayer />
          </Flex>
        )}

        {isMobile && <EventLiveStreamsAndHosts />}

        {isMobile && !!userLocation && <Flex className="map-mobile">{!!featStream && <EventMap />}</Flex>}

        <Flex className="timeline-desktop" css={{ '@bp3': { marginBottom: '$sm' } }}>
          <EventTimeline />
        </Flex>

        {!isMobile && (
          <>
            <Flex className="video-and-map" ref={elementRef}>
              {!!featStream && <EventVideoAndMap />}
            </Flex>

            <Flex className="share-desktop">{!!eventInfo && <EventShare />}</Flex>
          </>
        )}

        <Flex className="chat-container responsive-margin">
          {!!eventInfo && (
            <Flex
              css={{
                flexDirection: 'column',
                gap: '$sm',
                width: '100%',
                height: '100%',
                '@bp3': {
                  height: '14rem',
                },
              }}
            >
              {!isMobile && <EventHostPlayer />}
              <EventChat />
            </Flex>
          )}
        </Flex>

        {isMobile && (
          <>
            {featStream?.content?.logoUrl && (
              <Flex className="mobile-streamer-sponsor responsive-margin">
                {!!featStream && (
                  <Flex
                    css={{
                      touchAction: 'manipulation',
                      paddingTop: '$sm',
                      paddingBottom: '$sm',
                      width: '100%',
                      height: '10rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                      '& img': {
                        height: '100%',
                        objectFit: 'contain',
                      },
                    }}
                  >
                    <img src={featStream?.content?.logoUrl} alt="sponsor logo" />
                  </Flex>
                )}
              </Flex>
            )}
          </>
        )}

        <Flex className="event-title responsive-margin">
          {!!eventInfo && (
            <Heading
              name={eventInfo?.title || 'event not found'}
              link={eventInfo?.more_info_url}
              logoSrc={eventInfo?.photo?.cropped}
              isRedirectLinkVisible={!isMobile}
            />
          )}
        </Flex>

        <Flex className="event-more-info responsive-margin">{!!eventInfo && <EventMoreInfo />}</Flex>
      </Grid>
    </Flex>
  );
}
