import { createStitches } from '@stitches/react';

const newColors = {
  // Principal Color
  eletricLimeFluorescent: '#B8EE30',
  eletricLimeLight: '#E1F7AA',
  eletricLimeDark: '#82A91E',
  eletricLimeVeryDark: '#517200',
  eletricLimeMuted: '#81987C',
  eletricLimeVeryDarkMuted: '#303B2F',

  // Ornamental Colors
  radicalCherryFluorescent: '#FF3866',
  radicalCherryLight: '#F9C9D3',
  radicalCherryDark: '#D0153B',
  radicalCherryVeryDark: '#6B0013',
  radicalCherryMuted: '#AC6070',
  radicalCherryDarkMuted: '#6F3A45',
  radicalCherryVeryDarkMuted: '#2C171B',

  razzleRoseFluorescent: '#FF29A6',
  razzleRoseLight: '#F8D3E8',
  razzleRoseDark: '#BE0772',
  razzleRoseVeryDark: '#660039',
  razzleRoseMuted: '#B7759B',
  razzleRoseDarkMuted: '#834468',
  razzleRoseVeryDarkMuted: '#402133',

  sunGlowFluorescent: '#FFCC33',
  sunGlowLight: '#FBE8B0',
  sunGlowDark: '#FCAE01',
  sunGlowVeryDark: '#C97601',
  sunGlowMuted: '#E1B85F',
  sunGlowDarkMuted: '#B98923',
  sunGlowVeryDarkMuted: '#634813',

  aquaRushFluorescent: '#26DFD0',
  aquaRushLight: '#B4F7F1',
  aquaRushDark: '#01B6A7',
  aquaRushVeryDark: '#00786E',
  aquaRushMuted: '#7CB5B0',
  aquaRushVeryDarkMuted: '#25413F',

  blizzardBlastFluorescent: '#50BFE6',
  blizzardBlastLight: '#B6E3F3',
  blizzardBlastDark: '#109ECF',
  blizzardBlastVeryDark: '#0B6787',
  blizzardBlastMuted: '#6AA8BD',
  blizzardBlastVeryMuted: '#3B7386',
  blizzardBlastVeryDarkMuted: '#1C3740',

  // Base Colors
  black: '#000000',
  raven: '#1A1B1A',
  charcoal: '#343634',
  ironRiver: '#4C4F4C',
  gunMetal: '#5B5F5C',
  boulder: '#7B817C',

  white: '#FFFFFF',
  lynx: '#F6F7F6',
  moonlitSnow: '#EBEDEC',
  timberWolf: '#DCDFDC',
  silver: '#C5C9C6',
  manatee: '#9CA19C',
};

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } = createStitches({
  theme: {
    colors: {
      primary100: '#F7FCD9',
      primary200: '#ECF9A6',
      primary300: '#DFF466',
      primary400: '#D5F133',
      primary500: '#CAED00',
      primary600: '#B0CF00',
      primary700: '#96B100',
      primary800: '#7D9200',
      primary900: '#5C6D00',
      bgAppScreen100: '#fff',
      bgAppScreen200: '#F2F1F6',
      bgPrimary: '#F7FCD9',
      bgSuccess: '#EFFAF2',
      bgError: '#FBECEB',
      bgCaution: '#FEF3E5',
      blackGlass: 'rgba(19, 19, 19, 0.9)',
      blackTranslucentGlass: 'rgba(19, 19, 19, 0.7)',
      strongBlackGlass: 'rgba(28, 28, 31, 0.9)',

      // token aliases,

      // Constant Styles
      constantWhite: '#ffff',
      constantBlack: '#000',

      // Brand Colours
      primaryAdrenaline100: '#DAFF01',
      secondaryAdrenaline100: '#06F9CB',
      tertiaryAdrenaline100: '#FE544A',
      primaryLand100: '#222D1D',
      secondaryLand100: '#5E6C55',
      tertiaryLand100: '#7A3013',
      primaryAir100: '#B7B9AE',
      secondaryAir100: '#D8D8D8',
      tertiaryAir100: '#EDEDED',
      primaryWater100: '#A5CDD5',
      secondaryWater100: '#7A9A9F',
      tertiaryWater100: '#3E4A7A',

      // Colour Styles

      // Greyscale
      gs100: '#131313',
      gs200: '#403F40',
      gs300: '#6C6C6E',
      gs400: '#99989B',
      gs500: '#D5D4D8',
      gs600: '#F2F1F6',

      white300: '#ffffff99',

      // System
      sysSucess: '#62D181',
      sysError: '#D93D33',
      sysCaution: '#F98600',

      // Video Status
      vsLive100: '#FF3B30',
      vsPremiering100: '#AF52DE',

      // Button Styles Primary
      buttonActive: '$primary500',
      buttonDisabled: '$gs500',
      buttonTextActive: '$gs100',
      buttonTextDisabled: '$gs400',

      // Button Styles Secondary
      buttonSecondaryActive: '$primary200',
      buttonSecondaryDisabled: '#F0F0F1',
      buttonSecondaryTextActive: '#9EB900',
      buttonSecondaryTextDisabled: '#CCCCCD',

      // Button Outline
      outlineActive: '$primary500',
      outlineError: '$sysError',
      outlineGrey: '$gs500',
      outlineDisabled: '$gs500',

      // Button Text Colour
      buttonTextError: '$sysError',
      buttonTextGrey: '$gs100',

      ...newColors,
    },
    space: {
      none: '0px',
      quark: '2px',
      nano: '0.25rem', // 4px
      '3xs': '0.5rem', // 8px
      '2xs': '0.75rem', // 12px
      xs: '1rem', // 16px
      sm: '1.5rem', // 24px
      md: '2rem', // 32px
      lg: '2.5rem', // 40px
      xl: '3rem', // 48px
      '2xl': '3.5rem',
      '4xl': '4rem',

      0: '0rem',
      px: '1px',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
      screen: '100vw',
      full: '100%',
    },
    fontSizes: {
      // Headings
      hero: '4rem',
      h1: '3.5rem',
      h2: '2.5rem',
      h3: '2.125rem',
      h4: '1.75rem', // 28px
      h5: '1.375rem',
      h6: '1.25rem',

      // Body
      body: '1.0625rem', // 17px
      bodySm: '0.9375rem', // 15px

      // Buttons
      button: '1.0625rem',
      buttonSmall: '0.8125rem',

      // Links
      link: '1.0625rem',
      linkSmall: '0.8125rem',

      // Footnotes
      footnote: '0.8125rem',

      // Captions
      caption: '0.75rem', // 12px
      captionSmall: '0.625rem',
    },
    fonts: {
      untitled: 'Untitled Sans, apple-system, sans-serif',
      mono: 'Söhne Mono, menlo, monospace',
      roboto: 'Roboto, sans-serif',
      inter: 'Inter, sans-serif',
      apple: '-apple-system, BlinkMacSystemFont, sans-serif',
      gilroy: '"Gilroy", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, sans-serif',
    },
    fontWeights: {
      book: '400',
      medium: '500',
      demi: '600',
      bold: '700',
    },
    lineHeights: {
      auto: 'auto',
      default: '110%',
      xs: '120%',
      md: '130%',
      lg: '140%',
      xl: '150%',
      '2xl': '160%',
    },
    letterSpacings: {
      tighter: '-0.05em',
      tight: '-0.025em',
      normal: '0',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.1em',
    },
    sizes: {
      none: '0px',
      quark: '2px',
      nano: '4px',
      '3xs': '8px',
      '2xs': '12px',
      xs: '16px',
      sm: '24px',
      md: '32px',
      lg: '40px',
      xl: '48px',
      '2xl': '56px',
    },
    borderWidths: {
      none: '0',
      hairline: '0.0625rem',
      thin: '0.125rem', // 2px
      thick: '0.25rem',
      heavy: '0.5rem',
    },
    borderStyles: {},
    radii: {
      xs: '0.125rem', // 2px
      sm: '0.25rem',
      md: '0.375rem',
      lg: '0.5rem', // 8 px
      lgg: '0.75rem', // 12px
      '2lg': '1rem',
      '3lg': '1.375rem', // 22px
      xl: '6.25rem',
    },
    shadows: {
      1: '0px 8px 8px rgba(38, 63, 47, 0.08), 0px 8px 4px rgba(38, 63, 47, 0.04)',
      2: '0px 24px 24px rgba(38, 63, 47, 0.08), 0px 8px 8px rgba(38, 63, 47, 0.08), 0px 8px 4px rgba(38, 63, 47, 0.04)',
      3: '0px 100px 80px rgba(38, 63, 47, 0.08), 0px 40px 32px rgba(38, 63, 47, 0.08), 0px 8px 4px rgba(38, 63, 47, 0.04)',
      4: '0px 100px 80px rgba(38, 63, 47, 0.08), 0px 40px 32px rgba(38, 63, 47, 0.08), 0px 24px 16px rgba(38, 63, 47, 0.04), 0px 8px 4px rgba(38, 63, 47, 0.04)',
    },
    opacity: {
      hidden: '0',
      semiopaque: '0.5',
      intense: '0.64',
      medium: '0.32',
      light: '0.16',
      semitransparent: '0.08',
      normal: '1',
    },
    zIndices: {},
    transitions: {},
  },

  media: {
    bp1: '(min-width: 480px)',
    bp2: '(min-width: 640px)',
    bp3: '(max-width: 900px)',
    bp4: '(min-width: 1024px)',
    bp4Half: '(min-width: 1500px)',
    bp5: '(min-width: 1900px)',
    b2k: '(min-width: 2000px)',
    fullHd: '(min-width: 1900px) and (min-height: 1080px)',
    bp6: '(min-width: 2500px)',
    bp7: '(min-width: 2500px)',
    bp8: '(min-width: 3000px)',
    hbp1: '(max-height: 650px)',
    hover: '(hover: hover)',
    landscape: '(orientation: landscape) and (max-width: 900px)',
  },
  utils: {
    marginX: (value: string) => ({ marginLeft: value, marginRight: value }),
    thinBorder: (value: string) => ({
      border: `$borderWidths.hairline solid ${value}`,
    }),
  },
});

export const darkTheme = createTheme({
  colors: {
    primary100: '#F7FCD9',
    primary200: '#F2FFA6',
    primary300: '#E9FF67',
    primary400: '#E1FF34',
    primary500: '$primaryAdrenaline100',
    primary600: '#BBDB01',
    primary700: '#9CB601',
    primary800: '#7D9200',
    primary900: '#5C6D00',
    bgAppScreen100: '#1C1C1F',
    bgAppScreen200: '#2C2C2F',
    bgAppScreen300: '#242428',
    bgPrimary: '#212412',
    bgSuccess: '#27422E',
    bgError: '#451D1B',
    bgCaution: '#4D300E',
    black200: '#131313',

    // Colour Styles

    // Greyscale
    gs100: '#fff',
    gs200: '#E8E7EC',
    gs300: '#999999',
    gs400: '#6C6C6E',
    gs500: '#454545',
    gs500Dark: '#D5D4D8',
    gs600: '#191919',

    // Button Styles Primary
    buttonActive: '$primary500',
    buttonDisabled: '#343434',
    buttonTextActive: '$gs100',
    buttonTextDisabled: '$gs300',

    // Button Styles Secondary
    buttonSecondaryActive: '#2C3300',
    buttonSecondaryDisabled: '#1C1C1C',
    buttonSecondaryTextActive: '$primaryAdrenaline100',
    buttonSecondaryTextDisabled: '#414142',

    // Button Outline
    outlineActive: '$primaryAdrenaline100',
    outlineError: '$sysError',
    outlineGrey: '$gs500',
    outlineDisabled: '$gs500',

    // Button Text Colour
    buttonTextError: '$sysError',
    buttonTextGrey: '#fff',
  },
});

const global = {
  '*': {
    'box-sizing': 'border-box',
    '--rsbs-bg': '#2C2C2F',
    '--rsbs-handle-bg': '#6C6C6E',
    margin: 0,
    padding: 0,

    '@font-face': [
      {
        fontFamily: 'Gilroy',
        fontStyle: 'normal',
        fontWeight: 400,
        src: "url('../assets/fonts/Gilroy-Thin.otf') format('otf')",
      },
      {
        fontFamily: 'Gilroy',
        fontStyle: 'normal',
        fontWeight: 500,
        src: "url('../assets/fonts/Gilroy-Regular.otf') format('otf')",
      },
      {
        fontFamily: 'Gilroy',
        fontStyle: 'normal',
        fontWeight: 600,
        src: "url('../assets/fonts/Gilroy-Medium.otf') format('otf')",
      },
      {
        fontFamily: 'Gilroy',
        fontStyle: 'normal',
        fontWeight: 700,
        src: "url('../assets/fonts/Gilroy-Bold.otf') format('otf')",
      },
    ],

    fontFamily: 'Inter, sans-serif',
    '& ::-webkit-scrollbar-track': {
      background: '$bgAppScreen200',
    },
    '& ::-webkit-scrollbar-thumb': {
      background: '$gs500',
      borderRadius: '$3lg',
    },
    '& ::-webkit-scrollbar': {
      backgroundColor: '$bgAppScreen200',
      width: '.5rem',
    },
  },
  '*:before': {
    'box-sizing': 'border-box',
  },
  '*:after': {
    'box-sizing': 'border-box',
  },
  html: {
    'font-size': '16px',
    backgroundColor: '#1C1C1F',
    // zoom: '80%',
  },
  body: {
    width: '100vw',
    height: '100vh',
    overflowX: 'hidden',
    backgroundColor: '#1C1C1F',
    paddingLeft: 'env(safe-area-inset-left)',
    paddingRight: 'env(safe-area-inset-right)',
  },
  button: {
    border: 'none',
    backgroundColor: 'transparent',
  },
};

export const globalStyles = globalCss({
  ...global,
});
