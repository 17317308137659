import * as Checkbox from '@radix-ui/react-checkbox';
import { styled } from '@stitches/react';
import { blackA } from '@radix-ui/colors';
import { CheckIcon } from '@radix-ui/react-icons';

export const CheckboxRoot = styled(Checkbox.Root, {
  all: 'unset',
  width: '1.3rem',
  height: '1.3rem',
  border: '2px solid $gs300',
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  boxShadow: `0 2px 10px ${blackA.blackA7}`,
  '&:hover': { backgroundColor: '$gs300' },

  '&[data-state="checked"]': {
    border: 'none',
    backgroundColor: '$primary500',
  },

  '& svg': {
    marginTop: '3px',
  },
});

export const CheckboxIndicator = styled(Checkbox.Indicator, {
  color: '$bgAppScreen200',
});

export const CheckboxIcon = CheckIcon;

export const CheckboxLabel = styled('label', {
  color: '$constantWhite',
  fontSize: 15,
  lineHeight: 1,
  userSelect: 'none',
});
