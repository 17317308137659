import skeletonStyle from '../../../../common-styles/skeleton';
import Flex from '../../../../components/primitives/flex';
import { styled } from '../../../../stitches.config';

export const Grid = styled(Flex, {
  display: 'grid',
  width: '100%',
  gap: '$xs',
  maxWidth: '100rem',
  margin: '0px auto',

  gridTemplateColumns: '1fr 20rem',
  paddingRight: '0.75rem',
  paddingLeft: '0.75rem',

  gridTemplateAreas: `
      "video-and-map chat-container"
      "timeline-desktop chat-container"
      "event-title share-desktop"
      "event-more-info event-more-info"
    `,
  '& .header': {
    gridArea: 'header',
  },
  '& .video-and-map': {
    gridArea: 'video-and-map',
    borderRadius: '$2lg',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    height: '100%',
    overflow: 'hidden',
    zIndex: 1,
    '&:empty': skeletonStyle,
  },
  '& .video-mobile': {
    gridArea: 'video-mobile',
    height: '18rem',
    zIndex: 2,
    transition: 'all 0.5s',

    '&:empty': skeletonStyle,
  },
  '& .host-player': {
    gridArea: 'host-player',
    zIndex: 0,
    transition: 'all 0.5s',
    '&:empty': skeletonStyle,
  },
  '& .map-mobile': {
    gridArea: 'map-mobile',
    height: '15rem',
    width: 'auto',
    zIndex: 1,
    overflow: 'hidden',
    '&:empty': skeletonStyle,
  },

  '& .chat-container': {
    gridArea: 'chat-container',
    width: 'auto',
    maxHeight: 'calc(100vh - 5rem)',
    borderRadius: '$2lg',
    zIndex: 1,
    overflow: 'hidden',
    '&:empty': skeletonStyle,
  },
  '& .event-title': {
    gridArea: 'event-title',
    overflow: 'hidden',
    '&:empty': skeletonStyle,
  },
  '& .timeline-desktop': {
    gridArea: 'timeline-desktop',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    marginTop: '-1.5rem',
    zIndex: 1,
    overflow: 'hidden',
    '&:empty': skeletonStyle,
  },
  '& .share-desktop': {
    gridArea: 'share-desktop',
    '&:empty': skeletonStyle,
  },
  '& .event-more-info': {
    gridArea: 'event-more-info',
    '&:empty': skeletonStyle,
  },
  '& .mobile-select-stream-slider': {
    gridArea: 'mobile-select-stream-slider',
    '&:empty': skeletonStyle,
  },
  '& .mobile-chat-and-timeline-actions': {
    gridArea: 'mobile-chat-and-timeline-actions',
    '&:empty': skeletonStyle,
  },
  '& .mobile-streamer-sponsor': {
    gridArea: 'mobile-streamer-sponsor',
    overflow: 'hidden',
    borderRadius: '$2lg',
    zIndex: 1,
    '&:empty': skeletonStyle,
  },

  '@bp3': {
    paddingLeft: '0px',
    paddingRight: '0px',
    gap: '0px',
    '& .responsive-margin': {
      marginRight: '$xs',
      marginLeft: '$xs',
    },

    '& .video-mobile': {
      position: 'sticky',
      top: '0rem',
      overflow: 'hidden',
    },

    '& .timeline-desktop': {
      marginTop: '0px',
    },

    '& .event-more-info': {
      marginBottom: '8rem',
    },
  },

  '@b2k': {
    gridTemplateColumns: '1fr 30rem',
    maxWidth: '130rem',
  },
});
