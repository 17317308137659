import { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination } from 'swiper';
import { VideoJsPlayer } from 'video.js';
import useScreenOrientation from 'use-screen-orientation';

import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';

import { StreamModel } from '../../../../../../domain/models/stream-model';
import { SourceLocation } from '../../../../../../domain/models/event-timeline-model';
import StreamThumb from '../../../../../components/stream-thumb/stream-thumb';
import AppleLoading from '../../../../../components/spinners/AppleLoading/AppleLoading';
import Flex from '../../../../../components/primitives/flex';
import MobileAvatarSlide from './mobile-avatar-slide';
import { IMobileGridEventContext, MobileGridEventContext } from '../../mobile-grid-event/context';

interface IProps {
  isLiveSelected: boolean;
  isStreamListVisible: boolean;
  isLoading: boolean;
  onSelectStream: (index: number) => void;
  streamList: Map<string, StreamModel> | null;
  liveStreams: StreamModel[];
  streamSourceLocation: SourceLocation[];
  featuredStream: Partial<StreamModel> | null;
}

export default function MobileStreamListView({
  isLiveSelected,
  isStreamListVisible,
  isLoading,
  liveStreams,
  streamList,
  streamSourceLocation,
  featuredStream,
  onSelectStream,
}: IProps) {
  // const showLoopVideos = () => streamSourceLocation.length > 1 || liveStreams.length > 1;
  const { isFullscreen, selectedStreamPlayerRef } = useContext<IMobileGridEventContext>(MobileGridEventContext);
  const [orientation] = useScreenOrientation();

  const [swiperInstance, setSwiperInstance] = useState<any>(null);

  const onPlayerReady = (playerRef: VideoJsPlayer) => {
    selectedStreamPlayerRef.current = playerRef;
  };

  const showMobileAvatarSlide = (): boolean => {
    if (isLiveSelected && liveStreams.length > 1) return true;
    if (streamSourceLocation.length > 1) return true;
    return false;
  };

  const getVodStreamIndex = () => {
    const selectedStreamIndex = streamSourceLocation.findIndex((stream) => stream.stream_id === featuredStream?.id);
    return selectedStreamIndex;
  };

  const getLiveStreamIndex = () => {
    const selectedStreamIndex = liveStreams.findIndex((stream) => stream.id === featuredStream?.id);
    return selectedStreamIndex;
  };

  const onFeaturedStreamChanges = () => {
    if (!featuredStream) return;
    const foundIndex = isLiveSelected ? getLiveStreamIndex() : getVodStreamIndex();
    swiperInstance?.slideTo(foundIndex || 0);
  };
  useEffect(onFeaturedStreamChanges, [featuredStream]);

  const isAvatarListOnBottom = orientation === 'landscape' || isFullscreen;

  return (
    <Flex
      css={{
        width: '100%',
        height: '100%',
        flexDirection: isAvatarListOnBottom ? 'column-reverse' : 'column',
        backgroundColor: '$bgAppScreen100',
        gap: isAvatarListOnBottom ? '$3xs' : '$nano',
        paddingBottom: isAvatarListOnBottom ? '0.5rem' : 0,
        paddingTop: isAvatarListOnBottom ? '0.5rem' : 0,
      }}
    >
      {showMobileAvatarSlide() && (
        <MobileAvatarSlide
          isLiveSelected={isLiveSelected}
          isStreamListVisible={isStreamListVisible}
          liveStreams={liveStreams}
          streamList={streamList}
          streamSourceLocation={streamSourceLocation}
          featuredStream={featuredStream}
          onSelectStream={onSelectStream}
        />
      )}

      <Swiper
        slidesPerView="auto"
        centeredSlides
        spaceBetween={8}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        // loop={showLoopVideos()}
        // pagination={{
        //   clickable: true,
        // }}
        // modules={[Pagination]}
        allowTouchMove={!isFullscreen}
        onSlideChange={(e) => onSelectStream(e.realIndex)}
        className="mobile-stream-list"
      >
        {isStreamListVisible &&
          !isLiveSelected &&
          streamSourceLocation.map(({ stream_id, offset_ms }, index: number) => (
            <SwiperSlide
              key={stream_id}
              className={`swiper-slide-stream ${streamSourceLocation.length === 1 ? 'single' : ''}`}
            >
              <StreamThumb
                streamData={streamList?.get(stream_id)}
                isSelected={featuredStream?.id === stream_id}
                showVideo={featuredStream?.id === stream_id}
                isFirstOnList={index === 0}
                isLastOnList={index === streamSourceLocation.length - 1}
                videoTimeOffset={offset_ms}
                onPlayerReady={onPlayerReady}
                index={index}
              />
            </SwiperSlide>
          ))}
        {isStreamListVisible &&
          isLiveSelected &&
          liveStreams.map((stream, index: number) => (
            <SwiperSlide key={stream.id} className={`swiper-slide-stream ${liveStreams.length === 1 ? 'single' : ''}`}>
              <StreamThumb
                streamData={stream}
                isSelected={featuredStream?.id === stream.id}
                isFirstOnList={index === 0}
                isLastOnList={index === liveStreams.length - 1}
                onPlayerReady={onPlayerReady}
                index={index}
              />
            </SwiperSlide>
          ))}
      </Swiper>
      {isLoading && (
        <Flex
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.2)',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AppleLoading />
        </Flex>
      )}
    </Flex>
  );
}
