import { ComponentPropsWithRef } from 'react';
import { styled } from '../../../stitches.config';

export type TextProps = ComponentPropsWithRef<typeof Text>;

const Text = styled('span', {
  fontFamily: 'Inter, sans-serif',

  variants: {
    type: {
      h3: {
        fontSize: '$h3',
        fontWeight: '$bold',
        color: '$gs100',
        lineHeight: '1.5rem',
      },
      h4: {
        fontSize: '$h4',
        fontWeight: '$bold',
        color: '$gs100',
        lineHeight: '1.5rem',
      },
      h5: {
        fontSize: '$h5',
        fontWeight: '$bold',
        color: '$gs100',
        lineHeight: '1.5rem',
      },
      h6: {
        fontSize: '$h6',
        fontWeight: '$bold',
        color: '$gs100',
        lineHeight: '1.5rem',
      },
      bodyBold: {
        fontSize: '$body',
        fontWeight: '$bold',
        lineHeight: '1.25rem',
      },
      bodyRegular: {
        fontSize: '$body',
        fontWeight: '$book',
        lineHeight: '1.25rem',
      },
      bodySm: {
        fontSize: '$bodySm',
        fontWeight: '$demi',
        lineHeight: '1.25rem',
      },
      bodySmallMedium: {
        fontSize: '$bodySm',
        fontWeight: '$medium',
        lineHeight: '1.125rem',
      },
      linkSmall: {
        fontSize: '$linkSmall',
        fontWeight: '$bold',
        cursor: 'pointer',
        lineHeight: '1.125rem',
      },
      caption: {
        fontSize: '$caption',
        fontWeight: '$demi',
        lineHeight: '1.125rem',
      },
      captionSmall: {
        fontSize: '$captionSmall',
        fontWeight: '$book',
        lineHeight: '1.125rem',
      },
      footnote: {
        fontSize: '$footnote',
        fontWeight: '$medium',
        lineHeight: '1.125rem',
      },
    },
  },
});

export { Text };
