import Lottie from 'lottie-react';
import useWindowInnerHeight from '../../../hooks/use-window-inner-height/use-window-inner-height';
import Flex from '../../primitives/flex';
import animationData from './data.json';

export default function PulseLogoAnimated() {
  const windowInnerHeight = useWindowInnerHeight();

  return (
    <Flex
      className="init-loading"
      css={{
        width: '100%',
        height: windowInnerHeight,
        backgroundColor: '$primaryAdrenaline100',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Flex css={{ width: '37.5rem', alignItems: 'center', justifyContent: 'center' }}>
        <Lottie animationData={animationData} loop />
      </Flex>
    </Flex>
  );
}
