interface IProps {
  color?: string;
}

export default function PulseLogoMarkDark({ color = '#131313' }: IProps) {
  return (
    <svg
      width="43"
      height="70"
      viewBox="0 0 43 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="pulse-logo-mark-dark"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.818077 64.4298L10.253 69.8835C10.401 69.9629 10.567 70.003 10.735 69.9998C10.903 69.9967 11.0673 69.9505 11.2123 69.8657C11.3574 69.7809 11.4782 69.6602 11.5633 69.5154C11.6483 69.3705 11.6948 69.2062 11.6982 69.0382V21.4003C11.7037 21.1111 11.7912 20.8295 11.9505 20.5881C12.1098 20.3467 12.3343 20.1556 12.598 20.0368L39.8665 7.3843C40.0267 7.30172 40.1611 7.1766 40.2549 7.02268C40.3488 6.86876 40.3984 6.69198 40.3984 6.51171C40.3984 6.33145 40.3488 6.15466 40.2549 6.00074C40.1611 5.84682 40.0267 5.72171 39.8665 5.63912L30.377 0.185438C30.1507 0.0637145 29.8978 0 29.6408 0C29.3838 0 29.1309 0.0637145 28.9045 0.185438L0.899879 13.2743C0.63205 13.3873 0.403685 13.5771 0.243549 13.8198C0.0834135 14.0624 -0.00133211 14.347 1.58335e-05 14.6377V63.0119C-0.000168415 63.2993 0.0753513 63.5816 0.218959 63.8305C0.362566 64.0795 0.569203 64.2862 0.818077 64.4298ZM31.9042 32.6076L21.8421 37.2978C21.5032 37.4594 21.2177 37.7149 21.0195 38.0339C20.8214 38.3528 20.7188 38.722 20.7241 39.0975V47.0053C20.7229 47.1705 20.7633 47.3332 20.8417 47.4786C20.92 47.624 21.0338 47.7472 21.1725 47.837C21.3111 47.9267 21.4702 47.98 21.6349 47.9919C21.7996 48.0038 21.9647 47.974 22.1148 47.9052L41.5845 38.8793C41.7972 38.7802 41.977 38.6223 42.1027 38.4241C42.2283 38.226 42.2945 37.9959 42.2935 37.7613V17.8826C42.2902 17.7198 42.2464 17.5603 42.1662 17.4186C42.0859 17.2769 41.9717 17.1574 41.8337 17.0708C41.6958 16.9842 41.5384 16.9334 41.3759 16.9228C41.2134 16.9122 41.0508 16.9421 40.9028 17.01L33.7584 20.3368C33.4165 20.4898 33.1264 20.7386 32.9231 21.0532C32.7198 21.3679 32.6121 21.7347 32.6132 22.1092V31.4896C32.6177 31.7249 32.5529 31.9564 32.4268 32.1552C32.3008 32.354 32.119 32.5113 31.9042 32.6076Z"
        fill={color}
      />
    </svg>
  );
}
