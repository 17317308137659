import LiveIndicator from '../../../../components/icons/live-animated-indicator';
import Button from '../../../../components/primitives/button';
import useEventStore from '../../state-manager/store';

function formatDate(date: Date): string {
  return date.toISOString().slice(0, 10);
}

export function EventJumpToLiveButton() {
  const setCurrentTime = useEventStore((state) => state.setCurrentTime);
  const setCurrentDay = useEventStore((state) => state.setCurrentDay);
  const setFeatStream = useEventStore((state) => state.setFeatStream);
  const eventStreamsLinearList = useEventStore((state) => state.eventStreamsLinearList);
  const isEventLive = useEventStore((state) => state.isEventLive);
  const featStream = useEventStore((state) => state.featStream);
  if (featStream?.status === 'started' || !isEventLive) return null;

  const jumpToLive = () => {
    setCurrentDay(formatDate(new Date()));
    setCurrentTime(new Date());
    const liveStream = eventStreamsLinearList.find((stream) => stream.status === 'started');
    if (liveStream) setFeatStream(liveStream);
  };

  return (
    <Button
      size="sm"
      variant="default"
      onClick={jumpToLive}
      css={{
        display: 'flex',
        gap: '10px',
        background: 'rgba(218, 255, 1, 0.8)',
        color: 'rgba(0, 0, 0, 0.7)',
      }}
    >
      <LiveIndicator />
      Jump to live
    </Button>
  );
}
