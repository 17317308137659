import { useEffect, useState } from 'react';
import { UserInfo } from '../../../../components/user-info/user-info';
import useAuthStore from '../../../auth-wrapper/state-manager/store';
import { useStreamViewContext } from '../../state-manager/context';
import useStreamViewStore from '../../state-manager/store';
import useGlobalStore from '../../../../app-global-store';

export function StreamViewUserInfo() {
  const { channelRepository } = useStreamViewContext();
  const openAuthModalIfItsNotLogged = useAuthStore((state) => state.openAuthModalIfItsNotLogged);
  const isLogged = useAuthStore((state) => state.isLogged);
  const analytics = useGlobalStore((state) => state.analytics);
  const streamInfo = useStreamViewStore((state) => state.streamInfo);
  const [isFollowing, setIsFollowing] = useState<boolean>(streamInfo?.channel?.following || false);

  const onChangeStreamInfo = () => {
    if (!streamInfo) return;
    setIsFollowing(streamInfo?.channel?.following || false);
  };
  useEffect(onChangeStreamInfo, [streamInfo]);
  const onClickFollow = async () => {
    const pastIsFollowingState = isFollowing;

    try {
      if (!streamInfo?.channel?.id) return;
      if (!isLogged()) return openAuthModalIfItsNotLogged();
      analytics.track(pastIsFollowingState ? 'unfollow_channel' : 'follow_channel', {
        streamerId: streamInfo?.channel.id,
      });
      setIsFollowing(!pastIsFollowingState);
      if (!pastIsFollowingState) {
        await channelRepository.follow(streamInfo?.channel?.id);
      } else {
        await channelRepository.unfollow(streamInfo?.channel?.id);
      }
    } catch (e) {
      analytics.track(!pastIsFollowingState ? 'rollback_unfollow_channel' : 'rollback_follow_channel', {
        streamerId: streamInfo?.channel.id,
      });
      setTimeout(() => setIsFollowing(pastIsFollowingState), 1000);
    }
  };

  return (
    <UserInfo
      realname={streamInfo?.channel.title || ''}
      username={streamInfo?.channel?.username || ''}
      useravatar={streamInfo?.channel?.hero_photo.original || ''}
      onClickFollow={onClickFollow}
      isFollowing={isFollowing}
    />
  );
}
