import { PaceIcon } from '../../../../components/icons/pace';
import Button from '../../../../components/primitives/button';
import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';

interface IProps {
  onClickStartOver?: () => void;
  onClickReplayCurrentStream: () => void;
}

export default function NoNewStreams({ onClickStartOver, onClickReplayCurrentStream }: IProps) {
  return (
    <Flex
      css={{
        backgroundColor: 'rgba(0,0,0,0.7)',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: '100%',
        flexDirection: 'column',
        zIndex: 10,
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <Flex css={{ maxWidth: '13rem', flexDirection: 'column', alignItems: 'center', gap: '$sm' }}>
        <Flex css={{ flexDirection: 'column', alignItems: 'center', gap: '$3xs' }}>
          <PaceIcon />
          <Typo type="bodySm" css={{ color: '$constantWhite', fontWeight: '$book' }}>
            No new streams
          </Typo>
        </Flex>

        <Typo
          type="bodyRegular"
          css={{
            color: '$constantWhite',
            textAlign: 'center',
            fontWeight: '$bold',
            fontSize: '$h6',
            lineHeight: '24px',
            '@bp3': {
              display: 'none',
            },
          }}
        >
          Waiting for new streams to come live.
        </Typo>
        <Flex css={{ flexDirection: 'column', gap: '$3xs' }}>
          <Button onClick={onClickReplayCurrentStream} css={{ color: '$constantBlack' }}>
            Replay this stream
          </Button>
          <Button variant="outline" onClick={onClickStartOver}>
            Rewatch the event!
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
