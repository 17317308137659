import { SpeedType } from '../../domain/models/stream-model';

function secondsToTime(e: number) {
  const m = Math.floor((e % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const s = Math.floor(e % 60)
    .toString()
    .padStart(2, '0');

  return `${m}:${s}`;
}

function convertMetersPerSecondToMinPerKm(metersPerSecond: number) {
  if (metersPerSecond === null || metersPerSecond === undefined || metersPerSecond < 0) return '0:0';

  const MINIMUM_METERS_PER_SECOND = 0.2778;
  if (metersPerSecond <= MINIMUM_METERS_PER_SECOND) return '0:0';

  const metersPerMinute = metersPerSecond * 60;
  const minutesPerKm = 1000 / metersPerMinute;

  const secondsPerKm = minutesPerKm * 60;

  return secondsToTime(secondsPerKm);
}

function convertMetersPerSecondToKilometerPerHour(metersPerSecond: number): string {
  return `${((metersPerSecond * 60 * 60) / 1000).toFixed(1)}`;
}

export function formattedSpeedValue(speedType: SpeedType | undefined, value: number): string {
  switch (speedType) {
    case SpeedType.distance_per_hour:
      return convertMetersPerSecondToKilometerPerHour(value);

    case SpeedType.minutes_per_distance: {
      return convertMetersPerSecondToMinPerKm(value);
    }
    default:
      return convertMetersPerSecondToKilometerPerHour(value);
  }
}
export function getSpeedMeasurement(speedType: SpeedType | undefined): string {
  switch (speedType) {
    case SpeedType.distance_per_hour:
      return 'km/h';

    case SpeedType.minutes_per_distance:
      return 'min/km';

    default: {
      return 'km/h';
    }
  }
}
export function getSpeedMeasurementForMobile(speedType: SpeedType | undefined): string {
  switch (speedType) {
    case SpeedType.distance_per_hour:
      return 'km/h';

    case SpeedType.minutes_per_distance:
      return 'min';

    default: {
      return 'km/h';
    }
  }
}
export function getSpeedAlias(speedType: SpeedType | undefined): string {
  switch (speedType) {
    case SpeedType.distance_per_hour:
      return 'Speed';

    case SpeedType.minutes_per_distance:
      return 'Pace';
    default: {
      return 'Speed';
    }
  }
}

export function getAltitude(meters: number | undefined): string {
  if (!meters || meters < 0) return '0';
  return `${meters.toFixed(1)} `;
}
