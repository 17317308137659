import { createContext, useContext } from 'react';
import { RemoteChannel } from '../../../../data/usecases/remote-channel';
import { RemoteStream } from '../../../../data/usecases/remote-stream';
import { RemoteStreamAnonymous } from '../../../../data/usecases/remote-stream-anonymous';
import { LocalStorageAdapter } from '../../../../infra/cache/local-storage-adapter';

export interface IStreamViewContext {
  streamRepository: RemoteStream;
  streamAnonymousRepository: RemoteStreamAnonymous;
  channelRepository: RemoteChannel;
  localStorageAdapter: LocalStorageAdapter;
}

const StreamViewContext = createContext({} as IStreamViewContext);
export const StreamViewContextProvider = StreamViewContext.Provider;

export function useStreamViewContext() {
  return useContext(StreamViewContext);
}
