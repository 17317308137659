import Box from '../primitives/box';
import Flex from '../primitives/flex';
import { Typo } from '../primitives/typo/typo';

import Button from '../primitives/button';
import ArrowLinkIcon from '../icons/arrow-link';

interface IProps {
  name: string;
  link?: string;
  logoSrc?: string;
  isRedirectLinkVisible?: boolean;
}

export default function Heading({ name, link, logoSrc, isRedirectLinkVisible = true }: IProps) {
  return (
    <Flex
      className="heading"
      css={{
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        backgroundColor: '$bgAppScreen100',
        paddingRight: '$sm',
      }}
    >
      <Flex
        className="event-name"
        css={{
          gap: '$xs',
          alignItems: 'center',
          width: '100%',
          padding: '20px 0px 20px 0px',
        }}
      >
        {logoSrc && (
          <Box
            as="img"
            src={logoSrc}
            alt="pwc logo"
            css={{
              width: '3.37rem',
              height: '3.37rem',
              borderRadius: '$sm',
              overflow: 'hidden',
              objectFit: 'contain',
              '@bp3': {
                width: '12rem',
                borderRadius: '$lg',
              },
            }}
          />
        )}

        <Flex
          css={{
            alignItems: 'center',
            gap: '$3xs',
            width: '100%',
          }}
        >
          <Typo
            as="h5"
            type="h5"
            className="event-text"
            css={{
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              // whiteSpace: 'nowrap',
              '@bp3': {
                fontSize: '1.1rem',
                width: '100%',
              },
            }}
          >
            {name}
          </Typo>
          {link && isRedirectLinkVisible && (
            <Button as="a" href={link} target="_blank" variant="transparent">
              <ArrowLinkIcon />
            </Button>
          )}
        </Flex>
      </Flex>
      {/* <Flex className="sponsors-list" css={{ gap: '$sm' }}>
        {sponsorsLogos.map((sponsor) => (
          <Box key={sponsor.alt} as="a" href={sponsor.url} target="_blank">
            <Box as="img" src={sponsor.src} alt={sponsor.alt} />
          </Box>
        ))}
      </Flex> */}
    </Flex>
  );
}
