import { Link } from 'react-router-dom';
import { Link2Icon } from '@radix-ui/react-icons';
import { SpeedType } from '../../../../domain/models/stream-model';
import { formattedSpeedValue, getAltitude, getSpeedMeasurement } from '../../../utils/speed-conversions';
import Flex from '../../primitives/flex';
import { Text } from '../../primitives/text/text';
import { PulseAvatar } from '../../pulse-avatar/pulse-avatar';
import { GpsMetaData } from './gps-type';

interface IProps {
  gpsData: GpsMetaData | null;
  username?: string;
  useravatar?: string;
  channelTitle?: string;
  speedType?: SpeedType;
  isFullScreen?: boolean;
  isPip?: boolean;
  hasBackDrop?: boolean;
  streamId?: string;
  isVideoStreamClosed?: boolean;
}

const commonTextStyle = {
  textShadow: '0px 1px 5px rgba(0, 0, 0, 0.87)',
  color: '$white',
};

const mainTextBigStyle = {
  fontSize: '1.70rem',
  fontWeight: '$demi',
  '@bp3': {
    fontSize: '1.40rem',
  },
  ...commonTextStyle,
};

const secondaryTextBigStyle = {
  fontSize: '0.87rem',
  fontWeight: '$bold',
  '@bp3': {
    fontSize: '0.7rem',
  },
  ...commonTextStyle,
};

export default function NewTelemetryBadge({
  gpsData,
  username,
  useravatar,
  speedType,
  channelTitle,
  isPip = false,
  isFullScreen = false,
  hasBackDrop,
  streamId,
  isVideoStreamClosed,
}: IProps) {
  const MINIMUM_METERS_PER_SECOND = 0.2778;
  const isSpeedValid = (gpsData?.gps_speed || 0) > MINIMUM_METERS_PER_SECOND;
  const opacityValue = gpsData && isFullScreen ? 1 : 0;

  const contrastBackgroundStyle = hasBackDrop
    ? {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '$xs',
        borderRadius: '$2lg',
        backdropFilter: 'blur(2px)',
        minWidth: '9rem',
      }
    : {};

  return (
    <Flex
      className={`telemetry-wrapper ${isVideoStreamClosed && 'telemetry-wrapper-closed'}`}
      css={{
        flexDirection: 'column',
        gap: '$2xs',
        opacity: opacityValue,
        position: 'absolute',
        bottom: '1.5rem',
        transformOrigin: 'bottom left',
        transform: isFullScreen ? 'scale(1.5)' : 'scale(1)',
        left: '1rem',
        transition: 'visibility 0.5s, opacity 0.5s, width 0.5s',
        ...contrastBackgroundStyle,
        '&.telemetry-wrapper-closed': {
          display: 'none',
        },
        '@bp3': {
          left: '0.8rem',
        },
      }}
    >
      {!isPip && (
        <>
          <Flex
            className="speed-badge"
            css={{
              flexDirection: 'column',
              gap: '$nano',
              display: isSpeedValid ? 'flex' : 'none',
            }}
          >
            <Flex css={{ gap: '$nano', alignItems: 'flex-end' }}>
              <Text css={{ ...mainTextBigStyle }}>{formattedSpeedValue(speedType, gpsData?.gps_speed || 0)}</Text>
              <Text
                css={{
                  ...mainTextBigStyle,
                  fontSize: '1rem',
                  '@bp3': {
                    fontSize: '0.9rem',
                  },
                }}
              >
                {getSpeedMeasurement(speedType)}
              </Text>
            </Flex>
            <Text css={{ ...secondaryTextBigStyle }}>speed</Text>
          </Flex>
          <Flex className="altitude-badge" css={{ flexDirection: 'column', gap: '$nano' }}>
            <Flex css={{ gap: '$nano', alignItems: 'flex-end' }}>
              <Text css={{ ...mainTextBigStyle }}>{getAltitude(gpsData?.gps_altitude)}</Text>
              <Text
                css={{
                  ...mainTextBigStyle,
                  fontSize: '1rem',
                  '@bp3': {
                    fontSize: '0.9rem',
                  },
                }}
              >
                m
              </Text>
            </Flex>

            <Text css={{ ...secondaryTextBigStyle }}>altitude</Text>
          </Flex>
        </>
      )}

      {username && (
        <Flex className="user-info-badge" css={{ gap: '$2xs', alignItems: 'center' }}>
          {useravatar && <PulseAvatar imageUrl={useravatar} title={username} />}

          <Flex css={{ flexDirection: 'column', gap: '$nano' }}>
            <Link to={`/streams/${streamId}`} style={{ textDecoration: 'none' }}>
              <Text
                className="channel-title"
                css={{
                  fontSize: '1.36rem',
                  fontWeight: '$demi',
                  textDecoration: 'none',
                  '@bp3': {
                    fontSize: '1.1rem',
                  },
                  ...commonTextStyle,
                }}
              >
                {channelTitle} <Link2Icon style={{ width: '20px', height: '20px' }} />
              </Text>
            </Link>
            <Text
              className="user-name"
              css={{
                fontSize: '0.7rem',
                '@bp3': {
                  fontSize: '0.6rem',
                },
                fontWeight: '$demi',
                ...commonTextStyle,
              }}
            >
              {username}
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
