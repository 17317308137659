export default function PictureAndPictureIcon() {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V8C0 7.71667 0.0958333 7.47917 0.2875 7.2875C0.479167 7.09583 0.716667 7 1 7C1.28333 7 1.52083 7.09583 1.7125 7.2875C1.90417 7.47917 2 7.71667 2 8V14H18V2H10C9.71667 2 9.47917 1.90417 9.2875 1.7125C9.09583 1.52083 9 1.28333 9 1C9 0.716667 9.09583 0.479167 9.2875 0.2875C9.47917 0.0958333 9.71667 0 10 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H2ZM12 9.425L14.375 11.8C14.575 12 14.8083 12.1 15.075 12.1C15.3417 12.1 15.575 12 15.775 11.8C15.975 11.6 16.075 11.3625 16.075 11.0875C16.075 10.8125 15.975 10.575 15.775 10.375L13.4 8H15C15.2833 8 15.5208 7.90417 15.7125 7.7125C15.9042 7.52083 16 7.28333 16 7C16 6.71667 15.9042 6.47917 15.7125 6.2875C15.5208 6.09583 15.2833 6 15 6H11C10.7167 6 10.4792 6.09583 10.2875 6.2875C10.0958 6.47917 10 6.71667 10 7V11C10 11.2833 10.0958 11.5208 10.2875 11.7125C10.4792 11.9042 10.7167 12 11 12C11.2833 12 11.5208 11.9042 11.7125 11.7125C11.9042 11.5208 12 11.2833 12 11V9.425ZM1 5C0.716667 5 0.479167 4.90417 0.2875 4.7125C0.0958333 4.52083 0 4.28333 0 4V1C0 0.716667 0.0958333 0.479167 0.2875 0.2875C0.479167 0.0958333 0.716667 0 1 0H6C6.28333 0 6.52083 0.0958333 6.7125 0.2875C6.90417 0.479167 7 0.716667 7 1V4C7 4.28333 6.90417 4.52083 6.7125 4.7125C6.52083 4.90417 6.28333 5 6 5H1Z"
        fill="white"
        fillOpacity="0.91"
      />
    </svg>
  );
}
