import { useContext, useEffect, useState } from 'react';
import type { MapRef } from 'react-map-gl';
import { VideoJsPlayer } from 'video.js';
import GenericStreamMap from '../../../../components/generic-stream-map/generic-stream-map';

import VideoStream from '../../../../components/third-party/video-stream/video-stream';
import { ISquadVideoContext, SquadVideoContext } from '../../context';
import { EventMap } from '../event-map/event-map';

enum ContainerState {
  MapFull,
  StreamFull,
  Half,
}

let realTimeAthletePositionsRef = new Map();

export default function VideoAndMapWrapper() {
  const { featuredStream, isLiveSelected, realTimeAthletePositions, setFeaturedStreamPlayerRef } =
    useContext<ISquadVideoContext>(SquadVideoContext);
  const [isPip, setIsPip] = useState<boolean>(true);
  const [isStreamVisible, setIsStreamVisible] = useState<boolean>(true);
  const [mapRef, setMapRef] = useState<MapRef | null>(null);

  const onRealTimePositionChanges = () => {
    realTimeAthletePositionsRef = realTimeAthletePositions;
  };
  useEffect(onRealTimePositionChanges, [realTimeAthletePositions]);

  const onFeaturedStreamChanges = () => {
    if (!featuredStream) return;
    setIsStreamVisible(false);
    setTimeout(() => setIsStreamVisible(true), 100);
  };
  useEffect(onFeaturedStreamChanges, [featuredStream]);

  const resizeMap = () => {
    if (mapRef) mapRef.resize();
  };

  const onMapReady = (ref: MapRef | null) => {
    setMapRef(ref);
  };

  const getSelectedThumbnailPlayerRef = () => {
    const stream = realTimeAthletePositionsRef.get(featuredStream?.id || '');
    if (!stream) return null;
    return stream.videoPlayerRef;
  };

  const onFeaturedVideoPlay = () => {
    const thumnailPlayerRef = getSelectedThumbnailPlayerRef();
    if (!thumnailPlayerRef) return;
    thumnailPlayerRef?.play();
  };

  const onFeaturedVideoPause = () => {
    const thumnailPlayerRef = getSelectedThumbnailPlayerRef();
    if (!thumnailPlayerRef) return;
    thumnailPlayerRef?.pause();
  };

  const onFeaturedVideoSeek = (time: number) => {
    const thumnailPlayerRef = getSelectedThumbnailPlayerRef();
    if (!thumnailPlayerRef) return;
    thumnailPlayerRef?.currentTime(time);
  };

  const onVideoPlayerReady = (playerRef: VideoJsPlayer) => {
    setFeaturedStreamPlayerRef(playerRef);
  };

  const onChangeContainerState = (state: ContainerState) => {
    if (state === ContainerState.MapFull) return setIsPip(true);
    setIsPip(false);
  };

  return (
    <GenericStreamMap
      streamElement={
        !!featuredStream && !!featuredStream?.playback_url && isStreamVisible ? (
          <VideoStream
            url={featuredStream.playback_url}
            timeOffset={featuredStream.offset_ms}
            isFeatured
            isLive={isLiveSelected}
            onPlay={onFeaturedVideoPlay}
            onPause={onFeaturedVideoPause}
            onSeek={onFeaturedVideoSeek}
            onVideoPlayerReady={onVideoPlayerReady}
            username={featuredStream?.channel?.username || ''}
            useravatar={featuredStream?.channel?.hero_photo.original || ''}
            speedType={featuredStream?.activity_type?.speed_type}
            isPip={isPip}
          />
        ) : undefined
      }
      mapComponent={<EventMap onMapReady={onMapReady} />}
      onAnimationEnds={resizeMap}
      onChangeContainerState={onChangeContainerState}
    />
  );
}
