export function UnionRightIcon() {
  return (
    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.187 16.187C19.679 16.6957 19.05 16.95 18.3 16.95H2.65C1.9 16.95 1.27067 16.6957 0.762001 16.187C0.254001 15.679 0 15.05 0 14.3V2.65C0 1.9 0.254001 1.27067 0.762001 0.762C1.27067 0.254 1.9 0 2.65 0H18.3C19.05 0 19.679 0.254 20.187 0.762C20.6957 1.27067 20.95 1.9 20.95 2.65V14.3C20.95 15.05 20.6957 15.679 20.187 16.187ZM2.65 14.3H18.3V2.65H2.65V14.3ZM9.97539 3.47461C9.69925 3.47461 9.47539 3.69847 9.47539 3.97461V12.9746C9.47539 13.2508 9.69925 13.4746 9.97539 13.4746H16.9754C17.2515 13.4746 17.4754 13.2508 17.4754 12.9746V3.97461C17.4754 3.69847 17.2515 3.47461 16.9754 3.47461H9.97539Z"
        fill="white"
        fillOpacity="0.91"
      />
    </svg>
  );
}
