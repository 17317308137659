/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { create } from 'zustand';
import throttle from 'lodash/throttle';
import { VideoJsPlayer } from 'video.js';
import { MapRef } from 'react-map-gl';
import { GetEventInfo } from '../../../../domain/usecases';
import { makeRemoteGetEventInfo } from '../../../../main/usecases/remote-get-event-factory';
import { EventPlayStatus, EventStream, ListOfPolylines, NewEventStream, Polyline } from './store-types';
import { isLive } from '../utils/utils';

type StreamsHash = {
  [key: string]: EventStream;
};

type PlayerRef = {
  id: string;
  ref: VideoJsPlayer | null;
};

interface EventState {
  mainContentElement: Element | null;
  setMainContentElement: (element: Element | null) => void;

  currentDay: string | null;
  setCurrentDay: (currentDay: string | null) => void;

  fullScreenControler: { isFullScreen: boolean; toggleFullscreen: () => void } | null;
  setFullScreenControler: (controler: { isFullScreen: boolean; toggleFullscreen: () => void }) => void;

  isEventLive: boolean;
  setIsEventLive: (live: boolean) => void;

  isUserWatchingLive: () => boolean;

  currentTime: Date | null;
  setCurrentTime: (currentTime: Date | null) => void;

  hoverTime: Date | null;
  setHoverTime: (currentTime: Date | null) => void;

  incrementCurrentTime: (amount: number) => void;
  intervalId: ReturnType<typeof setTimeout> | null;
  setIntervalId: (value: ReturnType<typeof setTimeout> | null) => void;
  eventPlaybackSpeed: number;
  setEventPlaybackSpeed: (value: number) => void;

  eventInfo: GetEventInfo.Model | null;
  setEventInfo: (event: GetEventInfo.Model) => void;

  pulseLinesGroupedByStream: ListOfPolylines;
  setPulseLinesGroupedByStream: (pulselines: ListOfPolylines) => void;

  pulseLinesGroupedByUserAndSortedByTimestamp: ListOfPolylines;
  setPulseLinesGroupedByUserAndSortedByTimestamp: (pulselines: ListOfPolylines) => void;

  playStatus: EventPlayStatus;
  setPlayStatus: (status: EventPlayStatus) => void;

  isStreamVisible: boolean;
  setIsStreamVisible: (isVisible: boolean) => void;
  eventTimeline: GetEventInfo.TimeLineV2Model | null;
  setEventTimeline: (timeline: GetEventInfo.TimeLineV2Model) => void;

  playerList: PlayerRef[];
  addPlayerRef: (playerRef: PlayerRef) => void;
  removePlayerRef: (id: string) => void;

  playerElement: VideoJsPlayer | null;
  setPlayerElement: (playerElement: VideoJsPlayer | null) => void;

  eventStreams: StreamsHash;
  setEventStreams: (eventStreams: StreamsHash) => void;

  eventStreamsLinearList: NewEventStream[];
  setEventStreamsLinearList: (eventStreams: NewEventStream[]) => void;

  currentEventStreamsLinearList: NewEventStream[];
  setCurrentEventStreamsLinearList: (eventStreams: NewEventStream[]) => void;

  hoverEventStreamsLinearList: NewEventStream[];
  setHoverEventStreamsLinearList: (eventStreams: NewEventStream[]) => void;

  eventCurrentStreams: Polyline;
  eventHoverStreams: Polyline;
  setEventCurrentStreams: (eventStreams: Polyline) => void;
  setEventHoverStreams: (eventStreams: Polyline) => void;

  featStream: NewEventStream | null;
  setFeatStream: (stream: NewEventStream | null) => void;

  isStreamLive: (id: string) => boolean;

  rightSideStream: NewEventStream | null;
  setRightSideStream: (stream: NewEventStream | null) => void;

  mapRef: MapRef | null;
  setMapRef: (map: MapRef | null) => void;
  isAuthDialogVisible: boolean;
  setIsAuthDialogVisible: (isVisible: boolean) => void;
  eventRepository: GetEventInfo;
}

const UPDATE_INTERVAL = 1000;

const useEventStore = create<EventState>()((set, get) => ({
  mainContentElement: null,
  setMainContentElement: (mainContentElement) => set(() => ({ mainContentElement })),

  fullScreenControler: null,
  setFullScreenControler: (fullScreenControler) => set(() => ({ fullScreenControler })),

  currentDay: null,
  setCurrentDay: (currentDay) => set(() => ({ currentDay })),

  currentTime: null,
  setCurrentTime: (currentTime) => set(() => ({ currentTime })),
  incrementCurrentTime: (amount = UPDATE_INTERVAL) =>
    set((state) => {
      const newTime = (state?.currentTime?.getTime() || 0) + amount;
      return {
        currentTime: state?.currentTime ? new Date(newTime) : null,
      };
    }),

  hoverTime: null,
  setHoverTime: (hoverTime) => set(() => ({ hoverTime })),

  intervalId: null,
  setIntervalId: (intervalId) => set(() => ({ intervalId })),
  eventPlaybackSpeed: 1,
  setEventPlaybackSpeed: (eventPlaybackSpeed) => set(() => ({ eventPlaybackSpeed })),

  isEventLive: false,
  setIsEventLive: (isEventLive) => set(() => ({ isEventLive })),

  isUserWatchingLive: () => {
    const { featStream } = get();
    return isLive(featStream?.status || '');
  },
  isStreamLive: (id: string) => {
    const { eventStreamsLinearList } = get();
    const foundStream = eventStreamsLinearList.find((stream) => stream.id === id);
    return isLive(foundStream?.status || '');
  },

  eventInfo: null,
  setEventInfo: (event) => set(() => ({ eventInfo: event })),
  playStatus: EventPlayStatus.IDLE,
  setPlayStatus: (playStatus) => set(() => ({ playStatus })),
  isStreamVisible: true,
  setIsStreamVisible: (isStreamVisible) => set(() => ({ isStreamVisible })),

  pulseLinesGroupedByStream: [],
  setPulseLinesGroupedByStream: (pulseLinesGroupedByStream) => set(() => ({ pulseLinesGroupedByStream })),

  pulseLinesGroupedByUserAndSortedByTimestamp: [],
  setPulseLinesGroupedByUserAndSortedByTimestamp: (pulseLinesGroupedByUserAndSortedByTimestamp) =>
    set(() => ({ pulseLinesGroupedByUserAndSortedByTimestamp })),

  playerElement: null,
  setPlayerElement: (playerElement) => set(() => ({ playerElement })),

  playerList: [],
  addPlayerRef: (playerRef: PlayerRef) => {
    const { playerList } = get();
    playerList.push(playerRef);
    return set(() => ({ playerList }));
  },
  removePlayerRef: (id: string) => {
    const { playerList: playerListOld } = get();
    const playerList = playerListOld.filter((ref) => ref.id !== id);
    return set(() => ({ playerList }));
  },

  eventTimeline: null,
  setEventTimeline: (eventTimeline) => set(() => ({ eventTimeline })),
  eventStreams: {},
  eventCurrentStreams: [],
  eventHoverStreams: [],
  setEventCurrentStreams: (eventCurrentStreams) => set(() => ({ eventCurrentStreams })),
  setEventHoverStreams: (eventHoverStreams) => set(() => ({ eventHoverStreams })),
  setEventStreams: (newEventStreams) => {
    const oldEventStreams = get().eventStreams;
    const eventStreams = { ...oldEventStreams, ...newEventStreams };
    return set(() => ({ eventStreams }));
  },

  eventStreamsLinearList: [],
  setEventStreamsLinearList: (eventStreamsLinearList) => set(() => ({ eventStreamsLinearList })),

  currentEventStreamsLinearList: [],
  setCurrentEventStreamsLinearList: (currentEventStreamsLinearList) => set(() => ({ currentEventStreamsLinearList })),

  hoverEventStreamsLinearList: [],
  setHoverEventStreamsLinearList: (hoverEventStreamsLinearList) => set(() => ({ hoverEventStreamsLinearList })),

  featStream: null,
  setFeatStream: (featStream) => set(() => ({ featStream })),

  rightSideStream: null,
  setRightSideStream: (rightSideStream) => set(() => ({ rightSideStream })),

  mapRef: null,
  setMapRef: (mapRef) => set(() => ({ mapRef })),
  isAuthDialogVisible: false,
  setIsAuthDialogVisible: (isAuthDialogVisible) => set(() => ({ isAuthDialogVisible })),
  eventRepository: makeRemoteGetEventInfo(),
}));

const WAIT_MILISECONDS = 10;

export function playEventRef(intervalFrequency = UPDATE_INTERVAL) {
  const { intervalId } = useEventStore.getState();
  if (intervalId) return;
  useEventStore.getState().setPlayStatus(EventPlayStatus.PLAYING);
  const { eventPlaybackSpeed, playerList } = useEventStore.getState();
  if (playerList.length) {
    playerList?.forEach((playerElement) => playerElement.ref?.play());
  }

  const intervalIdRef = setInterval(() => {
    useEventStore.getState().incrementCurrentTime(UPDATE_INTERVAL);
  }, intervalFrequency / eventPlaybackSpeed);
  useEventStore.getState().setIntervalId(intervalIdRef);
  return intervalIdRef;
}

export const playEvent = throttle(playEventRef, WAIT_MILISECONDS);

export function pauseEventRef() {
  useEventStore.getState().setPlayStatus(EventPlayStatus.PAUSED);
  const { intervalId, playerList } = useEventStore.getState();
  if (!intervalId) return;
  if (playerList.length) {
    playerList?.forEach((playerElement) => playerElement.ref?.pause());
  }
  clearInterval(intervalId);
  useEventStore.getState().setIntervalId(null);
}

export const pauseEvent = throttle(pauseEventRef, WAIT_MILISECONDS);

export function toggleEventPlayStatus() {
  const { playStatus } = useEventStore.getState();
  if (playStatus === EventPlayStatus.PLAYING) return pauseEvent();
  return playEvent();
}

export function changeEventPlaybackSpeed(value: number) {
  const { intervalId, setEventPlaybackSpeed, playStatus } = useEventStore.getState();
  setEventPlaybackSpeed(value);
  if (!intervalId) return;
  clearInterval(intervalId);
  useEventStore.getState().setIntervalId(null);

  if (playStatus === EventPlayStatus.PLAYING) playEventRef();
}

export default useEventStore;
