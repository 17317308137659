import { styled } from '../../stitches.config';

const StyledTimeline = styled('div', {
  display: 'flex',
  overflowX: 'auto',
  position: 'relative',
  height: '100%',
  width: '100%',
  minHeight: '1rem',
  userSelect: 'none',
  cursor: 'pointer',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const Tick = styled('div', {
  position: 'absolute',
  height: '100%',
  borderRight: '1px solid #ccc',
});

const Hour = styled(Tick, {
  // background-color: #f0f0f0;
});

const HalfHour = styled(Tick, {
  // background-color: #f9f9f9;
});

const TimelineProgress = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  backgroundColor: 'rgba(184, 238, 48, 0.2)',
  zIndex: -1,
});

const TimelineCurrentProgress = styled('div', {
  position: 'absolute',
  height: '100%',
  backgroundColor: 'rgba(184, 238, 48, 0.5)',
});

const HoveredLine = styled('div', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '2px',
  backgroundColor: '#F2F1F6',
});

const HoveredTooltip = styled('div', {
  position: 'absolute',
  top: '30px',
  padding: '4px 8px',
  backgroundColor: '#F2F1F6',
  border: '1px solid #99989B',
  borderRadius: '999px',
  fontSize: '12px',
  whiteSpace: 'nowrap',
  zIndex: 2,
});

const CurrentTimeTooltip = styled('div', {
  position: 'absolute',
  top: '30px',
  padding: '4px 8px',
  backgroundColor: '$blizzardBlastFluorescent',
  border: '1px solid $blizzardBlastLight',
  borderRadius: '999px',
  fontSize: '12px',
  whiteSpace: 'nowrap',
  zIndex: 2,
});

const CurrentTimeLine = styled('div', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '2px',
  zIndex: 50,
  backgroundColor: '$vsLive100',
});

const SelectedLine = styled('div', {
  position: 'absolute',
  height: '100%',
  width: '2px',
  backgroundColor: '$blizzardBlastDark',
  pointerEvents: 'none',
});

export {
  StyledTimeline,
  Tick,
  Hour,
  HalfHour,
  TimelineProgress,
  TimelineCurrentProgress,
  HoveredLine,
  HoveredTooltip,
  CurrentTimeTooltip,
  CurrentTimeLine,
  SelectedLine,
};
