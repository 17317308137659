export const style = {
  width: '100%',
  height: '100%',

  '& .vis-timeline': {
    border: 'none',
    height: '100% !important',
  },

  '& .vis-panel.vis-left .vis-label .vis-inner': {
    height: '40px',
  },

  '& .vis-panel.vis-left .vis-label .vis-inner span': {
    fontSize: '0.7rem !important',
  },

  '& .vis-item': {
    fontSize: '0.75rem',
    height: '2.5rem',
    cursor: 'pointer',
    top: '0.5rem !important',
    backgroundColor: 'transparent !important',
    border: '1px solid rgba(225, 255, 52, 0.3) !important',
    borderRadius: '7px !important',
    overflow: 'hidden !important',
  },

  '& .vis-custom-time.t1': {
    backgroundColor: '$primaryAdrenaline100',
  },

  '& .vis-custom-time.t2': {
    backgroundColor: 'rgba(218, 255, 1, 0.2)',
    // opacity: 0.2,
  },

  '& .vis-left': {
    maxWidth: '8rem',
    overflow: 'hidden',
    touchAction: 'auto !important',
  },

  '& .vis-foreground .vis-group': {
    borderBottom: 'none',
  },

  '& .vis-panel.vis-center': {
    borderTopStyle: 'none',
    // borderLeftStyle: 'none',
    borderLeftColor: 'rgba(255, 255, 255, 0.1)',
    borderLeftWidth: '3px',
  },

  '& .vis-panel.vis-left': {
    borderTopStyle: 'none',
  },

  '& .vis-label.vis-group-level-0': {
    borderBottom: 'none',
  },

  '& .vis-panel.vis-top': {
    borderLeftStyle: 'none',
  },
  '& .vis-time-axis .vis-grid.vis-minor': {
    border: '2px solid rgba(255, 255, 255, 0.1)',
    borderTopStyle: 'none',
  },
  '& .vis-time-axis .vis-grid.vis-vertical': {
    borderLeft: '2px solid rgba(255, 255, 255, 0.1)',
  },
  '& .vis-inner.vis-hidden': {
    width: '100%',
  },
  '& .vis-item-content': {
    width: '100%',
    height: '100%',
    padding: 0,
  },
  '& .vis-item .vis-item-content': {
    padding: 0,
  },

  '& .vis-custom-time': {
    pointerEvents: 'none !important',
  },
  '& #timeline': {
    width: '100%',
  },

  '& #labelHoverElement,#labelPlayingElement': {
    backgroundColor: '$bgAppScreen100',
    marginTop: '20px',
    marginLeft: '5px',
    color: '$constantWhite',
    fontSize: '$caption',
    width: '3.15rem',
    height: '1.37rem',
    whiteSpace: 'nowrap',
    position: 'absolute',
    borderRadius: '$sm',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '$bold',
  },

  '@bp3': {
    '& .vis-item': {
      height: '2.5rem',
      top: '3px !important',
    },
  },
};
