export default function AppleIcon() {
  return (
    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.2375 5.41026C9.125 5.41026 10.2375 4.79182 10.9 3.96724C11.5 3.21996 11.9375 2.17635 11.9375 1.13274C11.9375 0.991015 11.925 0.84929 11.9 0.733334C10.9125 0.771986 9.725 1.41619 9.0125 2.27942C8.45 2.93651 7.9375 3.96724 7.9375 5.02373C7.9375 5.17834 7.9625 5.33295 7.975 5.38449C8.0375 5.39737 8.1375 5.41026 8.2375 5.41026ZM5.1125 21C6.325 21 6.8625 20.1625 8.375 20.1625C9.9125 20.1625 10.25 20.9742 11.6 20.9742C12.925 20.9742 13.8125 19.7116 14.65 18.4747C15.5875 17.0575 15.975 15.666 16 15.6016C15.9125 15.5758 13.375 14.5064 13.375 11.5044C13.375 8.90184 15.375 7.72939 15.4875 7.6392C14.1625 5.68082 12.15 5.62929 11.6 5.62929C10.1125 5.62929 8.9 6.55694 8.1375 6.55694C7.3125 6.55694 6.225 5.68082 4.9375 5.68082C2.4875 5.68082 0 7.76804 0 11.7106C0 14.1585 0.925 16.7482 2.0625 18.4232C3.0375 19.8404 3.8875 21 5.1125 21Z"
        fill="#131313"
      />
    </svg>
  );
}
