/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-useless-computed-key */
import type { GeoJSONSourceRaw, FillLayer, LineLayer } from 'react-map-gl';
import ut100 from './geoJson/ut-100.json';
import napoli from './geoJson/napoli.json';
import pt55 from './geoJson/pt-55.json';
import utct from './geoJson/utct.json';

import MAP_STYLE from './satellite-v9.json';
// import routeJson from './geoJson/track_points.json';
const geoUt100: GeoJSONSourceRaw = {
  type: 'geojson',
  // @ts-ignore
  data: ut100,
};

const napoli100: GeoJSONSourceRaw = {
  type: 'geojson',
  // @ts-ignore
  data: napoli,
};

const geoPt55: GeoJSONSourceRaw = {
  type: 'geojson',
  // @ts-ignore
  data: pt55,
};

const geoUtct: GeoJSONSourceRaw = {
  type: 'geojson',
  // @ts-ignore
  data: utct,
};

const pt55fillLayer: FillLayer = {
  id: 'geoPt55-fill',
  source: 'geoPt55',
  type: 'fill',
  paint: {
    'fill-opacity': 0,
  },
};

const pt55LineLayer: LineLayer = {
  id: 'geoPt55-outline',
  source: 'geoPt55',
  type: 'line',
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-width': 5,
    'line-color': 'rgba(120, 170, 0, 0.7)',
    'line-offset': 5,
  },
};

const ut100fillLayer: FillLayer = {
  id: 'geoUt100-fill',
  source: 'geoUt100',
  type: 'fill',
  paint: {
    'fill-opacity': 0,
  },
};

const napolifillLayer: FillLayer = {
  id: 'napoli-fill',
  source: 'napoli100',
  type: 'fill',
  paint: {
    'fill-opacity': 0,
  },
};

const napoliLineLayer: LineLayer = {
  id: 'napoli-outline',
  source: 'napoli100',
  type: 'line',
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-width': 5,
    'line-color': 'rgba(218, 255, 1, 0.5)',
    'line-offset': 5,
  },
};

const ut100LineLayer: LineLayer = {
  id: 'geoUt100-outline',
  source: 'geoUt100',
  type: 'line',
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-width': 5,
    'line-color': 'rgba(74, 37, 170, 0.7)',
    'line-offset': -5,
  },
};

const utctfillLayer: FillLayer = {
  id: 'geoUtct-fill',
  source: 'geoUtct',
  type: 'fill',
  paint: {
    'fill-opacity': 0,
  },
};

const utctLineLayer: LineLayer = {
  id: 'geoUtct-outline',
  source: 'geoUtct',
  type: 'line',
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-width': 5,
    'line-color': 'rgba(250, 0, 80, 0.6)',
    'line-opacity': 0.7,
  },
};

// Make a copy of the map style
export default {
  ...MAP_STYLE,
  sources: {
    ...MAP_STYLE.sources,
    // ['geoUt100']: geoUt100,
    // ['geoPt55']: geoPt55,
    // ['geoUtct']: geoUtct,
    ['napoli100']: napoli100,
  },
  layers: [
    ...MAP_STYLE.layers,
    // ut100fillLayer,
    // ut100LineLayer,
    // pt55fillLayer,
    // pt55LineLayer,
    // utctfillLayer,
    // utctLineLayer,
    napolifillLayer,
    napoliLineLayer,
  ],
};
