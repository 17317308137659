import { EventChannel, GPSData } from '../../../../domain/models/event-timeline-v3-model';
import { NewStreamModel } from '../../../../domain/models/new-stream-model';
import { StreamModel } from '../../../../domain/models/stream-model';
import { GpsMetaData } from '../../../components/third-party/video-stream/gps-type';

type UserLocation = { latitude: number; longitude: number; username: string; useravatar: string };

export type PointData = {
  timestamp: number;
  lat: number;
  long: number;
  altitude: number | null;
  speed: number | null;
  gps_precision: number;
  id: string;
  playback_url: string;
  vod_playback_url: string;
  logo_url: string;
  title: string;
  username: string;
  avatarPicture: string;
  duration_in_seconds: number;
  stream_tile_url: string;
  offset_ms: number;
  stream_start_date_ms: number;
  stream_end_date_ms: number | null;
  producer: boolean;
  speedType?: string;
  sponsorLogo?: string;
  official: boolean;
};

export interface IWSLocation {
  uuid: string;
  pulse_line: GPSData[];
}

export type Polyline = PointData[];

export type ListOfPolylines = Polyline[];

export type IUserLocation =
  | (UserLocation & Partial<GpsMetaData> & { stream_id?: string; duration_in_seconds?: number })
  | null;

export type EventStream = StreamModel &
  EventChannel & {
    pulse_lines?: Polyline;
    stream_start_date_ms: number;
    avatarPicture: string | undefined;
    stream_end_date_ms: number | null;
  };

export type NewEventStream = NewStreamModel & {
  pulse_lines?: Polyline;
  stream_start_date_ms: number;
  avatarPicture: string | undefined;
  stream_end_date_ms: number | null;
};

export enum EventPlayStatus {
  IDLE,
  STARTED_STREAMING,
  ENDED_ALL_STREAMS,
  PLAYING,
  PAUSED,
  LIVE,
}
