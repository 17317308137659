import Flex from '../primitives/flex';
import { Text } from '../primitives/text/text';

export function LiveChip() {
  return (
    <Flex
      className="live-chip"
      css={{
        width: '1.75rem',
        height: '0.8rem',
        backgroundColor: '$vsLive100',
        borderRadius: '$md',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text css={{ color: '$white', textTransform: 'uppercase', fontSize: '0.625rem' }}>Live</Text>
    </Flex>
  );
}
