import { ReactElement } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { StreamWrapper } from '../../../../components/stream-wrapper/stream-wrapper';
import VideoStream from '../../../../components/third-party/video-stream/video-stream';
import { styled } from '../../../../stitches.config';
import skeletonStyle from '../../../../common-styles/skeleton';
import { stringToColour } from '../../../../utils/colorConversion';
import { NewEventStream } from '../../state-manager/store-types';
import Flex from '../../../../components/primitives/flex';
import { StreamType } from '../../../../components/pulse-avatar/pulse-avatar';

interface IProps {
  isLive?: boolean;
  isSelected?: boolean;
  imageUrl?: string;
  streamUrl?: string;
  title: string;
  username: string;
  avatarImgUrl?: string;
  offsetInMs?: number;
  progress?: number;
  css?: any;
  children?: ReactElement;
  stream: NewEventStream;
}

const AvatarImage = styled(LazyLoadImage, {
  width: '100%',
  height: '100%',
  transition: 'all 0.5s ease-out',
  objectFit: 'cover',
  zIndex: 2,
  pointerEvents: 'none',
  userDrag: 'none',
  userSelect: 'none',
  overflow: 'hidden',

  '-webkit-user-drag': 'none',
});

export function EventStreamOrImageThumbnail({
  isLive,
  imageUrl,
  streamUrl,
  title,
  isSelected,
  progress,
  avatarImgUrl,
  username,
  offsetInMs,
  children,
  css,
  stream,
}: IProps) {
  // const [isHovered, setIsHovered] = useState(false);

  const getMediaElement = () => {
    if (!streamUrl && !imageUrl) return <>No media</>;
    if (streamUrl) return <VideoStream url={streamUrl} isFeatured={false} timeOffset={offsetInMs} />;
    if (imageUrl) return <AvatarImage effect="blur" src={imageUrl} alt={title} />;
    return <>No media</>;
  };

  return (
    <Flex css={css}>
      <StreamWrapper
        css={{
          '&': skeletonStyle,
          height: '4rem',
          width: '7.12rem',
          backgroundColor: '$gunMetal',
          border: `2px solid ${stringToColour(username)}`,
          ...css,
        }}
      >
        <>
          {isLive ? <StreamWrapper.LivePill /> : <StreamWrapper.RecordedPill />}
          <StreamWrapper.VideoOrImage>{getMediaElement()}</StreamWrapper.VideoOrImage>
          {isSelected ? <StreamWrapper.CurrentPlayingMask keyRef={username} /> : <StreamWrapper.BackFilter />}
          <StreamWrapper.ProgressBar progress={progress} />

          {children}
          {avatarImgUrl && (
            <StreamWrapper.AvatarAndText
              imageUrl={avatarImgUrl}
              title={title}
              streamType={stream.producer ? StreamType.COMMENTARY : StreamType.ATHLETE}
            />
          )}
        </>
      </StreamWrapper>
      {/* {isHovered && <EventPreFetchVideo featStream={stream} />} */}
    </Flex>
  );
}
