export default function AppStoreIcon() {
  return (
    <svg width="141" height="41" viewBox="0 0 141 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M140.217 36.0357C140.217 38.2065 138.422 39.9648 136.201 39.9648H4.80373C2.5846 39.9648 0.783325 38.2065 0.783325 36.0357V4.70274C0.783325 2.53304 2.5846 0.768555 4.80373 0.768555H136.2C138.422 0.768555 140.216 2.53304 140.216 4.70274L140.217 36.0357Z"
        fill="black"
      />
      <path
        d="M135.778 0.816007C138.196 0.816007 140.163 2.73429 140.163 5.09173V35.6417C140.163 37.9992 138.196 39.9175 135.778 39.9175H5.22222C2.80433 39.9175 0.836861 37.9992 0.836861 35.6417V5.09173C0.836861 2.73429 2.80433 0.816007 5.22222 0.816007H135.778ZM135.778 6.80443e-05H5.22222C2.35131 6.80443e-05 0 2.29259 0 5.09173V35.6417C0 38.4409 2.35131 40.7334 5.22222 40.7334H135.778C138.649 40.7334 141 38.4409 141 35.6417V5.09173C141 2.29259 138.649 6.80443e-05 135.778 6.80443e-05Z"
        fill="#A6A6A6"
      />
      <path
        d="M31.4671 20.1468C31.4368 16.8647 34.2234 15.2679 34.3508 15.1936C32.7726 12.9502 30.3265 12.6437 29.467 12.6193C27.4125 12.4085 25.4197 13.8178 24.3732 13.8178C23.3058 13.8178 21.6942 12.6396 19.9573 12.6743C17.7222 12.7079 15.6312 13.9696 14.4844 15.9288C12.1177 19.9238 13.8828 25.7945 16.1503 29.0236C17.2846 30.6051 18.61 32.3709 20.3448 32.3087C22.042 32.2405 22.676 31.2537 24.7241 31.2537C26.7535 31.2537 27.3488 32.3087 29.1181 32.269C30.9396 32.2405 32.0864 30.6804 33.181 29.0847C34.4918 27.2721 35.0182 25.4869 35.0391 25.3953C34.9962 25.381 31.5015 24.0806 31.4671 20.1468Z"
        fill="white"
      />
      <path
        d="M28.1248 10.495C29.0377 9.38198 29.6623 7.86772 29.4889 6.33105C28.1677 6.38808 26.5154 7.2221 25.5639 8.31069C24.722 9.26996 23.97 10.8423 24.1643 12.3209C25.6485 12.4288 27.1723 11.5907 28.1248 10.495Z"
        fill="white"
      />
      <path
        d="M56.0293 32.0815H53.6573L52.358 28.1009H47.8419L46.6042 32.0815H44.2949L48.7693 18.5295H51.5329L56.0293 32.0815ZM51.9664 26.4308L50.7914 22.8921C50.6671 22.5306 50.4342 21.6792 50.0905 20.3391H50.0488C49.9119 20.9155 49.6916 21.7668 49.3887 22.8921L48.2346 26.4308H51.9664Z"
        fill="white"
      />
      <path
        d="M67.5359 27.0755C67.5359 28.7375 67.0753 30.0511 66.1541 31.0155C65.329 31.8739 64.3044 32.3026 63.0813 32.3026C61.7611 32.3026 60.8128 31.8403 60.2352 30.9157H60.1934V36.0634H57.9667V25.5267C57.9667 24.4818 57.9385 23.4095 57.8842 22.3097H59.8425L59.9668 23.8586H60.0086C60.7512 22.6916 61.8781 22.1091 63.3905 22.1091C64.5728 22.1091 65.5598 22.5643 66.3494 23.4757C67.1411 24.3882 67.5359 25.5878 67.5359 27.0755ZM65.2673 27.155C65.2673 26.2038 65.048 25.4197 64.6072 24.8026C64.1258 24.159 63.4792 23.8372 62.6688 23.8372C62.1194 23.8372 61.6201 24.0165 61.1742 24.3698C60.7271 24.7262 60.4347 25.1916 60.2979 25.768C60.2289 26.0368 60.1945 26.2568 60.1945 26.4299V28.0593C60.1945 28.7701 60.418 29.3698 60.865 29.8597C61.312 30.3495 61.8927 30.5939 62.6071 30.5939C63.4458 30.5939 64.0986 30.2782 64.5655 29.6489C65.0334 29.0185 65.2673 28.1876 65.2673 27.155Z"
        fill="white"
      />
      <path
        d="M79.0634 27.0755C79.0634 28.7375 78.6028 30.0511 77.6805 31.0155C76.8565 31.8739 75.8319 32.3026 74.6088 32.3026C73.2887 32.3026 72.3403 31.8403 71.7638 30.9157H71.722V36.0634H69.4952V25.5267C69.4952 24.4818 69.467 23.4095 69.4127 22.3097H71.3711L71.4953 23.8586H71.5371C72.2787 22.6916 73.4056 22.1091 74.919 22.1091C76.1003 22.1091 77.0873 22.5643 77.879 23.4757C78.6675 24.3882 79.0634 25.5878 79.0634 27.0755ZM76.7949 27.155C76.7949 26.2038 76.5745 25.4197 76.1337 24.8026C75.6522 24.159 75.0078 23.8372 74.1963 23.8372C73.6459 23.8372 73.1477 24.0165 72.7006 24.3698C72.2536 24.7262 71.9622 25.1916 71.8254 25.768C71.7575 26.0368 71.722 26.2568 71.722 26.4299V28.0593C71.722 28.7701 71.9455 29.3698 72.3904 29.8597C72.8375 30.3485 73.4182 30.5939 74.1347 30.5939C74.9733 30.5939 75.6261 30.2782 76.093 29.6489C76.5609 29.0185 76.7949 28.1876 76.7949 27.155Z"
        fill="white"
      />
      <path
        d="M91.9519 28.2812C91.9519 29.4339 91.5414 30.3718 90.7174 31.0959C89.8119 31.8871 88.5512 32.2822 86.9313 32.2822C85.4356 32.2822 84.2366 32.0012 83.329 31.438L83.8449 29.6284C84.8225 30.2048 85.8952 30.494 87.0639 30.494C87.9026 30.494 88.5554 30.3087 89.0243 29.9401C89.4912 29.5714 89.7241 29.0765 89.7241 28.4594C89.7241 27.9095 89.5319 27.4462 89.1465 27.0704C88.7632 26.6946 88.123 26.3453 87.2289 26.0225C84.7954 25.1376 83.5797 23.8413 83.5797 22.1366C83.5797 21.0225 84.0058 20.1091 84.8591 19.3983C85.7093 18.6865 86.8435 18.3311 88.2619 18.3311C89.5267 18.3311 90.5774 18.5459 91.4161 18.9746L90.8594 20.7445C90.0761 20.329 89.1904 20.1213 88.1992 20.1213C87.4159 20.1213 86.8039 20.3097 86.3652 20.6844C85.9944 21.0195 85.8085 21.4278 85.8085 21.9115C85.8085 22.4472 86.0205 22.8901 86.4466 23.2384C86.8174 23.5602 87.4911 23.9085 88.4687 24.2842C89.6646 24.7537 90.543 25.3026 91.108 25.9319C91.671 26.5592 91.9519 27.3443 91.9519 28.2812Z"
        fill="white"
      />
      <path
        d="M99.3142 23.9389H96.8597V28.6833C96.8597 29.8901 97.2921 30.4929 98.159 30.4929C98.557 30.4929 98.887 30.4593 99.1481 30.3921L99.2097 32.0408C98.7711 32.2007 98.1935 32.2811 97.478 32.2811C96.5986 32.2811 95.9114 32.0194 95.4153 31.497C94.9212 30.9736 94.6727 30.0958 94.6727 28.8626V23.9369H93.2104V22.3076H94.6727V20.5183L96.8597 19.8748V22.3076H99.3142V23.9389Z"
        fill="white"
      />
      <path
        d="M110.388 27.1152C110.388 28.6173 109.948 29.8505 109.068 30.8148C108.146 31.8077 106.922 32.3026 105.396 32.3026C103.925 32.3026 102.755 31.827 101.881 30.8759C101.008 29.9248 100.572 28.7242 100.572 27.2771C100.572 25.7629 101.021 24.5225 101.922 23.5582C102.821 22.5928 104.035 22.1101 105.561 22.1101C107.032 22.1101 108.215 22.5857 109.108 23.5378C109.962 24.4614 110.388 25.6539 110.388 27.1152ZM108.078 27.1855C108.078 26.2843 107.881 25.5113 107.481 24.8667C107.014 24.0867 106.346 23.6977 105.482 23.6977C104.586 23.6977 103.907 24.0877 103.44 24.8667C103.04 25.5124 102.842 26.2975 102.842 27.2262C102.842 28.1274 103.04 28.9004 103.44 29.5439C103.921 30.324 104.594 30.713 105.462 30.713C106.312 30.713 106.979 30.3158 107.461 29.5236C107.871 28.8667 108.078 28.0857 108.078 27.1855Z"
        fill="white"
      />
      <path
        d="M117.626 24.2191C117.406 24.1794 117.171 24.159 116.924 24.159C116.141 24.159 115.535 24.4472 115.109 25.0246C114.738 25.5338 114.553 26.1774 114.553 26.9544V32.0817H112.327L112.348 25.3871C112.348 24.2609 112.32 23.2354 112.264 22.3108H114.204L114.285 24.1804H114.347C114.582 23.5379 114.953 23.0205 115.46 22.6326C115.956 22.2833 116.492 22.1091 117.07 22.1091C117.275 22.1091 117.461 22.1234 117.626 22.1488V24.2191Z"
        fill="white"
      />
      <path
        d="M127.585 26.7334C127.585 27.1224 127.559 27.4503 127.504 27.7181H120.824C120.85 28.6835 121.172 29.4218 121.793 29.931C122.356 30.3862 123.084 30.6143 123.978 30.6143C124.967 30.6143 125.869 30.4605 126.681 30.1519L127.03 31.6591C126.081 32.0623 124.962 32.2629 123.67 32.2629C122.115 32.2629 120.896 31.8169 120.008 30.9259C119.122 30.0348 118.678 28.8383 118.678 27.3373C118.678 25.8637 119.091 24.6367 119.917 23.658C120.782 22.6132 121.95 22.0908 123.421 22.0908C124.866 22.0908 125.959 22.6132 126.702 23.658C127.29 24.488 127.585 25.5145 127.585 26.7334ZM125.462 26.1703C125.477 25.5267 125.331 24.9707 125.029 24.5012C124.644 23.8973 124.052 23.5959 123.255 23.5959C122.527 23.5959 121.935 23.8902 121.483 24.4808C121.112 24.9503 120.891 25.5134 120.824 26.1692H125.462V26.1703Z"
        fill="white"
      />
      <path
        d="M51.23 10.1927C51.23 11.3912 50.8613 12.2935 50.125 12.8994C49.443 13.4585 48.4737 13.7385 47.2183 13.7385C46.5958 13.7385 46.0632 13.712 45.6172 13.6591V7.11017C46.1989 7.01852 46.8256 6.97168 47.5024 6.97168C48.6983 6.97168 49.5997 7.22524 50.2075 7.73237C50.8885 8.3057 51.23 9.12545 51.23 10.1927ZM50.0759 10.2222C50.0759 9.44521 49.8649 8.84949 49.443 8.43401C49.021 8.01954 48.4048 7.8118 47.5933 7.8118C47.2486 7.8118 46.9551 7.83421 46.7118 7.88105V12.8597C46.8465 12.8801 47.093 12.8892 47.4512 12.8892C48.2889 12.8892 48.9354 12.6621 49.3908 12.208C49.8461 11.7538 50.0759 11.0919 50.0759 10.2222Z"
        fill="white"
      />
      <path
        d="M57.3494 11.2395C57.3494 11.9778 57.1332 12.5827 56.7008 13.0572C56.2475 13.545 55.647 13.7884 54.8971 13.7884C54.1743 13.7884 53.5988 13.5552 53.1696 13.0868C52.7413 12.6193 52.5272 12.0297 52.5272 11.3189C52.5272 10.5756 52.7476 9.96557 53.1904 9.49205C53.6333 9.01852 54.2286 8.78125 54.9785 8.78125C55.7013 8.78125 56.282 9.01445 56.7217 9.48186C57.1395 9.93604 57.3494 10.5226 57.3494 11.2395ZM56.2141 11.2741C56.2141 10.8312 56.1159 10.4513 55.9206 10.1346C55.6908 9.75172 55.3639 9.56027 54.9388 9.56027C54.4991 9.56027 54.1649 9.75172 53.9351 10.1346C53.7388 10.4513 53.6416 10.8373 53.6416 11.2935C53.6416 11.7365 53.7398 12.1163 53.9351 12.433C54.1722 12.8159 54.5023 13.0073 54.9284 13.0073C55.3462 13.0073 55.6741 12.8128 55.9102 12.4228C56.1128 12.1 56.2141 11.7171 56.2141 11.2741Z"
        fill="white"
      />
      <path
        d="M65.5546 8.87891L64.014 13.6793H63.0114L62.3732 11.5948C62.2113 11.0744 62.0797 10.5571 61.9774 10.0439H61.9575C61.8625 10.5714 61.7309 11.0877 61.5617 11.5948L60.8838 13.6793H59.8697L58.421 8.87891H59.5459L60.1026 11.161C60.2373 11.7007 60.348 12.215 60.4368 12.7017H60.4566C60.5381 12.3005 60.6728 11.7893 60.8629 11.1712L61.5617 8.87992H62.4536L63.1231 11.1223C63.285 11.6691 63.4166 12.1956 63.5179 12.7027H63.5482C63.6224 12.2089 63.7341 11.6824 63.8824 11.1223L64.4798 8.87992H65.5546V8.87891Z"
        fill="white"
      />
      <path
        d="M71.2291 13.6793H70.1345V10.9298C70.1345 10.0826 69.8045 9.65893 69.1423 9.65893C68.8175 9.65893 68.5553 9.77503 68.3516 10.0082C68.1501 10.2414 68.0477 10.5164 68.0477 10.831V13.6783H66.9531V10.2506C66.9531 9.829 66.9396 9.37176 66.9135 8.87685H67.8754L67.9266 9.62737H67.9569C68.0843 9.39417 68.2744 9.2017 68.524 9.04793C68.8206 8.86871 69.1527 8.77808 69.5162 8.77808C69.9758 8.77808 70.358 8.92268 70.662 9.2129C71.04 9.5683 71.2291 10.0989 71.2291 10.8035V13.6793Z"
        fill="white"
      />
      <path d="M74.2475 13.6793H73.1539V6.67627H74.2475V13.6793Z" fill="white" />
      <path
        d="M80.6917 11.2395C80.6917 11.9778 80.4755 12.5827 80.0431 13.0572C79.5898 13.545 78.9882 13.7884 78.2394 13.7884C77.5156 13.7884 76.9401 13.5552 76.5118 13.0868C76.0836 12.6193 75.8695 12.0297 75.8695 11.3189C75.8695 10.5756 76.0899 9.96557 76.5327 9.49205C76.9756 9.01852 77.5709 8.78125 78.3198 8.78125C79.0436 8.78125 79.6232 9.01445 80.064 9.48186C80.4818 9.93604 80.6917 10.5226 80.6917 11.2395ZM79.5554 11.2741C79.5554 10.8312 79.4572 10.4513 79.2619 10.1346C79.0331 9.75172 78.7052 9.56027 78.2811 9.56027C77.8404 9.56027 77.5062 9.75172 77.2774 10.1346C77.0811 10.4513 76.9839 10.8373 76.9839 11.2935C76.9839 11.7365 77.0821 12.1163 77.2774 12.433C77.5145 12.8159 77.8446 13.0073 78.2707 13.0073C78.6885 13.0073 79.0154 12.8128 79.2514 12.4228C79.4551 12.1 79.5554 11.7171 79.5554 11.2741Z"
        fill="white"
      />
      <path
        d="M85.9891 13.6794H85.0063L84.9248 13.1265H84.8946C84.5582 13.5674 84.0789 13.7884 83.4564 13.7884C82.9916 13.7884 82.6156 13.6428 82.3325 13.3536C82.0756 13.0908 81.9471 12.764 81.9471 12.376C81.9471 11.7894 82.1978 11.3424 82.7023 11.0328C83.2057 10.7232 83.9138 10.5715 84.8256 10.5786V10.489C84.8256 9.85661 84.4851 9.54093 83.8031 9.54093C83.3175 9.54093 82.8892 9.66007 82.5195 9.89633L82.297 9.19571C82.7545 8.91974 83.3195 8.78125 83.9859 8.78125C85.2727 8.78125 85.9181 9.44317 85.9181 10.767V12.5348C85.9181 13.0145 85.9421 13.3963 85.9891 13.6794ZM84.8528 12.0297V11.2894C83.6454 11.269 83.0417 11.5918 83.0417 12.2568C83.0417 12.5073 83.1107 12.6947 83.2517 12.82C83.3927 12.9452 83.5723 13.0073 83.7864 13.0073C84.0266 13.0073 84.2512 12.933 84.4559 12.7853C84.6617 12.6367 84.788 12.4483 84.835 12.2171C84.8465 12.1652 84.8528 12.102 84.8528 12.0297Z"
        fill="white"
      />
      <path
        d="M92.2088 13.6793H91.2375L91.1863 12.9085H91.156C90.8458 13.495 90.3173 13.7883 89.5747 13.7883C88.9815 13.7883 88.4874 13.5612 88.0958 13.107C87.7041 12.6529 87.5088 12.0633 87.5088 11.3392C87.5088 10.5622 87.7208 9.9329 88.1469 9.45225C88.5595 9.00418 89.065 8.78015 89.6666 8.78015C90.3277 8.78015 90.7904 8.99705 91.0536 9.43188H91.0745V6.67627H92.1701V12.3861C92.1701 12.8535 92.1827 13.2842 92.2088 13.6793ZM91.0745 11.6549V10.8545C91.0745 10.716 91.0641 10.604 91.0442 10.5184C90.9826 10.2618 90.85 10.0459 90.6484 9.8718C90.4447 9.69766 90.1993 9.61009 89.9162 9.61009C89.5078 9.61009 89.1882 9.76793 88.9532 10.0846C88.7203 10.4013 88.6023 10.8056 88.6023 11.2995C88.6023 11.774 88.7141 12.159 88.9386 12.4553C89.1757 12.771 89.4953 12.9288 89.8953 12.9288C90.2546 12.9288 90.5419 12.7975 90.7601 12.5337C90.9711 12.2903 91.0745 11.9971 91.0745 11.6549Z"
        fill="white"
      />
      <path
        d="M101.57 11.2395C101.57 11.9778 101.354 12.5827 100.922 13.0572C100.468 13.545 99.8687 13.7884 99.1178 13.7884C98.3961 13.7884 97.8206 13.5552 97.3903 13.0868C96.962 12.6193 96.7479 12.0297 96.7479 11.3189C96.7479 10.5756 96.9683 9.96557 97.4111 9.49205C97.854 9.01852 98.4493 8.78125 99.2003 8.78125C99.922 8.78125 100.504 9.01445 100.942 9.48186C101.36 9.93604 101.57 10.5226 101.57 11.2395ZM100.436 11.2741C100.436 10.8312 100.338 10.4513 100.142 10.1346C99.9115 9.75172 99.5857 9.56027 99.1595 9.56027C98.7209 9.56027 98.3867 9.75172 98.1558 10.1346C97.9595 10.4513 97.8623 10.8373 97.8623 11.2935C97.8623 11.7365 97.9605 12.1163 98.1558 12.433C98.3929 12.8159 98.723 13.0073 99.1491 13.0073C99.5669 13.0073 99.8959 12.8128 100.132 12.4228C100.334 12.1 100.436 11.7171 100.436 11.2741Z"
        fill="white"
      />
      <path
        d="M107.456 13.6793H106.362V10.9298C106.362 10.0826 106.032 9.65893 105.369 9.65893C105.044 9.65893 104.782 9.77503 104.579 10.0082C104.377 10.2414 104.275 10.5164 104.275 10.831V13.6783H103.18V10.2506C103.18 9.829 103.167 9.37176 103.141 8.87685H104.102L104.153 9.62737H104.183C104.312 9.39417 104.502 9.2017 104.75 9.04793C105.048 8.86871 105.379 8.77808 105.744 8.77808C106.202 8.77808 106.585 8.92268 106.888 9.2129C107.268 9.5683 107.456 10.0989 107.456 10.8035V13.6793Z"
        fill="white"
      />
      <path
        d="M114.822 9.67826H113.617V12.0102C113.617 12.6029 113.831 12.8993 114.255 12.8993C114.451 12.8993 114.614 12.883 114.743 12.8494L114.771 13.6589C114.555 13.7384 114.271 13.7781 113.921 13.7781C113.488 13.7781 113.152 13.6498 112.909 13.3931C112.664 13.1365 112.543 12.7048 112.543 12.0988V9.67826H111.823V8.87887H112.543V7.99903L113.616 7.68335V8.87785H114.821V9.67826H114.822Z"
        fill="white"
      />
      <path
        d="M120.617 13.6793H119.521V10.9502C119.521 10.0897 119.191 9.65897 118.53 9.65897C118.022 9.65897 117.676 9.90846 117.485 10.4074C117.453 10.5123 117.434 10.6406 117.434 10.7914V13.6783H116.341V6.67627H117.434V9.56935H117.455C117.8 9.04288 118.294 8.78015 118.934 8.78015C119.387 8.78015 119.762 8.92475 120.06 9.21497C120.431 9.57648 120.617 10.1142 120.617 10.825V13.6793Z"
        fill="white"
      />
      <path
        d="M126.594 11.052C126.594 11.2435 126.579 11.4044 126.553 11.5357H123.271C123.285 12.0103 123.442 12.3718 123.746 12.6223C124.024 12.8463 124.382 12.9583 124.821 12.9583C125.306 12.9583 125.749 12.883 126.148 12.7313L126.319 13.4726C125.853 13.6702 125.303 13.7689 124.667 13.7689C123.905 13.7689 123.304 13.55 122.87 13.1121C122.433 12.6742 122.217 12.0866 122.217 11.3494C122.217 10.6253 122.418 10.0225 122.825 9.54183C123.249 9.02859 123.822 8.77197 124.546 8.77197C125.254 8.77197 125.792 9.02859 126.155 9.54183C126.449 9.94917 126.594 10.4532 126.594 11.052ZM125.55 10.7761C125.558 10.4594 125.486 10.1864 125.338 9.95629C125.148 9.65996 124.858 9.51128 124.467 9.51128C124.109 9.51128 123.818 9.65589 123.595 9.94611C123.414 10.1773 123.306 10.4532 123.271 10.7761H125.55Z"
        fill="white"
      />
    </svg>
  );
}
