import { ReactionModel } from '../../../domain/models/reaction-model';
import AvatarUser from '../avatar-user/avatar-user';
import { IosShareIcon } from '../icons/ios-share';
import { ShakaFilledIcon } from '../icons/shaka-filled';
import { ShakaWhiteIcon } from '../icons/shaka-white';
import Box from '../primitives/box';
import Button from '../primitives/button';
import Flex from '../primitives/flex';
import { Typo } from '../primitives/typo/typo';
import { shakaShake } from '../sidebar/sidebar';

interface IProps {
  reactionList: Partial<ReactionModel>[];
  likesCounter: number;
  isLiked: boolean;
  onClickLike: () => void;
  onClickShare: () => void;
}

export function UserShareLike({ reactionList, likesCounter, isLiked, onClickLike, onClickShare }: IProps) {
  const getLikeText = () => {
    if (likesCounter) return '';
    return isLiked ? 'Liked' : 'Like';
  };

  return (
    <Flex
      className="share-like-wrapper"
      css={{
        gap: '$3xs',
        transition: 'all 0.5s ease-in-out',
        '@bp3': {
          width: '100%',
          justifyContent: 'space-between',
        },
      }}
    >
      <Button
        className="share"
        variant="transparent"
        onClick={onClickShare}
        css={{ display: 'flex', alignItems: 'center', gap: '$xs' }}
      >
        <IosShareIcon />
        <Typo type="caption" css={{ color: '$gs300', marginBottom: '-5px' }}>
          Share
        </Typo>
      </Button>
      <Flex
        className="likes"
        css={{
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          borderRadius: '$lg',
          maxWidth: '15rem',
          alignItems: 'center',
        }}
        onClick={onClickLike}
      >
        <Button
          variant="transparent"
          css={{ gap: '$3xs', color: isLiked ? '$primaryAdrenaline100' : '$constantWhite' }}
        >
          <Box
            css={{
              animation: isLiked ? `${shakaShake} 0.6s ease-in-out ` : '',
            }}
          >
            {!isLiked ? <ShakaWhiteIcon /> : <ShakaFilledIcon />}
          </Box>
          {getLikeText()}
        </Button>
        {!!likesCounter && (
          <Flex css={{ alignItems: 'center', gap: '$3xs', paddingRight: '$xs' }}>
            <Flex
              className="divider"
              css={{ height: '2rem', width: '1px', backgroundColor: '$constantWhite', opacity: 0.3 }}
            />
            <Flex className="likes-avatar">
              {reactionList.map((reaction, index) => (
                <AvatarUser
                  css={{ border: 'none', width: '2rem', height: '2rem', marginLeft: index !== 0 && '-10px' }}
                  key={reaction.username}
                  user={{
                    username: reaction?.username || '',
                    useravatar: reaction?.hero_photo?.original || '',
                  }}
                />
              ))}
            </Flex>
            <Typo css={{ color: '$gs100', whiteSpace: 'nowrap' }}>
              {likesCounter} {likesCounter > 1 ? 'likes' : 'like'}
            </Typo>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
