import { keyframes, styled } from '../../stitches.config';

const pulseMe = keyframes({
  '0%': {
    transform: 'scale(0.5)',
    opacity: 0,
  },
  '50%': {
    opacity: 0.1,
  },
  '70%': {
    opacity: 0.09,
  },
  '100%': {
    transform: 'scale(5)',
    opacity: 0,
  },
});

const Wrapper = styled('div', {
  '& .pulse-css': {
    width: '10px',
    height: '10px',
    borderRadius: '25px',
    background: 'tomato',
    position: 'relative',
  },

  '& .pulse-css:before, .pulse-css:after': {
    content: '',
    width: '10px',
    height: '10px',
    borderRadius: '5px',
    backgroundColor: 'tomato',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
    transform: 'scale(0.5)',
    transformOrigin: 'center center',
    animation: `${pulseMe} 3s linear infinite`,
  },
  '& .pulse-css:after': {
    animationDelay: '2s',
  },
});

export default function LiveIndicator() {
  return (
    <Wrapper className="pulse-box">
      <div className="pulse-css" />
    </Wrapper>
  );
}
