interface IProps {
  color?: string;
}
export function RemoveIcon({ color = '#131313' }: IProps) {
  return (
    <svg width="15" height="2" viewBox="0 0 15 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 2C1.21667 2 0.979 1.904 0.787 1.712C0.595667 1.52067 0.5 1.28333 0.5 1C0.5 0.716667 0.595667 0.479 0.787 0.287C0.979 0.0956664 1.21667 0 1.5 0H13.5C13.7833 0 14.0207 0.0956664 14.212 0.287C14.404 0.479 14.5 0.716667 14.5 1C14.5 1.28333 14.404 1.52067 14.212 1.712C14.0207 1.904 13.7833 2 13.5 2H1.5Z"
        fill={color}
        fillOpacity="0.81"
      />
    </svg>
  );
}
