import Lottie from 'lottie-react';
import Button from '../primitives/button';

import Flex from '../primitives/flex';
import { Typo } from '../primitives/typo/typo';
import animationData from './data.json';

export default function ErrorPage() {
  return (
    <Flex
      className="error-wrapper"
      css={{
        width: '100%',
        height: '100%',
        backgroundColor: '$bgAppScreen100',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '$lg',
      }}
    >
      <Flex
        css={{
          width: '100%',
          maxWidth: '45rem',
          height: '30rem',
          backgroundColor: '$bgAppScreen200',
          borderRadius: '$lg',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '$xs',
        }}
      >
        <Flex className="error-logo" css={{ width: '8.56rem' }}>
          <Lottie animationData={animationData} loop={false} />
        </Flex>
        <Flex
          className="not-found-title"
          css={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '$sm', padding: '$xs' }}
        >
          <Typo as="h3" type="h3" css={{ lineHeight: '3rem', textAlign: 'center', fontSize: 48, fontWeight: '$bold' }}>
            Wipeout!
          </Typo>
          <Flex css={{ flexDirection: 'column', gap: '$3xs' }}>
            <Typo type="bodyBold" css={{ textAlign: 'center', color: '$constantWhite', opacity: 0.6 }}>
              You’ve encountered an unexpected error here.
            </Typo>
            <Typo type="bodyBold" css={{ textAlign: 'center', color: '$constantWhite', opacity: 0.6 }}>
              We have been notified and are working hard to patch things up!
            </Typo>
          </Flex>

          <Button title="Refresh Page" variant="transparentActive" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
