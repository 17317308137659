export default function PictureAndPictureRightIcon() {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 16C18.55 16 19.0208 15.8042 19.4125 15.4125C19.8042 15.0208 20 14.55 20 14L20 8C20 7.71667 19.9042 7.47917 19.7125 7.2875C19.5208 7.09583 19.2833 7 19 7C18.7167 7 18.4792 7.09583 18.2875 7.2875C18.0958 7.47917 18 7.71667 18 8L18 14L2 14L2 2L10 2C10.2833 2 10.5208 1.90417 10.7125 1.7125C10.9042 1.52083 11 1.28333 11 0.999999C11 0.716666 10.9042 0.479166 10.7125 0.287499C10.5208 0.0958325 10.2833 -8.49458e-07 10 -8.74228e-07L2 -1.57361e-06C1.45 -1.62169e-06 0.979166 0.195832 0.5875 0.587498C0.195833 0.979165 -1.26763e-07 1.45 -1.74846e-07 2L-1.22392e-06 14C-1.272e-06 14.55 0.195832 15.0208 0.587498 15.4125C0.979165 15.8042 1.45 16 2 16L18 16ZM8 9.425L5.625 11.8C5.425 12 5.19167 12.1 4.925 12.1C4.65833 12.1 4.425 12 4.225 11.8C4.025 11.6 3.925 11.3625 3.925 11.0875C3.925 10.8125 4.025 10.575 4.225 10.375L6.6 8L5 8C4.71667 8 4.47917 7.90417 4.2875 7.7125C4.09583 7.52083 4 7.28333 4 7C4 6.71667 4.09583 6.47917 4.2875 6.2875C4.47917 6.09583 4.71667 6 5 6L9 6C9.28333 6 9.52083 6.09583 9.7125 6.2875C9.90417 6.47917 10 6.71667 10 7L10 11C10 11.2833 9.90417 11.5208 9.7125 11.7125C9.52083 11.9042 9.28333 12 9 12C8.71667 12 8.47916 11.9042 8.2875 11.7125C8.09583 11.5208 8 11.2833 8 11L8 9.425ZM19 5C19.2833 5 19.5208 4.90417 19.7125 4.7125C19.9042 4.52083 20 4.28333 20 4L20 1C20 0.716667 19.9042 0.479167 19.7125 0.2875C19.5208 0.0958333 19.2833 -6.2653e-08 19 -8.74228e-08L14 -5.24537e-07C13.7167 -5.49306e-07 13.4792 0.0958328 13.2875 0.287499C13.0958 0.479166 13 0.716666 13 0.999999L13 4C13 4.28333 13.0958 4.52083 13.2875 4.7125C13.4792 4.90417 13.7167 5 14 5L19 5Z"
        fill="white"
        fillOpacity="0.91"
      />
    </svg>
  );
}
