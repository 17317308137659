/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import * as Select from '@radix-ui/react-select';
import { mauve } from '@radix-ui/colors';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { styled } from '../../../../stitches.config';
import Box from '../../../../components/primitives/box';
import Flex from '../../../../components/primitives/flex';
import { Text } from '../../../../components/primitives/text/text';

const SelectTrigger = styled(Select.SelectTrigger, {
  height: '1.5rem',
  width: '1.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

const SelectContent = styled(Select.Content, {
  overflow: 'hidden',
  backgroundColor: '$bgAppScreen200',
  borderRadius: 6,
  boxShadow: '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const SelectViewport = styled(Select.Viewport, {
  backgroundColor: '$bgAppScreen200',
});

const StyledItem = styled(Select.Item, {
  fontSize: '$body',
  lineHeight: 1,
  color: '#403F40',
  borderRadius: 3,
  display: 'flex',
  alignItems: 'center',
  height: '1.93rem',
  padding: '0 35px 0 25px',
  position: 'relative',
  userSelect: 'none',

  '&[data-disabled]': {
    color: mauve.mauve8,
    pointerEvents: 'none',
  },

  '&[data-highlighted]': {
    outline: 'none',
    backgroundColor: '$gs500',
  },
});

const StyledItemIndicator = styled(Select.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: 'white',
  color: '$gs500',
  cursor: 'default',
};

const SelectScrollUpButton = styled(Select.ScrollUpButton, scrollButtonStyles);

const SelectScrollDownButton = styled(Select.ScrollDownButton, scrollButtonStyles);

const SelectItem = React.forwardRef(({ children, ...props }, forwardedRef) => {
  return (
    <StyledItem {...props} ref={forwardedRef}>
      <Select.ItemText asChild>
        <Text css={{ fontWeight: '$bold', fontSize: '0.875rem', color: '#403F40' }}>{children}</Text>
      </Select.ItemText>
      <StyledItemIndicator>
        <Box
          css={{
            height: '0.125rem',
            width: '0.625rem',
            borderRadius: '50%',
            backgroundColor: '$blizzardBlastFluorescent',
          }}
        />
      </StyledItemIndicator>
    </StyledItem>
  );
});

interface IProps {
  value: number;
  onValueChange: (event: number) => void;
}

export const EventSpeedControl = ({ value, onValueChange }: IProps) => {
  return (
    <Select.Root value={value} onValueChange={onValueChange}>
      <SelectTrigger aria-label="Date">
        <Select.Value asChild>
          <Text css={{ fontWeight: '$bold', fontSize: '0.875rem', color: '#403F40' }}>{`${value}x`}</Text>
        </Select.Value>
      </SelectTrigger>

      <Select.Portal>
        <SelectContent className="select-content" css={{ zIndex: 10000 }}>
          <SelectScrollUpButton>
            <ChevronUpIcon color="rgba(0, 0, 0, 0.7)" />
          </SelectScrollUpButton>
          <SelectViewport>
            <Select.Group>
              <Flex
                css={{
                  height: '2.06rem',
                  width: '10rem',
                  alignItems: 'center',
                  paddingLeft: '$3xs',
                  borderBottom: '1px solid $gs500',
                }}
              >
                <Text type="bodySm" css={{ color: '$black', fontWeight: '$bold', fontSize: '0.87rem' }}>
                  Playback speed
                </Text>
              </Flex>
              <Flex css={{ flexDirection: 'column', padding: '$3xs $nano' }}>
                <SelectItem value={60}>60x</SelectItem>
                <SelectItem value={30}>30x</SelectItem>
                <SelectItem value={10}>10x</SelectItem>
                <SelectItem value={4}>4x</SelectItem>
                <SelectItem value={2}>2x</SelectItem>
                <SelectItem value={1}>1x</SelectItem>
              </Flex>
            </Select.Group>
          </SelectViewport>
          <SelectScrollDownButton>
            <ChevronDownIcon />
          </SelectScrollDownButton>
        </SelectContent>
      </Select.Portal>
    </Select.Root>
  );
};
