interface IProps {
  color?: string;
}
export default function SimpleLeftArrowIcon({ color = '#fff' }: IProps) {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="simple-left-arrow"
    >
      <path
        d="M0.132812 8.94922C0.132812 9.42773 0.308594 9.82812 0.708984 10.2188L8.0918 17.4551C8.39453 17.748 8.74609 17.8945 9.17578 17.8945C10.0352 17.8945 10.748 17.2012 10.748 16.3418C10.748 15.9023 10.5625 15.5117 10.2402 15.1895L3.80469 8.93945L10.2402 2.69922C10.5625 2.38672 10.748 1.98633 10.748 1.55664C10.748 0.707031 10.0352 0.00390625 9.17578 0.00390625C8.74609 0.00390625 8.39453 0.150391 8.0918 0.443359L0.708984 7.67969C0.308594 8.06055 0.142578 8.46094 0.132812 8.94922Z"
        fill={color}
      />
    </svg>
  );
}
