export default function UnfoldMoreIcon() {
  return (
    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.22501 5.32502C1.04168 5.14169 0.950012 4.90002 0.950012 4.60002C0.950012 4.30002 1.04168 4.05836 1.22501 3.87502L4.30001 0.800024C4.40001 0.700024 4.50835 0.629024 4.62501 0.587024C4.74168 0.545691 4.86668 0.525024 5.00001 0.525024C5.13335 0.525024 5.26268 0.545691 5.38801 0.587024C5.51268 0.629024 5.61668 0.700024 5.70001 0.800024L8.80001 3.90002C8.98335 4.08336 9.07068 4.32069 9.06201 4.61202C9.05401 4.90402 8.95835 5.14169 8.77501 5.32502C8.59168 5.50836 8.35001 5.60002 8.05001 5.60002C7.75001 5.60002 7.50835 5.50836 7.32501 5.32502L5.00001 3.00002L2.65001 5.35002C2.46668 5.53336 2.22935 5.62102 1.93801 5.61302C1.64601 5.60436 1.40835 5.50836 1.22501 5.32502ZM5.00001 17.575C4.86668 17.575 4.74168 17.55 4.62501 17.5C4.50835 17.45 4.40001 17.3834 4.30001 17.3L1.22501 14.225C1.04168 14.0417 0.950012 13.8 0.950012 13.5C0.950012 13.2 1.04168 12.9584 1.22501 12.775C1.40835 12.5917 1.65001 12.5 1.95001 12.5C2.25001 12.5 2.49168 12.5917 2.67501 12.775L5.00001 15.1L7.35001 12.75C7.53335 12.5667 7.77068 12.4794 8.06201 12.488C8.35401 12.496 8.59168 12.5917 8.77501 12.775C8.95835 12.9584 9.05001 13.2 9.05001 13.5C9.05001 13.8 8.95835 14.0417 8.77501 14.225L5.70001 17.3C5.61668 17.3834 5.51268 17.45 5.38801 17.5C5.26268 17.55 5.13335 17.575 5.00001 17.575Z"
        fill="white"
      />
    </svg>
  );
}
