import { SourceLocation } from '../../../../../../domain/models/event-timeline-model';
import { StreamModel } from '../../../../../../domain/models/stream-model';
import AvatarUser from '../../../../../components/avatar-user/avatar-user';
import SimpleLeftArrowIcon from '../../../../../components/icons/simple-left-arrow';
import SimpleRightArrowIcon from '../../../../../components/icons/simple-right-arrow';
import Button from '../../../../../components/primitives/button';
import Flex from '../../../../../components/primitives/flex';

interface IProps {
  isLiveSelected: boolean;
  isStreamListVisible: boolean;
  onSelectStream: (index: number) => void;
  streamList: Map<string, StreamModel> | null;
  liveStreams: StreamModel[];
  streamSourceLocation: SourceLocation[];
  featuredStream: Partial<StreamModel> | null;
}

export default function MobileAvatarSlide({
  isLiveSelected,
  isStreamListVisible,
  liveStreams,
  streamList,
  streamSourceLocation,
  featuredStream,
  onSelectStream,
}: IProps) {
  const onClickAvatar = (index: number) => {
    onSelectStream(index);
  };

  const getVodStreamIndex = () => {
    const selectedStreamIndex = streamSourceLocation.findIndex((stream) => stream.stream_id === featuredStream?.id);
    return selectedStreamIndex;
  };

  const getLiveStreamIndex = () => {
    const selectedStreamIndex = liveStreams.findIndex((stream) => stream.id === featuredStream?.id);
    return selectedStreamIndex;
  };

  const goNextStream = () => {
    let amountOfStreams = 0;
    let selectedStreamIndex = null;
    if (isLiveSelected) {
      amountOfStreams = liveStreams.length - 1;
      selectedStreamIndex = getLiveStreamIndex();
    } else {
      amountOfStreams = streamSourceLocation.length - 1;
      selectedStreamIndex = getVodStreamIndex();
    }
    if (selectedStreamIndex === amountOfStreams) return;
    onClickAvatar(selectedStreamIndex + 1);
  };

  const goPreviousStream = () => {
    let selectedStreamIndex = null;
    if (isLiveSelected) {
      selectedStreamIndex = getLiveStreamIndex();
    } else {
      selectedStreamIndex = getVodStreamIndex();
    }
    if (selectedStreamIndex === 0 || selectedStreamIndex === null) return;
    onClickAvatar(selectedStreamIndex - 1);
  };

  return (
    <Flex
      className="slider-wrapper"
      css={{
        width: '100%',
        minHeight: '2rem',
        maxHeight: '2rem',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Button
        css={{ position: 'absolute', left: 0, '& path': { fill: '$gs300' } }}
        size="sm"
        variant="transparent"
        onClick={goPreviousStream}
      >
        <SimpleLeftArrowIcon />
      </Button>

      <Flex
        css={{
          maxWidth: '80%',
          height: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
          alignItems: 'center',
          gap: '$3xs',
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
          '&::-webkit-scrollbar': {
            width: '0px',
            height: '0px',
          },
        }}
        className="avatar-list"
      >
        {isStreamListVisible &&
          !isLiveSelected &&
          streamSourceLocation.map(({ stream_id }, index: number) => (
            <Flex onClick={() => onClickAvatar(index)} key={stream_id}>
              <AvatarUser
                user={{
                  username: streamList?.get(stream_id)?.channel.username || '',
                  useravatar: streamList?.get(stream_id)?.channel.hero_photo.original || '',
                }}
                isSelected={featuredStream?.id === stream_id}
                css={{
                  opacity: featuredStream?.id === stream_id ? 1 : 0.5,
                  marginRight: streamSourceLocation.length - 1 === index ? '10px' : '0px',
                  marginLeft: index === 0 ? '20px' : '0px',
                }}
              />
            </Flex>
          ))}
        {isStreamListVisible &&
          isLiveSelected &&
          liveStreams.map((stream, index: number) => (
            <Flex onClick={() => onClickAvatar(index)} key={stream.id}>
              <AvatarUser
                user={{
                  username: stream?.channel.username || '',
                  useravatar: stream?.channel.hero_photo.original || '',
                }}
                isSelected={featuredStream?.id === stream.id}
                css={{
                  opacity: featuredStream?.id === stream.id ? 1 : 0.5,
                  marginRight: liveStreams.length - 1 === index ? '10px' : '0px',
                  marginLeft: index === 0 ? '20px' : '0px',
                }}
              />
            </Flex>
          ))}
      </Flex>
      <Button
        css={{ position: 'absolute', right: 0, '& path': { fill: '$gs300' } }}
        size="sm"
        variant="transparent"
        onClick={goNextStream}
      >
        <SimpleRightArrowIcon />
      </Button>
    </Flex>
  );
}
