import { keyframes } from '../../stitches.config';
import Flex from '../primitives/flex';

const pulseAnimation = keyframes({
  '0%': {
    transform: 'scaleY(1)',
    transformOrigin: '50% 50%',
  },

  '50%': {
    transform: 'scaleY(.7)',
    transformOrigin: '50% 50%',
  },

  '100%': {
    transform: 'scaleY(1)',
    transformOrigin: '50% 50%',
  },
});

export default function SoundWaveIcon() {
  return (
    <Flex
      className="loader"
      css={{
        transform: 'scale(0.3)',
        '& .loader': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        },

        '& #wave': {
          height: '70px',
          width: '70px',
          fill: '$primaryAdrenaline100',
        },

        '& #Line_1': {
          animation: `${pulseAnimation} 1s infinite`,
          animationDelay: '0.15s',
        },
        '& #Line_2': {
          animation: `${pulseAnimation} 1s infinite`,
          animationDelay: '0.3s',
        },
        '& #Line_3': {
          animation: `${pulseAnimation} 1s infinite`,
          animationDelay: '0.45s',
        },
        '& #Line_4': {
          animation: `${pulseAnimation} 1s infinite`,
          animationDelay: '0.6s',
        },
        '& #Line_5': {
          animation: `${pulseAnimation} 1s infinite`,
          animationDelay: '0.75s',
        },
        '& #Line_6': {
          animation: `${pulseAnimation} 1s infinite`,
          animationDelay: '0.9s',
        },
        '& #Line_7': {
          animation: `${pulseAnimation} 1s infinite`,
          animationDelay: '1.05s',
        },
        '& #Line_8': {
          animation: `${pulseAnimation} 1s infinite`,
          animationDelay: '1.2s',
        },
        '& #Line_9': {
          animation: `${pulseAnimation} 1s infinite`,
          animationDelay: '1.35s',
        },
      }}
    >
      <svg id="wave" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 38.05">
        <title>Audio Wave</title>
        <path
          id="Line_1"
          data-name="Line 1"
          d="M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z"
        />
        <path
          id="Line_2"
          data-name="Line 2"
          d="M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z"
        />
        <path
          id="Line_3"
          data-name="Line 3"
          d="M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z"
        />
        <path
          id="Line_4"
          data-name="Line 4"
          d="M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z"
        />
        <path
          id="Line_5"
          data-name="Line 5"
          d="M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z"
        />
        <path
          id="Line_6"
          data-name="Line 6"
          d="M30.91,10l-0.12,0A1,1,0,0,0,30,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H30.91Z"
        />
        <path
          id="Line_7"
          data-name="Line 7"
          d="M36.91,0L36.78,0A1,1,0,0,0,36,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H36.91Z"
        />
        <path
          id="Line_8"
          data-name="Line 8"
          d="M42.91,9L42.78,9A1,1,0,0,0,42,10V28a1,1,0,1,0,2,0s0,0,0,0V10a1,1,0,0,0-1-1H42.91Z"
        />
        <path
          id="Line_9"
          data-name="Line 9"
          d="M48.91,15l-0.12,0A1,1,0,0,0,48,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H48.91Z"
        />
      </svg>
    </Flex>
  );
}
