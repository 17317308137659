/* eslint-disable no-nested-ternary */
/* eslint-disable no-promise-executor-return */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/media-has-caption */
// @ts-nocheck
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import { useInterval } from 'usehooks-ts';
import useScreenOrientation from 'use-screen-orientation';
import NewTelemetryBadge from './new-telemetry-badge';
import FullScreenIcon from '../../icons/full-screen';
import FullScreenExitIcon from '../../icons/full-screen-exit';
import SponsorOverlayLogo from '../../sponsor-overlay-logo/sponsor-overlay-logo';
import UnfoldLessIcon from '../../icons/unfold-less';
import UnfoldMoreIcon from '../../icons/unfold-more';
import VolumeHighIcon from '../../icons/volume-high';
import MuteIcon from '../../icons/mute';
import 'video.js/dist/video-js.css';
import './video-js.scss';

import { VideoJSEvents } from './index.d';
import Flex from '../../primitives/flex';
import TelemetryBagde from './telemetry-bagde';
import LiveBadge from './live-badge';
import RecordedBadge from './recorded-badge';
import { GpsMetaData } from './gps-type';

interface IVideoStreamProps {
  options?: videojs.PlayerOptions;
  url: string;
  streamId?: string;
  sponsorImageUrl?: string;
  timeOffset?: number;
  startTimeInMs?: number;
  isFeatured?: boolean;
  showTelemetry?: boolean;
  showLiveBadge?: boolean;
  newTelemetry?: boolean;
  isPip?: boolean;
  isAndroidFullScreen?: boolean;
  onVideoError?: () => void;
  onClickExpand?: (isExpanded: boolean) => void;
  onClickFullScreen?: (isExpanded: boolean) => void;
  onTimeUpdate?: (currentTimeInSeconds: number) => void;
  onChangeFullScreen?: (isFullScreen: boolean) => void;
  onChangeGpsData?: (gpsData: GpsMetaData, player: VideoJsPlayer) => void;
  onVideoPlayerReady?: (playerRef: VideoJsPlayer) => void;
  onVideoEnds?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onProgramaticallyPauses?: () => void;
  onSeek?: (currentTime: number) => void;
  onChangeVideoState?: (state: PlayerState) => void;
  isLive?: boolean;
  isMobile?: boolean;
  username?: string;
  channelTitle?: string;
  useravatar?: string;
  speedType?: string;
  posterImage?: string;
  showAtheleteThumbnail?: boolean;
}

export enum PlayerState {
  IDLE = 'Idle',
  READY = 'Ready',
  BUFFERING = 'Buffering',
  PLAYING = 'Playing',
  ENDED = 'Ended',
}

const initialOptions: videojs.PlayerOptions = {
  techOrder: ['AmazonIVS'],
  controls: true,
  responsive: false,
  fluid: false,
  fill: true,
  liveui: true,
  bigPlayButton: true,
  loadingSpinner: true,
  preload: 'none',
  autoSetup: true,
  // html5: {
  //   hls: {
  //     limitRenditionByPlayerDimensions: true,
  //     useDevicePixelRatio: true,
  //     bandwidth: 100,
  //   },
  // },

  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
};

let liveRef = false;

function VideoStream({
  options = {},
  url,
  streamId,
  sponsorImageUrl,
  startTimeInMs,
  isFeatured = false,
  onVideoError,
  onChangeFullScreen,
  onChangeGpsData,
  timeOffset,
  onVideoPlayerReady,
  onVideoEnds,
  onPlay,
  onPause,
  onProgramaticallyPauses,
  onSeek,
  onClickExpand,
  onClickFullScreen,
  onTimeUpdate,
  onChangeVideoState,
  showTelemetry = true,
  showLiveBadge = true,
  newTelemetry = false,
  channelTitle,
  isAndroidFullScreen = false,
  isLive = false,
  isMobile = false,
  isPip = false,
  username,
  useravatar,
  speedType,
  showAtheleteThumbnail = true,
  posterImage,
}: IVideoStreamProps) {
  const videoElementRef = useRef<HTMLVideoElement>() as MutableRefObject<HTMLVideoElement>;
  const playerRef = useRef<videojs.Player>();
  const [videoState, setVideoState] = useState<PlayerState | null>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [canJumpToTimeOffset, setCanJumpToTimeOffset] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [gpsData, setGpsData] = useState<GpsMetaData | null>(null);
  const [latencyInSeconds, setLatencyInSeconds] = useState<number>(0);
  const [orientation] = useScreenOrientation();
  liveRef = isLive;
  const getIvsEvents = (player: VideoJsPlayer) => {
    const ivsEvents: VideoJSEvents = player.getIVSEvents();
    return ivsEvents;
  };

  const listenToVideoError = (player: VideoJsPlayer, events: VideoJSEvents) => {
    player.getIVSPlayer().addEventListener(events.PlayerEventType.ERROR, async () => {
      if (onVideoError) onVideoError();
    });

    player.getIVSPlayer().addEventListener(events.PlayerState.ENDED, async () => {
      if (onVideoEnds) onVideoEnds();
    });
  };

  const listenToIvsMetaData = (player: VideoJsPlayer, events: VideoJSEvents) => {
    player.getIVSPlayer().addEventListener(events.PlayerEventType.TEXT_METADATA_CUE, (cue) => {
      try {
        const gpsDataFromVideoStream: GpsMetaData = JSON.parse(cue.text);
        setGpsData(gpsDataFromVideoStream);
        if (onChangeGpsData) onChangeGpsData(gpsDataFromVideoStream, player);
      } catch (error) {
        // console.warn(error);
      }
    });
  };

  const listenToIvsStateChange = (player: VideoJsPlayer, events: VideoJSEvents) => {
    player.getIVSPlayer().addEventListener(events.PlayerEventType.STATE_CHANGED, (state: PlayerState) => {
      setVideoState(state);
      if (onChangeVideoState) onChangeVideoState(state);
    });
  };

  const syncThresholdInSeconds = 10;

  const jumpVideoToTime = () => {
    if (
      !timeOffset ||
      !playerRef?.current?.currentTime ||
      videoState !== PlayerState.PLAYING ||
      !canJumpToTimeOffset ||
      isLive
    )
      return;

    const videoCurrentTimeInSeconds = playerRef.current.currentTime();
    const timelineCurrentTimeInSeconds = timeOffset / 1000;
    const timeDifferenceInSeconds = timelineCurrentTimeInSeconds - videoCurrentTimeInSeconds;
    const isOutOfSync = Math.abs(timeDifferenceInSeconds) > syncThresholdInSeconds;
    if (isOutOfSync) {
      console.log('TRIED TO SYNC');
      // playerRef.current.getIVSPlayer().seekTo(timelineCurrentTimeInSeconds);
      playerRef.current.currentTime(timelineCurrentTimeInSeconds);
    }
  };
  useEffect(jumpVideoToTime, [timeOffset]);

  // const setVideoPoorQuality = (player: VideoJsPlayer) => {
  //   player.getIVSPlayer().setQuality({
  //     bitrate: 150000,
  //     codecs: 'avc1.4D401F,mp4a.40.2',
  //     framerate: 20,
  //     group: '160p30',
  //     height: 160,
  //     isDefault: false,
  //     name: '160p',
  //     width: 284,
  //   });
  // };

  // const setVideoMediumQuality = (player: VideoJsPlayer) => {
  //   player.getIVSPlayer().setQuality({
  //     bitrate: 230000,
  //     codecs: 'avc1.4D401F,mp4a.40.2',
  //     framerate: 30,
  //     group: '720p30',
  //     height: 160,
  //     isDefault: false,
  //     name: '720p',
  //     width: 284,
  //   });
  // };

  const playVideo = async (player: VideoJsPlayer) => {
    // setTimeout(() => setVideoPoorQuality(player), 100);
    // setTimeout(() => setVideoMediumQuality(player), 8000);

    // if (!liveRef && timeOffset) player.getIVSPlayer().seekTo(timeOffset / 1000);
    if (!liveRef && timeOffset) {
      player.currentTime(timeOffset / 1000);
      await player.play();
      console.log('JUMPED TO TIME');
    } else {
      console.log('PLAYING');
      await player.play();
    }

    player.one('play', () => {
      if (onVideoPlayerReady) {
        onVideoPlayerReady(player);
      }
    });
  };

  const onPlayerReady = async (p_url: string) => {
    const player = playerRef.current;
    if (player) {
      // player.enableIVSQualityPlugin();
      player.src(p_url);
      player.ready(async () => {
        playVideo(player);
        const ivsEvents = getIvsEvents(player);
        listenToIvsMetaData(player, ivsEvents);
        listenToIvsStateChange(player, ivsEvents);
        listenToVideoError(player, ivsEvents);
      });
    }
  };

  const registerPlayerOnAmazonIVS = () => {
    if (!playerRef.current) {
      const videoElement = videoElementRef.current;
      if (!videoElement) return;
      window.registerIVSTech(videojs);
      // window.registerIVSQualityPlugin(videojs);
      setIsMuted(!isFeatured);
      playerRef.current = videojs(
        videoElementRef.current,
        {
          ...initialOptions,
          ...options,
          poster: posterImage,
          controls: isFeatured,
          muted: !isFeatured,
        },
        () => onPlayerReady(url),
      );

      // playerRef.current.on('loadedmetadata', () => {
      //   playerRef.current.getIVSPlayer().seekTo(timeOffset / 1000);
      // });
    }
  };

  const unresgisterPlayerOnAmazonIVS = () => {
    if (playerRef.current) {
      const player = playerRef?.current?.getIVSPlayer();
      player.core.worker.terminate();
      playerRef.current.dispose();
      playerRef.current = null;
    }
  };

  const listenToPlayerEvents = () => {
    const player = playerRef?.current;
    if (!player) return;

    const controlBar = player.getChild('controlBar');

    const playToggle = controlBar.getChild('playToggle');

    playToggle.on('click', () => {
      if (!player.paused()) {
        if (onPlay && !liveRef) onPlay();
      } else if (onPause && !liveRef) {
        onPause();
      }
    });

    player.one('play', () => {
      if (onPlay) onPlay();
    });
    player.on('pause', () => {
      if (onProgramaticallyPauses) onProgramaticallyPauses();
    });

    player.on('timeupdate', (e) => {
      if (onTimeUpdate) onTimeUpdate(e?.target?.player['cache_']?.currentTime);
    });

    player.on('seeked', () => {
      if (liveRef) return;
      const currentTime = player?.currentTime();
      const currentTimestamp = startTimeInMs + Math.floor(currentTime * 1000);

      if (onSeek) onSeek(currentTimestamp);
    });
  };

  const refreshPlayer = () => {
    const player = playerRef.current;
    if (!player) return;

    player.poster(posterImage);
    player.src(url);
    player.ready(() => {
      playVideo(player);
      listenToPlayerEvents();
    });
    setGpsData(null);
    return () => {
      const events: VideoJSEvents = playerRef?.current?.getIVSEvents();
      playerRef?.current?.getIVSPlayer().removeEventListener(events.PlayerEventType.TEXT_METADATA_CUE, () => {});
      player.off('timeupdate');
    };
  };

  useEffect(refreshPlayer, [url, streamId]);

  const listenToFullScreenChanges = () => {
    document.addEventListener('fullscreenchange', () => {
      if (!isFeatured) return;
      if (onChangeFullScreen) onChangeFullScreen(playerRef.current?.isFullscreen() || false);
    });
  };

  const onIsFeaturedChanges = () => {
    if (!playerRef.current) return;
    playerRef.current.muted(!isFeatured);
    playerRef.current.controls(isFeatured);
  };
  useEffect(onIsFeaturedChanges, [isFeatured]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (onClickExpand) onClickExpand(!isExpanded);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    if (onClickFullScreen) onClickFullScreen(!isFullScreen);
  };

  const toggleMuteSound = () => {
    const isPlayerMuted = playerRef?.current.muted();
    setIsMuted(!isPlayerMuted);
    playerRef?.current.muted(!isPlayerMuted);
  };

  useInterval(() => {
    setLatencyInSeconds(playerRef?.current?.getIVSPlayer()?.getLiveLatency().toFixed(2));
  }, 10000);

  useEffect(() => {
    registerPlayerOnAmazonIVS();
    listenToFullScreenChanges();
    listenToPlayerEvents();
    setTimeout(() => setCanJumpToTimeOffset(true), 3000);
    return () => {
      const events: VideoJSEvents = playerRef?.current?.getIVSEvents();
      playerRef?.current?.getIVSPlayer().removeEventListener(events.PlayerEventType.TEXT_METADATA_CUE, () => {});
      document.removeEventListener('fullscreenchange', () => {});
      unresgisterPlayerOnAmazonIVS();
    };
  }, []);

  return (
    <Flex
      className="video-stream"
      css={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: '20px',
        position: 'relative',
        '& .vjs-tech': {
          objectFit: 'cover',
        },
        '& .video-js': {
          height: '100% !important',
        },
      }}
    >
      <Flex data-vjs-player data-mobile={isMobile} data-is-pip={isPip}>
        <video ref={videoElementRef} className="vjs-pulse video-js" playsInline />

        {isFeatured && !isPip && (
          <>
            {isMobile && (
              <Flex
                className="stream-mobile-actions"
                css={{
                  position: 'absolute',
                  right: '8px',
                  top: '8px',
                  gap: '$nano',
                  opacity: 0,
                  transition: 'all 1s',
                }}
              >
                {/* <div className="vjs-pulse vjs-control-background" /> */}
                <Flex
                  css={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '2.1rem',
                    height: '2.1rem',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                  }}
                  className="mobile-sound-button"
                  onClick={toggleMuteSound}
                >
                  {isMuted ? <MuteIcon /> : <VolumeHighIcon />}
                </Flex>
                <Flex
                  css={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '2.1rem',
                    height: '2.1rem',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                  }}
                  onClick={toggleFullScreen}
                >
                  {isFullScreen ? <FullScreenExitIcon /> : <FullScreenIcon />}
                </Flex>
                {!isAndroidFullScreen && orientation !== 'landscape' && (
                  <Flex
                    css={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '2.1rem',
                      height: '2.1rem',
                      borderRadius: '50%',
                      backgroundColor: 'rgba(0,0,0,0.5)',
                    }}
                    onClick={toggleExpand}
                  >
                    {isExpanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                  </Flex>
                )}
              </Flex>
            )}

            {showTelemetry && !newTelemetry && (
              <TelemetryBagde
                gpsData={gpsData}
                username={username}
                useravatar={useravatar}
                speedType={speedType}
                showAtheleteThumbnail={showAtheleteThumbnail}
              />
            )}

            {sponsorImageUrl && (
              <Flex
                css={{
                  position: 'absolute',
                  right: '0.5rem',
                  bottom: '0.8rem',
                  opacity: 1,
                  visibility: 'visible',
                  transition: 'all 0.3s',
                }}
                className="sponsor-overlay"
              >
                <SponsorOverlayLogo url={sponsorImageUrl} />
              </Flex>
            )}
          </>
        )}
        {showLiveBadge ? isLive ? <LiveBadge latencyInSeconds={latencyInSeconds} /> : <RecordedBadge /> : null}

        {showTelemetry && newTelemetry && (
          <NewTelemetryBadge
            gpsData={gpsData}
            channelTitle={channelTitle}
            username={username}
            useravatar={useravatar}
            speedType={speedType}
            showAtheleteThumbnail={showAtheleteThumbnail}
            isPip={isPip}
            streamId={streamId}
          />
        )}
        {/* 
        {videoState !== PlayerState.PLAYING && videoState !== PlayerState.IDLE && (
          <Flex
            css={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <AppleLoading />
          </Flex>
        )} */}
      </Flex>
    </Flex>
  );
}

export default React.memo(VideoStream);
