import { useCallback, useMemo } from 'react';
import useEventStore from '../../../pages/new-event/state-manager/store';

import AvatarUserWithSelectedAnimation from '../../avatar-with-selected-animation/avatar-with-selected-animation';
import { LiveChipWithBorderIcon } from '../../icons/live-chip-border';
import Flex from '../../primitives/flex';
import { Typo } from '../../primitives/typo/typo';

interface IProps {
  username: string;
  useravatar?: string;
  channelTitle?: string;
  channelStreams?: any[];
  showLiveChip?: boolean;
  content?: any;
}

function PlayIcon() {
  return (
    <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.7625 7.01254C0.595833 7.12087 0.427 7.12704 0.256 7.03104C0.0853334 6.93537 0 6.78754 0 6.58754V1.41254C0 1.21254 0.0853334 1.06454 0.256 0.96854C0.427 0.872873 0.595833 0.879207 0.7625 0.98754L4.8375 3.57504C4.9875 3.67504 5.0625 3.81671 5.0625 4.00004C5.0625 4.18337 4.9875 4.32504 4.8375 4.42504L0.7625 7.01254Z"
        fill="white"
        fillOpacity="0.91"
      />
    </svg>
  );
}

export function TimelineAvatar({
  username,
  useravatar,
  channelStreams,
  showLiveChip = false,
  content,
  channelTitle,
}: IProps) {
  const featStream = useEventStore((state) => state.featStream);
  const timelineRef = useEventStore((state) => state.timelineElement);
  const selectLiveStream = useEventStore((state) => state.selectLiveStream);

  const zoomChannelStreams = () => {
    if (!channelStreams || !channelStreams?.length) return;
    const streamIds = channelStreams?.map((stream) => stream?.id);
    timelineRef?.focus(streamIds);
  };

  const getChannelLiveStream = useCallback(
    () => channelStreams?.find((stream) => stream.duration_in_seconds <= 0),
    [channelStreams],
  );

  const onClickChannel = () => {
    const liveStream = getChannelLiveStream();
    if (liveStream) {
      return selectLiveStream(liveStream.id);
    }
    zoomChannelStreams();
  };

  const isSelected = useMemo(() => featStream?.content?.channelId === content?.id, [featStream]);

  return (
    <Flex
      css={{
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
      onClick={onClickChannel}
      id={content?.id}
    >
      <Flex css={{ position: 'relative', gap: '$3xs', alignItems: 'center' }}>
        <Flex css={{ position: 'relative', gap: '$3xs', alignItems: 'center' }}>
          <AvatarUserWithSelectedAnimation
            user={{
              username,
              useravatar: useravatar || '',
            }}
            isAnimatedBorder={isSelected}
            css={{ width: '1.6rem', height: '1.6rem', borderWidth: '2px' }}
          />
          {isSelected && (
            <Flex
              className="active-overlay"
              css={{
                position: 'absolute',
                backgroundColor: ' rgba(19, 19, 19, 0.62)',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                zIndex: 5,
              }}
            >
              <PlayIcon />
            </Flex>
          )}
        </Flex>

        <Typo
          css={{
            color: '$constantWhite',
            fontSize: '12px',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {channelTitle}
        </Typo>
      </Flex>

      {showLiveChip && !!getChannelLiveStream() && (
        <Flex css={{ position: 'absolute', top: 22, left: 12, zIndex: 5 }}>
          <LiveChipWithBorderIcon />
        </Flex>
      )}
    </Flex>
  );
}
