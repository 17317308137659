/* eslint-disable import/export */
import { UnexpectedError } from '../../domain/errors';
import { StreamAnonymousRepository } from '../../domain/usecases';
import { HttpStatusCode, HttpClient } from '../protocols/http';

export class RemoteStreamAnonymous implements StreamAnonymousRepository {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<StreamAnonymousRepository.Model | any>,
  ) {}

  async get(streamId: string): Promise<StreamAnonymousRepository.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${streamId}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;

      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteStreamAnonymous {
  export type Model = StreamAnonymousRepository.Model;
}
