import { SpeedType } from '../../../../domain/models/stream-model';
import {
  formattedSpeedValue,
  getAltitude,
  getSpeedAlias,
  getSpeedMeasurement,
  getSpeedMeasurementForMobile,
} from '../../../utils/speed-conversions';
import AvatarUser from '../../avatar-user/avatar-user';
import MontainIcon from '../../icons/montain';
import { TimerIcon } from '../../icons/timer';
import Flex from '../../primitives/flex';
import { Typo } from '../../primitives/typo/typo';
import { GpsMetaData } from './gps-type';

interface IProps {
  gpsData: GpsMetaData | null;
  username: string;
  useravatar: string;
  speedType?: SpeedType;
  showAtheleteThumbnail?: boolean;
  isFullScreen?: boolean;
}

export default function TelemetryBagde({
  gpsData,
  username,
  useravatar,
  speedType,
  showAtheleteThumbnail,
  isFullScreen = false,
}: IProps) {
  return (
    <Flex
      className="badge-wrapper"
      css={{
        opacity: gpsData ? 1 : 0,
        position: 'absolute',
        bottom: '1.5rem',
        transformOrigin: 'bottom left',
        transform: isFullScreen ? 'scale(1.5)' : 'scale(1)',
        left: '1rem',
        gap: '$nano',
        transition: 'visibility 0.5s, opacity 0.5s, width 0.5s',
        '& .info-badge': {
          flexDirection: 'column',
          padding: '0.56rem 0.625rem',
          height: '4.68rem',
          background: 'rgba(0, 0, 0, 0.6)',
          backdropFilter: 'blur(2px)',
          borderRadius: '$md',
          display: 'none',
          justifyContent: 'space-around',
          border: '1px solid rgba(255, 255, 255, 0.47)',
        },
        '& .mobile': {
          height: '2.12rem',
          display: 'flex',
          flexDirection: 'row',
        },
        '@bp3': {
          '& .info-badge': {
            display: 'none',
          },
          '& .mobile': {
            display: 'flex',
          },
        },
      }}
    >
      {showAtheleteThumbnail && (
        <Flex className="info-badge user-avatar desktop" css={{ alignItems: 'center' }}>
          <AvatarUser
            user={{
              username: username || '',
              useravatar: useravatar || '',
            }}
            css={{ height: '1.87rem', width: '1.87' }}
          />
          <Typo type="bodySmallMedium" css={{ color: '$constantWhite', fontWeight: '$book' }}>
            {`@${username || ''}`}
          </Typo>
        </Flex>
      )}

      <Flex className="info-badge desktop" css={{ display: gpsData?.gps_speed ? 'flex' : 'none' }}>
        <Flex css={{ gap: '$nano', alignItems: 'flex-end' }}>
          <Typo type="h5" css={{ color: '$constantWhite' }}>
            {formattedSpeedValue(speedType, gpsData?.gps_speed || 0)}
          </Typo>
          <Typo css={{ color: '$gs300', fontWeight: '$book', fontSize: '1rem', marginBottom: '1px' }}>
            {getSpeedMeasurement(speedType)}
          </Typo>
        </Flex>
        <Flex css={{ alignItems: 'center', gap: '$3xs' }}>
          <TimerIcon />
          <Typo type="bodyRegular" css={{ color: '$constantWhite', fontWeight: '$book' }}>
            {getSpeedAlias(speedType)}
          </Typo>
        </Flex>
      </Flex>

      <Flex className="info-badge desktop" css={{ display: gpsData?.gps_altitude ? 'flex' : 'none' }}>
        <Flex css={{ gap: '$nano', alignItems: 'flex-end' }}>
          <Typo type="h5" css={{ color: '$constantWhite' }}>
            {getAltitude(gpsData?.gps_altitude)}
          </Typo>
          <Typo css={{ color: '$gs300', fontWeight: '$book', fontSize: '1rem', marginBottom: '1px' }}>meters</Typo>
        </Flex>
        <Flex css={{ alignItems: 'center', gap: '$3xs' }}>
          <MontainIcon />
          <Typo type="bodyRegular" css={{ color: '$constantWhite', fontWeight: '$book' }}>
            Altitude
          </Typo>
        </Flex>
      </Flex>

      <Flex
        className="info-badge mobile"
        css={{ flexDirection: 'row', alignItems: 'center', gap: '$xs', alignSelf: 'flex-end' }}
      >
        {showAtheleteThumbnail && (
          <Flex css={{ gap: '$nano' }}>
            <AvatarUser
              user={{
                username: username || '',
                useravatar: useravatar || '',
              }}
              css={{ height: '1.125rem', width: '1.125rem', border: 'none' }}
            />
            <Typo type="caption" css={{ color: '$constantWhite' }}>
              {`@${username || ''}`}
            </Typo>
          </Flex>
        )}

        <Flex css={{ alignItems: 'center', gap: '$3xs', display: gpsData?.gps_speed ? 'flex' : 'none' }}>
          <TimerIcon />
          <Flex css={{ alignItems: 'center', gap: '$quark' }}>
            <Typo type="caption" css={{ fontWeight: '$bold' }}>
              {formattedSpeedValue(speedType, gpsData?.gps_speed || 0)}
            </Typo>
            <Typo css={{ color: '$gs300' }}>{getSpeedMeasurementForMobile(speedType)}</Typo>
          </Flex>
        </Flex>

        <Flex css={{ alignItems: 'center', gap: '$3xs', display: gpsData?.gps_altitude ? 'flex' : 'none' }}>
          <MontainIcon />
          <Flex css={{ alignItems: 'center', gap: '$quark' }}>
            <Typo type="caption" css={{ fontWeight: '$bold' }}>
              {getAltitude(gpsData?.gps_altitude)}
            </Typo>
            <Typo css={{ color: '$gs300' }}>m</Typo>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
