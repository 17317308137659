import { DataSet } from 'vis-data';
import { EventStream } from '../../../pages/new-event/state-manager/store-types';

export class TimelineUpdateController {
  // groups and items
  itemsDataset: DataSet<EventStream, 'id'> = new DataSet();

  groupsDataset: DataSet<any, 'id'> = new DataSet();

  addOrUpdateSingleGroup(group: any) {
    this.groupsDataset.update(group);
  }

  addOrUpdateSingleItem(item: EventStream) {
    this.itemsDataset.update(item);
  }

  addOrUpdateAllGroupsAndItems(groups: any[], items: EventStream[]) {
    groups.forEach((group) => this.addOrUpdateSingleGroup(group));
    items.forEach((item) => this.addOrUpdateSingleItem(item));
  }

  // remove item

  // update item

  // check if the item exists

  // getLiveItems

  // getAllItems
  getAllItems(): { items: DataSet<EventStream, 'id'>; groups: DataSet<any, 'id'> } {
    return { items: this.itemsDataset, groups: this.groupsDataset };
  }

  // getRecordedItems
}
