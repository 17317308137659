export default function WhatsAppShareIcon() {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_601_10456)">
        <path
          d="M37.7667 28.1827C37.7667 28.3897 37.7602 28.8359 37.7473 29.1851C37.7149 30.0323 37.6503 31.1252 37.5468 31.6231C37.3916 32.3733 37.1653 33.0782 36.8613 33.6667C36.5056 34.3651 36.0529 34.9859 35.5097 35.5227C34.973 36.0594 34.3522 36.5121 33.6537 36.8678C33.0652 37.1717 32.3539 37.3981 31.5973 37.5533C31.1058 37.6503 30.0193 37.7214 29.1786 37.7473C28.8294 37.7602 28.3832 37.7667 28.1763 37.7667H9.57747C9.37053 37.7667 8.92432 37.7602 8.5751 37.7473C7.72794 37.7149 6.63504 37.6503 6.13708 37.5468C5.38692 37.3916 4.68203 37.1653 4.09354 36.8613C3.39512 36.5056 2.7743 36.0529 2.23755 35.5097C1.70079 34.973 1.24811 34.3522 0.892432 33.6537C0.588488 33.0652 0.362146 32.3539 0.206941 31.5973C0.109937 31.1058 0.0388014 30.0193 0.0129338 29.1786C0.0064669 28.8359 0 28.3897 0 28.1827V9.58394C0 9.377 0.0064669 8.93078 0.0194007 8.58157C0.0517352 7.73441 0.116404 6.6415 0.219874 6.14355C0.37508 5.39339 0.601421 4.6885 0.905365 4.10001C1.26104 3.40159 1.71373 2.78077 2.25048 2.24401C2.78723 1.70726 3.40805 1.25458 4.10648 0.898899C4.69497 0.594954 5.40633 0.368613 6.16295 0.213408C6.65444 0.116404 7.74087 0.0452683 8.58157 0.0194007C8.93078 0.0064669 9.377 0 9.58394 0H28.1827C28.3897 0 28.8359 0.0064669 29.1851 0.0194007C30.0323 0.0517352 31.1252 0.116404 31.6231 0.219874C32.3733 0.37508 33.0782 0.601421 33.6667 0.905365C34.3651 1.26104 34.9859 1.71373 35.5227 2.25695C36.0594 2.7937 36.5121 3.41452 36.8678 4.11295C37.1717 4.70143 37.3981 5.41279 37.5533 6.16942C37.6503 6.6609 37.7214 7.74734 37.7473 8.58804C37.7602 8.93725 37.7667 9.38347 37.7667 9.59041V28.1827Z"
          fill="url(#paint0_linear_601_10456)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.8166 9.04075C26.2751 6.49926 22.8929 5.09595 19.2908 5.09595C11.8668 5.09595 5.8332 11.136 5.82673 18.5536C5.82673 20.9269 6.44756 23.2421 7.62453 25.2791L5.7168 32.2569L12.8563 30.388C14.8222 31.4615 17.0339 32.0241 19.2908 32.0241H19.2973C26.7148 32.0241 32.7549 25.984 32.7614 18.5665C32.7614 14.9644 31.358 11.5822 28.8166 9.04075ZM19.2973 29.7478C17.2861 29.7478 15.3137 29.2045 13.5999 28.1892L13.1925 27.95L8.95671 29.0623L10.0884 24.9364L9.82328 24.516C8.7045 22.7376 8.10955 20.6747 8.10955 18.56C8.10955 12.3906 13.1343 7.37229 19.3037 7.37229C22.2915 7.37229 25.1046 8.53634 27.2128 10.651C29.3274 12.7657 30.4915 15.5788 30.485 18.5665C30.4785 24.7294 25.4602 29.7478 19.2973 29.7478ZM25.4344 21.3731C25.0981 21.205 23.4426 20.3902 23.1386 20.2802C22.8282 20.1703 22.6083 20.1121 22.382 20.4484C22.1556 20.7846 21.5154 21.5413 21.315 21.7676C21.1209 21.9939 20.9205 22.0198 20.5842 21.8517C20.2479 21.6835 19.1615 21.3279 17.881 20.1832C16.8787 19.2908 16.2061 18.1914 16.0121 17.8551C15.8181 17.5189 15.9927 17.3378 16.1608 17.1696C16.3096 17.0209 16.4971 16.7752 16.6653 16.5812C16.8334 16.3871 16.8916 16.2449 17.0015 16.0185C17.1115 15.7922 17.0597 15.5982 16.9757 15.43C16.8916 15.2619 16.219 13.6064 15.941 12.9338C15.6694 12.2807 15.3913 12.3647 15.1843 12.3583C14.9903 12.3453 14.764 12.3453 14.5376 12.3453C14.3113 12.3453 13.9492 12.4294 13.6387 12.7657C13.3283 13.102 12.4618 13.9168 12.4618 15.5723C12.4618 17.2278 13.6646 18.8252 13.8328 19.0515C14.0009 19.2779 16.2061 22.673 19.5818 24.128C20.3837 24.4772 21.011 24.6842 21.5025 24.8394C22.3109 25.0981 23.0416 25.0593 23.6236 24.9752C24.2703 24.8782 25.6154 24.1604 25.8935 23.3779C26.1716 22.5954 26.1716 21.9163 26.0875 21.7805C25.9905 21.6253 25.7706 21.5413 25.4344 21.3731Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_601_10456"
          x1="18.8848"
          y1="37.7696"
          x2="18.8848"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25CF43" />
          <stop offset="1" stopColor="#61FD7D" />
        </linearGradient>
        <clipPath id="clip0_601_10456">
          <rect width="37.7667" height="37.7667" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
