export function CreateIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.90909 21.9651L3.23211 20.329C3.1615 20.2858 3.10287 20.2238 3.06213 20.1492C3.02138 20.0745 2.99995 19.9898 3 19.9036V5.39131C2.99962 5.3041 3.02367 5.21872 3.0691 5.14593C3.11454 5.07314 3.17933 5.0162 3.25532 4.98228L11.2012 1.05563C11.2654 1.01911 11.3371 1 11.4101 1C11.483 1 11.5547 1.01911 11.6189 1.05563L14.3114 2.69174C14.3569 2.71651 14.395 2.75405 14.4216 2.80022C14.4482 2.8464 14.4623 2.89943 14.4623 2.95351C14.4623 3.00759 14.4482 3.06063 14.4216 3.1068C14.395 3.15298 14.3569 3.19052 14.3114 3.21529L6.57447 7.01105C6.49965 7.04667 6.43593 7.10402 6.39073 7.17643C6.34553 7.24884 6.32071 7.33334 6.31915 7.42008V21.7115C6.31818 21.7619 6.305 21.8111 6.28087 21.8546C6.25673 21.8981 6.22245 21.9343 6.1813 21.9597C6.14015 21.9852 6.09352 21.999 6.04586 21.9999C5.9982 22.0009 5.95111 21.9889 5.90909 21.9651ZM9.19734 12.1893L12.0523 10.7823C12.1132 10.7534 12.1648 10.7062 12.2006 10.6466C12.2363 10.5869 12.2547 10.5175 12.2534 10.4469V7.63277C12.2531 7.5204 12.2837 7.41036 12.3414 7.31598C12.399 7.2216 12.4814 7.14694 12.5784 7.10104L14.6055 6.10301C14.6475 6.08265 14.6936 6.07365 14.7397 6.07683C14.7858 6.08002 14.8305 6.09528 14.8696 6.12125C14.9087 6.14721 14.9412 6.18307 14.9639 6.22559C14.9867 6.2681 14.9991 6.31594 15 6.36479V12H11.2998C10.9423 12 10.6364 12.1274 10.382 12.3822C10.1272 12.6366 9.99984 12.9425 9.99984 13.3C9.99984 13.9831 9.99984 14.5492 9.99985 15.0161L9.27471 15.3716C9.23211 15.3922 9.18528 15.4011 9.13854 15.3976C9.0918 15.394 9.04667 15.378 9.00733 15.3511C8.96799 15.3242 8.93571 15.2872 8.91347 15.2436C8.89124 15.2 8.87976 15.1511 8.88012 15.1016V12.7292C8.87862 12.6166 8.90772 12.5059 8.96394 12.4102C9.02017 12.3145 9.10117 12.2378 9.19734 12.1893Z"
        fill="#DAFF01"
        fillOpacity="0.2"
      />
      <path
        d="M15.4166 19.3667C15.5701 19.3667 15.6988 19.3147 15.8028 19.2107C15.9064 19.1071 15.9583 18.9785 15.9583 18.825V17.7417H17.0416C17.1951 17.7417 17.3238 17.6897 17.4278 17.5857C17.5314 17.4821 17.5833 17.3535 17.5833 17.2C17.5833 17.0466 17.5314 16.9178 17.4278 16.8138C17.3238 16.7102 17.1951 16.6584 17.0416 16.6584H15.9583V15.575C15.9583 15.4216 15.9064 15.2928 15.8028 15.1888C15.6988 15.0852 15.5701 15.0334 15.4166 15.0334C15.2631 15.0334 15.1346 15.0852 15.0309 15.1888C14.9269 15.2928 14.8749 15.4216 14.8749 15.575V16.6584H13.7916C13.6381 16.6584 13.5094 16.7102 13.4054 16.8138C13.3017 16.9178 13.2499 17.0466 13.2499 17.2C13.2499 17.3535 13.3017 17.4821 13.4054 17.5857C13.5094 17.6897 13.6381 17.7417 13.7916 17.7417H14.8749V18.825C14.8749 18.9785 14.9269 19.1071 15.0309 19.2107C15.1346 19.3147 15.2631 19.3667 15.4166 19.3667ZM12.1666 21.5334C11.8687 21.5334 11.6137 21.4274 11.4018 21.2154C11.1894 21.0031 11.0833 20.7479 11.0833 20.45V13.95C11.0833 13.6521 11.1894 13.3972 11.4018 13.1852C11.6137 12.9729 11.8687 12.8667 12.1666 12.8667H18.6666C18.9645 12.8667 19.2196 12.9729 19.432 13.1852C19.6439 13.3972 19.7499 13.6521 19.7499 13.95V16.3875L21.4562 14.6813C21.5374 14.6 21.6346 14.5796 21.7476 14.6201C21.8603 14.6609 21.9166 14.7445 21.9166 14.8709V19.5292C21.9166 19.6556 21.8603 19.739 21.7476 19.7794C21.6346 19.8203 21.5374 19.8 21.4562 19.7188L19.7499 18.0125V20.45C19.7499 20.7479 19.6439 21.0031 19.432 21.2154C19.2196 21.4274 18.9645 21.5334 18.6666 21.5334H12.1666Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>
  );
}
