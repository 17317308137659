export default function MontainIcon() {
  return (
    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.51395 9.4375C1.16673 9.4375 0.916725 9.28472 0.763947 8.97917C0.611169 8.67361 0.638947 8.38194 0.847281 8.10417L4.18061 3.66667C4.34728 3.44444 4.5695 3.33333 4.84728 3.33333C5.12506 3.33333 5.34728 3.44444 5.51395 3.66667L8.59728 7.77083H14.8473L10.6806 2.22917L8.59728 4.97917L7.55561 3.60417L10.0139 0.333333C10.1806 0.111111 10.4028 0 10.6806 0C10.9584 0 11.1806 0.111111 11.3473 0.333333L17.1806 8.10417C17.3889 8.38194 17.4167 8.67361 17.2639 8.97917C17.1112 9.28472 16.8612 9.4375 16.5139 9.4375H1.51395ZM3.18061 7.77083H6.51395L4.84728 5.54167L3.18061 7.77083Z"
        fill="white"
      />
    </svg>
  );
}
