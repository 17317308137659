export function PlayArrowIcon() {
  return (
    <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.525 12.5251C1.19167 12.7417 0.854 12.7541 0.512 12.5621C0.170667 12.3707 0 12.0751 0 11.6751V1.32508C0 0.92508 0.170667 0.62908 0.512 0.43708C0.854 0.245747 1.19167 0.258413 1.525 0.47508L9.675 5.65008C9.975 5.85008 10.125 6.13341 10.125 6.50008C10.125 6.86675 9.975 7.15008 9.675 7.35008L1.525 12.5251ZM2 9.85008L7.25 6.50008L2 3.15008V9.85008Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>
  );
}
