interface IProps {
  color?: string;
}
export default function SimpleRightArrowIcon({ color = '#fff' }: IProps) {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="simple-right-arrow"
    >
      <path
        d="M11.4629 8.94922C11.4629 8.46094 11.2871 8.06055 10.8867 7.67969L3.50391 0.443359C3.21094 0.150391 2.84961 0.00390625 2.41992 0.00390625C1.56055 0.00390625 0.857422 0.707031 0.857422 1.55664C0.857422 1.98633 1.0332 2.38672 1.35547 2.69922L7.79102 8.93945L1.35547 15.1895C1.0332 15.5117 0.857422 15.9023 0.857422 16.3418C0.857422 17.2012 1.56055 17.8945 2.41992 17.8945C2.84961 17.8945 3.21094 17.748 3.50391 17.4551L10.8867 10.2188C11.2969 9.82812 11.4629 9.42773 11.4629 8.94922Z"
        fill={color}
      />
    </svg>
  );
}
