import React from 'react';
import * as S from './styles';
import { stringToColour } from '../../utils/colorConversion';
import { keyframes, styled } from '../../stitches.config';

export enum StreamType {
  ATHLETE = 'ATHLETE',
  STATIONARY = 'STATIONARY',
  SPECTATOR = 'SPECTATOR',
  COMMENTARY = 'COMMENTARY',
}

const moveGradient = keyframes({
  '0%': { backgroundPosition: '50% 0%' },
  '25%': { backgroundPosition: '100% 50%' },
  '50%': { backgroundPosition: '50% 100%' },
  '75%': { backgroundPosition: '0% 50%' },
  '100%': { backgroundPosition: '50% 0%' },
});

interface PulseAvatarProps {
  imageUrl: string;
  title: string;
  isSelected?: boolean;
  streamType?: StreamType;
  size?: number;
}

const DIAMOND_RESIZE_FACTOR = 0.872;
const COMMENTARY_RESIZE_FACTOR = 1.2;
const BORDER_SIZE_FACTOR = 23.8;

export function PulseAvatarComponent({
  imageUrl,
  title,
  isSelected,
  size = 2.5,
  streamType = StreamType.ATHLETE,
}: PulseAvatarProps) {
  const imgAltText = `${title}'s avatar`;
  const avatarColor = stringToColour(title);

  const AvatarImageWrapper = styled('div', {
    width: `${size}rem`,
    height: `${size}rem`,
    borderRadius: '50%',
    objectFit: 'cover',
    transition: 'all 0.5s ease-out',
    zIndex: 2,
    boxShadow: '0px 0px 0px 0.11rem rgba(0, 0, 0, 0.75)',
    boxSizing: 'border-box',
    overflow: 'hidden',
    span: {
      height: '100%',
    },

    variants: {
      streamType: {
        ATHLETE: {
          // borderRadius: '50%',
        },
        STATIONARY: {
          borderRadius: '0.43rem',
          overflow: 'hidden',
        },
        SPECTATOR: {
          borderRadius: '0.25rem',
          transform: 'rotate(45deg)',
          overflow: 'hidden',
          width: `${size * DIAMOND_RESIZE_FACTOR}rem`,
          height: `${size * DIAMOND_RESIZE_FACTOR}rem`,
        },
        COMMENTARY: {
          width: `${size * COMMENTARY_RESIZE_FACTOR}rem`,
          borderRadius: '0.43rem',
        },
      },
    },
  });

  const GradientBorder = styled('div', {
    position: 'absolute',
    width: `${size}rem`,
    height: `${size}rem`,
    zIndex: 2,
    background: 'radial-gradient(closest-side at 50% 50%, $eletricLimeFluorescent 0%, $raven 100%)',
    backgroundSize: '200% 200%',
    animation: `${moveGradient} 1.2s linear infinite`,

    variants: {
      streamType: {
        ATHLETE: {
          borderRadius: '50%',
        },
        STATIONARY: {
          borderRadius: '0.43rem',
        },
        SPECTATOR: {
          borderRadius: '0.25rem',
          transform: 'rotate(45deg)',
          width: `${size * DIAMOND_RESIZE_FACTOR}rem`,
          height: `${size * DIAMOND_RESIZE_FACTOR}rem`,
        },
        COMMENTARY: {
          borderRadius: '0.43rem',
          width: `${size * COMMENTARY_RESIZE_FACTOR}rem`,
        },
      },
    },
  });

  return (
    <S.AvatarContainer className="avatar-container" streamType={streamType}>
      {isSelected && <GradientBorder streamType={streamType} />}

      <AvatarImageWrapper
        streamType={streamType}
        css={{
          border: isSelected
            ? `${size / BORDER_SIZE_FACTOR}rem solid transparent`
            : `${size / BORDER_SIZE_FACTOR}rem solid ${avatarColor}`,
        }}
      >
        <S.AvatarImage
          src={imageUrl}
          alt={imgAltText}
          streamType={streamType}
          // onDragStart={(event: React.DragEvent<HTMLImageElement>) => event.preventDefault()}
        />
      </AvatarImageWrapper>
    </S.AvatarContainer>
  );
}

export const PulseAvatar = React.memo(PulseAvatarComponent);
