export default function PersonAdd() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="person-add-icon"
    >
      <mask
        id="mask0_830_5006"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_830_5006)">
        <path
          d="M19 14C18.7167 14 18.4793 13.904 18.288 13.712C18.096 13.5207 18 13.2833 18 13V11H16C15.7167 11 15.4793 10.904 15.288 10.712C15.096 10.5207 15 10.2833 15 10C15 9.71667 15.096 9.479 15.288 9.287C15.4793 9.09567 15.7167 9 16 9H18V7C18 6.71667 18.096 6.479 18.288 6.287C18.4793 6.09567 18.7167 6 19 6C19.2833 6 19.5207 6.09567 19.712 6.287C19.904 6.479 20 6.71667 20 7V9H22C22.2833 9 22.5207 9.09567 22.712 9.287C22.904 9.479 23 9.71667 23 10C23 10.2833 22.904 10.5207 22.712 10.712C22.5207 10.904 22.2833 11 22 11H20V13C20 13.2833 19.904 13.5207 19.712 13.712C19.5207 13.904 19.2833 14 19 14ZM9 12C7.9 12 6.95833 11.6083 6.175 10.825C5.39167 10.0417 5 9.1 5 8C5 6.9 5.39167 5.95833 6.175 5.175C6.95833 4.39167 7.9 4 9 4C10.1 4 11.0417 4.39167 11.825 5.175C12.6083 5.95833 13 6.9 13 8C13 9.1 12.6083 10.0417 11.825 10.825C11.0417 11.6083 10.1 12 9 12ZM2 20C1.71667 20 1.47933 19.904 1.288 19.712C1.096 19.5207 1 19.2833 1 19V17.2C1 16.6333 1.146 16.1123 1.438 15.637C1.72933 15.1623 2.11667 14.8 2.6 14.55C3.63333 14.0333 4.68333 13.6457 5.75 13.387C6.81667 13.129 7.9 13 9 13C10.1 13 11.1833 13.129 12.25 13.387C13.3167 13.6457 14.3667 14.0333 15.4 14.55C15.8833 14.8 16.2707 15.1623 16.562 15.637C16.854 16.1123 17 16.6333 17 17.2V19C17 19.2833 16.904 19.5207 16.712 19.712C16.5207 19.904 16.2833 20 16 20H2ZM3 18H15V17.2C15 17.0167 14.9543 16.85 14.863 16.7C14.771 16.55 14.65 16.4333 14.5 16.35C13.6 15.9 12.6917 15.5623 11.775 15.337C10.8583 15.1123 9.93333 15 9 15C8.06667 15 7.14167 15.1123 6.225 15.337C5.30833 15.5623 4.4 15.9 3.5 16.35C3.35 16.4333 3.22933 16.55 3.138 16.7C3.046 16.85 3 17.0167 3 17.2V18ZM9 10C9.55 10 10.021 9.804 10.413 9.412C10.8043 9.02067 11 8.55 11 8C11 7.45 10.8043 6.97933 10.413 6.588C10.021 6.196 9.55 6 9 6C8.45 6 7.97933 6.196 7.588 6.588C7.196 6.97933 7 7.45 7 8C7 8.55 7.196 9.02067 7.588 9.412C7.97933 9.804 8.45 10 9 10Z"
          fill="#DAFF01"
        />
      </g>
    </svg>
  );
}
