import { useState } from 'react';
import skeletonStyle from '../../../../common-styles/skeleton';
import { GenericBottomSheet } from '../../../../components/generic-bottom-sheet/generic-bottom-sheet';
import ExpandContentIcon from '../../../../components/icons/expand_content';
import ForumIcon from '../../../../components/icons/forum';
import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';
import GenericPulseChat from '../../../../components/third-party/chat/generic-chat';
import { useIsMobile } from '../../../../utils/useIsMobile';
import useAuthStore from '../../../auth-wrapper/state-manager/store';
import useEventStore from '../../state-manager/store';

export default function EventChat() {
  const eventInfo = useEventStore((state) => state.eventInfo);
  const setIsAuthDialogVisible = useAuthStore((state) => state.setIsAuthDialogVisible);
  const authToggleState = useAuthStore((state) => state.authToggleState);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const onClickOpenAuthDialog = () => {
    setIsAuthDialogVisible(true);
  };

  const openChatSheet = () => {
    if (isOpen || !isMobile) return;
    // 48 is the header size in px so 3rem is 3*16 = 48
    window?.scrollTo({ top: 48, left: 0, behavior: 'smooth' });
    setIsOpen(true);
  };

  const onClickClose = () => {
    setIsOpen(false);
  };

  return (
    <Flex
      css={{
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: '$bgAppScreen200',
        position: 'relative',
        paddingBottom: '10px',
        zIndex: 2,
        overflow: 'hidden',
        '@bp3': {
          paddingBottom: '0px',
          minHeight: '7.40rem',
        },
      }}
      onClick={openChatSheet}
    >
      {!!eventInfo?.getstream_channel_id && (
        <>
          <Flex
            className="chat-title"
            css={{
              alignItems: 'center',
              paddingLeft: '$xs',
              paddingRight: '$xs',
              minHeight: '2.5rem',
              borderBottom: '1px solid $gs500',
              backgroundColor: '$bgAppScreen200',
              justifyContent: 'space-between',
            }}
          >
            <Flex css={{ gap: '$2xs' }}>
              <ForumIcon />
              <Typo type="bodyBold" css={{ color: '$constantWhite', fontSize: '0.875rem' }}>
                Live Chat
              </Typo>
            </Flex>

            {isMobile && <ExpandContentIcon />}
          </Flex>
          <Flex
            className="chat-body"
            css={{ height: '100%', width: '100%', overflow: 'hidden', '&:empty': skeletonStyle }}
          >
            {authToggleState && (
              <>
                <GenericPulseChat
                  showInput={!isMobile}
                  chatType="event"
                  chatId={eventInfo?.getstream_channel_id}
                  onClickOpenAuthDialog={onClickOpenAuthDialog}
                />

                <GenericBottomSheet
                  isOpen={isOpen}
                  onClickClose={onClickClose}
                  title="Live Chat"
                  headerIcon={<ForumIcon />}
                >
                  <GenericPulseChat
                    chatId={eventInfo?.getstream_channel_id}
                    chatType="event"
                    onClickOpenAuthDialog={onClickOpenAuthDialog}
                  />
                </GenericBottomSheet>
              </>
            )}
          </Flex>
        </>
      )}
      {isMobile && (
        <Flex css={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0 }} onClick={openChatSheet} />
      )}
    </Flex>
  );
}
