import { useContext } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { SourceLocation } from '../../../../../domain/models/event-timeline-model';
import AvatarUser from '../../../../components/avatar-user/avatar-user';
import Box from '../../../../components/primitives/box';
import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';
import { getUniqueListBy, stringToColour } from '../../../../utils/utils';
import { ISquadVideoContext, SquadVideoContext } from '../../context';

interface IProps {
  children: string;
  timeStamp: string;
  isSelected?: boolean;
  sourceLocations?: SourceLocation[];
  onClick?: (sourceLocations: SourceLocation[]) => void;
}

export default function TimeItem({ children, isSelected = false, sourceLocations = [], onClick, timeStamp }: IProps) {
  const { streamList, athletePositionsOnHover, setAthletePositionsOnHover } =
    useContext<ISquadVideoContext>(SquadVideoContext);
  const isMobile = useMediaQuery('(max-width: 900px)');

  const MAX_USERS_TO_SHOW = 4;

  const getUserListFromSourceLocations = () => {
    const users = sourceLocations.map(({ stream_id }) => {
      const stream = streamList?.get(stream_id);
      return {
        username: stream?.channel.username,
        useravatar: stream?.channel.hero_photo.original,
        color: stringToColour(stream?.channel.username),
      };
    });

    return getUniqueListBy(users, 'username');
  };

  const hasMoreThanMaxDisplayUsers = () => getUserListFromSourceLocations().length > MAX_USERS_TO_SHOW;

  const showUserPreviewLocations = () => {
    if (isSelected || isMobile) return;
    athletePositionsOnHover.clear();
    setAthletePositionsOnHover(new Map());
    const usersPreviewLocations = sourceLocations.map(({ stream_id, latitude, longitude }) => {
      const stream = streamList?.get(stream_id);
      return {
        id: stream_id,
        username: stream?.channel.username,
        useravatar: stream?.channel.hero_photo.original,
        color: stringToColour(stream?.channel.username),
        latitude,
        longitude,
      };
    });
    usersPreviewLocations.forEach((userLocation) => {
      athletePositionsOnHover.set(userLocation.id, userLocation);
    });
    setAthletePositionsOnHover(new Map(athletePositionsOnHover));
  };

  const hideUsersPreviewLocations = () => {
    setAthletePositionsOnHover(new Map());
  };

  const onClickItem = () => {
    if (onClick) onClick(sourceLocations);
  };

  return (
    <Flex
      className="time-item"
      data-time={timeStamp}
      onClick={onClickItem}
      onMouseEnter={showUserPreviewLocations}
      onMouseLeave={hideUsersPreviewLocations}
      css={{
        minHeight: '2.3rem',
        width: '100%',
        borderRadius: '$sm',
        transition: 'all 0.5s',
        '@hover': {
          '&:hover': { backgroundColor: '$gs500' },
        },
        backgroundColor: isSelected ? '$gs500' : 'transparent',
        alignItems: 'center',
        padding: '0px $3xs',
        cursor: 'pointer',
        justifyContent: 'space-between',
      }}
    >
      <Flex className="time-item-inner" css={{ alignItems: 'center', gap: '$nano' }}>
        {isSelected && (
          <Box
            css={{
              height: '0.125rem',
              width: '0.625rem',
              borderRadius: '50%',
              backgroundColor: '$primaryAdrenaline100',
            }}
          />
        )}

        <Typo type="bodySm" css={{ color: '$gs100', fontWeight: '$book' }}>
          {children}
        </Typo>
      </Flex>

      <Flex className="timeline-users" css={{ gap: '$nano', alignItems: 'center' }}>
        {getUserListFromSourceLocations().map((user) => (
          <AvatarUser key={user.username} user={user} />
        ))}

        {hasMoreThanMaxDisplayUsers() && (
          <Typo type="captionSmall" css={{ color: '$primaryAdrenaline100' }}>
            {`+${getUserListFromSourceLocations().length - MAX_USERS_TO_SHOW}`}
          </Typo>
        )}
      </Flex>
    </Flex>
  );
}
