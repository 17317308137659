import { createContext } from 'react';

export interface IMobileGridEventContext {
  onExpand: (isExpanded: boolean) => void;
  onFullScreen: (isExpanded: boolean) => void;
  isFullscreen: boolean;
  selectedStreamPlayerRef: any;
}

export const MobileGridEventContext = createContext({} as IMobileGridEventContext);
