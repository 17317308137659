export default function BroadCastIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_93_475)">
        <path
          d="M8 15.29C11.9648 15.29 15.2188 12.0293 15.2188 8.06445C15.2188 4.10645 11.958 0.845703 8 0.845703C4.03516 0.845703 0.78125 4.10645 0.78125 8.06445C0.78125 12.0293 4.04199 15.29 8 15.29ZM8 13.4922C4.99902 13.4922 2.58594 11.0723 2.58594 8.06445C2.58594 5.06348 4.99219 2.64355 8 2.64355C11.001 2.64355 13.4209 5.06348 13.4209 8.06445C13.4209 11.0723 11.0078 13.4922 8 13.4922ZM8.00684 10.751C9.49023 10.751 10.6934 9.54785 10.6934 8.05078C10.6934 6.57422 9.49023 5.36426 8.00684 5.36426C6.50977 5.36426 5.30664 6.57422 5.30664 8.05078C5.30664 9.54785 6.50977 10.751 8.00684 10.751Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_93_475">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
