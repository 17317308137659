import { styled } from '../../stitches.config';
import { Text } from '../primitives/text/text';

const AvatarContainer = styled('button', {
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  variants: {
    streamType: {
      ATHLETE: {},
      STATIONARY: {},
      SPECTATOR: {
        margin: '0.2rem 0rem',
      },
      COMMENTARY: {},
    },
  },
});

const AvatarDetail = styled('div', {
  position: 'absolute',
  // left: '1px',
  top: '50%',
  transform: 'translateY(-50%)',
  width: 0,
  justifyContent: 'center',
  // height: '0px',
  padding: '10px 10px',
  backgroundColor: '$blackTranslucentGlass',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
  opacity: 1,
  // visibility: 'hidden',
  transition: 'all 0.3s',
  flexDirection: 'column',
  zIndex: 1,
  display: 'flex',
  overflow: 'hidden',
  paddingRight: '12px',
  borderRadius: '8px',
  alignItems: 'flex-start',
  paddingLeft: '4rem',

  height: '55px',
  // opacity: 1,
  left: '-5px',
  visibility: 'visible',

  '.avatar-container:hover &': {
    height: '55px',
    opacity: 1,
    left: '-5px',
    visibility: 'visible',
  },
  '.avatar-container:focus &': {
    height: '55px',
    opacity: 1,
    left: '-5px',
    visibility: 'visible',
  },
  '&[data-is-spectator]': {
    '.avatar-container:hover &': { left: '-10px' },
    '.avatar-container:focus &': { left: '-10px' },
  },
});

const Title = styled(Text, {
  whiteSpace: 'nowrap',
  marginBottom: 0,
  marginRight: '5px',
  userSelect: 'none',
  fontWeight: '$medium',
  color: '$white',
});

const Subtitle = styled(Text, {
  fontSize: '0.9em',
  whiteSpace: 'nowrap',
  userSelect: 'none',
  color: '$white',
  fontWeight: '$demi',
});

export { AvatarContainer, Title, Subtitle, AvatarDetail };
