import { styled } from '../../stitches.config';

const AvatarContainer = styled('div', {
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  variants: {
    streamType: {
      ATHLETE: {},
      STATIONARY: {},
      SPECTATOR: {
        margin: '0.2rem 0rem',
      },
      COMMENTARY: {},
    },
  },
});

const AvatarImage = styled('img', {
  width: '100%',
  height: '100%',
  transition: 'all 0.5s ease-out',
  objectFit: 'cover',
  zIndex: 2,
  pointerEvents: 'none',
  userDrag: 'none',
  userSelect: 'none',
  overflow: 'hidden',

  '-webkit-user-drag': 'none',
  variants: {
    streamType: {
      ATHLETE: {
        borderRadius: '50%',
      },
      STATIONARY: {
        borderRadius: '0.43rem',
      },
      SPECTATOR: {
        borderRadius: '0.25rem',
        transform: 'scale(1.55) rotate(-45deg)',
      },
      COMMENTARY: {
        // borderRadius: '0.43rem',
      },
    },
  },
});

export { AvatarContainer, AvatarImage };
