import CloseCrossIcon from '../../../../components/icons/close-cross';
import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';
import VideoStream from '../../../../components/third-party/video-stream/video-stream';
import useEventStore from '../../state-manager/store';
import { useIsMobile } from '../../../../utils/useIsMobile';
import HostPill from '../../../../components/icons/host-pill';
import AvatarUser from '../../../../components/avatar-user/avatar-user';
import SoundWaveIcon from '../../../../components/icons/sound-wave';

export default function EventHostPlayer() {
  const playingHostStream = useEventStore((state) => state.playingHostStream);
  const setPlayingHostStream = useEventStore((state) => state.setPlayingHostStream);
  const isMobile = useIsMobile();
  const closeHostStream = () => {
    setPlayingHostStream(null);
  };

  if (!playingHostStream) return null;

  if (isMobile)
    return (
      <Flex
        css={{
          minHeight: '10rem',
          zIndex: 2,
          overflow: 'hidden',
          backgroundColor: '$bgAppScreen200',
          position: 'relative',
          width: '100%',
        }}
      >
        <Flex css={{ minWidth: '13rem' }}>
          <VideoStream
            url={playingHostStream.content.playback_url || ''}
            isFeatured
            isPip
            streamId={playingHostStream.content?.id}
            username={playingHostStream?.content?.username || ''}
            useravatar={playingHostStream?.content?.useravatar || ''}
            isLive
            onVideoEnds={closeHostStream}
            onVideoError={closeHostStream}
          />
        </Flex>

        <Flex
          css={{
            padding: '$xs $xs',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Flex css={{ justifyContent: 'space-between', width: '100%' }}>
            <HostPill />
            <Flex
              as="button"
              css={{
                touchAction: 'manipulation',
                border: 'none',
                backgroundColor: 'transparent',
                width: '3rem',
                height: '3rem',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '-5px',
              }}
              onClick={closeHostStream}
            >
              <CloseCrossIcon />
            </Flex>
          </Flex>
          <Flex css={{ alignItems: 'center', gap: '$xs' }}>
            <Flex css={{ minWidth: '2rem' }}>
              <AvatarUser
                user={{
                  username: playingHostStream?.content?.username || '',
                  useravatar: playingHostStream?.content?.useravatar || '',
                }}
              />
            </Flex>

            <Typo
              css={{ color: '$constantWhite' }}
            >{`${playingHostStream?.content?.username} ● ${playingHostStream?.content.title}`}</Typo>
          </Flex>
          <Flex css={{ height: '2rem', marginLeft: '-20px' }}>
            <SoundWaveIcon />
          </Flex>
        </Flex>
      </Flex>
    );

  return (
    <Flex
      css={{
        minHeight: '20rem',
        borderRadius: '$2lg',
        zIndex: 2,
        overflow: 'hidden',
        backgroundColor: '$bgAppScreen200',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <VideoStream
        url={playingHostStream.content.playback_url || ''}
        isFeatured
        streamId={playingHostStream.content?.id}
        username={playingHostStream?.content?.username || ''}
        useravatar={playingHostStream?.content?.useravatar || ''}
        showTelemetry={false}
        isLive
        onVideoEnds={closeHostStream}
        onVideoError={closeHostStream}
      />

      <Flex css={{ alignItems: 'center', padding: '0px $2xs', height: '4rem' }}>
        <Typo
          css={{ color: '$constantWhite' }}
        >{`${playingHostStream?.content?.username} ● ${playingHostStream?.content.title}`}</Typo>
      </Flex>

      <Flex
        as="button"
        css={{
          border: 'none',
          width: '3rem',
          height: '3rem',
          backgroundColor: 'rgba(0,0,0,0.5)',
          borderRadius: '50%',
          cursor: 'pointer',
          position: 'absolute',
          top: 15,
          right: 15,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={closeHostStream}
      >
        <CloseCrossIcon />
      </Flex>
    </Flex>
  );
}
