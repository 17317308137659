import { StreamModel } from '../../../../domain/models/stream-model';
import { GpsMetaData } from '../../../components/third-party/video-stream/gps-type';

type UserLocation = { latitude: number; longitude: number; username: string; useravatar: string };
export type IUserLocation =
  | (UserLocation & Partial<GpsMetaData> & { stream_id?: string; duration_in_seconds?: number })
  | null;

interface EventContentStream extends StreamModel {
  username?: string;
  useravatar?: string;
  channelId?: string;
  logoUrl?: string;
}
export interface EventStream {
  id: string;
  content: EventContentStream;
  group: string | undefined;
  channelTitle: string | undefined;
  timeoffsetInSeconds?: number;
  start: Date;
  end: Date;
  isProducer: boolean;
}

export enum EventPlayStatus {
  IDLE,
  STARTED_STREAMING,
  ENDED_ALL_STREAMS,
  LIVE,
}
