import { useState } from 'react';

import EventVideo from '../event-video/event-video';
import useEventStore from '../../state-manager/store';
import { styled } from '../../../../stitches.config';
import Flex from '../../../../components/primitives/flex';
import FullScreenExitIcon from '../../../../components/icons/full-screen-exit';
import FullScreenIcon from '../../../../components/icons/full-screen';

const ExpandIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="expand_content">
      <mask
        id="mask0_819_51295"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect id="Bounding box" y="18" width="18" height="18" transform="rotate(-90 0 18)" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_819_51295)">
        <path
          id="expand_content_2"
          d="M14.25 14.25L9.75 14.25L9.75 12.75L12.75 12.75L12.75 9.75L14.25 9.75L14.25 14.25ZM8.25 5.25L5.25 5.25L5.25 8.25L3.75 8.25L3.75 3.75L8.25 3.75L8.25 5.25Z"
          fill="#131313"
          fillOpacity="0.81"
        />
      </g>
    </g>
  </svg>
);

const ShrinkIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="close_fullscreen">
      <mask
        id="mask0_819_51317"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect id="Bounding box" y="18" width="18" height="18" transform="rotate(-90 0 18)" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_819_51317)">
        <path
          id="close_fullscreen_2"
          d="M9.0001 8.2499C9.0001 8.4624 8.92822 8.64053 8.78447 8.78428C8.64072 8.92803 8.4626 8.9999 8.2501 8.9999L3.7501 8.9999C3.5376 8.9999 3.35947 8.92803 3.21572 8.78428C3.07197 8.64053 3.0001 8.4624 3.0001 8.2499C3.0001 8.0374 3.07197 7.85928 3.21572 7.71553C3.35947 7.57178 3.5376 7.4999 3.7501 7.4999L6.4501 7.4999L2.0251 3.0749C1.8876 2.9374 1.81885 2.7624 1.81885 2.5499C1.81885 2.3374 1.8876 2.1624 2.0251 2.0249C2.1626 1.8874 2.3376 1.81865 2.5501 1.81865C2.7626 1.81865 2.9376 1.8874 3.0751 2.0249L7.5001 6.4499L7.5001 3.7499C7.5001 3.5374 7.57197 3.35928 7.71572 3.21553C7.85947 3.07178 8.0376 2.9999 8.2501 2.9999C8.4626 2.9999 8.64072 3.07178 8.78447 3.21553C8.92822 3.35928 9.0001 3.5374 9.0001 3.7499L9.0001 8.2499ZM15.9751 15.9749C15.8376 16.1124 15.6626 16.1812 15.4501 16.1812C15.2376 16.1812 15.0626 16.1124 14.9251 15.9749L10.5001 11.5499L10.5001 14.2499C10.5001 14.4624 10.4282 14.6405 10.2845 14.7843C10.1407 14.928 9.9626 14.9999 9.7501 14.9999C9.5376 14.9999 9.35947 14.928 9.21572 14.7843C9.07197 14.6405 9.0001 14.4624 9.0001 14.2499L9.0001 9.7499C9.0001 9.5374 9.07197 9.35928 9.21572 9.21553C9.35947 9.07178 9.5376 8.9999 9.7501 8.9999L14.2501 8.9999C14.4626 8.9999 14.6407 9.07178 14.7845 9.21553C14.9282 9.35928 15.0001 9.5374 15.0001 9.7499C15.0001 9.9624 14.9282 10.1405 14.7845 10.2843C14.6407 10.428 14.4626 10.4999 14.2501 10.4999L11.5501 10.4999L15.9751 14.9249C16.1126 15.0624 16.1813 15.2374 16.1813 15.4499C16.1813 15.6624 16.1126 15.8374 15.9751 15.9749Z"
          fill="#131313"
          fillOpacity="0.81"
        />
      </g>
    </g>
  </svg>
);

const CloseIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="close">
      <mask
        id="mask0_819_50988"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect id="Bounding box" width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_819_50988)">
        <path
          id="close_2"
          d="M4.8 14.25L3.75 13.2L7.95 9L3.75 4.8L4.8 3.75L9 7.95L13.2 3.75L14.25 4.8L10.05 9L14.25 13.2L13.2 14.25L9 10.05L4.8 14.25Z"
          fill="#131313"
          fillOpacity="0.81"
        />
      </g>
    </g>
  </svg>
);

const SButton = styled('button', {
  width: '1.125rem',
  height: '1.125rem',
  cursor: 'pointer',

  borderRadius: '0.4rem',
  '&:active': {
    transform: 'scale(.98)',
  },
  '@hover': {
    '&:hover': {
      transform: 'scale(.96)',
    },
  },
});

const Wrapper = styled(Flex, {
  position: 'absolute',
  zIndex: 10000,
  transform: 'none',
  left: '12rem',
  top: '3.8rem',
  height: 'calc(50% - 9.8rem)',
  maxWidth: 'calc(100% - 25rem)',
  backgroundColor: '$raven',
  aspectRatio: '16 / 9',
  transition: 'height 0.3s',
});

const FeatStreamActions = styled(Flex, {
  position: 'absolute',
  width: '1.725rem',
  height: '5.25rem',
  padding: '0.25rem',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  gap: '0.3rem',
  borderRadius: '1rem',
  background: '$white',
  top: '0rem',
  right: '-2.5rem',
  alignItems: 'center',
  border: '1px solid $gs500Dark',
});

export function EventFeatStream() {
  const featStream = useEventStore((state) => state.featStream);
  const setFeatStream = useEventStore((state) => state.setFeatStream);
  const fullScreenControler = useEventStore((state) => state.fullScreenControler);

  const [isStreamExpanded, setIsStreamExpanded] = useState<boolean>(false);

  const openFullScreen = () => {
    fullScreenControler?.toggleFullscreen();
    setIsStreamExpanded(true);
  };

  return (
    <Flex css={{ display: featStream ? 'flex' : 'none' }}>
      <Wrapper css={{ height: isStreamExpanded ? 'calc(98% - 9.8rem)' : 'calc(60% - 9.8rem)' }}>
        {featStream && <EventVideo featStream={featStream} isStreamExpanded={isStreamExpanded} />}

        <FeatStreamActions>
          <SButton type="button" onClick={() => setFeatStream(null)}>
            <CloseIcon />
          </SButton>
          <SButton type="button" onClick={() => setIsStreamExpanded(!isStreamExpanded)}>
            {isStreamExpanded ? <ShrinkIcon /> : <ExpandIcon />}
          </SButton>
          <SButton type="button" onClick={openFullScreen}>
            {/* {fullScreenControler?.isFullScreen ? <ShrinkIcon /> : <ExpandIcon />} */}
            {fullScreenControler?.isFullScreen ? (
              <FullScreenExitIcon color="#131313" />
            ) : (
              <FullScreenIcon color="#131313" />
            )}
          </SButton>
        </FeatStreamActions>
      </Wrapper>
    </Flex>
  );
}
