import { stringToColour } from '../../utils/utils';
import { Avatar, AvatarImage, AvatarFallback } from '../primitives/avatar-image/avatar-image';

interface IUser {
  username: string;
  color?: string;
  useravatar: string;
}

interface IProps {
  user: IUser;
  isSelected?: boolean;
  css?: any;
}

export default function AvatarUser({ user, css = {}, isSelected = false }: IProps) {
  const sizeNormal = '2rem';
  const sizeSelected = '2.5rem';
  return (
    <Avatar
      css={{
        transition: 'all 0.5s',
        border: `3px solid ${stringToColour(user.username || 'pulse')}`,
        width: isSelected ? sizeSelected : sizeNormal,
        height: isSelected ? sizeSelected : sizeNormal,
        ...css,
      }}
      className="avatar-user"
    >
      <AvatarImage src={user.useravatar} alt={user.username} />
      <AvatarFallback delayMs={600}>{user?.username?.substring(0, 2).toUpperCase()}</AvatarFallback>
    </Avatar>
  );
}
