export type PointData = {
  timestamp: number;
  lat: number;
  long: number;
  altitude: number | null;
  speed: number | null;
  gps_precision: number;
  id: string;
  playback_url: string;
  vod_playback_url: string;
  logo_url: string;
  title: string;
  username: string;
  avatarPicture: string;
  duration_in_seconds: number;
  stream_tile_url: string;
  offset_ms: number;
  stream_start_date_ms: number;
  stream_end_date_ms: number | null;
  producer: boolean;
  speedType?: string;
  sponsorLogo?: string;
};

export function closestTimestampIndex(polyline: PointData[], targetTime: number): number | null {
  const MAX_TIME_DIFFERENCE = 60000; // 1 minute in miliseconds
  const INITIAL_INDEX = 0;
  const LENGTH_ADJUSTMENT = 1;

  if (!polyline.length) return null;

  let left = INITIAL_INDEX;
  let right = polyline.length - LENGTH_ADJUSTMENT;

  while (left < right) {
    const middle = Math.floor((left + right) / 2);
    const { timestamp: middleTime } = polyline[middle];

    if (middleTime === targetTime) {
      return middle;
    }
    if (middleTime < targetTime) {
      left = middle + LENGTH_ADJUSTMENT;
    } else {
      right = middle - LENGTH_ADJUSTMENT;
    }
  }

  const leftTimeDiff = Math.abs(polyline[left].timestamp - targetTime);
  const rightTimeDiff =
    left - LENGTH_ADJUSTMENT >= INITIAL_INDEX
      ? Math.abs(polyline[left - LENGTH_ADJUSTMENT].timestamp - targetTime)
      : Infinity;

  const closestIndex = leftTimeDiff <= rightTimeDiff ? left : left - LENGTH_ADJUSTMENT;
  const closestTimeDiff = Math.min(leftTimeDiff, rightTimeDiff);

  return closestTimeDiff <= MAX_TIME_DIFFERENCE ? closestIndex : null;
}
