export default function MuteIcon() {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7407 11.5747L13.75 2.25098C13.75 1.56445 13.2305 1.00781 12.5347 1.00781C12.0615 1.00781 11.7275 1.22119 11.208 1.68506L7.3208 5.14551L8.49902 6.31445L11.7183 3.39209C11.7646 3.35498 11.811 3.31787 11.876 3.31787C11.9409 3.31787 11.9966 3.37354 11.9966 3.45703V9.82129L13.7407 11.5747ZM16.8301 18.5049C17.1177 18.7925 17.5908 18.7925 17.8691 18.5049C18.1567 18.2173 18.166 17.7534 17.8691 17.4658L1.65234 1.24902C1.36475 0.961426 0.882324 0.961426 0.594727 1.24902C0.316406 1.52734 0.316406 2.00977 0.594727 2.29736L16.8301 18.5049ZM4.29639 13.2539H6.93115C7.00537 13.2539 7.07959 13.2817 7.13525 13.3281L11.2173 16.9741C11.6997 17.4102 12.0708 17.605 12.5532 17.605C12.9893 17.605 13.3511 17.4009 13.5645 17.0298L12.0522 15.5269L7.96094 11.8345C7.77539 11.6582 7.63623 11.6211 7.38574 11.6211H4.4541C4.13867 11.6211 3.99951 11.4727 3.99951 11.1572V7.45557L2.64502 6.11035C2.38525 6.44434 2.24609 6.91748 2.24609 7.53906V11.1016C2.24609 12.5396 2.94189 13.2539 4.29639 13.2539Z"
        fill="white"
      />
    </svg>
  );
}
