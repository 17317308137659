import Flex from '../../../../components/primitives/flex';
import { styled } from '../../../../stitches.config';
import useEventStore, { changeEventPlaybackSpeed, toggleEventPlayStatus } from '../../state-manager/store';
import { EventPlayStatus } from '../../state-manager/store-types';
import { EventSpeedControl } from './event-speed-control';

const Button = styled('button', {
  height: '1.5rem',
  width: '1.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all .5s ease',

  '&:active': {
    transform: 'scale(.98)',
  },
  '@hover': {
    '&:hover': {
      transform: 'scale(.96)',
    },
  },
});

const PauseIcon = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 14C9.45 14 8.97917 13.8042 8.5875 13.4125C8.19583 13.0208 8 12.55 8 12V2C8 1.45 8.19583 0.979167 8.5875 0.5875C8.97917 0.195833 9.45 0 10 0C10.55 0 11.0208 0.195833 11.4125 0.5875C11.8042 0.979167 12 1.45 12 2V12C12 12.55 11.8042 13.0208 11.4125 13.4125C11.0208 13.8042 10.55 14 10 14ZM2 14C1.45 14 0.979167 13.8042 0.5875 13.4125C0.195833 13.0208 0 12.55 0 12V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0C2.55 0 3.02083 0.195833 3.4125 0.5875C3.80417 0.979167 4 1.45 4 2V12C4 12.55 3.80417 13.0208 3.4125 13.4125C3.02083 13.8042 2.55 14 2 14Z"
      fill="#403F40"
    />
  </svg>
);

const PlayIcon = () => (
  <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.525 13.0252C1.19167 13.2418 0.854167 13.2543 0.5125 13.0627C0.170833 12.871 0 12.5752 0 12.1752V1.82518C0 1.42518 0.170833 1.12935 0.5125 0.937683C0.854167 0.746017 1.19167 0.758517 1.525 0.975183L9.675 6.15018C9.975 6.35018 10.125 6.63352 10.125 7.00018C10.125 7.36685 9.975 7.65018 9.675 7.85018L1.525 13.0252Z"
      fill="#403F40"
    />
  </svg>
);

const Divider = () => (
  <svg width="1" height="18" viewBox="0 0 1 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line opacity="0.3" x1="0.5" y1="0.5" x2="0.499999" y2="17.5" stroke="#403F40" />
  </svg>
);

export function EventPlayControl() {
  const playStatus = useEventStore((state) => state.playStatus);
  const eventPlaybackSpeed = useEventStore((state) => state.eventPlaybackSpeed);

  return (
    <Flex
      className="event-play-control-wrapper"
      css={{
        backgroundColor: '#D5D4D8',
        border: '1px solid #403F40',
        borderRadius: '999px',
        height: '2rem',
        width: '5.5rem',
        padding: '0px $2xs',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Button onClick={toggleEventPlayStatus}>
        {playStatus === EventPlayStatus.PLAYING ? <PauseIcon /> : <PlayIcon />}
      </Button>
      <Divider />
      <EventSpeedControl onValueChange={changeEventPlaybackSpeed} value={eventPlaybackSpeed} />
    </Flex>
  );
}
