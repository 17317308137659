import Flex from '../primitives/flex';
import { useIsMobile } from '../../utils/useIsMobile';

interface IProps {
  url: string;
}

export default function SponsorOverlayLogo({ url }: IProps) {
  const isMobile = useIsMobile();

  return (
    <Flex
      css={{
        height: isMobile ? '3rem' : '6rem',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
        padding: '$nano',
        '& img': {
          height: '100%',
          objectFit: 'scale-down',
        },
      }}
    >
      <img src={url} alt="sponsor logo" />
    </Flex>
  );
}
