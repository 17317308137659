export default function TimelineIcon() {
  return (
    <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.50016 10C2.04183 10 1.64961 9.83694 1.3235 9.51083C0.996829 9.18417 0.833496 8.79167 0.833496 8.33333C0.833496 7.875 0.996829 7.4825 1.3235 7.15583C1.64961 6.82972 2.04183 6.66667 2.50016 6.66667H2.71933C2.78155 6.66667 2.84738 6.68056 2.91683 6.70833L6.7085 2.91667C6.68072 2.84722 6.66683 2.78111 6.66683 2.71833V2.5C6.66683 2.04167 6.83016 1.64917 7.15683 1.3225C7.48294 0.996389 7.87516 0.833333 8.3335 0.833333C8.79183 0.833333 9.18433 0.996389 9.51099 1.3225C9.83711 1.64917 10.0002 2.04167 10.0002 2.5C10.0002 2.52778 9.98627 2.66667 9.9585 2.91667L12.0835 5.04167C12.1529 5.01389 12.2191 5 12.2818 5H12.7185C12.7813 5 12.8474 5.01389 12.9168 5.04167L15.8752 2.08333C15.8474 2.01389 15.8335 1.94778 15.8335 1.885V1.66667C15.8335 1.20833 15.9966 0.816111 16.3227 0.49C16.6493 0.163333 17.0418 0 17.5002 0C17.9585 0 18.351 0.163333 18.6777 0.49C19.0038 0.816111 19.1668 1.20833 19.1668 1.66667C19.1668 2.125 19.0038 2.51722 18.6777 2.84333C18.351 3.17 17.9585 3.33333 17.5002 3.33333H17.2818C17.2191 3.33333 17.1529 3.31944 17.0835 3.29167L14.1252 6.25C14.1529 6.31944 14.1668 6.38556 14.1668 6.44833V6.66667C14.1668 7.125 14.0038 7.51722 13.6777 7.84333C13.351 8.17 12.9585 8.33333 12.5002 8.33333C12.0418 8.33333 11.6496 8.17 11.3235 7.84333C10.9968 7.51722 10.8335 7.125 10.8335 6.66667V6.44833C10.8335 6.38556 10.8474 6.31944 10.8752 6.25L8.75016 4.125C8.68072 4.15278 8.61461 4.16667 8.55183 4.16667H8.3335C8.30572 4.16667 8.16683 4.15278 7.91683 4.125L4.12516 7.91667C4.15294 7.98611 4.16683 8.05222 4.16683 8.115V8.33333C4.16683 8.79167 4.0035 9.18417 3.67683 9.51083C3.35072 9.83694 2.9585 10 2.50016 10Z"
        fill="white"
      />
    </svg>
  );
}
