interface IProps {
  color?: string;
}

export function DiscoverIcon({ color = 'white' }: IProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_252_1082"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill={color} />
      </mask>
      <g mask="url(#mask0_252_1082)">
        <path
          d="M12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C14.4333 2 16.5623 2.76233 18.387 4.287C20.2123 5.81233 21.35 7.725 21.8 10.025H19.75C19.4333 8.80833 18.8627 7.72067 18.038 6.762C17.2127 5.804 16.2 5.08333 15 4.6V5C15 5.55 14.8043 6.02067 14.413 6.412C14.021 6.804 13.55 7 13 7H11V9C11 9.28333 10.9043 9.52067 10.713 9.712C10.521 9.904 10.2833 10 10 10H8V12H10V15H9L4.2 10.2C4.15 10.5 4.104 10.8 4.062 11.1C4.02067 11.4 4 11.7 4 12C4 14.1833 4.76667 16.0583 6.3 17.625C7.83333 19.1917 9.73333 19.9833 12 20V22ZM20.4 20.8L17.9 18.3C17.55 18.5 17.175 18.6667 16.775 18.8C16.375 18.9333 15.95 19 15.5 19C14.25 19 13.1873 18.5627 12.312 17.688C11.4373 16.8127 11 15.75 11 14.5C11 13.25 11.4373 12.1873 12.312 11.312C13.1873 10.4373 14.25 10 15.5 10C16.75 10 17.8127 10.4373 18.688 11.312C19.5627 12.1873 20 13.25 20 14.5C20 14.95 19.9333 15.375 19.8 15.775C19.6667 16.175 19.5 16.55 19.3 16.9L21.8 19.4C21.9833 19.5833 22.075 19.8167 22.075 20.1C22.075 20.3833 21.9833 20.6167 21.8 20.8C21.6167 20.9833 21.3833 21.075 21.1 21.075C20.8167 21.075 20.5833 20.9833 20.4 20.8ZM15.5 17C16.2 17 16.7917 16.7583 17.275 16.275C17.7583 15.7917 18 15.2 18 14.5C18 13.8 17.7583 13.2083 17.275 12.725C16.7917 12.2417 16.2 12 15.5 12C14.8 12 14.2083 12.2417 13.725 12.725C13.2417 13.2083 13 13.8 13 14.5C13 15.2 13.2417 15.7917 13.725 16.275C14.2083 16.7583 14.8 17 15.5 17Z"
          fill={color}
          // fillOpacity="0.6"
        />
      </g>
    </svg>
  );
}
