/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AnalyticsLogging } from '../../data/protocols/event-tracking/event-tracking';
import { ProfileModel } from '../../domain/models/profile-model';
import { initiaLizeSegment } from './segment-config-file';

export class AnalyticsLoggingClient implements AnalyticsLogging {
  initialize(platformId: string) {
    initiaLizeSegment(platformId);
  }

  track(eventName: string, eventDetails?: Record<string, unknown>) {
    // @ts-ignore
    if (window?.analytics) {
      let eventDetailsWithProduct = eventDetails;

      // add pulse_product set to web to every tracked event
      if (eventDetailsWithProduct == null) {
        eventDetailsWithProduct = { pulse_product: 'web' };
      } else {
        eventDetailsWithProduct.pulse_product = 'web';
      }

      // @ts-ignore
      window?.analytics.track(eventName, eventDetailsWithProduct);
    }
  }

  identify(profile: ProfileModel) {
    // @ts-ignore
    if (window?.analytics) {
      // @ts-ignore
      window?.analytics.identify(profile.id, {
        username: profile.username,
        displayName: profile.display_name,
        name: profile.display_name, // for mixpanel
        createdAt: profile.created_at,
        avatar: profile.profile_photo.original,
        $avatar: profile.profile_photo.original, // for mixpanel
        chatUser: profile.config.chat_user,
        email: profile.email ?? '',
      });
    }
  }
}
