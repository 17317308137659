import { useContext } from 'react';
import ExpandContentIcon from '../../../../components/icons/expand_content';
import ForumIcon from '../../../../components/icons/forum';
import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';
import PulseChat from '../../../../components/third-party/chat/chat';
import { ISquadVideoContext, SquadVideoContext } from '../../context';

type IProps = {
  isInputVisible?: boolean;
};

export default function MobileChatEvent({ isInputVisible = true }: IProps) {
  const { isChatVisible } = useContext<ISquadVideoContext>(SquadVideoContext);

  return (
    <Flex
      css={{
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: '$bgAppScreen200',
        borderRadius: '$2lg',
        transform: 'translateZ(0)',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Flex
        className="chat-title"
        css={{
          gap: '$2xs',
          alignItems: 'center',
          paddingLeft: '$xs',
          paddingRight: '$xs',
          borderBottom: '1px solid $gs500',
          backgroundColor: '$bgAppScreen200',
          minHeight: '3rem',
          justifyContent: 'space-between',
          zIndex: 100,
        }}
      >
        <Flex
          css={{
            gap: '$2xs',
          }}
        >
          <ForumIcon />
          <Typo type="bodyBold" css={{ color: '$constantWhite' }}>
            Live Chat
          </Typo>
        </Flex>

        <ExpandContentIcon />
      </Flex>

      {isChatVisible && <PulseChat isInputVisible={isInputVisible} />}
    </Flex>
  );
}
