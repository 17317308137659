import flatten from 'lodash/flatten';
import useEventStore from '../state-manager/store';
import { ListOfPolylines } from '../state-manager/store-types';
import { getFlattenStreams } from '../utils/getFlattenStreams';
import { hasLiveStreamOnEvent, getAllPulseLinesFromAllStreams } from '../utils/utils';
import { convertOldStreamModelToNewStreamModel } from '../utils/convertOldStreamModelToNewStreamModel';
import consumer from '../web-socket/web-socket';

function findUserPulseLinesIndex(username: string): number {
  const { pulseLinesGroupedByUserAndSortedByTimestamp } = useEventStore.getState();
  if (!pulseLinesGroupedByUserAndSortedByTimestamp.length) return -1;
  return pulseLinesGroupedByUserAndSortedByTimestamp.findIndex(
    (pulseLineGroupedByUser) => pulseLineGroupedByUser[0].username === username,
  );
}

function findStreamPulseLinesIndex(streamId: string): number {
  const { pulseLinesGroupedByStream } = useEventStore.getState();
  if (!pulseLinesGroupedByStream.length) return -1;
  return pulseLinesGroupedByStream.findIndex((pulseLineGroupedByStream) => pulseLineGroupedByStream[0].id === streamId);
}

export function useWsChannelUpdate() {
  const subscribeToEventTimelineUpdateChannel = (event_id: string) => {
    consumer.subscriptions.create(
      { channel: 'EventTimelineUpdateChannelV2', event_id },
      {
        initialized: () => {},
        connected() {},
        disconnected() {},
        received(timeline) {
          try {
            const {
              pulseLinesGroupedByStream,
              pulseLinesGroupedByUserAndSortedByTimestamp,
              featStream,
              setFeatStream,
              setEventStreams,
              setIsEventLive,
              setPulseLinesGroupedByStream,
              setPulseLinesGroupedByUserAndSortedByTimestamp,
              eventStreamsLinearList,
              setEventStreamsLinearList,
            } = useEventStore.getState();
            const newEventStreamsLinearList = [...eventStreamsLinearList];
            const { flattenStreams, streamsHashMap } = getFlattenStreams(timeline);

            flattenStreams?.forEach((flatStream) => {
              const convertedFlatStream = convertOldStreamModelToNewStreamModel(flatStream);
              console.log({ convertedFlatStream });
              const existingStreamIndex = newEventStreamsLinearList.findIndex(
                (stream) => stream.id === convertedFlatStream.id,
              );
              if (existingStreamIndex !== -1) {
                newEventStreamsLinearList[existingStreamIndex] = convertedFlatStream;

                if (newEventStreamsLinearList[existingStreamIndex].id === featStream?.id) {
                  setFeatStream(convertedFlatStream);
                }
              } else {
                newEventStreamsLinearList.push(convertedFlatStream);
              }
            });

            const newPulseLinesGroupedByStream: ListOfPolylines = getAllPulseLinesFromAllStreams(flattenStreams);

            const flattenPulseLines = flatten(newPulseLinesGroupedByStream);

            flattenPulseLines?.forEach((pulseline) => {
              const indexFromPulseLinesGroupedByStream = findStreamPulseLinesIndex(pulseline.id);
              const indexFromPulseLinesGroupedByUser = findUserPulseLinesIndex(pulseline.username);
              if (indexFromPulseLinesGroupedByStream !== -1) {
                pulseLinesGroupedByStream[indexFromPulseLinesGroupedByStream].push(pulseline);
              } else {
                pulseLinesGroupedByStream.push([pulseline]);
              }

              if (indexFromPulseLinesGroupedByUser !== -1) {
                pulseLinesGroupedByUserAndSortedByTimestamp[indexFromPulseLinesGroupedByUser].push(pulseline);
              } else {
                pulseLinesGroupedByUserAndSortedByTimestamp.push([pulseline]);
              }
            });
            const eventIsLive = hasLiveStreamOnEvent(newEventStreamsLinearList);
            setIsEventLive(eventIsLive);
            setEventStreams(streamsHashMap);

            setPulseLinesGroupedByStream([...pulseLinesGroupedByStream]);
            setPulseLinesGroupedByUserAndSortedByTimestamp([...pulseLinesGroupedByUserAndSortedByTimestamp]);
            setEventStreamsLinearList([...newEventStreamsLinearList]);
          } catch (e) {
            console.log({ e });
          }
        },
      },
    );
  };

  return subscribeToEventTimelineUpdateChannel;
}
