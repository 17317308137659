import { useMediaQuery } from 'usehooks-ts';
import useGlobalStore from '../../../../app-global-store';
import { LoggedAvatar } from '../../../../components/logged-avatar/logged-avatar';
import Box from '../../../../components/primitives/box';
import Button from '../../../../components/primitives/button';
import Flex from '../../../../components/primitives/flex';
import useAuthStore from '../../../auth-wrapper/state-manager/store';
import { PulseLogoHorizontalDark } from '../../../../components/icons/pulse-logo-horizontal-dark';

const RecordVideoIcon = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 12C8.28333 12 8.52083 11.9042 8.7125 11.7125C8.90417 11.5208 9 11.2833 9 11V9H11C11.2833 9 11.5208 8.90417 11.7125 8.7125C11.9042 8.52083 12 8.28333 12 8C12 7.71667 11.9042 7.47917 11.7125 7.2875C11.5208 7.09583 11.2833 7 11 7H9V5C9 4.71667 8.90417 4.47917 8.7125 4.2875C8.52083 4.09583 8.28333 4 8 4C7.71667 4 7.47917 4.09583 7.2875 4.2875C7.09583 4.47917 7 4.71667 7 5V7H5C4.71667 7 4.47917 7.09583 4.2875 7.2875C4.09583 7.47917 4 7.71667 4 8C4 8.28333 4.09583 8.52083 4.2875 8.7125C4.47917 8.90417 4.71667 9 5 9H7V11C7 11.2833 7.09583 11.5208 7.2875 11.7125C7.47917 11.9042 7.71667 12 8 12ZM2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H14C14.55 0 15.0208 0.195833 15.4125 0.5875C15.8042 0.979167 16 1.45 16 2V6.5L19.15 3.35C19.3 3.2 19.4792 3.1625 19.6875 3.2375C19.8958 3.3125 20 3.46667 20 3.7V12.3C20 12.5333 19.8958 12.6875 19.6875 12.7625C19.4792 12.8375 19.3 12.8 19.15 12.65L16 9.5V14C16 14.55 15.8042 15.0208 15.4125 15.4125C15.0208 15.8042 14.55 16 14 16H2ZM2 14H14V2H2V14Z"
      fill="#131313"
      fillOpacity="0.62"
    />
  </svg>
);

export default function EventHeader() {
  const analytics = useGlobalStore((state) => state.analytics);
  useAuthStore((state) => state.authToggleState);
  const isLogged = useAuthStore((state) => state.isLogged);
  const openAuthModalIfItsNotLogged = useAuthStore((state) => state.openAuthModalIfItsNotLogged);
  const isUserLogged = isLogged();
  const isMobile = useMediaQuery('(max-width: 900px)');

  return (
    <Flex
      className="top-header"
      css={{
        width: '100%',
        height: '4rem',
        backgroundColor: '$bgAppScreen100',
        paddingLeft: '$sm',
        paddingRight: '$sm',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .mobile': {
          display: 'none',
        },
        '@landscape': {
          display: 'none',
        },
        '@bp3': {
          backgroundColor: '$bgAppScreen100',
          paddingLeft: '$sm',
          paddingRight: '$3xs',
          height: '3rem',
          '& .mobile': {
            display: 'flex',
          },
          '& .desktop': {
            display: 'none',
          },
        },
      }}
    >
      <Box
        as="a"
        href="https://pulse.tv/"
        onClick={() => {
          analytics?.track(`clicked_pulse_logo`);
        }}
        target="_blank"
        css={{
          transition: 'all 0.5s',
          marginLeft: '0.625rem',
          '&:hover': {
            transform: 'scale(1.1)',
          },
          '&:active': {
            transform: 'scale(1.2)',
          },
          img: {
            height: '1.9rem',
          },
        }}
      >
        <PulseLogoHorizontalDark />
      </Box>

      <Flex className="header-right-side" css={{ alignItems: 'center' }}>
        <Button
          as="a"
          variant="transparent"
          css={{ alignItems: 'center', gap: '$3xs', textDecoration: 'none', span: { color: '$charcoal' } }}
          target="_blank"
          href="https://pulse.tv/university/the-best-way-to-stream-from-your-gopro"
          onClick={() => {
            analytics?.track(`click_get_the_app_button`);
          }}
        >
          <RecordVideoIcon />
        </Button>
        <Button
          as="a"
          variant="transparent"
          css={{ alignItems: 'center', gap: '$3xs', textDecoration: 'none', span: { color: '$gs300' } }}
          target="_blank"
          href="https://apps.apple.com/us/app/pulse-share-what-you-see/id1576189738"
          onClick={() => {
            analytics?.track(`click_get_the_app_button`);
          }}
        >
          <span className="desktop">Download</span>
          <span className="mobile">Download</span>
        </Button>
        {/* {showShareButton && <ButtonSocialInvite />} */}

        {!isUserLogged && !isMobile && (
          <>
            <Button
              onClick={openAuthModalIfItsNotLogged}
              variant="transparent"
              css={{ alignItems: 'center', gap: '$3xs', textDecoration: 'none', span: { color: '$charcoal' } }}
            >
              <span className="desktop">Sign up</span>
            </Button>
            <Button
              onClick={openAuthModalIfItsNotLogged}
              variant="transparent"
              css={{ alignItems: 'center', gap: '$3xs', textDecoration: 'none', span: { color: '$charcoal' } }}
            >
              <span className="desktop">Login</span>
            </Button>
          </>
        )}
        {!!isUserLogged && <LoggedAvatar />}
        {!isUserLogged && isMobile && (
          <Button
            onClick={openAuthModalIfItsNotLogged}
            variant="transparent"
            css={{ alignItems: 'center', gap: '$3xs', textDecoration: 'none', span: { color: '$charcoal' } }}
          >
            <span>Login</span>
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
