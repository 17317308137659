/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import * as Select from '@radix-ui/react-select';
import { mauve, blackA } from '@radix-ui/colors';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { styled, darkTheme } from '../../../../stitches.config';
import { Typo } from '../../../../components/primitives/typo/typo';
import { Flex } from '../../../../components/primitives/flex/flex';
import { Box } from '../../../../components/primitives/box/box';

const SelectTrigger = styled(Select.SelectTrigger, {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$sm',
  padding: '0 15px',
  fontSize: '$bodySm',
  lineHeight: 1,
  height: 35,
  gap: 5,
  backgroundColor: 'transparent',
  color: '$gs200',
  fontWeight: '$medium',
  boxShadow: `0 2px 10px ${blackA.blackA7}`,
  '&:hover': { backgroundColor: '$gs500' },
  '&:focus': { boxShadow: `0 0 0 2px $gs500` },
  '&[data-placeholder]': { color: '$gs200' },
});

const SelectIcon = styled(Select.SelectIcon, {
  color: '$gs100',
});

const SelectContent = styled(Select.Content, {
  overflow: 'hidden',
  backgroundColor: '$bgAppScreen200',
  borderRadius: 6,
  boxShadow: '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const SelectViewport = styled(Select.Viewport, {
  backgroundColor: '$bgAppScreen200',
});

const StyledItem = styled(Select.Item, {
  fontSize: '$body',
  lineHeight: 1,
  color: '$gs100',
  borderRadius: 3,
  display: 'flex',
  alignItems: 'center',
  height: '1.93rem',
  padding: '0 35px 0 25px',
  position: 'relative',
  userSelect: 'none',

  '&[data-disabled]': {
    color: mauve.mauve8,
    pointerEvents: 'none',
  },

  '&[data-highlighted]': {
    outline: 'none',
    backgroundColor: '$gs500',
  },
});

const StyledItemIndicator = styled(Select.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: 'white',
  color: '$gs500',
  cursor: 'default',
};

const SelectScrollUpButton = styled(Select.ScrollUpButton, scrollButtonStyles);

const SelectScrollDownButton = styled(Select.ScrollDownButton, scrollButtonStyles);

const SelectItem = React.forwardRef(({ children, ...props }, forwardedRef) => {
  return (
    <StyledItem {...props} ref={forwardedRef}>
      <Select.ItemText>{children}</Select.ItemText>
      <StyledItemIndicator>
        <Box
          css={{ height: '0.125rem', width: '0.625rem', borderRadius: '50%', backgroundColor: '$primaryAdrenaline100' }}
        />
      </StyledItemIndicator>
    </StyledItem>
  );
});

interface IProps {
  dateRange: string[];
  value: string | null;
  onValueChange: (event: string) => void;
}

export const DateSelect = ({ dateRange, value, onValueChange }: IProps) => (
  <Select.Root value={value} onValueChange={onValueChange}>
    <SelectTrigger aria-label="Date" className={darkTheme}>
      <Select.Value placeholder={value || 'Loading'} />
      <SelectIcon>
        <ChevronDownIcon />
      </SelectIcon>
    </SelectTrigger>

    <Select.Portal className={darkTheme}>
      <SelectContent className="select-content">
        <SelectScrollUpButton>
          <ChevronUpIcon />
        </SelectScrollUpButton>
        <SelectViewport>
          <Select.Group>
            <Flex
              css={{ height: '2.06rem', alignItems: 'center', paddingLeft: '$3xs', borderBottom: '1px solid $gs500' }}
            >
              <Typo type="bodySm" css={{ color: '$gs200' }}>
                Select date
              </Typo>
            </Flex>
            <Flex css={{ flexDirection: 'column', padding: '$3xs $nano' }}>
              {dateRange.map((date) => (
                <SelectItem key={date} value={date}>
                  {date}
                </SelectItem>
              ))}
            </Flex>
          </Select.Group>
        </SelectViewport>
        <SelectScrollDownButton>
          <ChevronDownIcon />
        </SelectScrollDownButton>
      </SelectContent>
    </Select.Portal>
  </Select.Root>
);

export default DateSelect;
