import { useMemo } from 'react';
import dayjs from 'dayjs';

import Flex from '../../../../components/primitives/flex';
import { DatePill } from './date-pill';
import useEventStore from '../../state-manager/store';

export function EventDateSelect() {
  const value = useEventStore((state) => state.currentDay);
  const eventInfo = useEventStore((state) => state.eventInfo);
  const setCurrentDay = useEventStore((state) => state.setCurrentDay);
  const setCurrentTime = useEventStore((state) => state.setCurrentTime);
  const isEventLive = useEventStore((state) => state.isEventLive);

  const formatDate = (date: string | Date) => dayjs(date).format('DD MMM');
  const formatedDates = useMemo(
    () => eventInfo?.timeline_days.map((date: string) => ({ value: date, label: formatDate(date) })),
    [eventInfo?.timeline_days],
  );

  const onValueChange = (day: string, isLive: boolean) => {
    setCurrentDay(day);
    if (isLive) {
      setCurrentTime(new Date());
    }
  };

  const isLive = (date: string) => date === formatDate(new Date()) && isEventLive;

  return (
    <Flex
      className="event-date-select-wrapper"
      css={{ position: 'absolute', top: '0.5rem', left: '0.6rem', gap: '$3xs', zIndex: 1000 }}
    >
      {formatedDates?.map((date) => (
        <DatePill
          label={date.label}
          key={date.value}
          date={isLive(date.label) ? 'LIVE' : ''}
          isSelected={value === date.value}
          onClick={() => onValueChange(date.value, isLive(date.label))}
          isLive={isLive(date.label)}
        />
      ))}

      {/* <DatePill
        label="Live"
        date={formatDate(new Date())}
        onClick={() => onValueChange(formatDate(new Date()))}
        isLive
      /> */}
    </Flex>
  );
}
