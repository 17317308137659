import Button from '../primitives/button';
import Flex from '../primitives/flex';

const LeftChevron = () => (
  <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.86664 7.53345L0.799971 4.46678C0.733305 4.40011 0.686194 4.32789 0.658638 4.25011C0.630638 4.17234 0.616638 4.089 0.616638 4.00011C0.616638 3.91123 0.630638 3.82789 0.658638 3.75011C0.686194 3.67234 0.733305 3.60011 0.799971 3.53345L3.86664 0.46678C3.98886 0.344558 4.14442 0.283447 4.3333 0.283447C4.52219 0.283447 4.67775 0.344558 4.79997 0.46678C4.92219 0.589003 4.9833 0.744558 4.9833 0.933447C4.9833 1.12234 4.92219 1.27789 4.79997 1.40011L2.19997 4.00011L4.79997 6.60011C4.92219 6.72234 4.9833 6.87789 4.9833 7.06678C4.9833 7.25567 4.92219 7.41122 4.79997 7.53345C4.67775 7.65567 4.52219 7.71678 4.3333 7.71678C4.14442 7.71678 3.98886 7.65567 3.86664 7.53345Z"
      fill="#403F40"
    />
  </svg>
);

const RightChevron = () => (
  <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.800032 7.53345C0.67781 7.41122 0.616699 7.25567 0.616699 7.06678C0.616699 6.87789 0.67781 6.72234 0.800032 6.60011L3.40003 4.00011L0.800032 1.40011C0.67781 1.27789 0.616699 1.12234 0.616699 0.933447C0.616699 0.744558 0.67781 0.589003 0.800032 0.46678C0.922255 0.344558 1.07781 0.283447 1.2667 0.283447C1.45559 0.283447 1.61114 0.344558 1.73337 0.46678L4.80003 3.53345C4.8667 3.60011 4.91403 3.67234 4.94203 3.75011C4.96959 3.82789 4.98337 3.91123 4.98337 4.00011C4.98337 4.089 4.96959 4.17234 4.94203 4.25011C4.91403 4.32789 4.8667 4.40011 4.80003 4.46678L1.73337 7.53345C1.61114 7.65567 1.45559 7.71678 1.2667 7.71678C1.07781 7.71678 0.922255 7.65567 0.800032 7.53345Z"
      fill="#403F40"
    />
  </svg>
);

const Divider = () => (
  <svg width="1" height="18" viewBox="0 0 1 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line opacity="0.3" x1="0.5" y1="0.5" x2="0.499999" y2="17.5" stroke="#403F40" />
  </svg>
);

interface IProps {
  onClickLeft: () => void;
  onClickRight: () => void;
}

export function MoveHorizontalControl({ onClickLeft, onClickRight }: IProps) {
  return (
    <Flex
      className="move-horizontal-control"
      css={{
        minWidth: '4.5rem',
        height: '2rem',
        backgroundColor: '#D5D4D8',
        borderRadius: '62.43rem',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Button
        size="sm"
        variant="transparentNoHover"
        onClick={onClickLeft}
        data-testid="left-chevron-button"
        aria-label="Move left"
      >
        <LeftChevron />
      </Button>
      <Divider />
      <Button
        size="sm"
        variant="transparentNoHover"
        onClick={onClickRight}
        data-testid="right-chevron-button"
        aria-label="Move right"
      >
        <RightChevron />
      </Button>
    </Flex>
  );
}
