import { useEffect, useState, useContext } from 'react';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, MessageInput, MessageList, Window } from 'stream-chat-react';

import 'stream-chat-react/dist/css/v2/index.css';
import { ProfileModel } from '../../../../domain/models/profile-model';
import { LocalStorageAdapter } from '../../../../infra/cache';
import { ISquadVideoContext, SquadVideoContext } from '../../../pages/event-v1/context';
import AuthDialog from '../../auth-dialog/auth-dialog';
import Flex from '../../primitives/flex';

type IProps = {
  isInputVisible?: boolean;
};

const localStorageInstance = new LocalStorageAdapter();

export default function PulseChat({ isInputVisible = true }: IProps) {
  const [chatClient] = useState(new StreamChat(import.meta.env.VITE_CHAT_ID));
  const { eventInfo } = useContext<ISquadVideoContext>(SquadVideoContext);
  const [channel, setChannel] = useState<any>(null);
  const [loggedUser, setLoggedUser] = useState<any>(null);

  const addChannel = async () => {
    const instance = await chatClient.channel('event', eventInfo?.getstream_channel_id);
    setChannel(instance);
  };

  const connectUser = () => {
    const profile = localStorageInstance.get('profile') as unknown as ProfileModel;
    setLoggedUser(profile);

    if (profile) {
      return chatClient.connectUser(
        { id: profile.channel.chat_user_id, name: profile.display_name, image: profile.profile_photo.small },
        profile.config.chat_token,
      );
    }

    return chatClient.connectAnonymousUser();
  };

  const disconnect = () => {
    chatClient.closeConnection();
    chatClient.disconnectUser();
  };

  const initializeChat = async () => {
    await connectUser();
    await addChannel();
  };

  useEffect(() => {
    initializeChat();
    return () => {
      disconnect();
    };
  }, []);

  return (
    <Flex
      className="chat-wrapper"
      css={{
        height: '100%',
        '& ::-webkit-scrollbar-track': {
          background: '$bgAppScreen200',
        },
        '& ::-webkit-scrollbar-thumb': {
          background: '$gs500',
          borderRadius: '$3lg',
        },

        '& .str-chat': { width: '100%' },
        '& .str-chat__list': {
          backgroundColor: '$bgAppScreen200',
          '&::-webkit-scrollbar': {
            backgroundColor: '$bgAppScreen200',
            width: '.5rem',
          },
        },
        '& .str-chat__main-panel': {
          backgroundColor: '$bgAppScreen200',
        },
        '& .str-chat__message-input': {
          backgroundColor: '$bgAppScreen200',
        },
        '& .str-chat__message-textarea': {
          overflowY: 'hidden !important',
          color: '$gs200 !important',
        },
        '& .str-chat__message-bubble': {
          backgroundColor: '$bgAppScreen100 !important',
          color: '$gs200 !important',
        },
        '& .str-chat__message-attachment-card--title': {
          color: '$gs200 !important',
        },
        '& .str-chat__message-input .str-chat__send-button svg path': {
          fill: '$primaryAdrenaline100',
        },
        '& .str-chat__message-input .str-chat__send-button:disabled svg path': {
          fill: '#b4b7bb',
        },
        '& .str-chat__empty-channel': {
          backgroundColor: '$bgAppScreen200 !important',
        },
        '& .str-chat__message-input-inner': {
          alignItems: 'center !important',
        },
        '& .str-chat__message-textarea-emoji-picker': {
          display: 'none !important',
        },
        '& .str-chat__message-inner': {
          width: '100%',
        },
        '& .str-chat__message': {
          position: 'relative',
          marginTop: '20px',
        },

        '& .str-chat__message-simple__actions': {
          position: 'absolute',
          right: '50px',
          bottom: -20,
        },

        '@bp3': {
          paddingBottom: isInputVisible ? '5rem' : '2rem',
          '& .str-chat__message-input': {
            visibility: isInputVisible ? 'visible' : 'hidden',
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 100,
          },
          '& .str-chat__file-input-container': {
            display: 'none !important',
          },
        },
      }}
    >
      {channel && (
        <Chat client={chatClient} theme="str-chat__theme-light">
          <Channel channel={channel}>
            <Window>
              <MessageList />
              {loggedUser && <MessageInput grow disableMentions />}
            </Window>
          </Channel>
        </Chat>
      )}

      {isInputVisible && <AuthDialog />}
    </Flex>
  );
}
