import { useState } from 'react';
import useGlobalStore from '../../../../app-global-store';
import Button from '../../../../components/primitives/button';
import Flex from '../../../../components/primitives/flex';
import { Typo } from '../../../../components/primitives/typo/typo';

export default function EventShare() {
  const [copied, setCopied] = useState<boolean>(false);
  const analytics = useGlobalStore((state) => state.analytics);

  const copyLinkToClipBoard = () => {
    analytics.track('Copied event link to clipboard');
    navigator?.clipboard?.writeText(window.location.href);
  };

  const copyUrlToClipBoard = () => {
    setCopied(true);
    copyLinkToClipBoard();
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Flex
      className="event-share"
      css={{
        backgroundColor: 'rgba(41, 42, 46, 0.5)',
        alignItems: 'center',
        padding: '0px $3xs',
        borderRadius: '$lg',
        overflow: 'hidden',
        gap: '$xs',
        width: '100%',
      }}
    >
      <Typo
        css={{
          color: 'rgba(255, 255, 255, 0.42)',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '90%',
        }}
      >
        {window.location.href}
      </Typo>
      <Button
        variant="realOutline"
        size="sm"
        onClick={copyUrlToClipBoard}
        css={{
          border: '2px solid $primaryAdrenaline100',
          color: '$primaryAdrenaline100',
          maxWidth: '5.43rem',
          height: '2rem',
        }}
      >
        {copied ? 'Copied' : 'Share'}
      </Button>
    </Flex>
  );
}
