import { useContext } from 'react';

import Heading from '../../../../components/heading/heading';
import Box from '../../../../components/primitives/box';
import Flex from '../../../../components/primitives/flex';
import { styled } from '../../../../stitches.config';
import VideoAndMapWrapper from '../video-map/video-map';
import StreamList from '../stream-list/stream-list';
import ChatEvent from '../chat-event/chat-event';
import TimelineEvent from '../timeline-event/timeline-event';
import { ISquadVideoContext, SquadVideoContext } from '../../context';

const GridWrapper = styled(Box, {
  display: 'grid',
  overflow: 'hidden',
  gap: '$3xs',
  height: '100%',
  paddingTop: '$3xs',
  paddingBottom: '$md',
  paddingLeft: '$sm',
  paddingRight: '$sm',
  backgroundColor: '$bgAppScreen100',
  borderRadius: '16px 16px 0px 0px',
  alignItems: 'end',
  justifyItems: 'end',
  '& .heading-wrapper': {
    gridArea: 'heading-wrapper',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  '& .video-and-map': {
    transition: 'width 0.2s ease-in-out',
    gridArea: 'video-and-map',
    height: '100%',
    width: '100%',
  },
  '& .chat-wrapper': {
    gridArea: 'chat-wrapper',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  '& .time-line': {
    transition: 'width 0.5s ease-in-out',
    gridArea: 'time-line',
    height: '100%',
    width: '100%',
  },
  '& .stream-list': {
    transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out',
    gridArea: 'stream-list',
    justifySelf: 'start',
    height: '100%',
    width: '100%',
  },

  gridTemplateColumns: '18rem 1fr 20rem',
  gridTemplateRows: '1fr 7rem 4.37rem 10rem',

  gridTemplateAreas: `
    "video-and-map video-and-map chat-wrapper"
    "video-and-map video-and-map chat-wrapper"
    "heading-wrapper heading-wrapper time-line"
    "stream-list stream-list time-line"
  `,

  '& .rounded-border': {
    borderRadius: '$2lg',
    backgroundColor: '$bgAppScreen200',
    overflow: 'hidden',
  },

  '@bp3': {
    gridTemplateColumns: '1fr',
    gridTemplateRows: '4.31rem 20rem 10rem 17rem 20rem',
    gap: '$xs',
    overflowY: 'auto',
    gridTemplateAreas: `
      "heading-wrapper"
      "video-and-map"
      "stream-list"
      "time-line"
      "chat-wrapper"
    `,
  },
});

export default function GridEvent() {
  const { eventInfo } = useContext<ISquadVideoContext>(SquadVideoContext);

  return (
    <GridWrapper className="grid-event-wrapper">
      <Flex className="video-and-map rounded-border">
        <VideoAndMapWrapper />
      </Flex>
      <Flex className="heading-wrapper rounded-border">
        <Heading
          name={eventInfo?.title || 'event not found'}
          link={eventInfo?.more_info_url}
          logoSrc={eventInfo?.photo?.original}
        />
      </Flex>
      <Flex className="chat-wrapper rounded-border">
        <ChatEvent />
      </Flex>
      <Flex className={`time-line rounded-border `}>
        <TimelineEvent />
      </Flex>
      <Flex className={`stream-list rounded-border `}>
        <StreamList />
      </Flex>
    </GridWrapper>
  );
}
