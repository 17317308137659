export default function TwitterShareIcon() {
  return (
    <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_601_10462)">
        <path
          d="M33.331 37.7667H5.00599C2.40009 37.7667 0.285156 35.6517 0.285156 33.0458V4.72083C0.285156 2.11493 2.40009 0 5.00599 0H33.331C35.9369 0 38.0518 2.11493 38.0518 4.72083V33.0458C38.0518 35.6517 35.9369 37.7667 33.331 37.7667Z"
          fill="#1D9BF0"
        />
        <path
          d="M14.7877 28.4761C23.6912 28.4761 28.5631 21.0927 28.5631 14.7007C28.5631 14.493 28.5631 14.2853 28.5536 14.0776C29.4978 13.3978 30.3192 12.5386 30.9707 11.5661C30.1021 11.9532 29.1673 12.2081 28.1854 12.3309C29.1862 11.736 29.951 10.7824 30.3192 9.64943C29.3845 10.2065 28.3459 10.603 27.2412 10.8202C26.3537 9.87603 25.098 9.29065 23.7101 9.29065C21.0381 9.29065 18.8665 11.4622 18.8665 14.1342C18.8665 14.5119 18.9137 14.8801 18.9892 15.2389C14.9671 15.0406 11.3981 13.1051 9.00939 10.1782C8.59395 10.8957 8.35791 11.7266 8.35791 12.6141C8.35791 14.2947 9.2171 15.7771 10.5106 16.6457C9.71751 16.6174 8.97162 16.4002 8.32014 16.0414C8.32014 16.0603 8.32014 16.0792 8.32014 16.1075C8.32014 18.4491 9.99132 20.4129 12.2007 20.8567C11.7947 20.97 11.3698 21.0266 10.926 21.0266C10.6145 21.0266 10.3123 20.9983 10.0196 20.9417C10.6334 22.8678 12.4273 24.2651 14.5422 24.3029C12.8805 25.6059 10.7939 26.3801 8.52786 26.3801C8.14075 26.3801 7.75364 26.3612 7.37598 26.314C9.50979 27.6736 12.059 28.4761 14.7877 28.4761Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_601_10462">
          <rect width="37.7667" height="37.7667" fill="white" transform="translate(0.285156)" />
        </clipPath>
      </defs>
    </svg>
  );
}
