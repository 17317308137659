import { useEffect, useRef } from 'react';
import { VideoJsPlayer } from 'video.js';
import { StreamModel } from '../../../../domain/models/stream-model';
import { scrollHorizontalParentElementToSelectedItem, stringToColour } from '../../../utils/utils';
import ArrowCircleRightIcon from '../../icons/arrow-circle-right';
import Box from '../../primitives/box';
import Flex from '../../primitives/flex';
import { Typo } from '../../primitives/typo/typo';
import { GpsMetaData } from '../../third-party/video-stream/gps-type';
import VideoStream from '../../third-party/video-stream/video-stream';

interface IProps {
  onSelectStream: () => void;
  onVideoPlayerReady: (playerRef: VideoJsPlayer) => void;
  onVideoError: () => void;
  onChangeGpsData: (data: GpsMetaData, player: VideoJsPlayer) => void;
  streamData: StreamModel | undefined;
  isSelected: boolean;
  showVideo?: boolean;
  isLastOnList: boolean;
  isVideoVisible: boolean;
  isFirstOnList: boolean;
  videoTimeOffset: number;
}

export default function DesktopStreamThumb({
  onSelectStream,
  onChangeGpsData,
  onVideoPlayerReady,
  onVideoError,
  streamData,
  isSelected,
  isLastOnList,
  isVideoVisible,
  isFirstOnList,
  videoTimeOffset,
  showVideo,
}: IProps) {
  const elementRef = useRef(null);

  const onIsSelectedChanges = () => {
    if (!isSelected || !elementRef?.current) return;
    setTimeout(() => scrollHorizontalParentElementToSelectedItem(elementRef?.current), 500);
  };
  useEffect(onIsSelectedChanges, [isSelected, elementRef]);

  if (!streamData) return <div>Stream not found</div>;

  return (
    <Flex
      ref={elementRef}
      title={streamData.title}
      data-stream-thumb-id={streamData.id}
      data-selected={isSelected}
      css={{
        marginLeft: isFirstOnList ? '$xs' : '0px',
        marginRight: isLastOnList ? '$xs' : '0px',
        minWidth: '16.56rem',
        maxWidth: '23.75rem',
        height: '5.12rem',
        gap: '$3xs',
        padding: '$nano',
        borderRadius: '$lg',
        transition: 'all 0.5s',
        cursor: 'pointer',
        overflow: 'hidden',
        background: isSelected ? 'rgba(255, 255, 255, 0.2)' : 'transparent',

        '@hover': {
          '&:hover': {
            transform: 'scale(.96)',
            background: 'rgba(255, 255, 255, 0.2)',

            '& .hover-video-mask': {
              opacity: 1,
            },
            '& .line-divider': {
              width: '90%',
            },
          },
          '&:active': {
            transform: 'scale(.92)',
          },
        },

        '& .hover-video-mask': {
          opacity: isSelected ? 1 : 0,
        },
      }}
      className="video-thumbnail-wrapper"
      onClick={onSelectStream}
    >
      <Flex
        className="video-wrapper"
        css={{
          minWidth: '8.18rem',
          maxWidth: '8.18rem',
          height: '4.62rem',
          overflow: 'hidden',
          borderRadius: '$sm',
          position: 'relative',
        }}
      >
        {isVideoVisible && showVideo && (
          <VideoStream
            url={streamData?.playback_url}
            onChangeGpsData={onChangeGpsData}
            timeOffset={videoTimeOffset}
            onVideoPlayerReady={onVideoPlayerReady}
            onVideoError={onVideoError}
          />
        )}
        {!showVideo && <img alt="showing stream thumbnail" src={streamData.thumbnail} />}
        <Flex
          className="hover-video-mask"
          css={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.6)',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 0,
            transition: 'all 0.5s',
            gap: '$3xs',
          }}
        >
          <Typo type="bodyBold" css={{ color: '$primaryAdrenaline100' }}>
            {isSelected ? 'Now playing' : 'Teleport'}
          </Typo>
          {!isSelected && <ArrowCircleRightIcon />}
        </Flex>
      </Flex>

      <Flex
        className="video-info"
        css={{ flexDirection: 'column', gap: '$nano', margin: 'auto 0px', width: '100%', overflow: 'hidden' }}
      >
        <Box
          className="line-divider"
          css={{
            height: '0.25rem',
            width: '1rem',
            borderRadius: '$sm',
            backgroundColor: stringToColour(streamData?.channel.username),
            transition: 'all 0.5s',
          }}
        />
        <Typo
          type="bodyBold"
          css={{
            fontSize: 14,
            color: '$constantWhite',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            // whiteSpace: 'nowrap',
            display: '-webkit-box',
            '-webkit-line-clamp': 3,
            lineHeight: '15px',
            '-webkit-box-orient': 'vertical',
          }}
        >
          {streamData.title}
        </Typo>
        <Flex css={{ gap: '$3xs' }}>
          <Typo type="bodySmallMedium" css={{ color: '$gs300' }}>
            {`@${streamData?.channel.username}`}
          </Typo>
        </Flex>
      </Flex>
    </Flex>
  );
}
