import { useEffect, useMemo, useState } from 'react';
import { AuthFireBaseUser } from '@/infra/firebase/model';
import dayjs from 'dayjs';
import { useMediaQuery } from 'usehooks-ts';
import { useParams, useSearchParams } from 'react-router-dom';
import { VideoJsPlayer } from 'video.js';

import { AnalyticsLogging } from '../../../data/protocols/event-tracking/event-tracking';
import { SocialAuth } from '../../../data/protocols/social-auth';
import { AddProfile, GetEventInfo } from '../../../domain/usecases';
import { LocalStorageAdapter } from '../../../infra/cache';
import TopHeader from '../../components/top-header/top-header';
import Flex from '../../components/primitives/flex';
import GridEvent from './components/grid-event/grid-event';
import { createUserName, isDateBelongsToDateInterval, removeTimeZoneFromTimeStamp } from '../../utils/utils';
import { ISquadVideoContext, RealTimePosition, SquadVideoContext } from './context';
import PulseLogoAnimated from '../../components/spinners/pulse-logo-animated/pulse-logo-animated';
import { SourceLocation, TimelineModel } from '../../../domain/models/event-timeline-model';
import { StreamModel } from '../../../domain/models/stream-model';
import EventNotFound from '../../components/event-not-found/event-not-found';
import MobileGridEvent from './components/mobile-grid-event/mobile-grid-event';
import { EventModel } from '../../../domain/models/event-model';
import useGlobalStore from '../../app-global-store';
import useWindowInnerHeight from '../../hooks/use-window-inner-height/use-window-inner-height';

interface IProps {
  addProfile: AddProfile;
  getEvent: GetEventInfo;
  socialSignIn: SocialAuth;
  localStorageAdapter: LocalStorageAdapter;
  userMarketingEventTrack: AnalyticsLogging;
}

let intervalRef: any | null = null;
let realTimeAthletePositionsRef: Map<string, RealTimePosition> = new Map();
const MAP_UPDATE_INTERVAL = 2000;

export default function EventPage({
  addProfile,
  getEvent,
  socialSignIn,
  localStorageAdapter,
  userMarketingEventTrack,
}: IProps) {
  const { eventName } = useParams();
  const windowInnerHeight = useWindowInnerHeight();

  const [searchParams, setSearchParams] = useSearchParams();
  const analytics = useGlobalStore((state) => state.analytics);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isChatVisible, setIsChatVisible] = useState<boolean>(true);
  const [timeline, setTimeline] = useState<TimelineModel[]>([]);
  const [isTimelineLoading, setIsTimelineLoading] = useState<boolean>(false);
  const [eventInfo, setEventInfo] = useState<GetEventInfo.Model | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [streamList, setStreamList] = useState<Map<string, StreamModel> | null>(null);
  const [featuredStream, setFeaturedStream] = useState<Partial<StreamModel> | null>(null);
  const [featuredStreamPlayerRef, setFeaturedStreamPlayerRef] = useState<VideoJsPlayer | null>(null);
  const [streamSourceLocation, setStreamSourceLocation] = useState<SourceLocation[]>([]);

  const [isLiveSelected, setIsLiveSelected] = useState<boolean>(false);
  const [liveStreams, setLiveStreams] = useState<StreamModel[]>([]);

  const [isMapFollowLocked, setIsMapFollowLocked] = useState<boolean>(false);

  const isMobile = useMediaQuery('(max-width: 900px)');

  const [realTimeAthletePositions, setStateRealTimeAthletePositions] = useState<Map<string, RealTimePosition>>(
    new Map(),
  );
  const [athletePositionsOnHover, setAthletePositionsOnHover] = useState<Map<string, RealTimePosition>>(new Map());

  const setRealTimeAthletePositions = (positions: Map<string, RealTimePosition>) => {
    realTimeAthletePositionsRef = positions;
  };

  const startUpdatingAthleteLocations = () => {
    intervalRef = setInterval(() => {
      setStateRealTimeAthletePositions(new Map(realTimeAthletePositionsRef));
    }, MAP_UPDATE_INTERVAL);
  };

  const updateRouteQueryParams = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const getStreamTimeOffset = (foundStreamFromQuery: SourceLocation | undefined, firstStreamOnList: SourceLocation) => {
    const offsetMs = searchParams.get('offset_ms');
    if (!foundStreamFromQuery) return firstStreamOnList.offset_ms;
    if (offsetMs) return Number(offsetMs);
    return foundStreamFromQuery.offset_ms >= 0 ? foundStreamFromQuery.offset_ms : firstStreamOnList.offset_ms;
  };

  const getStreamFromQueryParamOrFromFirstStreamOnList = (
    streamIdFromQueryString: string | undefined | null,
    streamIdFromFirstPositionOnList: string,
    streamListHash: Map<string, StreamModel>,
  ) => {
    const streamFromQueryString = streamListHash.get(streamIdFromQueryString || '');
    const streamFromFirstOnLIst = streamListHash.get(streamIdFromFirstPositionOnList);

    return streamFromQueryString || streamFromFirstOnLIst || {};
  };

  const initializeFeaturedStream = (
    streamLocations: SourceLocation[],
    streamListHash: Map<string, StreamModel> | null,
    streamIdFromQueryParam?: string | null,
  ) => {
    if (!streamLocations.length || !streamListHash) return;
    const firstStreamOnList = streamLocations[0];
    const streamId = streamIdFromQueryParam || firstStreamOnList.stream_id;
    const foundStreamFromQuery = streamLocations.find(
      (streamLocation) => streamLocation.stream_id === streamIdFromQueryParam,
    );
    const streamOffset = getStreamTimeOffset(foundStreamFromQuery, firstStreamOnList);
    const streamFromHash = getStreamFromQueryParamOrFromFirstStreamOnList(
      streamIdFromQueryParam,
      firstStreamOnList.stream_id,
      streamListHash,
    );
    const featuredStreamObj = {
      ...streamFromHash,
      offset_ms: streamOffset,
    };
    updateRouteQueryParams('stream_id', streamId);
    setFeaturedStream(featuredStreamObj);
  };

  const initializeSelectedTime = (timelineFromApi: TimelineModel[]) => {
    if (!timelineFromApi.length) return;
    if (!timelineFromApi[0].stream_source_locations.length) return;
    const dateFromQueryParam: string | null = searchParams.get('stream_date');
    const initialSelectedTime =
      timelineFromApi.find((timeItem) => removeTimeZoneFromTimeStamp(timeItem.time) === dateFromQueryParam) ||
      timelineFromApi[0];
    updateRouteQueryParams('stream_date', removeTimeZoneFromTimeStamp(initialSelectedTime.time));
    setStreamSourceLocation(initialSelectedTime.stream_source_locations);
  };

  const populateStreamListHashMapState = (streams: StreamModel[]) => {
    const streamHash = new Map();
    streams.map((stream) => streamHash.set(stream.id, stream));
    setStreamList(streamHash);
    return streamHash;
  };

  const getEventTimeLine = async (id: string, day: string) => {
    const timelineFromApi = await getEvent.timeline(id, day);
    return { streams: timelineFromApi.streams, timeline: timelineFromApi.timeline.reverse() };
  };

  const onSelectedDateChanges = () => {
    if (!selectedDate || !eventInfo) return;
    setIsTimelineLoading(true);
    const formatedDateToSendToApi = dayjs(selectedDate).format('YYYY-MM-DD');
    getEventTimeLine(eventInfo?.id, formatedDateToSendToApi || eventInfo?.end_time)
      .then(({ timeline: timelineFromApi, streams }) => {
        if (!timelineFromApi.length || !streams.length) return setIsTimelineLoading(false);
        setIsLiveSelected(false);
        setTimeline(timelineFromApi);
        const streamListHash = populateStreamListHashMapState(streams);
        initializeSelectedTime(timelineFromApi);
        const streamLocations = timelineFromApi[0].stream_source_locations;
        initializeFeaturedStream(streamLocations, streamListHash);
        setIsTimelineLoading(false);
      })
      .catch(() => {
        setIsTimelineLoading(false);
      });
  };
  useEffect(onSelectedDateChanges, [selectedDate]);

  const onSelectedTimeChanges = () => {
    const foundTime = timeline.find((value) => removeTimeZoneFromTimeStamp(value.time) === selectedTime);
    if (!foundTime) return;
    updateRouteQueryParams('stream_date', removeTimeZoneFromTimeStamp(foundTime.time));
    setIsLiveSelected(false);
    setStreamSourceLocation(foundTime.stream_source_locations);
    initializeFeaturedStream(foundTime.stream_source_locations, streamList);
  };
  useEffect(onSelectedTimeChanges, [selectedTime]);

  const initializeLiveOrFeatStream = (
    streams: StreamModel[],
    listOfStreamsFromSelectedTime: SourceLocation[],
    p_liveStreams: StreamModel[],
    isFromQueryString: boolean,
  ) => {
    const streamListHash = populateStreamListHashMapState(streams);
    const streamIdFromQueryParam: string | null = searchParams.get('stream_id');
    if (p_liveStreams.length && !isFromQueryString) {
      setFeaturedStream(p_liveStreams[0]);
    } else {
      initializeFeaturedStream(listOfStreamsFromSelectedTime, streamListHash, streamIdFromQueryParam);
    }
    setLiveStreams(p_liveStreams);
  };

  const openLiveStream = () => {
    userMarketingEventTrack.track('Click on go back to live');
    realTimeAthletePositions.clear();
    setRealTimeAthletePositions(realTimeAthletePositions);
    setIsLiveSelected(true);
  };

  const onIsLive = () => {
    if (!isLiveSelected) return;
    setFeaturedStream(liveStreams[0]);
  };
  useEffect(onIsLive, [isLiveSelected]);

  const getInitialStreamListFromSelectedDate = (
    timelineFromApi: TimelineModel[],
  ): { listOfStreamsFromSelectedTime: SourceLocation[]; isFromQueryString: boolean } => {
    if (!timelineFromApi.length) return { listOfStreamsFromSelectedTime: [], isFromQueryString: false };
    const dateFromQueryParam: string | null = searchParams.get('stream_date');
    const foundStream = timelineFromApi.find(
      (timeItem) => removeTimeZoneFromTimeStamp(timeItem.time) === dateFromQueryParam,
    );
    const initialSelectedTime = foundStream || timelineFromApi[0] || [];
    return {
      listOfStreamsFromSelectedTime: initialSelectedTime.stream_source_locations || [],
      isFromQueryString: !!foundStream,
    };
  };

  const initializeTimeLine = async (event: EventModel, liveStreamsFromApi: GetEventInfo.StreamList) => {
    // Initialize timeline
    const todayDate = dayjs().format().substring(0, 10);
    const eventEndDate = event.end_time?.substring(0, 10);

    const lastStreamDate = event.timeline_updated_at?.substring(0, 10);
    const firstStreamDate = event.timeline_started_at?.substring(0, 10);
    const lastRecordedStreamDate = lastStreamDate || todayDate || eventEndDate;
    // Data from route query param
    const dateFromQueryParam = searchParams.get('stream_date');
    let dateToQueryOnBackend = lastRecordedStreamDate;

    if (isDateBelongsToDateInterval(dateFromQueryParam, firstStreamDate, lastRecordedStreamDate)) {
      if (!dateFromQueryParam) return;
      dateToQueryOnBackend = dateFromQueryParam;
    }

    const { timeline: timelineFromApi, streams } = await getEventTimeLine(event.id, dateToQueryOnBackend);
    setTimeline(timelineFromApi);
    const { listOfStreamsFromSelectedTime, isFromQueryString } = getInitialStreamListFromSelectedDate(timelineFromApi);
    setStreamSourceLocation(listOfStreamsFromSelectedTime);
    initializeLiveOrFeatStream(streams, listOfStreamsFromSelectedTime, liveStreamsFromApi, isFromQueryString);
  };

  const getEventInfo = async () => {
    try {
      setIsLoading(true);
      const event = await getEvent.get(eventName || import.meta.env.VITE_EVENT_ID);
      setEventInfo(event);

      const liveStreamsFromApi = await getEvent.listStreams(event?.id);

      document.title = `Pulse | ${event?.title}`;
      setIsLiveSelected(!!liveStreamsFromApi.length);

      await initializeTimeLine(event, liveStreamsFromApi);

      setIsLoading(false);
    } catch (e) {
      setEventInfo(null);
      setIsLoading(false);
    }
  };

  const createProfile = async () => {
    try {
      const firebaseUser: AuthFireBaseUser = localStorageAdapter.get('fireBaseUser') as unknown as AuthFireBaseUser;
      const profileCreated = await addProfile.add({
        bio: '',
        display_name: firebaseUser.displayName,
        profile_photo: firebaseUser?.providerData[0]?.photoURL,
        username: createUserName(firebaseUser.email),
      });
      localStorageAdapter.set('profile', profileCreated);
      userMarketingEventTrack.identify(profileCreated);
      analytics.identify(profileCreated);
    } catch (error) {
      const firebaseUser: AuthFireBaseUser = localStorageAdapter.get('fireBaseUser') as unknown as AuthFireBaseUser;
      analytics.track('error_creating_new_profile', { firebaseUser, error });
    }
  };

  const toggleChatView = () => {
    setIsChatVisible(false);
    setTimeout(() => setIsChatVisible(true), 1000);
  };

  const onFirebaseAuthSignIn = async () => {
    await createProfile();
    toggleChatView();
  };

  const clearIntervalRef = () => {
    if (intervalRef) {
      clearInterval(intervalRef);
      intervalRef = null;
    }
  };

  const onMount = () => {
    getEventInfo();
    startUpdatingAthleteLocations();

    return () => {
      clearIntervalRef();
    };
  };
  useEffect(onMount, []);

  const sharedValues: ISquadVideoContext = useMemo(
    () => ({
      isMobile,
      onFirebaseAuthSignIn,
      socialSignIn,
      localStorageAdapter,
      eventInfo,
      userMarketingEventTrack,
      isChatVisible,
      timeline,
      isTimelineLoading,
      streamList,
      setSelectedDate,
      streamSourceLocation,
      selectedDate,
      selectedTime,
      setSelectedTime,
      realTimeAthletePositions,
      setRealTimeAthletePositions,
      setStateRealTimeAthletePositions,
      athletePositionsOnHover,
      setAthletePositionsOnHover,
      featuredStream,
      setFeaturedStream,
      getEvent,
      isLiveSelected,
      setIsLiveSelected,
      liveStreams,
      setLiveStreams,
      openLiveStream,
      isMapFollowLocked,
      setIsMapFollowLocked,
      featuredStreamPlayerRef,
      setFeaturedStreamPlayerRef,
    }),
    [
      onFirebaseAuthSignIn,
      socialSignIn,
      localStorageAdapter,
      eventInfo,
      userMarketingEventTrack,
      isChatVisible,
      timeline,
      isTimelineLoading,
      setSelectedDate,
      streamSourceLocation,
      selectedDate,
      selectedTime,
      setSelectedTime,
      realTimeAthletePositions,
      realTimeAthletePositionsRef,
      setRealTimeAthletePositions,
      setStateRealTimeAthletePositions,
      athletePositionsOnHover,
      setAthletePositionsOnHover,
      featuredStream,
      setFeaturedStream,
      getEvent,
      isLiveSelected,
      setIsLiveSelected,
      liveStreams,
      setLiveStreams,
      openLiveStream,
      isMapFollowLocked,
      setIsMapFollowLocked,
      featuredStreamPlayerRef,
      setFeaturedStreamPlayerRef,
    ],
  );

  if (isLoading) return <PulseLogoAnimated />;
  if (!eventInfo) return <EventNotFound />;

  return (
    <SquadVideoContext.Provider value={sharedValues}>
      <Flex
        css={{
          width: '100%',
          height: windowInnerHeight,
          flexDirection: 'column',
          backgroundColor: '$bgAppScreen300',
          overflow: 'hidden',
        }}
      >
        <TopHeader />
        {!isMobile && <GridEvent />}
        {isMobile && <MobileGridEvent />}
      </Flex>
    </SquadVideoContext.Provider>
  );
}
