import * as React from 'react';
import { styled } from '../../../stitches.config';

export type ButtonProps = React.ComponentPropsWithRef<typeof Button>;

const Button = styled('button', {
  touchAction: 'manipulation',
  appearance: 'button',
  fontWeight: '$xl',
  fontSize: '$buttonSmall',
  textRendering: 'geometricPrecision',
  cursor: 'pointer',
  transition: 'all .5s ease',
  border: 'none',
  userSelect: 'none',
  textDecoration: 'none',
  outline: 'none',
  borderRadius: '$xl',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  WebkitTapHighlightColor: '$colors$transparent',
  '&:active': {
    transform: 'scale(.98)',
  },
  '@hover': {
    '&:hover': {
      transform: 'scale(.96)',
    },
  },

  '&:focus': {
    boxShadow: '$focusRing',
  },
  variants: {
    variant: {
      default: {
        color: '$gs100',
        background: '$primary500',
        borderRadius: '$lgg',
        fontSize: '$body',
        fontWeight: '$demi',
        '@hover': {
          '&:hover, &:active': {
            background: '$primary400',
          },
        },

        '&:focus': {
          boxShadow: '$primary400',
        },
      },
      transparent: {
        background: 'transparent',
        borderRadius: '$lg',
        color: '$primary500',
        fontWeight: '$demi',
        '@hover': {
          '&:hover, &:active': {
            background: 'rgba(218, 255, 1, 0.1)',
          },
        },

        '&:focus': {
          boxShadow: '$primary400',
        },
      },
      transparentNoHover: {
        background: 'transparent',
        borderRadius: '$lg',
        color: '$primary500',
        fontWeight: '$demi',
        '@hover': {
          '&:hover, &:active': {
            background: 'none',
          },
        },

        '&:focus': {
          boxShadow: '$primary400',
        },
      },
      transparentActive: {
        background: 'rgba(218, 255, 1, 0.1)',
        borderRadius: '$lg',
        color: '$primary500',
        fontWeight: '$demi',
        '@hover': {
          '&:hover, &:active': {
            background: 'rgba(218, 255, 1, 0.1)',
          },
        },

        '&:focus': {
          boxShadow: '$primary400',
        },
      },
      outline: {
        border: '1px solid $gs500',
        borderRadius: '$lgg',
        display: 'flex',
        backgroundColor: '$constantWhite',
        width: '100%',
        alignItems: 'center',
        position: 'relative',
      },
      realOutline: {
        border: '2px solid rgba(255,255,255, 0.6)',
        color: 'rgba(255,255,255, 0.6)',
        borderRadius: '$lgg',
        display: 'flex',
        backgroundColor: 'transparent',
        width: '100%',
        alignItems: 'center',
        position: 'relative',
      },
    },
    size: {
      xsm: {
        fontSize: '$sm',
        lineHeight: '$sm',
        height: '1.87rem', // 30px
      },
      sm: {
        fontSize: '$sm',
        lineHeight: '$sm',
        padding: '0.25rem 0.81rem',
        height: '2.5rem',
      },
      md: {
        fontSize: '$md',
        lineHeight: '$md',
        padding: '0.5rem 1rem',
        height: '3rem',
      },
      xl: {
        fontSize: '$md',
        lineHeight: '$md',
        padding: '0.5rem 1rem',
        height: '3.5rem',
      },
    },
    width: {
      full: {
        minWidth: '100%',
      },
      auto: {
        minWidth: '10.5rem',
      },
      init: {
        minWidth: 'auto',
      },
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'md',
    width: 'init',
  },
});

export default Button;
