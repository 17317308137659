/* eslint-disable no-promise-executor-return */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useRef, useState } from 'react';

import Flex from '../../../../components/primitives/flex';
import { styled } from '../../../../stitches.config';
import useEventStore from '../../state-manager/store';
import { calculateStreamProgress, isLive } from '../../utils/utils';
import { EventStreamOrImageThumbnail } from '../event-stream-or-image-thumbnail/event-stream-or-image-thumbnail';
import { NewEventStream } from '../../state-manager/store-types';
// import { EventChannelsAvatarList } from '../event-channels-avatar-list/event-channels-avatar-list';

const Button = styled('button', {});

export function EventThumbnailList({ isDragging }: { isDragging: boolean }) {
  const currentEventStreamsLinearList = useEventStore((state) => [...state.currentEventStreamsLinearList])?.sort(
    (a, b) => {
      if (b?.official === a?.official) return 0; // they're equal, return 0
      return b?.official ? 1 : -1; // if b is true, it will be placed before a
    },
  );
  // const setRightSideStream = useEventStore((state) => state.setRightSideStream);

  const setFeatStream = useEventStore((state) => state.setFeatStream);
  const featStream = useEventStore((state) => state.featStream);
  const currentTime = useEventStore((state) => state.currentTime);
  const setCurrentTime = useEventStore((state) => state.setCurrentTime);

  const selectFeatStream = async (stream: NewEventStream) => {
    setCurrentTime(currentTime);
    // if (stream.producer) {
    //   return setRightSideStream(stream);
    // }
    setFeatStream(stream);
  };

  return (
    <Flex className="event-avatar-list" css={{ flexDirection: 'column', gap: '$xs' }}>
      {currentTime &&
        currentEventStreamsLinearList.map((stream) => {
          const { id, title, avatarPicture, stream_start_date_ms, stream_end_date_ms } = stream;
          const { id: selectedStreamId } = featStream || {};

          return (
            <Button
              onClick={() => {
                if (!isDragging) selectFeatStream(stream);
              }}
              key={id}
            >
              <EventStreamOrImageThumbnail
                avatarImgUrl={avatarPicture}
                imageUrl={stream.thumbnail}
                // imageUrl={`${stream_tile_url}?timestamp=${currentTime?.getTime()}&start_time=${stream_start_date_ms}`}
                username={stream.channel.username || ''}
                isLive={isLive(stream.status)}
                title={stream.channel.title || stream.title}
                progress={calculateStreamProgress(
                  stream_start_date_ms,
                  stream_end_date_ms,
                  currentTime?.getTime() || 0,
                )}
                stream={stream}
                isSelected={selectedStreamId === id}
                css={{ width: '10rem', height: '7rem' }}
              />
            </Button>
          );
        })}
    </Flex>
  );
}

export function EventLeftContainer() {
  const [isDragging, setIsDragging] = useState(false);
  const dragStartY = useRef(0);
  const leftContainerRef = useRef<HTMLDivElement | null>(null);

  const handleDragStart = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    dragStartY.current = event.clientY;
  };

  const handleDrag = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging && leftContainerRef.current) {
      const deltaY = event.clientY - dragStartY.current;
      const container = leftContainerRef.current;
      container.scrollTop -= deltaY;
      dragStartY.current = event.clientY;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (leftContainerRef.current && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
      const scrollAmount = event.key === 'ArrowUp' ? -30 : 30;
      leftContainerRef.current.scrollTop += scrollAmount;
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    window.addEventListener('mouseup', handleDragEnd);

    return () => {
      window.removeEventListener('mouseup', handleDragEnd);
    };
  }, []);

  return (
    <Flex
      className="left-container"
      tabIndex={0}
      css={{
        zIndex: 10,
        position: 'absolute',
        left: '0rem',
        top: '3rem',
        bottom: '6.4rem',

        width: '12rem',
        height: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        flexDirection: 'column',
      }}
      role="complementary"
      aria-label="Channels List"
    >
      <Flex
        className="left-container-scrollable-part"
        ref={leftContainerRef}
        onMouseDown={handleDragStart}
        onMouseMove={handleDrag}
        onMouseUp={handleDragEnd}
        onKeyDown={handleKeyDown}
        css={{
          flexDirection: 'column',
          padding: '0.8rem 0rem 0.8rem 1rem',
          overflowY: 'auto',
          width: '100%',
          height: '100%',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Flex className="thumbnail-list-wrapper">
          {/* <EventChannelsAvatarList /> */}
          <EventThumbnailList isDragging={isDragging} />
        </Flex>
      </Flex>
    </Flex>
  );
}
