export default function ArrowLinkIcon() {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9999 3.9L2.1249 12.8C1.9249 13 1.68724 13.1 1.4119 13.1C1.13724 13.1 0.899902 13 0.699902 12.8C0.499902 12.6 0.399902 12.3623 0.399902 12.087C0.399902 11.8123 0.499902 11.575 0.699902 11.375L9.5999 2.5H1.9999C1.71657 2.5 1.4789 2.40433 1.2869 2.213C1.09557 2.021 0.999902 1.78333 0.999902 1.5C0.999902 1.21667 1.09557 0.979 1.2869 0.787C1.4789 0.595667 1.71657 0.5 1.9999 0.5H11.9999C12.2832 0.5 12.5206 0.595667 12.7119 0.787C12.9039 0.979 12.9999 1.21667 12.9999 1.5V11.5C12.9999 11.7833 12.9039 12.0207 12.7119 12.212C12.5206 12.404 12.2832 12.5 11.9999 12.5C11.7166 12.5 11.4792 12.404 11.2879 12.212C11.0959 12.0207 10.9999 11.7833 10.9999 11.5V3.9Z"
        fill="#DAFF01"
      />
    </svg>
  );
}
